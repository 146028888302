import * as Sentry from "@sentry/react";

/* eslint-disable no-use-before-define */
import util from "utils/utils";
import actions from "actions";
import { store } from "store";
import { auth } from "./auth";
import { search } from "./search";
import { challenges } from "./challenges";
import { ideas } from "./ideas";
import { groups } from "./groups";
import { uploads } from "./uploads";
import { users } from "./users";
import { organisations } from "./organisations";
import { messages } from "./messages";
import { notifications } from "./notifications";
import { polls } from "./polls";
import { tags } from "./tags";
import { webhooks } from "./webhooks";
import { announcements } from "./announcements";
import { invitations } from "./invitations";
import { verifications } from "./verifications";
import { superadmin } from "./superadmin";
import { boards } from "./boards";
import { innovationIntelligence } from "./innovationIntelligence";
import { journey } from "./journey";
import { roles } from "./roles";
import { persistentTokens } from "./persistentTokens";
import { connections } from "./connections";
import { pages } from "./pages";
import { QueryClient } from "@tanstack/react-query";

type IAPIReq = (
  method: string,
  path: string,
  data: object | null,
  success: ((payload: typeof JSON.parse) => void) | null,
  failure: ((payload: { status: number; message: string; errorCode?: string }) => void) | null,
  withAuth?: boolean,
) => void;

const APIModules = {
  auth,
  search,
  challenges,
  ideas,
  groups,
  uploads,
  users,
  organisations,
  messages,
  notifications,
  polls,
  tags,
  webhooks,
  announcements,
  invitations,
  verifications,
  superadmin,
  boards,
  innovationIntelligence,
  journey,
  roles,
  persistentTokens,
  connections,
  pages,
};

type IAPIModules = {
  [apiFunction in keyof typeof APIModules]: ReturnType<(typeof APIModules)[apiFunction]>;
};

export type IAPIFuncs = {
  token?: string;
  revertToken?: string;
  webVersion?: string;
  queryClient: QueryClient;
  store?: typeof store;
  req: IAPIReq;
  authenticatedRequest: IAPIReq;
  unauthenticatedRequest: IAPIReq;
  maybeAuthenticatedRequest: IAPIReq;
};

export type IAPI = IAPIFuncs & IAPIModules;

// Type helper that extract the parameters of an API function since they are always structured [...parameters, successCallback, failCallback]
// Therefore this extrats those last two callbacks which is useful to not only destructure the params, but also gives us access to the success callback return type
type APIParams<T extends (...args: any[]) => any> = T extends (...args: infer P) => any
  ? P extends [...infer Params, infer SuccessCallback, infer FailCallback]
    ? [Params, SuccessCallback, FailCallback]
    : never
  : never;
// Type helper that extracts the return type of the success callback of any API function
export type Response<T extends (...args: any[]) => any> = Parameters<APIParams<T>[1]>[0];

// asQuery can be used for easier compatibility between the simplydo codebase and the react-query library, for the most part this just transforms the callback pattern to a promise. Under the hood we also make some typescript inference to make sure useQuery can infer the correct parameters and return type of the queryFn
// Generally just wrapping any API function with asQuery will make it compatible with react-query's useQuery hook as the value for the queryFn
// This will only work for api functions that end with the typical success and fail callback pattern (which should be all of them!)
export const asQuery = <
  T extends (...args: any[]) => any,
  APIParam extends APIParams<T>,
  TBody extends Parameters<APIParam[1]>[0],
>(
  fn: T,
  ...params: APIParam[0] extends { 0: any }
    ? [{ params: APIParam[0]; onSuccess?: () => void; onError?: () => void }]
    : [] | [{ params?: []; onSuccess?: () => void; onError?: () => void }]
): (<T extends TBody>() => Promise<T>) => {
  return async () =>
    await new Promise((resolve, reject) => {
      fn(
        ...(params[0]?.params ?? []),
        (response) => {
          params[0]?.onSuccess?.();
          resolve(response);
        },
        (error) => {
          params[0]?.onError?.();
          reject(error);
        },
      );
    });
};

// This is conceptually the same as asQuery, but for mutations instead of queries
// Instead of having to supply parameters directly to the definition, you supply them to the .mutate function call later.
type MutationFunction<TData, TVariables> = (variables: TVariables) => Promise<TData>;
export const asMutation = <
  T extends (...args: any[]) => any,
  APIParam extends APIParams<T>,
  TBody extends Parameters<APIParam[1]>[0],
  TParams extends APIParam[0] extends { 0: any } ? { params: APIParam[0] } : never,
>(
  fn: T,
): MutationFunction<TBody, TParams> => {
  return async (vars: TParams) =>
    await new Promise((resolve, reject) => {
      fn(
        ...(vars?.params ?? []),
        (response: TBody) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        },
      );
    });
};

export const api: IAPI = {
  token: null,
  revertToken: null,
  webVersion: null,
  queryClient: new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 60000,
      },
    },
  }),

  req(method, path, data, success, fail, withAuth) {
    const now = new Date();
    const { user } = store.getState();
    if (
      user &&
      user?._id &&
      util.localStorageIsSupported() &&
      now.getTime() - new Date(localStorage.getItem("heartbeat")).getTime() > 3600000
    ) {
      localStorage.setItem("heartbeat", now.toString());
      this.users.heartbeat(
        user._id,
        () => {},
        () => {},
      );
    }
    const xhr = new XMLHttpRequest();
    xhr.open(method, `${import.meta.env.VITE_API_URL}${path}`);
    xhr.setRequestHeader("Content-Type", "application/json");
    if (withAuth && api.token) {
      xhr.setRequestHeader("Authorization", `Bearer ${api.token}`);
    }
    xhr.setRequestHeader("SimplyDo-Device", navigator.userAgent);
    xhr.setRequestHeader("SimplyDo-ClientVersion", import.meta.env.VITE_GIT_SHA);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let response;
          api.webVersion = xhr.getResponseHeader("SimplyDo-WebVersion");
          try {
            response = JSON.parse(xhr.responseText);
          } catch (err) {
            Sentry.captureException(err, { extra: { originalResponse: xhr.responseText } });
            console.error(err);
            if (fail) fail({ status: 400, message: "There was a problem with this request" });
            return;
          }
          if (success) success(response);
        } else {
          // 530 and 401 are special cases that are handled differently
          if (xhr.status === 530) {
            store.dispatch(
              actions.auth.ipNotAllowed({ status: xhr.status, message: "Access from this IP address is not allowed" }),
            );
            if (fail) {
              fail({ status: xhr.status, message: "Access from this IP address is not allowed" });
            }
          } else if (xhr.status === 401 && api.token) {
            this.auth.localLogout(() => {
              store.dispatch(actions.auth.getUserFail({ status: xhr.status, message: "Failed to authenticate" }));
              if (fail) {
                // If we immediately invoke the fail callback after logging out here, the user and auth reducers in localLogout will cause an immediate re-render in the direct component that just made the request because typically we will set something like `setLoading(false)` even if a request fails.
                // This immediate re-render will already load the `null` value that is now stored in the user reducer. However, in many cases such a component might crash in that instance because of hard accesses on a null user object which technically should always exist at that part of the navigation tree.
                // By moving the fail callback off the main thread, we ensure that all components that are subscribed to the user reducer render within the same tick, this means that the rest of the react application re-renders at the same time as the component that started the request.
                // Since any component that always expects the user object to exist will always be mounted in a part of the navigation tree that is excluded from the main navigation tree if the user object is null, we can be sure that the user object will always be available when the component renders. This fully avoids the previously mentioned crash in the requesting component.
                setTimeout(() => {
                  fail({ status: xhr.status, message: "Failed to authenticate" });
                }, 0);
              }
            });
          } else {
            try {
              const errorPayload = JSON.parse(xhr.responseText);

              // If the user is logged in, and gets a response saying their email isn't verified
              // Then we know email verification has been turned on since they attempted login
              // If so, we restrict app access and show them the verify email screen
              if (user && errorPayload.code === "emailVerificationRequired") {
                store.dispatch(actions.user.updateOrganisation("emailValidationEnforcing", true));
              }

              if (fail) {
                fail({ status: xhr.status, message: errorPayload.message, errorCode: errorPayload.errorCode });
              }
            } catch (err) {
              if (fail) {
                fail({ status: xhr.status, message: "There was a problem with this request" });
              }
            }
          }
        }
      }
    };
    xhr.send(data && JSON.stringify(data));
  },

  unauthenticatedRequest(method, path, data, success, fail) {
    api.req(method, path, data, success, fail);
  },

  authenticatedRequest(method, path, data, success, fail) {
    if (!api.token) return;
    api.req(method, path, data, success, fail, true);
  },

  maybeAuthenticatedRequest(method, path, data, success, fail) {
    if (api.token) api.authenticatedRequest(method, path, data, success, fail);
    else api.unauthenticatedRequest(method, path, data, success, fail);
  },
} as IAPI;

api.journey = journey(api);
api.auth = auth(api);
api.search = search(api);
api.superadmin = superadmin(api);
api.challenges = challenges(api);
api.ideas = ideas(api);
api.groups = groups(api);
api.uploads = uploads(api);
api.users = users(api);
api.organisations = organisations(api);
api.messages = messages(api);
api.notifications = notifications(api);
api.polls = polls(api);
api.tags = tags(api);
api.webhooks = webhooks(api);
api.announcements = announcements(api);
api.invitations = invitations(api);
api.verifications = verifications(api);
api.boards = boards(api);
api.innovationIntelligence = innovationIntelligence(api);
api.roles = roles(api);
api.persistentTokens = persistentTokens(api);
api.connections = connections(api);
api.pages = pages(api);

export default api;
