import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import cy from "./cy.json";
import { getIndefiniteArticle } from "./indefiniteArticle";

export const languages = [
  { key: "en", name: "English" },
  { key: "cy", name: "Cymraeg" },
];

const common = {
  uppercase: "$t({{key}}, uppercase)",
  lowercase: "$t({{key}}, capitalise)",
  capitalise: "$t({{key}}, capitalise)",
};

const defaultOptions = {
  resources: {
    cy: { translation: cy, common },
    en: { translation: en, common },
  },
  lng: "en",
  keySeparator: false as const,
  fallbackLng: {
    default: ["en"],
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18nStore: "added",
  },
};

i18n.use(initReactI18next).init(defaultOptions);

i18n.services.formatter.add("injectDefault", (value, lng, options) => {
  const interpolation = options[options.interpolationkey] ?? options.interpolationkey;
  if (interpolation) {
    return i18n.getResource(lng, options.ns, options[options.interpolationkey] || options.interpolationkey) || value;
  }
  return value;
});

i18n.services.formatter.add("uppercase", (value) => {
  return value.toUpperCase();
});

i18n.services.formatter.add("lowercase", (value) => {
  return value.toLowerCase();
});

i18n.services.formatter.add("capitalise", (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

type Terminology = {
  [key: (typeof languages)[number]["key"]]: {
    challenge?: string;
    challenges?: string;
    idea?: string;
    ideas?: string;
  };
};

export const setInterpolationDictionary = (terminology: Terminology = {}) => {
  Object.entries(terminology ?? {}).forEach(([lang, terms]) => {
    const interpolationVariables = terms ?? {};

    const challengeOne =
      interpolationVariables.challenge || i18n.getResource(lang, "translation", "generic.challengeDefault");
    const challengeOther =
      interpolationVariables.challenges || i18n.getResource(lang, "translation", "generic.challengesDefault");
    const challengeArticle = getIndefiniteArticle(challengeOne);

    const ideaOne = interpolationVariables.idea || i18n.getResource(lang, "translation", "generic.ideaDefault");
    const ideaOther = interpolationVariables.ideas || i18n.getResource(lang, "translation", "generic.ideasDefault");
    const ideaArticle = getIndefiniteArticle(ideaOne);

    const resources = {
      "generic.challenge": challengeOne,
      "generic.challenges": challengeOther,
      "generic.challengeArticle": challengeArticle,
      "generic.idea": ideaOne,
      "generic.ideas": ideaOther,
      "generic.ideaArticle": ideaArticle,
    };

    i18n.addResourceBundle(lang, "translation", resources, false, true);
  });
};

export default i18n;
