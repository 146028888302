import React, { useCallback } from "react";
import { HoverAction } from "components/lib/HoverActions";
import { IdeaLikeHandler } from "components/lib/Ideas";
import api from "api";
import toast from "react-hot-toast";
import { useAppDispatch } from "store";
import { useTranslation } from "react-i18next";
import { OpenAPI } from "simplydo/interfaces";
import actions from "actions";

type LikeActionProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
  onUpdateIdea: (idea: Partial<OpenAPI.GET<"/ideas/{id}">["response"]>) => void;
};

const LikeAction = ({ idea, onUpdateIdea }: LikeActionProps) => {
  const { t } = useTranslation();
  const ideaId = idea?._id;
  const dispatch = useAppDispatch();

  const onFollow = useCallback(
    (context, id) => {
      dispatch(actions.user.onFollow(context, id));
    },
    [dispatch],
  );

  const voteForIdea = useCallback(() => {
    api.ideas.vote(
      ideaId,
      !idea?.votedFor,
      ({ addedVotesFor, removedVotesFor }) => {
        const newIdea = { ...idea };
        if (!newIdea.voteCount) newIdea.voteCount = 0;
        if (addedVotesFor.indexOf(idea._id) > -1) {
          onFollow("challenge", idea.challenge);
          newIdea.votedFor = true;
          newIdea.voteCount += 1;
        }
        if (removedVotesFor.indexOf(idea._id) > -1) {
          newIdea.votedFor = false;
          newIdea.voteCount -= 1;
        }
        onUpdateIdea({ voteCount: newIdea.voteCount, votedFor: newIdea.votedFor });
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [ideaId, idea, onUpdateIdea, onFollow]);

  if (idea.ownerChallenge && idea.ownerChallenge.votingVisibility !== "users") {
    return null;
  }

  return (
    <IdeaLikeHandler
      idea={idea}
      challenge={idea.ownerChallenge}
      onLikeIdea={() => voteForIdea()}
      trigger={
        <HoverAction icon="thumbs up">
          {idea?.votedFor
            ? `Un-like ${t("generic.idea")} ${idea.ownerChallenge?.voteCountVisibility === "users" ? `(${idea.voteCount || 0})` : ""}`
            : `Like ${t("generic.idea")} ${idea.ownerChallenge?.voteCountVisibility === "users" ? `(${idea.voteCount || 0})` : ""}`}
        </HoverAction>
      }
    />
  );
};

export default LikeAction;
