import React, { useState, useCallback, useEffect, useContext, useMemo, useRef } from "react";
import { Button, Message, Container, Label, Popup } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import api from "api";
import util from "utils/utils";
import styled, { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
import SearchParams from "simplydo/src/searchParams";
import moment from "moment";
import { LaneInfo } from "components/ideas/Header";

import SlidingModal from "components/lib/SlidingModal";
import { UserChip, IdeaBusinessProfileChip } from "components/lib/Chips";
import { PlaceholderItem, PlaceholderStack } from "components/lib/UI";
import { Emoji } from "emoji-mart";
import { HoverActions, HoverAction } from "components/lib/HoverActions";
import IdeaBody from "components/ideas/Body";
import { useAssessmentEditor } from "components/ideas/hooks";

import ReportContentChip from "components/lib/ReportContent/ReportContentChip";
import IdeaManagement from "./IdeaManagement/IdeaManagement";
import PinAction from "./Actions/PinAction";
import JoinTeamAction from "./Actions/JoinTeamAction";
import FollowAction from "./Actions/FollowAction";
import LikeAction from "./Actions/LikeAction";
import DownloadCoverImageAction from "./Actions/DownloadCoverImageAction";
import LabelAction from "./Actions/LabelAction";
import TagAction from "./Actions/TagAction";
import DownloadPDFAction from "./Actions/DownloadPDFAction";
import AssignAssessorsAction from "./Actions/AssignAssessorsAction";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f2f2f3;
`;

const PreviewHeader = styled.div<{ $coverImage?: string }>`
  width: 100%;
  position: relative;

  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.21);
    z-index: 11;
  }
  .overlay-body {
    position: relative;
    z-index: 12;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  top: 0;
  left: 0;
  ${({ $coverImage }) =>
    $coverImage
      ? `
    background-image: url(${$coverImage});
    background-size:cover;
    background-position: center center;
  `
      : `
    background-color: #e9ebee;
  `}
  border-bottom: 1px solid lightgray;
  z-index: 999;
  min-height: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  .ui.button {
    border: 0.5px solid gray;
  }
  h1 {
    font-size: 2.3em;
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
    line-height: 1.15em;
  }
`;

const IdeaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 13;
  background-color: #f2f2f3;
`;

const BodyContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

const FlexRow = styled.div<{ $wrap?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ $wrap }) =>
    $wrap &&
    `
    flex-wrap: wrap;
    margin-top: -7.5px;
    > * {
      margin-top: 7.5px;
    }
  `}
`;

const IdeaPreview = (props) => {
  const {
    isOpen,

    idea,
    loading,
    challenge: propChallenge,

    closeModal: propCloseModal = () => {},
    setIsClosing = () => {},

    onAssessmentEdited,
    moveIdea,
    canGoNextIdea,
    canGoPrevIdea,
    assessmentEnabled,
    onUpdateIdea,
    onIdeaActivity,
    updateProjectLane,
    lanes,
    removeIdeaFromMerge,
    removingIdeaFromMerge,
    currentIdeaPosition,
    totalIdeas,
    redirectToBoard,
  } = props;
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const previewContainerRef = useRef(null);
  const [modalIdeaWidth, setModalIdeaWidth] = useState(0);

  const sectionRefs = useRef({});

  const setRef = useCallback((ref, itemId) => {
    sectionRefs.current[itemId] = ref;
  }, []);

  const { t } = useTranslation();

  const theme = useContext(ThemeContext);
  const [previewInitialised, setPreviewInitialised] = useState(false);
  const challengeId = idea?.challenge;
  const board = useMemo(
    () => idea?.projectManagement?.boards?.find((b) => b.forId === challengeId),
    [idea, challengeId],
  );
  const ideaId = idea?._id;

  const { assessmentState, updateAssessment, submitAssessment } = useAssessmentEditor(idea, onAssessmentEdited);
  const { assessment, assessmentIsSubmissable } = assessmentState;

  // only show pm board management actions if the user can manage challenge (collaborator/owner/admins) or is assigned to the idea
  const canManageChallenge = util.canManageChallenge(user, propChallenge ?? idea?.ownerChallenge);
  const canViewProjectBoard =
    canManageChallenge ||
    (board?.assignees?.indexOf(user._id) > -1 && idea?.ownerChallenge?.projectManagementVisibility === "users") ||
    util.hasPermission(user, "challenge.viewProjectBoard", challengeId);

  const closeModal = useCallback(() => {
    setIsClosing(true);
    propCloseModal();
  }, [propCloseModal, setIsClosing]);

  const updateQueryParams = useCallback(() => {
    const currQueryParams = new SearchParams(window.location.search);
    const currQueryParamsObject = Object.fromEntries(currQueryParams as any);
    const shouldIncludeIdeaId = !!ideaId;
    const { idea: _, ...otherQueryParams } = currQueryParamsObject;
    const newQueryParams = shouldIncludeIdeaId ? { ...otherQueryParams, idea: ideaId } : otherQueryParams;

    const queryParams = new SearchParams(newQueryParams).toString();
    navigate({ search: `?${queryParams}` }, { replace: true });
  }, [ideaId, navigate]);

  const onOpenPreview = useCallback(() => {
    updateQueryParams();
    setModalIdeaWidth(previewContainerRef.current?.clientWidth ?? 0);
  }, [previewContainerRef, updateQueryParams]);

  // To prevent the preview immediately resetting the query params to nothing, we only set the query parameters once the preview has been open ONCE
  // It's then permanently allowed to be edited
  useEffect(() => {
    if (idea) {
      setPreviewInitialised((prev) => prev || true);
    }
  }, [idea]);

  useEffect(() => {
    if (previewInitialised) {
      updateQueryParams();
    }
  }, [updateQueryParams, previewInitialised]);

  return (
    <SlidingModal
      isOpen={isOpen}
      onOpen={onOpenPreview}
      onClose={() => closeModal()}
      onAfterClose={() => setIsClosing(false)}
      width={theme?.sizes.isComputer ? "60%" : "100%"}
    >
      <ModalContainer>
        {!theme?.sizes.isMobile && idea && !loading ? (
          <HoverActions $width={modalIdeaWidth}>
            <div className="hover-actions-inner">
              {canViewProjectBoard ? (
                <IdeaManagement
                  idea={idea}
                  onUpdateIdea={onUpdateIdea}
                  assessmentEnabled={assessmentEnabled}
                  lanes={lanes}
                  updateProjectLane={updateProjectLane}
                  onIdeaActivity={onIdeaActivity}
                  challenge={propChallenge}
                />
              ) : null}

              <PinAction idea={idea} onUpdateIdea={onUpdateIdea} />
              <JoinTeamAction idea={idea} onUpdateIdea={onUpdateIdea} />
              <FollowAction idea={idea} />
              <LikeAction idea={idea} onUpdateIdea={onUpdateIdea} />
              <DownloadCoverImageAction idea={idea} />
              <LabelAction idea={idea} onUpdateIdea={onUpdateIdea} />
              <LabelAction idea={idea} onUpdateIdea={onUpdateIdea} />
              <TagAction idea={idea} onUpdateIdea={onUpdateIdea} />
              <DownloadPDFAction idea={idea} />
              <AssignAssessorsAction idea={idea} onUpdateIdea={onUpdateIdea} />

              {moveIdea && isOpen ? (
                <>
                  {currentIdeaPosition && totalIdeas && (canGoNextIdea || canGoPrevIdea) && (
                    <HoverAction margined disabled>
                      {t("common:capitalise", { key: "generic.idea" })} {currentIdeaPosition} of {totalIdeas}
                    </HoverAction>
                  )}

                  {canGoPrevIdea ? (
                    <HoverAction
                      icon="arrow left"
                      onClick={() => moveIdea(-1)}
                      margined={!(currentIdeaPosition && totalIdeas)}
                    >
                      Previous {t("generic.idea")}
                    </HoverAction>
                  ) : null}
                  {canGoNextIdea ? (
                    <HoverAction
                      icon="arrow right"
                      onClick={() => moveIdea(1)}
                      margined={!canGoPrevIdea && !(currentIdeaPosition && totalIdeas)}
                    >
                      Next {t("generic.idea")}
                    </HoverAction>
                  ) : null}
                </>
              ) : null}
              <ReportContentChip
                forId={ideaId}
                forType="idea"
                trigger={
                  <HoverAction margined icon="flag">
                    Report
                  </HoverAction>
                }
              />
            </div>
          </HoverActions>
        ) : null}
        <PreviewContainer ref={previewContainerRef}>
          {!loading && !idea ? (
            <Container style={{ height: "100vh" }}>
              <Message error style={{ margin: "2rem 0" }}>
                <Message.Header>Unable to load {t("generic.idea")}</Message.Header>
                <p>Sorry, we were unable to load this {t("generic.idea")}. Please try again later.</p>
              </Message>
              <Button content={`Close`} icon="chevron left" onClick={() => closeModal()} />
            </Container>
          ) : (
            <>
              <PreviewHeader $coverImage={util.ideaCoverImage(idea)}>
                <div className="overlay" />
                <div className="overlay-body">
                  <FlexRow>
                    <div>
                      <Button size="tiny" icon="chevron left" onClick={() => closeModal()} content="Close" />
                    </div>
                    <ModalContainer style={{ justifyContent: "flex-end" }}>
                      {!loading ? (
                        <>
                          <Button
                            primary
                            size="tiny"
                            content={`Open full ${t("generic.idea")}`}
                            icon="file alternate"
                            as={Link}
                            to={`/ideas/${ideaId}`}
                          />
                          {redirectToBoard && (
                            <Button
                              primary
                              size="tiny"
                              content={`View in project board`}
                              icon="file alternate"
                              as={Link}
                              to={`/challenges/${challengeId}/board?idea=${ideaId}`}
                            />
                          )}
                        </>
                      ) : null}
                    </ModalContainer>
                  </FlexRow>
                  <div style={{ paddingLeft: 10 }}>
                    <div style={{ marginTop: 10 }}>
                      {loading ? (
                        <PlaceholderStack gap={20} style={{ width: 400 }}>
                          <PlaceholderItem height={40} style={{ width: 400 }} />
                          <PlaceholderItem height={25} style={{ width: 200 }} />
                          <PlaceholderItem height={30} style={{ width: 200 }} />
                        </PlaceholderStack>
                      ) : (
                        <>
                          <h1>{idea?.name}</h1>
                          <h5 style={{ marginTop: 0, color: "white" }}>
                            <span style={{ textTransform: "uppercase" }}>{t("generic.idea")}</span>
                            {idea.ownerChallenge && (
                              <span style={{ color: "white" }}>
                                {" "}
                                For{" "}
                                <Link style={{ color: "white" }} to={`/challenges/${idea.challenge}`}>
                                  {idea.ownerChallenge?.name}
                                </Link>
                              </span>
                            )}
                          </h5>
                          <div style={{ display: "flex", marginBottom: 25 }}>
                            {board.laneObject?.laneCount > 0 && util.canManageChallenge(user, idea.ownerChallenge) && (
                              <>
                                <LaneInfo board={board} idea={idea} style={{ marginRight: 10 }} />
                                <div style={{ borderLeft: "2px solid white", height: 24, marginRight: 10 }}></div>
                              </>
                            )}

                            {!loading ? (
                              <>
                                {idea.isSubmitted ? (
                                  <Popup
                                    trigger={
                                      <Label style={{ maxHeight: "fit-content" }} color="green">
                                        Submitted
                                      </Label>
                                    }
                                    content={`Submitted ${moment(idea.submittedAt).format("DD/MM/YYYY HH:mm")}`}
                                  />
                                ) : (
                                  <Label style={{ maxHeight: "fit-content" }} color="yellow">
                                    Draft
                                  </Label>
                                )}
                              </>
                            ) : null}
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                              <span style={{ color: "white", marginRight: 5 }}>Created by </span>
                              {idea.lockedOwnerIdeaBusinessProfile ? (
                                <IdeaBusinessProfileChip
                                  ideaBusinessProfile={idea.lockedOwnerIdeaBusinessProfile}
                                  inverted
                                  style={{ marginRight: 15 }}
                                />
                              ) : null}
                              {idea.authors
                                ? idea.authors.map((a, idx) => (
                                    <UserChip
                                      user={a}
                                      key={idx}
                                      inverted
                                      style={{ marginRight: 15, marginBottom: 4 }}
                                    />
                                  ))
                                : null}
                              {idea?.stamps?.length ? (
                                <div style={{ marginTop: 5, display: "flex" }}>
                                  {idea.stamps.map((stamp, i) => (
                                    <Emoji key={i} emoji={{ id: stamp }} size={22} />
                                  ))}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </PreviewHeader>
              <IdeaContainer>
                <BodyContainer>
                  {theme?.sizes.isMobile && canViewProjectBoard ? (
                    <IdeaManagement
                      idea={idea}
                      canManage={canManageChallenge}
                      onUpdateIdea={onUpdateIdea}
                      assessmentEnabled={assessmentEnabled}
                      lanes={lanes}
                      updateProjectLane={updateProjectLane}
                      onIdeaActivity={onIdeaActivity}
                      challenge={propChallenge}
                    />
                  ) : null}
                  <IdeaBody
                    idea={idea ?? {}}
                    setRef={setRef}
                    sectionRefs={sectionRefs}
                    challenge={idea?.ownerChallenge}
                    loading={loading}
                    assessment={assessment}
                    updateAssessment={updateAssessment}
                    submitAssessment={submitAssessment}
                    assessmentIsSubmissable={assessmentIsSubmissable}
                    isPreview
                    isEditing={false}
                    divRef={previewContainerRef}
                    removeIdeaFromMerge={removeIdeaFromMerge}
                    removingIdeaFromMerge={removingIdeaFromMerge}
                  />
                </BodyContainer>
              </IdeaContainer>
            </>
          )}
        </PreviewContainer>
      </ModalContainer>
    </SlidingModal>
  );
};

export const HandledIdeaPreview = ({
  ideaId,
  onUpdateIdea: propUpdateIdea = (_ideaId, _data) => {},
  removeIdeaFromMerge: propRemoveIdeaFromMerge,
  ...rest
}) => {
  const [idea, setIdea] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (ideaId) {
      setLoading(true);
      api.ideas.get(
        ideaId,
        (data) => {
          setIdea(data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
        null,
      );
    } else if (!isClosing) {
      setIdea(null);
    }
  }, [ideaId, isClosing]);

  const onUpdateIdea = useCallback(
    (data) => {
      const updatedIdea = { ...idea, ...data };
      propUpdateIdea(idea._id, data);
      setIdea(updatedIdea);
    },
    [idea, setIdea, propUpdateIdea],
  );

  const removeIdeaFromMerge = useCallback(
    (mergeIdeaId, childIdeaId) => {
      propRemoveIdeaFromMerge(mergeIdeaId, childIdeaId, () => {
        if (mergeIdeaId === ideaId) {
          setIdea((prevIdea) => ({
            ...prevIdea,
            children: prevIdea.children.filter((c) => c !== childIdeaId),
            ownerChildren: prevIdea.ownerChildren.filter((c) => c._id !== childIdeaId),
          }));
        }
      });
    },
    [ideaId, propRemoveIdeaFromMerge],
  );

  return (
    <IdeaPreview
      isOpen={!!ideaId}
      idea={idea}
      onUpdateIdea={onUpdateIdea}
      loading={loading}
      removeIdeaFromMerge={removeIdeaFromMerge}
      isClosing={isClosing}
      setIsClosing={setIsClosing}
      {...rest}
    />
  );
};

export default IdeaPreview;
