import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import styled from "styled-components";

const HIDE_FOOTER_PATHS = ["innovationintelligence"];

const StyledFooter = styled.div`
background: #efefef;
padding: ${({ theme }) => (theme.sizes.isMobile ? "15px 50px 15px 50px;" : "10px 10px 10px 10px;")}
flex: 0 0 auto;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-end;
`;

function Footer() {
  const year = new Date().getFullYear();
  const location = useLocation();
  const ideaViewType = useAppSelector((state) => state.challenges.ideaViewType);

  const hideFooter = useMemo(
    () =>
      HIDE_FOOTER_PATHS.some((path) => location.pathname.includes(path)) ||
      (ideaViewType === "board" && location.pathname.match(/^\/challenges\/[a-f\d]{24}\/ideas$/)),
    [ideaViewType, location.pathname],
  );

  if (hideFooter) {
    return null;
  }
  return (
    <StyledFooter>
      <div>
        <span
          style={{
            display: "block",
            fontSize: 10,
            fontWeight: 300,
            textTransform: "uppercase",
          }}
        >
          Innovation powered by
        </span>
        <span style={{ display: "block", fontSize: 14 }}>
          Simply Do{" "}
          <span style={{ textTransform: "uppercase", fontSize: 8, color: "rgb(170,170,170)" }}>
            Version {import.meta.env.VITE_GIT_SHA}
          </span>
        </span>
        <span style={{ display: "block", fontSize: 10, fontWeight: 300 }}>&copy; {year} Simply Do Ideas Limited</span>
      </div>

      <div>
        <span style={{ fontSize: 10, marginLeft: 10 }}>
          <a href="https://simplydo.co.uk/terms-of-use" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>{" "}
          -{" "}
          <a href="https://simplydo.co.uk/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </span>
      </div>
    </StyledFooter>
  );
}

export default Footer;
