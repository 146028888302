import { Button, Dropdown, Icon, Input, Modal, Popup } from "semantic-ui-react";
import { DateInput } from "components/lib/DateInputs";
import { styled } from "styled-components";
import { useAppSelector } from "store";
import { useCallback, useEffect, useMemo, useState } from "react";
import ProjectComments from "components/lib/ProjectBoard/ProjectComments";
import util from "utils/utils";
import moment from "moment";
import api from "api";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { UserChip } from "components/lib/Chips";
import { UserChooser } from "components/lib/Choosers";
import { ChallengeAudienceWarning } from "components/lib/Choosers/UserChooser/Content/ChallengeAudienceWarning";

const DateInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 33%;

  input {
    width: 100%;
  }
`;

const OpenModalAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: block;
    font-style: italic;
    color: gray;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const ExistingAssignees = styled.div`
  max-width: 100%;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
`;

const PendingInvites = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const PendingInvite = styled.div`
  display: flex;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
  justify-content: space-between;
  align-items: center;
  span {
    display: block;
    margin-right: 10px;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export const OverviewManagement = ({
  idea,
  onUpdateIdea,
  onIdeaActivity,
  lanes,
  currentLane,
  handleUpdateProjectLane,
  canManage,
}) => {
  const [invitationsModalOpen, setInvitationsModalOpen] = useState(false);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [refetchComments, setRefetchComments] = useState(false);
  const [assignmentNote, setAssignmentNote] = useState("");

  const { t } = useTranslation();

  const ideaId = idea._id;
  const challengeId = idea.challenge;
  const board = idea?.projectManagement?.boards?.find((b) => b.forId === idea.challenge);
  const user = useAppSelector((state) => state.user);

  const userIsAssigned = useMemo(
    () => !!(board?.assignees || []).find((assigneeId) => assigneeId === user._id),
    [user, board],
  );

  const allLanes = useMemo(() => {
    return [
      { _id: "default", text: "Default", value: "default" },
      ...lanes.map((l) => ({ _id: l._id, value: l._id, text: l.name })),
    ];
  }, [lanes]);

  const getInvitations = useCallback(() => {
    api.invitations.getForType(
      "projectInvited",
      ideaId,
      ({ invitations }) => {
        setPendingInvitations(invitations);
      },
      () => {},
    );
  }, [setPendingInvitations, ideaId]);

  useEffect(() => {
    getInvitations();
  }, [board, getInvitations]);

  const handleSearch = useCallback(
    (search, callback, fail) => {
      api.boards.searchProjectAssignees(
        challengeId,
        search,
        ({ users: potentialAssignees }) => {
          callback(potentialAssignees);
        },
        fail,
      );
    },
    [challengeId],
  );

  const addAssignee = useCallback(
    (assigneeId, assignmentNote) => {
      api.boards.assignProjectIdea(
        challengeId,
        ideaId,
        assigneeId,
        { message: assignmentNote },
        ({ assignees, assigneeUsers, updatedAt }) => {
          const newBoard = idea?.projectManagement?.boards?.find((b) => b.forId === idea.challenge);
          newBoard.assigneeUsers = assigneeUsers;
          newBoard.assignees = assignees;
          newBoard.updatedAt = updatedAt;
          onUpdateIdea({
            ...idea,
            projectManagement: {
              ...idea.projectManagement,
              boards: [...idea.projectManagement.boards.filter((b) => b._id !== challengeId), newBoard],
            },
          });
          if (assignmentNote) {
            setRefetchComments(true);
          }
        },
        (err) => toast.error(err.message),
      );
    },
    [ideaId, challengeId, idea, onUpdateIdea],
  );

  const addAllAssignees = useCallback(
    (usersToAssign, assignmentNote) => {
      const emailUsers = usersToAssign.filter((u) => u.isEmailInvitee);
      const existingUsers = usersToAssign.filter((u) => !u.isEmailInvitee);
      if (emailUsers.length > 0) {
        api.invitations.createBulk(
          {
            invitees: emailUsers.map((u) => u._id),
            invitationType: "email",
            forType: "projectInvited",
            forId: ideaId,
            // @ts-ignore
            board: { forId: challengeId },
          },
          () => {
            getInvitations();
            toast.success(`Invitation${emailUsers.length > 1 ? "s" : ""} sent`);
            if (assignmentNote) {
              api.boards.postProjectComment(
                challengeId,
                ideaId,
                {
                  message: {
                    text: `${user.profile.fullName} invited ${util.pluralise(emailUsers.length, "email user", "email users")} to this card: ${assignmentNote}`,
                  },
                },
                () => {},
                () => {},
              );
            }
          },
          () => {},
        );
      }
      if (existingUsers.length > 0) {
        existingUsers.forEach((u) => addAssignee(u._id, assignmentNote));
      }
    },
    [challengeId, ideaId, user, addAssignee, getInvitations],
  );

  const removeAssignee = useCallback(
    (assigneeId) => {
      util
        .confirm(
          "Remove assignee",
          "This user will be un-assigned from this project card, and will be unable to complete any project actions until you re-assign them. Are you sure you want to do this?",
        )
        .then(() => {
          api.boards.unassignProjectIdea(
            challengeId,
            ideaId,
            assigneeId,
            ({ assignees, assigneeUsers, updatedAt }) => {
              const updatedIdea = { ...idea };
              const newBoard = idea?.projectManagement?.boards?.find((b) => b.forId === idea.challenge);
              newBoard.assigneeUsers = assigneeUsers;
              newBoard.assignees = assignees;
              newBoard.updatedAt = updatedAt;
              onUpdateIdea(updatedIdea);
            },
            (err) => toast.error(err.message),
          );
        })
        .catch(() => {});
    },
    [ideaId, challengeId, idea, onUpdateIdea],
  );

  const updateProjectDeadline = useCallback(
    (inputDate) => {
      if (!board) return;
      const deadline = inputDate ? moment(inputDate, "YYYY-MM-DD").toDate() : null;
      const currentDeadline = board?.deadline;
      if ((!deadline && !currentDeadline) || deadline === currentDeadline) return;
      api.boards.updateProjectIdeaDeadline(
        challengeId,
        ideaId,
        deadline,
        () => {
          const updatedIdea = { ...idea };

          const newBoard = updatedIdea?.projectManagement?.boards?.find((b) => b.challengeId === updatedIdea.challenge);
          newBoard.deadline = deadline && new Date(deadline);
          onUpdateIdea(updatedIdea);
        },
        (err) => toast.error(err.message),
      );
    },
    [ideaId, idea, board, challengeId, onUpdateIdea],
  );

  const addReviewDateNote = useCallback(() => {
    util
      .prompt(
        `Add ${t("generic.ideaWithArticle")} review note`,
        `What would you like to talk about during the next review of this ${t("generic.idea")}? The note will be added to the project notes section.`,
        (reviewDateNote) => {
          if (reviewDateNote) {
            const formattedReviewDateNote = "Note for next review: " + reviewDateNote;
            api.boards.postProjectComment(
              challengeId,
              ideaId,
              { message: { text: formattedReviewDateNote } },
              () => {
                setRefetchComments(true);
              },
              () => {},
            );
          }
        },
      )
      .catch(() => {});
  }, [challengeId, ideaId, t]);

  const updateProjectReviewDate = useCallback(
    (inputDate) => {
      if (!board) return;
      const reviewDate = inputDate ? moment(inputDate, "YYYY-MM-DD").toDate() : null;
      const currentreviewDate = board?.reviewDate;
      if ((!reviewDate && !currentreviewDate) || reviewDate === currentreviewDate) return;
      api.boards.updateProjectIdeaReviewDate(
        challengeId,
        ideaId,
        reviewDate,
        () => {
          const updatedIdea = { ...idea };
          const newBoard = idea?.projectManagement?.boards?.find((b) => b.forId === idea.challenge);
          newBoard.reviewDate = reviewDate && new Date(reviewDate);
          onUpdateIdea(updatedIdea);
          if (reviewDate && util.hasPermission(user, "challenge.createProjectNotes", updatedIdea.challenge))
            addReviewDateNote();
        },
        (err) => toast.error(err.message),
      );
    },
    [ideaId, idea, board, challengeId, onUpdateIdea, addReviewDateNote, user],
  );

  const removePendingInvitation = useCallback(
    (invitationId) => {
      api.invitations.remove(
        invitationId,
        () => {
          const updatedPendingInvitations = pendingInvitations.filter((i) => i._id !== invitationId);
          setPendingInvitations(updatedPendingInvitations);
          toast.success("Invitation removed. The user can no longer accept it.");
        },
        () => toast.error("Failed to remove invite"),
      );
    },
    [pendingInvitations],
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#f7f7f7",
          borderRadius: 5,
          padding: 5,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", gap: 10 }}>
          <DateInputContainer>
            <b>In lane</b>
            <Dropdown
              selection
              disabled={
                !userIsAssigned && !canManage && !util.hasPermission(user, "challenge.moveProjectIdeas", idea.challenge)
              }
              value={currentLane}
              options={allLanes}
              onChange={(e, { value }) => handleUpdateProjectLane(value)}
            />
          </DateInputContainer>
          <DateInputContainer>
            <b>Review date</b>
            <DateInput
              clearable={
                userIsAssigned || canManage || util.hasPermission(user, "challenge.editProjectDates", idea.challenge)
              }
              closable={true}
              disabled={
                !userIsAssigned && !canManage && !util.hasPermission(user, "challenge.editProjectDates", idea.challenge)
              }
              placeholder="Add a review date"
              value={board && board.reviewDate ? moment(board.reviewDate).format("YYYY-MM-DD") : null}
              dateFormat="YYYY-MM-DD"
              iconPosition="left"
              size="tiny"
              onChange={(event, { value }) => updateProjectReviewDate(moment(value).format("YYYY-MM-DD"))}
              onClear={() => updateProjectReviewDate(null)}
            />
          </DateInputContainer>
          <DateInputContainer>
            <b>Deadline</b>
            <DateInput
              clearable={
                userIsAssigned || canManage || util.hasPermission(user, "challenge.editProjectDates", idea.challenge)
              }
              disabled={
                !userIsAssigned && !canManage && !util.hasPermission(user, "challenge.editProjectDates", idea.challenge)
              }
              placeholder="Add a deadline"
              value={board && board.deadline ? moment(board.deadline).format("YYYY-MM-DD") : null}
              dateFormat="YYYY-MM-DD"
              iconPosition="left"
              size="tiny"
              onChange={(event, { value }) => updateProjectDeadline(moment(value).format("YYYY-MM-DD"))}
              onClear={() => updateProjectDeadline(null)}
            />
          </DateInputContainer>
        </div>
      </div>

      <div>
        {(userIsAssigned ||
          canManage ||
          util.hasPermission(user, "challenge.editProjectAssignees", idea.challenge)) && (
          <div style={{ marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#f7f7f7",
                borderRadius: 5,
                padding: 5,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {(userIsAssigned ||
                  canManage ||
                  util.hasPermission(user, "challenge.viewProjectAssignees", idea.challenge)) &&
                board &&
                board.assignees &&
                board?.assigneeUsers?.length ? (
                  <div>
                    <div className="content-item">
                      <ExistingAssignees>
                        {board.assigneeUsers.map((a) => (
                          <UserChip
                            compact
                            hideDescription
                            user={a}
                            key={a._id}
                            style={{ marginRight: 15 }}
                            actionIcon={
                              canManage || util.hasPermission(user, "challenge.editProjectAssignees", idea.challenge)
                                ? "close"
                                : null
                            }
                            actionClick={
                              canManage || util.hasPermission(user, "challenge.editProjectAssignees", idea.challenge)
                                ? () => removeAssignee(a._id)
                                : null
                            }
                          />
                        ))}
                      </ExistingAssignees>
                    </div>
                  </div>
                ) : null}
                {(userIsAssigned ||
                  canManage ||
                  util.hasPermission(user, "challenge.viewProjectAssignees", idea.challenge)) &&
                pendingInvitations.length > 0 ? (
                  <OpenModalAction>
                    <span style={{ marginRight: 20 }} onClick={() => setInvitationsModalOpen(true)}>
                      View pending invitations
                    </span>
                  </OpenModalAction>
                ) : null}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <UserChooser
                  trigger={
                    <Button
                      size="small"
                      content={
                        <p>
                          <Icon name="user plus" /> Assign user{" "}
                          {idea?.ownerChallenge?.projectManagementVisibility !== "users" ? (
                            <Popup
                              trigger={<Icon name="question circle" color="grey" style={{ marginLeft: 10 }} />}
                              content={`This project board is only visible to ${t("generic.challenge")} administrators. Only
                  assignees that are ${t("generic.challenge")} administrators will be able to view the project
                  board and ${t("generic.ideas")}.`}
                            />
                          ) : null}
                        </p>
                      }
                      style={{ minWidth: "max-content" }}
                      disabled={
                        !canManage && !util.hasPermission(user, "challenge.editProjectAssignees", idea.challenge)
                      }
                    />
                  }
                  onComplete={(users) => {
                    addAllAssignees(
                      users.filter((u) => !board?.assignees?.includes(u._id)),
                      assignmentNote,
                    );
                  }}
                  clearOnComplete
                  searchFunction={handleSearch}
                  enabledFeatures={{ search: true, invite: true }}
                  forType="projectInvited"
                  confirm="Assign these users"
                  afterContent={(extraContentProps) => (
                    <div>
                      <ChallengeAudienceWarning
                        extraContentProps={extraContentProps}
                        externalInvitesInAudience={
                          idea?.ownerChallenge?.visibility?.organisations?.length > 0 &&
                          idea?.ownerChallenge?.projectManagementVisibility === "users"
                        }
                      >
                        <ChallengeAudienceWarning.AssigneeAudienceWarning challengeId={challengeId} />
                      </ChallengeAudienceWarning>
                      <div>
                        <h5>Assignment note</h5>
                        <p>
                          This note will be added to the 'Project Notes' along with information about who has been
                          assigned. Use it to explain why the user(s) have been assigned.
                        </p>
                        <Input
                          fluid
                          placeholder="Add an assignment note"
                          value={assignmentNote}
                          onChange={(e, { value }) => setAssignmentNote(value)}
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {(userIsAssigned || canManage || util.hasPermission(user, "challenge.viewProjectNotes", idea.challenge)) && (
          <ProjectComments
            forId={challengeId}
            forIdea={ideaId}
            onIdeaActivity={onIdeaActivity}
            canPost={userIsAssigned || canManage}
            setRefetchComments={setRefetchComments}
            refetchComments={refetchComments}
          />
        )}
      </div>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={invitationsModalOpen}
        onClose={() => setInvitationsModalOpen(false)}
        style={{ width: 700 }}
      >
        <>
          <Modal.Header>Pending invitations</Modal.Header>
          <PendingInvites>
            {pendingInvitations.length > 0 && (
              <>
                {pendingInvitations.map((i) =>
                  i?.invitee ? (
                    <PendingInvite key={i._id}>
                      <p style={{ display: "inline-block" }}>{i.invitee}</p>
                      <div>
                        <span>
                          Invited by <b>{i.inviterUser ? util.getProfileName(i.inviterUser) : "someone"}</b> on{" "}
                          {moment(i.createdAt).format("DD/MM/YY")}
                        </span>
                        {canManage || util.hasPermission(user, "challenge.editProjectAssignees", challengeId) ? (
                          <Button
                            icon="trash"
                            basic
                            size="small"
                            style={{ marginLeft: 20 }}
                            onClick={() => removePendingInvitation(i._id)}
                          />
                        ) : null}
                      </div>
                    </PendingInvite>
                  ) : null,
                )}
              </>
            )}
          </PendingInvites>
          <Modal.Actions>
            <Button onClick={() => setInvitationsModalOpen(false)}>Close</Button>
          </Modal.Actions>
        </>
      </Modal>
    </>
  );
};
