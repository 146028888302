import { UserChip } from "components/lib/Chips";
import { useTranslation } from "react-i18next";
import { Icon, Message } from "semantic-ui-react";
import { ExtraContentFunctionProps } from "../UserChooser";

export const ChallengeAudienceWarning = ({
  extraContentProps,
  externalInvitesInAudience,
  children,
}: {
  extraContentProps: ExtraContentFunctionProps;
  externalInvitesInAudience: boolean;
  children: React.ReactNode;
}) => {
  const cannotViewChosenUsers = extraContentProps.users.filter(
    (c) => !(c.isInAudience || (c.isEmailInvitee && externalInvitesInAudience)),
  );

  if (cannotViewChosenUsers?.length > 0) {
    return (
      <Message warning icon>
        <Icon name="exclamation triangle" color="orange" />
        <Message.Content>
          {children}
          {cannotViewChosenUsers.map((c) => (
            <div key={c._id} style={{ display: "inline-block", margin: 10 }}>
              <UserChip user={c} actionIcon="remove" actionClick={() => extraContentProps.unpickUser(c._id)} />
            </div>
          ))}
        </Message.Content>
      </Message>
    );
  }
  return null;
};

const AssessorAudienceWarning = ({ challengeId }: { challengeId: string }) => {
  const { t } = useTranslation();

  return (
    <span>
      The users below are currently outside of the {t("generic.challenge")} audience. Users not in the{" "}
      {t("generic.challenge")} audience will be unable to view the {t("generic.challenge")} and their assessments. To
      ensure assessors can access their assessments, add them to the{" "}
      <a target="_blank" rel="noreferrer" href={`/challenges/${challengeId}/settings/audience`}>
        {t("generic.challenge")} audience
      </a>{" "}
      after making this assignment.
    </span>
  );
};
ChallengeAudienceWarning.AssessorAudienceWarning = AssessorAudienceWarning;

const AssigneeAudienceWarning = ({ challengeId }: { challengeId: string }) => {
  const { t } = useTranslation();

  return (
    <span>
      The users below are currently outside of the {t("generic.challenge")} audience. Users not in the{" "}
      {t("generic.challenge")} audience will be unable to view the {t("generic.challenge")} and their{" "}
      {t("generic.ideas")}. To ensure assignees can access their {t("generic.ideas")}, add them to the{" "}
      <a target="_blank" rel="noreferrer" href={`/challenges/${challengeId}/settings/audience`}>
        {t("generic.challenge")} audience
      </a>{" "}
      after making this assignment.
    </span>
  );
};
ChallengeAudienceWarning.AssigneeAudienceWarning = AssigneeAudienceWarning;
