import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Icon as SemanticIcon, Menu } from "semantic-ui-react";
import api from "api";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import useTheme from "theme/useTheme";
import util from "utils/utils";

import { IdeaAssessmentReport } from "components/lib/Ideas";
import { HoverActionButton, HoverAction, HoverActionsContentBox } from "components/lib/HoverActions";
import ImpactManagement from "./ImpactManagement/ImpactManagement";
import ImpactStatementManagement from "./ImpactStatementManagement";

import { OverviewManagement } from "./OverviewManagement";
import { useAppSelector } from "store";
import ActivityManagement from "./ActivityManagement";
import CommsManagement from "./CommsManagement";

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

const SubMenuItemContainer = styled.div`
  margin: 0 5px;
`;

const ProjectTabs = {
  OVERVIEW: "overview",
  COMMS: "comms",
  NOTES: "notes",
  ASSIGNEES: "assignees",
  ASSESSMENT: "assessment",
  ACTIVITY: "activity",
  IMPACT: "impact",
};

const IdeaManagement = ({
  idea,
  onUpdateIdea,
  lanes = [],
  updateProjectLane,
  onIdeaActivity,
  assessmentEnabled,
  challenge: propChallenge,
}) => {
  const user = useAppSelector((state) => state.user);

  const [isOpen, setOpen] = useState(true);

  const [activeProjectTab, setActiveProjectTab] = useState(ProjectTabs.OVERVIEW);

  const [impactList, setImpactList] = useState(null);
  const { t } = useTranslation();
  const [currentLane, setCurrentLane] = useState("default");

  const usingChallenge = useMemo(() => propChallenge ?? idea?.ownerChallenge, [propChallenge, idea]);
  const ideaId = idea?._id;
  const challengeId = idea?.challenge;
  const board = useMemo(() => idea?.projectManagement?.boards?.find((b) => b.forId === idea.challenge), [idea]);
  const theme = useTheme();

  useEffect(() => {
    const currentLaneId = board?.lane || "default";
    setCurrentLane(currentLaneId);
  }, [board]);

  const userIsAssigned = useMemo(
    () => !!(board?.assignees || []).find((assigneeId) => assigneeId === user._id),
    [user, board],
  );
  const userIsAssessor = useMemo(
    () => !!(idea?.assessments || []).find((assessment) => assessment?.user === user._id),
    [idea, user],
  );

  const canManage = util.canManageChallenge(user, idea?.ownerChallenge);
  const canViewImpacts =
    canManage || userIsAssigned || util.hasPermission(user, "challenge.viewProjectImpacts", challengeId);

  useEffect(() => {
    if (user.ownerOrganisation._id) {
      api.organisations.getImpacts(
        user.ownerOrganisation._id,
        ({ impacts }) => {
          setImpactList(impacts);
        },
        () => {},
      );
    }
  }, [user.ownerOrganisation._id]);

  const handleUpdateProjectLane = useCallback(
    (laneId) => {
      updateProjectLane(ideaId, laneId, 0);
      setCurrentLane(laneId);
    },
    [ideaId, updateProjectLane],
  );

  const impactEnabled = user.ownerOrganisation.enabledFeatures.includes("ideaImpacts");
  const impactStatementsEnabled =
    user.ownerOrganisation.enabledFeatures.includes("impactStatements") && usingChallenge?.impactStatements?.enabled;

  return (
    <ActionContainer data-testid="simplydo-project-board-actions">
      {!isOpen ? (
        <HoverAction
          icon="compass"
          margined
          onClick={() => {
            setOpen(true);
          }}
        >
          {t("common:capitalise", { key: "generic.idea" })} management
        </HoverAction>
      ) : (
        <HoverActionsContentBox $large>
          <HoverActionButton onClick={() => setOpen(false)}>
            <SemanticIcon name="compass" style={{ height: "1.1em" }} />
            <span>{t("common:capitalise", { key: "generic.idea" })} management</span>
            {theme.sizes.isMobile ? (
              <div className="fake-icon" />
            ) : (
              <SemanticIcon name="close" style={{ height: "1.1em" }} onClick={() => setOpen(false)} />
            )}
          </HoverActionButton>
          <div className="content-box">
            <Menu tabular size="tiny" stackable style={{ overflowX: "auto", overflowY: "hidden" }}>
              {userIsAssigned || canManage || util.hasPermission(user, "challenge.viewProjectBoard", challengeId) ? (
                <Menu.Item
                  name="Overview"
                  active={activeProjectTab === ProjectTabs.OVERVIEW}
                  onClick={() => setActiveProjectTab(ProjectTabs.OVERVIEW)}
                />
              ) : null}
              {canManage || util.hasPermission(user, "challenge.viewSettings", challengeId) ? (
                <Menu.Item
                  name="Comms"
                  active={activeProjectTab === ProjectTabs.COMMS}
                  onClick={() => setActiveProjectTab(ProjectTabs.COMMS)}
                />
              ) : null}
              {(userIsAssigned ||
                canManage ||
                userIsAssessor ||
                util.hasPermission(user, "challenge.viewAssessments", challengeId)) &&
              assessmentEnabled ? (
                <Menu.Item
                  name="Assessment"
                  active={activeProjectTab === ProjectTabs.ASSESSMENT}
                  onClick={() => setActiveProjectTab(ProjectTabs.ASSESSMENT)}
                />
              ) : null}
              {userIsAssigned || canManage || util.hasPermission(user, "challenge.viewProjectActivity", challengeId) ? (
                <Menu.Item
                  name="Activity"
                  active={activeProjectTab === ProjectTabs.ACTIVITY}
                  onClick={() => setActiveProjectTab(ProjectTabs.ACTIVITY)}
                />
              ) : null}
              {impactEnabled && canViewImpacts && impactList?.length > 0 && (
                <Menu.Item
                  name="Impact"
                  active={activeProjectTab === ProjectTabs.IMPACT}
                  onClick={() => setActiveProjectTab(ProjectTabs.IMPACT)}
                />
              )}
            </Menu>
            {(userIsAssigned || canManage || util.hasPermission(user, "challenge.viewProjectBoard", challengeId)) &&
            activeProjectTab === "overview" ? (
              <SubMenuItemContainer>
                <OverviewManagement
                  idea={idea}
                  onUpdateIdea={onUpdateIdea}
                  onIdeaActivity={onIdeaActivity}
                  lanes={lanes}
                  currentLane={currentLane}
                  handleUpdateProjectLane={handleUpdateProjectLane}
                  canManage={canManage}
                />
              </SubMenuItemContainer>
            ) : null}
            {(canManage || util.hasPermission(user, "challenge.viewSettings", challengeId)) &&
            activeProjectTab === "comms" ? (
              <SubMenuItemContainer>
                <CommsManagement idea={idea} />
              </SubMenuItemContainer>
            ) : null}
            {(userIsAssigned ||
              canManage ||
              userIsAssessor ||
              util.hasPermission(user, "challenge.viewAssessments", challengeId)) &&
              activeProjectTab === "assessment" && (
                <SubMenuItemContainer>
                  <IdeaAssessmentReport idea={idea} challenge={usingChallenge} />
                </SubMenuItemContainer>
              )}
            {(userIsAssigned || canManage || util.hasPermission(user, "challenge.viewProjectActivity", challengeId)) &&
            activeProjectTab === "activity" ? (
              <SubMenuItemContainer>
                <ActivityManagement idea={idea} userIsAssigned={userIsAssigned} />
              </SubMenuItemContainer>
            ) : null}
            {activeProjectTab === "impact" &&
            impactEnabled &&
            !impactStatementsEnabled &&
            canViewImpacts &&
            impactList?.length > 0 ? (
              <SubMenuItemContainer>
                <ImpactManagement
                  idea={idea}
                  onUpdateIdea={onUpdateIdea}
                  impactList={impactList}
                  canManage={userIsAssigned || canManage}
                />
              </SubMenuItemContainer>
            ) : null}
            {activeProjectTab === "impact" && impactEnabled && impactStatementsEnabled && canViewImpacts ? (
              <SubMenuItemContainer>
                <ImpactStatementManagement idea={idea} onUpdateIdea={onUpdateIdea} impactList={impactList} />
              </SubMenuItemContainer>
            ) : null}
          </div>
        </HoverActionsContentBox>
      )}
    </ActionContainer>
  );
};

export default IdeaManagement;
