import { styled } from "styled-components";

const AfterContentButton = styled.span<{ $inverted?: boolean }>`
  color: ${({ $inverted }) => ($inverted ? "white" : "grey")};
  opacity: ${({ theme }) => (theme.sizes.isComputer ? 0.8 : 1)};
  font-weight: 550;
  cursor: pointer;
  font-size: 0.85em;
  display: flex;
  align-items: center;
  justify-content: center;
  &&:hover {
    opacity: 1 !important;
  }
`;

export default AfterContentButton;
