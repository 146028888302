import React from "react";
import { useTranslation } from "react-i18next";
import { OpenAPI } from "simplydo/interfaces";

const PinIdeaHelper = ({
  challenge,
}: {
  challenge: {
    ideaVisibilityLimits?: OpenAPI.Schemas["Challenge"]["ideaVisibilityLimits"];
  };
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <p>{t("ideas.pin.info")}</p>
      {challenge.ideaVisibilityLimits?.length ? (
        <p>
          {t("ideas.pin.visibilityInfo")}{" "}
          <b>{challenge.ideaVisibilityLimits.map((iv) => iv.charAt(0).toUpperCase() + iv.slice(1)).join(", ")}</b>
        </p>
      ) : null}
    </div>
  );
};

export default PinIdeaHelper;
