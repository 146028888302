import React, { useEffect, useState, useCallback } from "react";
import { Popup, Button, Input } from "semantic-ui-react";
import styled from "styled-components";

const HelpText = styled.p`
  margin: 0 0 5px 0;
`;

const ChooserPopup = styled(Popup)`
  z-index:5;
  max-width:400px !important;
  .menu {
    max-width:400px !important;
    min-width:300px !important;
  }
}`;

type LinkChooserProps = {
  trigger: React.ReactElement;
  isOpen: boolean;
  onComplete: (text: string, link: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
  selectedText?: string;
  shouldFix?: boolean;
  hideLinkText?: boolean;
};

const LinkChooser = ({
  trigger,
  isOpen,
  onComplete,
  onOpen,
  onClose,
  selectedText,
  shouldFix = true,
  hideLinkText = false,
}: LinkChooserProps) => {
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const [linkText, setLinkText] = useState("");

  const linkChosen = useCallback(() => {
    const fixedLink = shouldFix && link.toLowerCase().indexOf("http") !== 0 ? `http://${link}` : link;
    onComplete(linkText, fixedLink);
    setOpen(false);
    setLink("");
    setLinkText("");
  }, [setLink, shouldFix, setLinkText, link, linkText, onComplete]);

  useEffect(() => {
    if (open !== isOpen) {
      setOpen(isOpen);
    }
  }, [open, isOpen]);

  useEffect(() => {
    if (selectedText) setLinkText(selectedText);
  }, [selectedText]);

  const togglePopup = () => {
    if (!open && onOpen) onOpen();
    if (open && onClose) onClose();
    setOpen(!open);
  };

  const keyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      linkChosen();
    }
  };

  return (
    <>
      <ChooserPopup
        mountNode={document.getElementById("semantic-modal-mount-node")}
        on="click"
        position="bottom left"
        open={open}
        trigger={React.cloneElement(trigger, { onClick: togglePopup })}
        content={
          <div>
            {hideLinkText ? null : (
              <>
                <HelpText>Enter the text to be linked.</HelpText>
                <Input
                  disabled={!!selectedText}
                  fluid
                  value={linkText}
                  onChange={(e) => setLinkText(e.target.value)}
                  placeholder="Link text"
                  style={{ marginBottom: 10 }}
                />
              </>
            )}
            <HelpText>Enter or paste a URL to create your link.</HelpText>
            <Input
              fluid
              value={link}
              onKeyDown={keyDown}
              onChange={(e) => setLink(e.target.value)}
              placeholder="https://..."
              style={{ marginBottom: 10 }}
            />
            <Button primary fluid onClick={linkChosen} disabled={link === ""} icon="check" content="Insert link" />
          </div>
        }
      />
    </>
  );
};

export default LinkChooser;
