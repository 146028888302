import React, { useEffect, useContext, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Container } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import api from "api";
import actions from "actions";
import toast from "react-hot-toast";
import SearchParams from "simplydo/src/searchParams";

import { Sticky } from "components/lib/UI";
import { HomeSidebar as Sidebar, HomeContentColumn as ContentColumn } from "./Containers";
import Header from "./Header";
import ActivityFeed from "./ActivityFeed";
import Invitations from "./Invitations";
import User from "./User";
import Ideas from "./Ideas";
import AssessmentAssignments from "./AssessmentAssignments";
import ProjectAssignedIdeas from "./ProjectAssignedIdeas";
import Challenges from "./Challenges";
import CuratedChallenges from "./CuratedChallenges";
import ProjectBoards from "./ProjectBoards";
import Polls from "./Polls";
import SecurityRecommendations from "./SecurityRecommendations";
import Tasklist from "./Tasklist";
import DownloadAppBanner from "./DownloadAppBanner";
import HomeGroups from "./HomeGroups";
import HomeSuggestions from "./Suggestions";
import HomeInnovationIntelligence from "./InnovationIntelligence";
import Pages from "./Pages";
import { HandledIdeaPreview as IdeaPreview } from "components/lib/Ideas";

const HomeColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ $fluid }) => ($fluid ? "0 0 10px" : "10px")};
  align-self: stretch;
  flex: 1;
  align-self: stretch;
`;

const HomeColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => (!theme.sizes.isComputer ? "0" : "25px 0")};
  flex: ${({ theme }) => (!theme.sizes.isComputer ? "1" : "1 1 0")};
  min-height: ${({ theme }) => (!theme.sizes.isComputer ? "100%" : null)};
  margin: ${({ theme }) => (!theme.sizes.isComputer ? "" : "0 0 0 30px;")};
  align-self: stretch;
`;

const IsMobileSidebar = ({ isComputer, children }) => {
  if (!isComputer) {
    return null;
  }
  return (
    <Sticky top={50} style={{ flex: "0.2 1 20%" }}>
      <Sidebar $top={50}>{children}</Sidebar>
    </Sticky>
  );
};

function Home({ onMessageThreadSelected, user }) {
  // Example of the structure received from the API
  const [dashboardState, setDashboardState] = useState({
    ideas: [],
    challenges: [],
    invitations: [],
    projectAssignments: [],
    assessmentAssignments: [],
    boards: [],
    polls: [],
    pinnedChallenges: [],
    messages: {
      messageThreads: [],
      newMessageCount: 0,
    },
    groups: [],
  });
  const [ideaPreviewId, setIdeaPreviewId] = useState("");
  const theme = useContext(ThemeContext);
  const { isMobile } = theme.sizes;
  const hasAcceptedInvitation = user?.hasAcceptedInvitation;

  const getDashboard = useCallback(() => {
    api.users.dashboard(
      (data) => {
        setDashboardState(data);
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, []);

  useEffect(() => {
    if (hasAcceptedInvitation) {
      getDashboard();
    }
  }, [hasAcceptedInvitation, getDashboard]);

  const updateInvitations = useCallback((updatedInvitations) => {
    setDashboardState((prevState) => ({
      ...prevState,
      invitations: updatedInvitations,
    }));
  }, []);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const params = new SearchParams(window.location.search);
    const thread = params.get("openThread");
    if (thread) {
      api.messages.getMessageThread(thread, (threadObject) => {
        onMessageThreadSelected(threadObject, isMobile);
      });
    }
  }, [onMessageThreadSelected, isMobile]);

  if (!user) {
    return null;
  }
  return (
    <div>
      <Helmet title={"Home"} />
      <Container
        fluid
        style={{
          display: "flex",
          flexDirection: !theme.sizes.isComputer ? "column" : "row",
          flex: 1,
          paddingTop: !theme.sizes.isComputer ? "1rem" : null,
        }}
      >
        {/* @ts-ignore */}
        <IdeaPreview ideaId={ideaPreviewId} closeModal={() => setIdeaPreviewId("")} redirectToBoard={true} />
        <IsMobileSidebar isComputer={theme.sizes.isComputer} isTablet={theme.sizes.isTablet}>
          <User />
          <Challenges challenges={dashboardState.challenges} />
          <Ideas ideas={dashboardState.ideas} />
        </IsMobileSidebar>
        <HomeColumnContainer>
          <DownloadAppBanner padded />
          <HomeColumn $fluid>
            <Header compact />
            <Invitations
              invitations={dashboardState.invitations}
              updateInvitations={updateInvitations}
              limit={3}
              showOnlyAcceptable
            />
            <HomeSuggestions />
            <Tasklist />
            <SecurityRecommendations />
            <ActivityFeed />
          </HomeColumn>
        </HomeColumnContainer>
        <ContentColumn>
          <HomeInnovationIntelligence />
          <AssessmentAssignments challenges={dashboardState.assessmentAssignments} />
          <ProjectAssignedIdeas assignments={dashboardState.projectAssignments} setIdeaPreviewId={setIdeaPreviewId} />
          <ProjectBoards boards={dashboardState.boards} />
          <Polls polls={dashboardState.polls} />
          <CuratedChallenges challenges={dashboardState.pinnedChallenges} />
          <HomeGroups groups={dashboardState.groups} />
          <Pages />
        </ContentColumn>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
  onMessageThreadSelected: (thread, isPhone = false) => dispatch(actions.messages.selectThread(thread, isPhone)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Home));
