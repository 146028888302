import React from "react";
import RcSlider from "rc-slider";
import styled from "styled-components";
import "rc-slider/assets/index.css";

const StyledSlider = styled(RcSlider)<{ value: number }>`
  max-width: 80%;
  display: block;
  z-index: 0;
  margin: 20px auto;

  .rc-slider-rail {
    ${({ value }) => value === undefined && "opacity: 0.25;"}
    height: 8px;
  }
  .rc-slider-track {
    ${({ value }) => value === undefined && "opacity: 0.25;"}
    ${({ value }) => value === undefined && "background-color: transparent !important;"}
    height: 8px;
  }
  .rc-slider-dot {
    display: none;
  }
  .rc-slider-mark-text {
    z-index: 5;
    user-select: none;
  }
  .rc-slider-handle {
    width: 30px;
    ${({ value }) => value === undefined && "left: 50% !important;"}
    height: 30px;
    margin-top: -12px;
    z-index: 6;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  }
`;

type SliderProps = {
  value: number;
  min?: number;
  max?: number;
  steps?: number;
  onChange?: (value: number) => void;
  marks?: { [key: number]: string | number };
  style?: React.CSSProperties;
};

const Slider = ({ value, style, steps = 1, min = 1, max = 10, marks, onChange }: SliderProps) => {
  return (
    <StyledSlider
      style={style}
      value={value}
      min={min !== undefined ? min : 1}
      max={max !== undefined ? max : 10}
      step={steps}
      onChange={onChange}
      marks={marks ?? { [min]: min, [max]: max }}
    />
  );
};

export default Slider;
