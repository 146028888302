import React, { useCallback } from "react";
import { HoverAction } from "components/lib/HoverActions";
import api from "api";
import toast from "react-hot-toast";
import { useAppSelector, useAppDispatch } from "store";
import { useTranslation } from "react-i18next";
import util from "utils/utils";
import actions from "actions";
import { OpenAPI } from "simplydo/interfaces";

type FollowActionProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
};

const FollowAction = ({ idea }: FollowActionProps) => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const followObject = util.userIsFollowingContextObject(user, "idea", idea?._id);

  const updateUser = useCallback((user) => dispatch(actions.user.receiveUser(user)), [dispatch]);

  const followIdea = useCallback(() => {
    api.ideas.follow(
      idea._id,
      true,
      () => {
        toast.success(`${t("common:capitalise", { key: "generic.idea" })} followed`);
        followObject.following = true;
        followObject.unfollowed = false;

        const oldFollowing = (user?.following || []).filter((f) => f._id !== idea?._id);
        const newFollowing = [followObject, ...oldFollowing];

        updateUser({ ...user, following: newFollowing });
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [idea, updateUser, user, t, followObject]);

  const unfollowIdea = useCallback(() => {
    api.ideas.follow(
      idea._id,
      false,
      () => {
        toast(`${t("common:capitalise", { key: "generic.idea" })} unfollowed`);
        followObject.following = false;
        followObject.unfollowed = true;

        const oldFollowing = (user?.following || []).filter((f) => f._id !== idea?._id);
        const newFollowing = [followObject, ...oldFollowing];

        updateUser({ ...user, following: newFollowing });
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [idea, updateUser, user, t, followObject]);

  return (
    <HoverAction
      icon="star"
      popupContent={`Follow ${t("generic.ideaWithArticle")} to receive notifications when somebody likes it or posts a comment. When creating or interacting with ${t("generic.ideaWithArticle")} you will automatically start following it.`}
      popupPosition="right center"
      onClick={() => {
        followObject?.following ? unfollowIdea() : followIdea();
      }}
    >
      {followObject?.following ? "Following" : "Follow"}
    </HoverAction>
  );
};

export default FollowAction;
