import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Color from "color";

const deviceSizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

const isDark = (color, threshold = 145) => {
  const rgb = color.rgb().color;
  const yiq = (rgb[0] * 2126 + rgb[1] * 7152 + rgb[2] * 722) / 10000;
  return yiq < threshold;
};

const withTheme = (ComponentToWrap) => {
  const mapStateToProps = (state) => {
    const urlOrganisation = state.organisations?.urlOrganisation;
    return { organisation: state?.user?.ownerOrganisation || urlOrganisation || null };
  };

  return connect(mapStateToProps)((props) => {
    const { innerWidth } = window;
    const [width, setInnerWidth] = useState(innerWidth);
    const [theme, setTheme] = useState({
      sizes: {
        width: 0,
        isMobile: width < deviceSizes.tablet,
        isTablet: width >= deviceSizes.tablet,
        isComputer: width >= deviceSizes.laptop,
        isLargeComputer: width >= deviceSizes.laptopL,
      },
    });

    useEffect(() => {
      const updateDimensions = () => {
        const { innerWidth: currentInnerWidth } = window;
        setInnerWidth(currentInnerWidth);
      };

      window.addEventListener("resize", updateDimensions);

      return () => {
        window.removeEventListener("resize", updateDimensions);
      };
    }, []);

    useEffect(() => {
      const isMobile = width < deviceSizes.tablet;
      const isTablet = width >= deviceSizes.tablet;
      const isComputer = width >= deviceSizes.laptop;
      const isLargeComputer = width >= deviceSizes.laptopL;
      const sizes = {
        isMobile,
        isTablet,
        isComputer,
        isLargeComputer,
        width,
        minStyle: Object.entries(deviceSizes).reduce((curr, [key, val]) => ({
          ...curr,
          [key]: `min-width: ${val}px;`,
        })),
        maxStyle: Object.entries(deviceSizes).reduce((curr, [key, val]) => ({
          ...curr,
          [key]: `max-width: ${val}px;`,
        })),
      };

      const { primaryColour = "#14435B", secondaryColour = "#1b678e" } = props.organisation || {};
      const accent = Color(primaryColour).lighten(0.2);
      const secondaryAccent = Color(secondaryColour).lighten(0.6).fade(0.8);

      setTheme({
        primaryColour,
        secondaryColour: secondaryColour || primaryColour,
        accent: accent.hex(),
        secondaryAccent: secondaryColour ? secondaryAccent.hex() : accent.hex(),
        shouldBeWhiteOnSecondary: isDark(Color(secondaryColour)),
        shouldBeWhiteOnPrimary: isDark(Color(primaryColour)),
        shouldBeWhiteOnAccent: isDark(accent),
        shouldBeWhiteOnSecondaryAccent: isDark(secondaryAccent),
        boxShadow: "0.5px 0.5px 2.5px 1px #C0C0C0",
        sizes,
      });
    }, [props.organisation, width]);

    return <ComponentToWrap {...props} theme={theme} />;
  });
};

export default withTheme;
