import React, { useCallback, useEffect, useState, useMemo, Dispatch, SetStateAction } from "react";
import { Message, Input, Dropdown, Button, Loader } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import styled from "styled-components";
import toast from "react-hot-toast";
import useTheme from "theme/useTheme";
import util from "utils/utils";
import api from "api";
import { useTranslation } from "react-i18next";

import { useLocation, useNavigate } from "react-router-dom";

import TagDropdownChooser from "components/lib/Choosers/Tags/TagDropdownChooser";
import {
  IProjectGeneralProps,
  IProjectLaneProps,
  IProjectIdeaProps,
  IProjectAssigneeProps,
  ProjectSelectionModes,
} from ".";
import CustomLaneCard from "./CustomLaneCard";
import CustomLaneHeader from "./CustomLaneHeader";
import BoardEvents from "./BoardEvents";
import Board, { IIdeaCard, ILaneData } from "./Board";
import SearchParams from "simplydo/src/searchParams";

const StyledProjectBoardContainer = styled.div<{ $minHeight: number }>`
  // The height of the board must be the viewports height minus the top bars
  height: calc(100vh - 205px);
  ${({ $minHeight }) =>
    $minHeight
      ? `
    min-height: ${$minHeight}px;
  `
      : ""}
  display: flex;
  flex-direction: column;
`;

const StyledBoard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  > .row-flex {
    display: flex;
    align-items: center;
  }
  margin-top: -10px;
  flex-wrap: wrap;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Inter";
  }
  .ui.message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: "Inter";
    width: 40%;
    margin: 0 0 0 20px;
    padding: 0.5em 1em;
  }
`;

const StyledLaneContainer = styled.div`
  min-height: 200px;
  flex-grow: 1;
  font-family: "Inter";
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Inter";
  }
  > p {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px;
  > span {
    display: block;
    margin: 0 0 0 5px;
    font-weight: bold;
  }
`;

const FilterHeight = "35px";

const StyledBoardHeader = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.sizes.isComputer ? "row" : "column")};
  > .filter-container {
    display: flex;
    flex-direction: ${({ theme }) => (theme.sizes.isComputer ? "row" : "column")};
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    ${({ theme }) =>
      !theme.sizes.isComputer
        ? `
      margin-bottom: 5px;
    `
        : ""}
    height: ${FilterHeight};
  }
  .filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    margin-top: 10px;
    height: ${FilterHeight};
  }
  ${({ theme }) =>
    !theme.sizes.isComputer
      ? `
    gap: 5px;
  `
      : `
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -3px 0 0 0;
    > * {
      margin: 3px;
      &:last-child {
        margin-right: 0;
      }
    }
  `}
  ${({ theme }) =>
    !theme.sizes.isMobile
      ? `
    .header {
      flex: 0;
      min-width: fit-content;
    }
  `
      : `
    .header {
      margin-bottom: 5px;
    }
  `}
`;

// const StyledProjectBoard = styled(Board)`
// background: none;
// /* .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper{
//   overflow: visible;
// }
// .imFibB{
//   overflow: visible;
// } */
// `;

type IProjectBoardProps = IProjectGeneralProps &
  IProjectLaneProps &
  IProjectIdeaProps &
  IProjectAssigneeProps & {
    setNewLaneName: Dispatch<SetStateAction<string>>;
    setLaneOpen: Dispatch<SetStateAction<boolean>>;
    setNewLaneDescription: Dispatch<SetStateAction<string>>;
    cardClicked: (ideaId: string) => void;
    loading?: boolean;
    basicLoading?: boolean;
    initialDataLoaded?: boolean;
    setCurrentIdeaId: Dispatch<SetStateAction<string>>;
    highlightCard?: string;
    setLaneIdeaOrder: Dispatch<SetStateAction<string[]>>;
    heading?: string;
    updateProjectLane: (ideaId: string, targetLaneId: string, position: number) => void;
    onIdeaActivity: (ideaId: string) => void;
    rewardIdeaId: string;
    setRewardIdeaId: Dispatch<SetStateAction<string>>;
    setSetupModalOpen: Dispatch<SetStateAction<boolean>>;
    laneOpen: boolean;
    laneFilter?: string[];
    laneImpactMeasures: OpenAPI.GET<"/challenges/{id}/ideas">["response"]["laneImpactMeasures"];
    newLaneName: string;
    newLaneDescription: string;
    newLaneNameSaved: boolean;
    newLaneDescriptionSaved: boolean;
    setNewLaneNameSaved: Dispatch<SetStateAction<boolean>>;
    setNewLaneDescriptionSaved: Dispatch<SetStateAction<boolean>>;
    updateProjectLaneMulti: (originalLane: string, targetLane: string) => void;

    selectionMode?: ProjectSelectionModes;
    selectedIdeas: OpenAPI.Schemas["Idea"][];
    setSelectedIdeas: Dispatch<SetStateAction<OpenAPI.Schemas["Idea"][]>>;
  };

const ProjectBoard = (props: IProjectBoardProps) => {
  const {
    setNewLaneName,
    setLaneOpen,
    setCurrentLane,
    defaultLane,
    setNewLaneDescription,
    user,
    setLanes,
    lanes,
    laneFilter,
    ideas,
    usingPropIdeas,
    ideasTotal,
    loading,
    basicLoading,
    initialDataLoaded,
    setCurrentIdeaId,
    highlightCard,
    handleWebsocketLanes,
    setLaneIdeaOrder,
    canManageBoard,
    laneImpactMeasures,
    forId,
    updateProjectLane,
    rewardIdeaId,
    setRewardIdeaId,
    setSetupModalOpen,
    availableTags,
    selectionMode,
    selectedIdeas,
    setSelectedIdeas,
  } = props;
  const [laneData, setLaneData] = useState<ILaneData[]>([]);
  const [challenge, setChallenge] = useState<OpenAPI.Schemas["Challenge"] | null>(null);
  const totalIdeas = ideas?.length || 0;
  const theme = useTheme();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const queryParams = useMemo(() => new SearchParams(search), [search]);
  const ideaFilter = useMemo(() => queryParams.get("ideaFilter") || "", [queryParams]);
  const ideaView = useMemo(() => queryParams.get("ideaView") || "", [queryParams]);
  const queryTags = useMemo(() => queryParams.get("tags") || "", [queryParams]);
  const queryTagsArray = useMemo(() => queryTags.split(","), [queryTags]);

  const selectedTags = useMemo(
    () => (challenge && challenge.tagList?.filter((tag) => queryTagsArray.includes(tag._id))) || [],
    [queryTagsArray, challenge],
  );

  const isMultiSelect = useMemo(() => selectionMode === "multi", [selectionMode]);

  const onSelectIdeas = useCallback(
    (ideas: OpenAPI.Schemas["Idea"][], toSelect: boolean) => {
      if (toSelect) {
        setSelectedIdeas((prev) => {
          const newIdeas = ideas.filter((idea) => !prev.find((i) => i._id === idea._id));
          return [...prev, ...newIdeas];
        });
      } else {
        setSelectedIdeas((prev) => prev.filter((idea) => !ideas.map((i) => i._id).includes(idea._id)));
      }
    },
    [setSelectedIdeas],
  );

  const removeTag = useCallback(
    (tag) => {
      if (!queryTagsArray.includes(tag._id)) {
        return;
      }
      queryTagsArray.splice(queryTagsArray.indexOf(tag._id), 1);
      queryParams.set("tags", queryTagsArray.join(","));
      navigate({ ...location, search: queryParams.toString() });
    },
    [queryTagsArray, queryParams, navigate, location],
  );

  const addTag = useCallback(
    (tag) => {
      if (queryTagsArray.includes(tag._id)) {
        return;
      }
      queryTagsArray.push(tag._id);
      queryParams.set("tags", queryTagsArray.join(","));
      navigate({ ...location, search: queryParams.toString() });
    },
    [queryTagsArray, queryParams, navigate, location],
  );

  const setIdeaFilter = useCallback(
    (filter) => {
      if (filter.length === 0) {
        queryParams.delete("ideaFilter");
      } else {
        queryParams.set("ideaFilter", filter);
      }
      navigate({ ...location, search: queryParams.toString() });
    },
    [navigate, queryParams, location],
  );

  const setIdeaView = useCallback(
    (view) => {
      queryParams.set("ideaView", view);
      navigate({ ...location, search: queryParams.toString() });
    },
    [navigate, queryParams, location],
  );

  const getLaneData = useCallback(() => {
    const cards: IIdeaCard[] = ideas
      ?.map((idea) => {
        const board = idea?.projectManagement?.boards?.find((b) => b.forId === idea.challenge);
        const ret: IIdeaCard = {
          id: idea._id,
          idea,
          lane: board?.lane,
          position: board?.order || 0,
        };
        if (board?.lane) {
          const cardLane = lanes.find((l) => l._id === board.lane);
          ret.laneMetaType = cardLane?.metaType || "normal";
        }
        if (
          !canManageBoard &&
          !util.hasPermission(user, "challenge.moveProjectIdeas", challenge?._id) &&
          !(board?.assignees?.indexOf(user._id) > -1)
        ) {
          ret.draggingDisabled = true;
        }
        return ret;
      })
      .sort((a, b) => (a.position > b.position ? 1 : -1));

    const newLanes: ILaneData[] =
      !laneFilter?.length || laneFilter.includes("default")
        ? [
            {
              id: "default",
              title: defaultLane?.name || "Default",
              description: defaultLane?.description || "",
              label: "Default",
              cards: cards.filter((i) => !i.lane),
              draggingDisabled: true,
            },
          ]
        : [];
    lanes.forEach((l) => {
      newLanes.push({
        id: l._id,
        title: l.name,
        description: l.description,
        metaType: l.metaType,
        cards: cards.filter((i) => i.lane === l._id),
      });
    });
    const ideaOrder: string[] = [];
    newLanes.forEach((lane) => {
      const { cards: newCards = [] } = lane;
      newCards.forEach((card) => {
        const { idea } = card;
        ideaOrder.push(idea._id);
      });
    });
    setLaneIdeaOrder(ideaOrder);
    setLaneData(newLanes);
  }, [
    ideas,
    laneFilter,
    defaultLane?.name,
    defaultLane?.description,
    lanes,
    setLaneIdeaOrder,
    canManageBoard,
    user,
    challenge?._id,
  ]);

  useEffect(() => {
    getLaneData();
  }, [getLaneData]);

  useEffect(() => {
    if (forId) {
      api.challenges.get(
        forId,
        (c) => {
          setChallenge({
            ...c,
            collaborators: c.collaborators || [],
          });
        },
        () => {},
      );
    }
  }, [forId]);

  const createLane = useCallback(() => {
    const unnamedLanes = lanes.filter((l) => (l.name || "").indexOf("New lane ") > -1);
    api.boards.createProjectLane(
      forId,
      { name: `New lane ${unnamedLanes.length + 1}` },
      (lane) => {
        const newLanes = Object.assign([], lanes);
        newLanes.push(lane);
        setLanes(newLanes);
        handleWebsocketLanes(newLanes);
        setNewLaneName("");
        setNewLaneDescription("");
        setCurrentLane(lane);
      },
      (err) => toast.error(err.message),
    );
  }, [forId, setLanes, handleWebsocketLanes, lanes, setCurrentLane, setNewLaneName, setNewLaneDescription]);

  const cardDragged = useCallback(
    (event) => {
      if (!event.destination) {
        return;
      }
      if (event.type === "card") {
        updateProjectLane(
          event.draggableId.split("-")[1],
          event.destination.droppableId.split("-")[1],
          event.destination.index,
        );
      } else if (event.type === "lane") {
        if (event.source.index === 0) {
          toast.error("Can't move default lane");
          return;
        }
        const actualTargetIndex = event.destination.index - 1;
        const actualSourceIndex = event.source.index - 1;

        const selectedLane = lanes[actualSourceIndex];
        // Create new lanes list
        const prevLanes = Object.assign([], lanes);
        const newLanes = util.moveArrayItem(lanes, actualSourceIndex, actualTargetIndex);

        setLanes(newLanes);
        // Better UX if we update before API call, then reset if it fails
        api.boards.updateProjectLane(
          forId,
          selectedLane._id,
          { order: actualTargetIndex },
          () => {
            handleWebsocketLanes(newLanes);
          },
          () => {
            toast.error("Failed to move lane");
            setLanes(prevLanes);
          },
        );
      }
    },
    [updateProjectLane, lanes, setLanes, forId, handleWebsocketLanes],
  );

  const laneClicked = useCallback(
    (laneId) => {
      setNewLaneName("");
      setLaneOpen(true);
      if (laneId === "default") {
        setCurrentLane(defaultLane);
        setNewLaneDescription(defaultLane?.description || "");
      } else {
        const newLane = lanes.filter((l) => l._id === laneId)[0];
        setCurrentLane(newLane);
        setNewLaneDescription(newLane?.description || "");
      }
    },
    [setNewLaneName, setLaneOpen, setCurrentLane, setNewLaneDescription, lanes, defaultLane],
  );

  const ideaStatusText = useMemo(
    () => ({
      all: `All ${t("generic.ideas")}`,
      me: `${t("common:capitalise", { key: "generic.ideas" })} assigned to me`,
      unassigned: `${t("common:capitalise", { key: "generic.ideas" })} that are unassigned`,
    }),
    [t],
  );

  const showIdeaFilters = useMemo(
    () => (ideas?.length > 0 || ideaFilter || ideaView || loading) && !usingPropIdeas,
    [ideaFilter, ideaView, ideas?.length, loading, usingPropIdeas],
  );
  const IdeaFiltersContent = useMemo(
    () =>
      showIdeaFilters ? (
        <>
          <Input
            icon="search"
            iconPosition="left"
            placeholder={`Filter ${ideasTotal} ${t("generic.ideas")} by name or author...`}
            value={ideaFilter}
            onChange={(e) => setIdeaFilter(e.target.value)}
            style={{
              height: FilterHeight,
              width: 300,
            }}
            size="small"
            loading={loading}
          />
          <TagDropdownChooser
            tags={selectedTags}
            availableTags={availableTags}
            addTag={addTag}
            removeTag={removeTag}
            showCount={false}
            style={{
              marginLeft: 5,
              height: FilterHeight,
              minHeight: 0,
              fontSize: ".92857143em",
            }}
            onStateChange={undefined}
            size="small"
          />
          <Dropdown
            icon={null}
            style={{ marginLeft: 5, height: FilterHeight }}
            direction="left"
            trigger={
              <Button
                basic
                content={`Showing ${ideaStatusText[ideaView || "all"].toLowerCase()}`}
                icon="dropdown"
                style={{ height: FilterHeight }}
                size="small"
              />
            }
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setIdeaView("all")}>Show all {t("generic.ideas")}</Dropdown.Item>
              <Dropdown.Item onClick={() => setIdeaView("me")}>Show {t("generic.ideas")} assigned to me</Dropdown.Item>
              <Dropdown.Item onClick={() => setIdeaView("unassigned")}>
                Show {t("generic.ideas")} that are unassigned
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : null,
    [
      showIdeaFilters,
      ideasTotal,
      ideaFilter,
      loading,
      selectedTags,
      availableTags,
      addTag,
      removeTag,
      ideaStatusText,
      ideaView,
      setIdeaFilter,
      setIdeaView,
      t,
    ],
  );

  const canSetupLanes = useMemo(
    () => (canManageBoard || util.hasPermission(user, "challenge.editProjectBoard", challenge?._id)) && !lanes.length,
    [canManageBoard, challenge?._id, user, lanes.length],
  );
  const browserIsSupported = useMemo(() => util.browserIsSupported(), []);
  const showHeaderContent = useMemo(
    () => canSetupLanes || !browserIsSupported || !usingPropIdeas,
    [browserIsSupported, canSetupLanes, usingPropIdeas],
  );

  const BoardHeaderContent = useMemo(
    () => (
      <>
        {canSetupLanes ? (
          <Button
            icon="cogs"
            size="small"
            content="Setup lanes"
            onClick={() => setSetupModalOpen(true)}
            style={{ marginLeft: 5, height: FilterHeight }}
          />
        ) : null}
        {!browserIsSupported && (
          <Message warning icon="info circle">
            <Message.Header>Unsupported browser</Message.Header>
            <p>
              {util.appName()} is unable to display the project board because your browser does not support the required
              features. We recommend updating to a more modern browser, such as a newer version of Edge, Firefox or
              Chrome.
            </p>
          </Message>
        )}
        {!usingPropIdeas ? <BoardEvents forId={forId} setCurrentIdeaId={setCurrentIdeaId} /> : null}
      </>
    ),
    [browserIsSupported, canSetupLanes, forId, setCurrentIdeaId, setSetupModalOpen, usingPropIdeas],
  );

  const laneMinHeight = useMemo(() => {
    // The min height of a board is:
    // Take the lane with the most cards
    // 250px * the number of cards in that lane, with a max of 1000px
    if (!laneData.length) {
      return 0;
    }
    const laneWithMostCards = laneData.reduce((prev, current) =>
      (prev?.cards?.length ?? 0) > (current?.cards?.length ?? 0) ? prev : current,
    );
    return Math.min(250 * (laneWithMostCards?.cards?.length ?? 0), 1000);
  }, [laneData]);

  return (
    <StyledProjectBoardContainer $minHeight={laneMinHeight}>
      {showIdeaFilters || showHeaderContent ? (
        <StyledBoard>
          <StyledBoardHeader>
            {theme.sizes.isComputer ? (
              <>
                <div className="filter-container">
                  <div className="filters">{IdeaFiltersContent}</div>
                </div>
                <div className="filter-container">
                  <div className="filters">{BoardHeaderContent}</div>
                </div>
              </>
            ) : (
              <div className="filter-container">
                <div className="filters">
                  {IdeaFiltersContent}
                  {BoardHeaderContent}
                </div>
              </div>
            )}
          </StyledBoardHeader>
        </StyledBoard>
      ) : null}
      <StyledLaneContainer>
        {basicLoading && !ideaFilter && !ideaView && !initialDataLoaded ? (
          <LoadingContainer>
            <Loader active inline size="small" />
            <span>Loading project board...</span>
          </LoadingContainer>
        ) : (
          <>
            {!ideas?.length && !ideaFilter && !ideaView && !loading && !usingPropIdeas ? (
              <Message
                style={{ margin: 10 }}
                info
                header={`No ${t("generic.ideas")} are on this board yet`}
                content={`Submitted ${t("generic.ideas")} will be automatically added to this board.`}
              />
            ) : null}
            <Board
              lanes={laneData}
              onDragEnd={cardDragged}
              createLane={
                canManageBoard || util.hasPermission(user, "challenge.editProjectBoard", challenge?._id)
                  ? createLane
                  : null
              }
              highlightCard={highlightCard}
              renderLaneHeader={(lane, handle) => {
                const { cards = [] } = lane;
                const allIdeasSelected =
                  cards.length > 0 && cards.every((card) => !!selectedIdeas.find((sidea) => sidea === card.idea._id));
                return (
                  <CustomLaneHeader
                    lane={lane}
                    handleProps={handle}
                    totalIdeas={totalIdeas}
                    clickLane={laneClicked}
                    user={user}
                    canManageBoard={canManageBoard}
                    laneImpactMeasures={laneImpactMeasures[lane.id] ?? []}
                    laneSettingsProps={props}
                    isSelecting={isMultiSelect}
                    isSelected={allIdeasSelected}
                    onSelect={() => {
                      if (allIdeasSelected) {
                        onSelectIdeas(
                          cards.map((card) => card.idea),
                          false,
                        );
                      } else {
                        onSelectIdeas(
                          cards.map((card) => card.idea),
                          true,
                        );
                      }
                    }}
                  />
                );
              }}
              renderCard={(card, handle) => (
                <CustomLaneCard
                  card={card}
                  handleProps={handle}
                  user={user}
                  onClick={setCurrentIdeaId}
                  highlightCard={highlightCard}
                  rewardIdeaId={rewardIdeaId}
                  setRewardIdeaId={setRewardIdeaId}
                  isSelecting={isMultiSelect}
                  onSelect={(idea) => onSelectIdeas([idea], !selectedIdeas.find((i) => i._id === idea._id))}
                  isSelected={selectedIdeas.find((i) => i._id === card.idea._id) !== undefined}
                  loading={loading}
                  basicLoading={basicLoading}
                />
              )}
            />
          </>
        )}
      </StyledLaneContainer>
    </StyledProjectBoardContainer>
  );
};

export default ProjectBoard;
