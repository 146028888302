import React from "react";
import { Label } from "semantic-ui-react";
import styled from "styled-components";

const CardContainerGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  max-width: 100%;
`;

const ChallengeListCard = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 7.5px;
  border-radius: 5px;
  height: 55px;
  flex: 1;
  h4 {
    margin: 0;
  }
  background-color: #fff;
  max-width: 100%;
  ${({ $isPinned }) => $isPinned && "border: 1px solid#048C85"}
  ${({ $showCompleteness }) =>
    $showCompleteness &&
    `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}
`;

const ChallengeCoverImage = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 5px;
  background-image: url(${({ $coverImage }) => $coverImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    box-shadow: inset 0 0 0 2000px rgba(211, 211, 211, 0.3);
  }
`;

const ChallengeInfo = styled.div`
  display: flex;
  flex: 1 1 0%;
  width: 0;
  flex-direction: column;
  margin: 0;
  align-items: flex-start;
  padding-right: 5px;
  margin-right: 10px;
  border-right: 0.5px solid gray;
  max-width: ${({ theme }) => (theme.sizes.isMobile ? "70%" : "30%")};
  h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    margin-bottom: 2px;
    cursor: pointer;
    &:hover {
      text-decoration-line: underline;
    }
  }
  span {
    display: block;
    color: gray;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    a,
    .link {
      text-decoration-line: underline;
      color: gray;
    }
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
  }
`;

const StatusLabel = ({ style, ...props }) => <Label {...props} style={{ ...style, marginRight: 5 }} circular empty />;

const ChallengeStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2px;
  span,
  a {
    color: black;
  }
  a {
    text-decoration-line: underline;
  }
  .ui.label {
    width: 1em !important;
    height: 1em !important;
    margin-bottom: 1.5px;
  }
  i {
    line-height: 1.2em;
    height: 1.2em;
    width: 0.9em;
  }
`;

const ChallengeActions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ChallengeSocialChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;

  i,
  span {
    color: #505050;
    font-size: 14px;
    line-height: 14px;
  }
  span {
    font-weight: bold;
  }
`;

const ChallengePinnedIcon = styled.div`
  background-color: ${({ $isPinned }) => ($isPinned ? "#048C85" : "#f5f5f5")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $isPinned }) => ($isPinned ? "#fff" : "#505050")};
  height: 55px;
  border-top-left-radius: ${({ $showCompleteness }) => ($showCompleteness ? "0" : "5px")};
  border-bottom-left-radius: 5px;
  margin: 0 7.5px 0 -7.5px;
  padding: 0 5px;
  // border-top: 1px solid ${({ $isPinned }) => ($isPinned ? "#048C85" : "lightgray")};
  // border-bottom: 1px solid ${({ $isPinned }) => ($isPinned ? "#048C85" : "lightgray")};
  &:hover {
    ${({ $isPinned }) => !$isPinned && "background-color: #e6e6e6"}
  }
  i {
    font-size: 1.2em;
    margin: 0;
  }
  ${({ $canManage }) =>
    $canManage &&
    `
    cursor: pointer;
  `}
`;

export {
  CardContainerGroup,
  CardContainer,
  ChallengeListCard,
  ChallengeCoverImage,
  ChallengeInfo,
  ChallengeStatus,
  StatusLabel,
  ChallengeActions,
  ChallengeSocialChip,
  ChallengePinnedIcon,
};
