import { OpenAPI } from "simplydo/interfaces";
import ConfigurableTable from "../../ConfigurableTable";
import { Link } from "react-router-dom";
import util from "utils/utils";
import { Button, Image, Segment, Table } from "semantic-ui-react";
import moment from "moment";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import RoleAssigner from "../../Roles/RoleAssigner";
import constants from "utils/constants";
import api, { asMutation, asQuery } from "api";
import { UserChooser } from "../../Choosers";
import toast from "react-hot-toast";
import { UserChip } from "../../Chips";
import actions from "actions";
import { useMutation, useQuery } from "@tanstack/react-query";

type UsersProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  closeModal: () => void;
};

export const Users = ({ businessProfile, closeModal }: UsersProps) => {
  const [assignRoleUser, setAssignRoleUser] = useState(null);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const canManageUsers = util.hasPermission(user, "ideaBusinessProfile.editMembers", businessProfile._id);

  const usersQuery = useQuery({
    queryKey: ["get", "roles", "ideaBusinessProfile", businessProfile._id, "users"],
    queryFn: asQuery(api.roles.getUsers, {
      params: ["ideaBusinessProfile", businessProfile._id, {}],
    }),
  });

  const invitationQuery = useQuery({
    queryKey: ["get", "invitations", "ideaBusinessProfile", businessProfile._id],
    queryFn: asQuery(api.invitations.getForType, {
      params: ["ideaBusinessProfile", businessProfile._id],
    }),
    enabled: canManageUsers,
  });

  const createInvitationMutation = useMutation({
    mutationKey: ["post", "invitations", "bulk"],
    mutationFn: asMutation(api.invitations.createBulk),
    onSuccess: () => {
      toast.success("Invitation sent");
      api.queryClient.invalidateQueries({
        queryKey: ["get", "invitations", "ideaBusinessProfile", businessProfile._id],
      });
    },
    onError: () => {
      toast.error("Failed to send invite");
    },
  });

  const removePendingInvitationMutation = useMutation({
    mutationKey: ["delete", "invitations"],
    mutationFn: asMutation(api.invitations.remove),
    onSuccess: () => {
      toast.success("Invitation removed. The user can no longer accept it.");
      api.queryClient.invalidateQueries({
        queryKey: ["get", "invitations", "ideaBusinessProfile", businessProfile._id],
      });
    },
    onError: () => {
      toast.error("Failed to remove invite");
    },
  });

  const removeUserFromRolesMutation = useMutation({
    mutationKey: ["delete", "roles", "ideaBusinessProfile", businessProfile._id, "users"],
    mutationFn: asMutation(api.roles.removeUserFromAllRoles),
    onSuccess: () => {
      api.queryClient.invalidateQueries({
        queryKey: ["get", "users", "roles", "ideaBusinessProfile", businessProfile._id],
      });
    },
  });

  return (
    <div
      style={{
        display: "flex",
        gap: 12,
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {util.hasPermission(user, "ideaBusinessProfile.editMembers", businessProfile._id) ? (
        <>
          <RoleAssigner
            forType="ideaBusinessProfile"
            forId={businessProfile._id}
            forUsers={assignRoleUser ? [assignRoleUser._id] : []}
            permissionOptions={constants.permissions.ideaBusinessProfile}
            onAssign={() => {
              api.queryClient.invalidateQueries({
                queryKey: ["get", "roles", "ideaBusinessProfile", businessProfile._id, "users"],
              });
            }}
            onUnassign={() => {
              api.queryClient.invalidateQueries({
                queryKey: ["get", "roles", "ideaBusinessProfile", businessProfile._id, "users"],
              });
            }}
            modalProps={{
              onClose: () => setAssignRoleUser(null),
            }}
          />
          {invitationQuery.data?.invitations?.length > 0 ? (
            <Segment style={{ alignSelf: "stretch" }}>
              <b style={{ fontSize: 18 }}>Pending invitations</b>
              <Table basic="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>User</Table.HeaderCell>
                    <Table.HeaderCell>Invited by</Table.HeaderCell>
                    <Table.HeaderCell>Created at</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {invitationQuery.data?.invitations?.map((r) => (
                    <Table.Row key={r.inviteeUser?._id || r.invitee}>
                      <Table.Cell>{r.inviteeUser ? <UserChip user={r.inviteeUser} /> : r.invitee}</Table.Cell>
                      <Table.Cell>
                        <UserChip user={r.inviterUser} />
                      </Table.Cell>
                      <Table.Cell>{moment(r.createdAt).format("DD/MM/YY")}</Table.Cell>
                      <Table.Cell textAlign="right">
                        <Button
                          icon="trash"
                          basic
                          size="tiny"
                          onClick={() =>
                            removePendingInvitationMutation.mutate({
                              params: [r._id],
                            })
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>
          ) : null}
        </>
      ) : null}
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          alignSelf: "stretch",
        }}
      >
        <b style={{ fontSize: 22 }}>Users</b>
        {util.hasPermission(user, "ideaBusinessProfile.editMembers", businessProfile._id) ? (
          <UserChooser
            trigger={<Button content="Invite users" primary />}
            forType="ideaBusinessProfile"
            forId={businessProfile._id}
            enabledFeatures={{ search: true, invite: true, persistentToken: true }}
            searchFunction={(term, cb) =>
              api.roles.getPotentialRoleUsers(
                "ideaBusinessProfile",
                businessProfile._id,
                "default",
                { query: term },
                (res) => cb(res.users),
                () => {},
              )
            }
            confirm="Invite users to profile"
            clearOnComplete
            onComplete={(users) => {
              const emailUsers = users.filter((u) => u.isEmailInvitee);
              if (emailUsers.length) {
                createInvitationMutation.mutate({
                  params: [
                    {
                      invitees: emailUsers.map((u) => u._id),
                      invitationType: "email",
                      forType: "ideaBusinessProfile",
                      forId: businessProfile._id,
                    },
                  ],
                });
              }

              const internalInvitees = users.filter((u) => !u.isEmailInvitee);
              if (internalInvitees.length > 0) {
                createInvitationMutation.mutate({
                  params: [
                    {
                      invitees: internalInvitees.map((u) => u._id),
                      invitationType: "user",
                      forType: "ideaBusinessProfile",
                      forId: businessProfile._id,
                    },
                  ],
                });
              }
            }}
          />
        ) : null}
      </div>
      <ConfigurableTable
        tableKey="businessProfileUsers"
        data={usersQuery.data?.users || []}
        keyExtractor={(user) => user._id}
        actions={(items) =>
          canManageUsers
            ? [
                {
                  name: "Assign roles",
                  icon: "key",
                  onClick: (items) => setAssignRoleUser(items[0]),
                },
                {
                  name: "Remove from profile",
                  icon: "trash",
                  onClick: (items) => {
                    util
                      .confirm("Remove user", "Are you sure you want to remove this user from the profile?")
                      .then(() => {
                        removeUserFromRolesMutation.mutate({
                          params: ["ideaBusinessProfile", businessProfile._id, items[0]._id],
                        });
                      })
                      .catch(() => {});
                  },
                },
              ]
            : items.length === 1 && items[0]._id === user._id
              ? [
                  {
                    name: "Leave profile",
                    icon: "trash",
                    onClick: () => {
                      util
                        .confirm("Leave profile", "Are you sure you want to leave this profile?")
                        .then(() => {
                          api.roles.removeUserFromAllRoles(
                            "ideaBusinessProfile",
                            businessProfile._id,
                            user._id,
                            () => {
                              dispatch(actions.user.updatePermissions(businessProfile._id, []));
                              closeModal();
                            },
                            () => {},
                          );
                        })
                        .catch(() => {});
                    },
                  },
                ]
              : []
        }
        columns={[
          {
            key: "image",
            name: "",
            settingName: "Image",
            width: 40,
            center: true,
            render: ({ item }) => (
              <Link to={`/users/${item._id}`}>
                <Image style={{ objectFit: "cover" }} avatar src={util.avatarUrl(item)} />
              </Link>
            ),
          },
          {
            key: "firstName",
            name: "First name",
            render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.firstName}</Link>,
            sortable: true,
          },
          {
            key: "lastName",
            name: "Last name",
            render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.lastName}</Link>,
            sortable: true,
          },
          {
            key: "department",
            name: "Department",
            render: ({ item }) => item.profile.department,
            sortable: false,
          },
          {
            key: "roles",
            name: "Roles",
            render: ({ item }) => item.roles.map((r) => r.name).join(", "),
            sortable: false,
          },
          {
            key: "lastSeenAt",
            name: "Last seen",
            render: ({ cell }) => moment(cell).format("DD/MM/YYYY"),
            sortable: false,
          },
        ]}
      />
    </div>
  );
};
