import React from "react";
import styled from "styled-components";

const TimezoneParagraph = styled.p`
  margin-bottom: 20px;
  color: grey;
  font-style: italic;
`;

type TimezoneExplainerProps = {
  style?: React.CSSProperties;
};

const TimezoneExplainer = ({ style }: TimezoneExplainerProps) => (
  <div style={style}>
    <TimezoneParagraph>The date and time will be displayed in users' local timezones.</TimezoneParagraph>
  </div>
);

export default TimezoneExplainer;
