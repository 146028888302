import { useEffect, useState, useCallback, useRef } from "react";
import { Button } from "semantic-ui-react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DraggableProvidedDragHandleProps,
  OnDragEndResponder,
  DropResult,
  ResponderProvided,
  DragUpdate,
  DragStart,
} from "react-beautiful-dnd";
import styled from "styled-components";
import { OpenAPI } from "simplydo/interfaces";
import { ProjectSelectionModes } from "../.";

export type IIdeaCard = {
  id: string;
  idea: OpenAPI.Schemas["Idea"];
  lane?: string;
  position?: number;
  laneMetaType?: OpenAPI.Schemas["projectLanes"]["metaType"];
  draggingDisabled?: boolean;
};

export type ILaneData = {
  id: string;
  title: string;
  label?: string;
  description?: string;
  metaType?: string;
  cards: IIdeaCard[];
  draggingDisabled?: boolean;
};

type BoardProps = {
  lanes: ILaneData[];
  highlightCard?: string;
  renderCard: (card: ILaneData["cards"][0], handle: DraggableProvidedDragHandleProps) => JSX.Element;
  renderLaneHeader?: (lane: ILaneData, handle: DraggableProvidedDragHandleProps) => JSX.Element;
  draggable?: boolean;
  onDragEnd?: OnDragEndResponder;
  createLane?: () => void;
  projectSelectionMode?: ProjectSelectionModes;
};

type PlaceholderProps = {
  destination: { droppableId: string; index: number };
  contextId: string;
  isBetweenLanes: boolean;
  leftSourceLane: boolean;
  isLastItem: boolean;
  clientHeight: number;
  clientWidth: number;
  clientY: number;
  clientX: number;
};

const BoardWrapper = styled.div`
  scroll-margin-top: 160px;
  display: flex;
  padding: 0 0 10px;
  height: 100%;
`;

const BoardOverflow = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const LaneWrapper = styled.div<{ $isDraggingCard?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  flex: 0 0 300px;
  width: 300px;
  margin: 0 10px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  max-height: 100%;
  .lane-content {
    width: 100%;
    height: 100%;
    padding: 1px 8px;
    ${({ $isDraggingCard }) =>
      $isDraggingCard &&
      `
      ::-webkit-scrollbar {
        display: none;
      }
    `}
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5px; /* Add border-radius for a rounded thumb */
    }
    ::-webkit-scrollbar-track {
      background: rgb(230, 231, 233);
    }
  }

  border-radius: 5px;
  background: rgb(241, 242, 244);
  box-shadow:
    rgba(9, 30, 66, 0.25) 0px 1px 1px 0px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px 0px;

  div[data-rbd-placeholder-context-id] {
    transition-duration: 0s !important;
  }
`;

const NewLaneWrapper = styled(LaneWrapper)`
  padding: 0;
  .ui.button {
    border: 0;
    margin: 0;
    box-shadow: none;
  }
`;

const CardDropZone = styled.div`
  min-height: 100px;
  position: relative;

  > div[data-droppable-id] {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
`;

const cardMargin = 8;
const CardWrapper = styled.div`
  margin-bottom: ${cardMargin}px;
  cursor: pointer;
  z-index: 1;
  &.is-dragging > * {
    transition-duration: 0.1s !important;
    transform: rotate(7deg);
    box-shadow:
      rgba(9, 30, 66, 0.37) 0px 10px 20px -5px,
      rgba(9, 30, 66, 0.47) 0px 0px 1px;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  border-radius: 8px;
  background-color: #dadbe0;

  transition: all 0.1s ease-in-out;
  z-index: 0;
  width: 100%;
`;

const queryAttr = "data-rbd-drag-handle-draggable-id";

const Board = ({ lanes, renderCard, renderLaneHeader, onDragEnd, createLane, highlightCard }: BoardProps) => {
  const [stateLanes, setStateLanes] = useState(lanes);
  const [placeholderProps, setPlaceholderProps] = useState<PlaceholderProps>(null);
  const [isDraggingCard, setIsDraggingCard] = useState(false);
  const [isDraggingBoard, setIsDraggingBoard] = useState(false);
  const boardRef = useRef(null);
  const highlightRef = useRef(null);
  const [didRender, setDidRender] = useState(false);

  useEffect(() => {
    setStateLanes(lanes);
  }, [lanes]);

  useEffect(() => {
    if (boardRef.current) {
      boardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (highlightRef.current) {
      highlightRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
  }, [didRender]);

  useEffect(() => {
    const draggableBoard = document.getElementsByClassName("board-overflow").item(0) as HTMLDivElement;
    if (!draggableBoard) {
      return;
    }

    const dragBoardListener = (e: MouseEvent) => {
      if (isDraggingBoard) {
        draggableBoard.scrollLeft -= e.movementX;
      } else if (isDraggingCard) {
        const isNearLeftEdge = e.clientX - draggableBoard.offsetLeft < 175;
        const isNearRightEdge = e.clientX > window.innerWidth - 175;
        const speed = 10;
        if (isNearLeftEdge) {
          const movement = Math.min(speed, draggableBoard.scrollLeft);
          draggableBoard.scrollLeft -= movement;
          const dragged = document.getElementsByClassName("is-dragging").item(0) as HTMLDivElement;
          if (dragged) {
            dragged.style.left = `${parseInt(dragged.style.left, 10) + movement}px`;
          }
        } else if (isNearRightEdge) {
          const movement = Math.min(
            speed,
            draggableBoard.scrollWidth - draggableBoard.scrollLeft - draggableBoard.clientWidth,
          );
          draggableBoard.scrollLeft += movement;
          const dragged = document.getElementsByClassName("is-dragging").item(0) as HTMLDivElement;
          if (dragged) {
            dragged.style.left = `${parseInt(dragged.style.left, 10) - movement}px`;
          }
        }
      }
    };
    document.addEventListener("mousemove", dragBoardListener);
    return () => {
      document.removeEventListener("mousemove", dragBoardListener);
    };
  }, [isDraggingBoard, isDraggingCard]);

  useEffect(() => {
    const listener = (_evt) => {
      // Always release the board or card if a mouseup occurs anywhere on the document
      setIsDraggingBoard(false);
      setIsDraggingCard(false);

      const dragged = document.getElementsByClassName("is-dragging").item(0) as HTMLDivElement;
      if (!dragged) {
        return;
      }
      dragged.classList.remove("is-dragging");
    };
    document.addEventListener("mouseup", listener);
    return () => {
      document.removeEventListener("mouseup", listener);
    };
  }, []);

  // This function is used to update the internal state of the board until the data is updated from the outside, this way the result looks smooth to the user.
  const handleInternalStateUpdate = (event: DropResult, _responder: ResponderProvided) => {
    const { draggableId } = event;
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery) as HTMLDivElement;

    if (draggedDOM) {
      draggedDOM.parentElement.classList.remove("is-inbetween");
      draggedDOM.parentElement.classList.remove("left-source-lane");
      draggedDOM.parentElement.classList.remove("is-dragging");
    }

    setPlaceholderProps(null);
    if (!event.destination) {
      if (event.type === "card" && placeholderProps.destination) {
        event.destination = placeholderProps.destination;
      } else {
        return;
      }
    }

    if (event.type === "lane") {
      setStateLanes((prevState) => {
        const newState = [...prevState];
        const [removed] = newState.splice(event.source.index, 1);
        newState.splice(event.destination!.index, 0, removed);
        return newState;
      });
    } else if (event.type === "card") {
      setStateLanes((prevState) => {
        const newState = [...prevState];
        const sourceLane = newState.find((lane) => `lane-${lane.id}` === event.source.droppableId);
        const destinationLane = newState.find((lane) => `lane-${lane.id}` === event.destination!.droppableId);
        const [removed] = sourceLane!.cards.splice(event.source.index, 1);
        destinationLane!.cards.splice(event.destination!.index, 0, removed);
        return newState;
      });
    }
  };

  const onCardDragStart = useCallback((update: DragStart) => {
    if (update.type === "card") {
      setIsDraggingCard(true);
    }
  }, []);

  const onCardDragEnd = useCallback(() => {
    setIsDraggingCard(false);
  }, []);

  /**
   * This function has to calculate a ton of different things to make the placeholder look good.
   */
  const onDragUpdate = (update: DragUpdate) => {
    if (update.type !== "card") {
      return;
    }

    // Check whether the currently dragged card is inbetween two lanes
    // If it is, we want to render the placeholder in the "last" lane rather than the source lane which is the default behaviour
    let isBetweenLanes = false;
    if (!update.destination) {
      if (placeholderProps?.destination) {
        update.destination = placeholderProps.destination;
        isBetweenLanes = true;
      } else {
        return;
      }
    }

    const { draggableId } = update;
    const sourceIndex = update.source.index;
    const destinationIndex = update.destination.index;

    const leftSourceLane = update.source.droppableId !== update.destination.droppableId;
    const movedLanes = placeholderProps && update.destination.droppableId !== placeholderProps?.destination.droppableId;
    if (movedLanes || isBetweenLanes) {
      // Find all currently rendered placeholders
      // Placeholder appear only once a card is dragged into the lane, but stay until the card is dropped with a height of 0
      const pholderQuery = `[data-rbd-placeholder-context-id='${placeholderProps?.contextId}']`;
      const placeholderDOMS = document.querySelectorAll(pholderQuery);

      // For each placeholder...
      placeholderDOMS.forEach((placeholderDOM: HTMLDivElement) => {
        const droppableId = placeholderDOM.parentElement.getAttribute("data-rbd-droppable-id");
        const isSourceLane = droppableId === update.source.droppableId;
        const isCurrentLane = droppableId === update.destination.droppableId;

        const placeholderContainerQuery = `[data-droppable-id='${droppableId}']`;
        const placeholderContainerDOM = document.querySelector(placeholderContainerQuery) as HTMLDivElement;
        const placeholderChildren = [...Array.from(placeholderContainerDOM.children)];

        // Check if the placeholder is currently rendered in the lane the card is dragged into
        if (isCurrentLane) {
          placeholderChildren.forEach((child: HTMLDivElement) => {
            // We use the scale(1) attribute just to delineate between our manual offsets and those of react-beautiful-dnd
            // If we re-enter a lane we want to remove any of these manual offsets because react-beautiful-dnd will take over again
            if (child.style.transform.indexOf("scale") !== -1) {
              child.style.transform = "";
            }
          });

          if (isBetweenLanes || placeholderProps.isBetweenLanes) {
            // If we are in the source lane we have to consider the reordering of the cards which happens by dragging the card up and down
            // in any other lanes this is not necessary because the index technically never actually exists and it's all handled through css offsets
            let laterItems = placeholderChildren;
            if (isSourceLane) {
              const movedPlaceholderChild = laterItems[sourceIndex];
              laterItems.splice(sourceIndex, 1);
              laterItems.splice(destinationIndex, 0, movedPlaceholderChild);
            }
            laterItems = placeholderChildren.slice(destinationIndex);

            // When swiping in between lanes react-beautiful-dnd will render the placeholder in the source lane rather than in the last position a card was dragged into
            // This is annoying and unwanted behaviour, here we fix it by rendering the placeholder in the last position a card was dragged into
            const maintainPlaceholderHeight = placeholderProps.clientHeight + (leftSourceLane ? cardMargin : 0);
            const applyStyles = () => {
              placeholderDOM.style.height = `${maintainPlaceholderHeight}px`;

              // we have to apply a transform to all children following the card placeholder so that they don't render on top of it
              laterItems.forEach((child: HTMLDivElement) => {
                child.style.transform = `translate(0px, ${placeholderProps.clientHeight + cardMargin}px) scale(1)`;
              });
            };

            applyStyles();
          }
        } else {
          placeholderDOM.style.height = "0px";
          placeholderChildren.forEach((child: HTMLDivElement) => {
            if (child.classList.contains("is-dragging")) {
              return;
            }
            // reset only y transform
            const match = child.style.transform.match(/translate\((.*?),\s?(.*?)\)/);
            if (match) {
              child.style.transform = `translate(${match[1]}, 0px)`;
            }
          });
        }
      });
    }

    const currentLane = stateLanes.find((lane) => `lane-${lane.id}` === update.destination.droppableId);
    const isLastItem = update.destination.index === currentLane.cards.length - (leftSourceLane ? 0 : 1);

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery) as HTMLDivElement;

    const containerQuery = `[data-droppable-id='${update.destination.droppableId}']`;
    const containerDOM = document.querySelector(containerQuery) as HTMLDivElement;

    if (!draggedDOM || !containerDOM) {
      return;
    }
    draggedDOM.parentElement.classList.add("is-dragging");
    if (isBetweenLanes) {
      draggedDOM.parentElement.classList.add("is-inbetween");
    }
    if (leftSourceLane) {
      draggedDOM.parentElement.classList.add("left-source-lane");
    }

    // Calculate the position, height and width of the actual placeholder itself
    const { clientHeight, clientWidth } = draggedDOM;
    const childrenArray = [...Array.from(containerDOM.children)];
    if (!leftSourceLane) {
      const movedItem = childrenArray[sourceIndex];
      childrenArray.splice(sourceIndex, 1);
      childrenArray.splice(destinationIndex, 0, movedItem);
    }

    const clientY =
      parseFloat(window.getComputedStyle(containerDOM).paddingTop) +
      childrenArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      destination: update.destination,
      contextId: draggedDOM.getAttribute("data-rbd-drag-handle-context-id"),
      isBetweenLanes,
      leftSourceLane,
      isLastItem,
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(containerDOM).paddingLeft),
    });
  };

  return (
    <DragDropContext
      onDragEnd={(event, responder) => {
        handleInternalStateUpdate(event, responder);
        onDragEnd && onDragEnd(event, responder);
        onCardDragEnd();
      }}
      onDragUpdate={onDragUpdate}
      onDragStart={(update) => {
        const dragUpdate = update as DragUpdate;
        dragUpdate.destination = update.source;
        onCardDragStart(update);
        onDragUpdate(dragUpdate);
      }}
    >
      <Droppable droppableId="board" direction="horizontal" type="lane">
        {(providedLaneDroppable) => (
          <BoardWrapper
            className="board-wrapper"
            ref={(node) => {
              providedLaneDroppable.innerRef(node);
              boardRef.current = node;
              setDidRender(true);
            }}
            {...providedLaneDroppable.droppableProps}
          >
            <BoardOverflow
              className="board-overflow"
              onMouseDown={(e) => {
                if (!(e.target as HTMLDivElement).classList.contains("board-overflow")) {
                  return;
                }
                if (e.button !== 0 || e.buttons !== 1) {
                  return;
                }
                setIsDraggingBoard(true);
              }}
              onMouseUp={() => {
                if (isDraggingBoard) {
                  setIsDraggingBoard(false);
                }
              }}
            >
              {stateLanes.map((lane, laneIndex) => {
                const isDraggingOverLane = placeholderProps?.destination?.droppableId === `lane-${lane.id}`;
                return (
                  <Draggable
                    isDragDisabled={lane.draggingDisabled}
                    key={lane.id}
                    draggableId={`lane-${lane.id}`}
                    index={laneIndex}
                  >
                    {(providedLaneDraggable) => (
                      <LaneWrapper
                        $isDraggingCard={isDraggingCard}
                        ref={providedLaneDraggable.innerRef}
                        {...providedLaneDraggable.draggableProps}
                      >
                        {renderLaneHeader ? renderLaneHeader(lane, providedLaneDraggable.dragHandleProps ?? {}) : null}
                        <div className="lane-content">
                          <Droppable droppableId={`lane-${lane.id}`} direction="vertical" type="card">
                            {(providedCardDroppable) => (
                              <CardDropZone
                                ref={providedCardDroppable.innerRef}
                                {...providedCardDroppable.droppableProps}
                              >
                                <div data-droppable-id={`lane-${lane.id}`}>
                                  {lane.cards.map((card, cardIndex) => (
                                    <Draggable
                                      key={card.id}
                                      draggableId={`card-${card.id}`}
                                      isDragDisabled={card.draggingDisabled}
                                      index={cardIndex}
                                    >
                                      {(providedCardDraggable) => (
                                        <CardWrapper
                                          ref={(node) => {
                                            providedCardDraggable.innerRef(node);
                                            if (highlightCard === card.id) {
                                              highlightRef.current = node;
                                            }
                                          }}
                                          {...providedCardDraggable.draggableProps}
                                        >
                                          {renderCard(card, providedCardDraggable.dragHandleProps ?? {})}
                                        </CardWrapper>
                                      )}
                                    </Draggable>
                                  ))}
                                </div>
                                {providedCardDroppable.placeholder}
                                {isDraggingOverLane ? (
                                  <Placeholder
                                    style={{
                                      top: placeholderProps.clientY,
                                      left: placeholderProps.clientX,
                                      height: placeholderProps.clientHeight,
                                    }}
                                    {...placeholderProps}
                                  />
                                ) : null}
                              </CardDropZone>
                            )}
                          </Droppable>
                        </div>
                      </LaneWrapper>
                    )}
                  </Draggable>
                );
              })}
              {providedLaneDroppable.placeholder}
              {createLane ? (
                <NewLaneWrapper>
                  <Button basic content="Create a new lane" icon="plus" onClick={createLane} />
                </NewLaneWrapper>
              ) : null}
            </BoardOverflow>
          </BoardWrapper>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Board;
