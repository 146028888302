import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import util from "utils/utils";

import StageChangedContent from "./Challenges/StageChanged";
import ClosingSoonContent from "./Challenges/ClosingSoon";
import IdeaVotedContent from "./Idea/Voted";
import UnlockedAchievementContent from "./UnlockedAchievement";
import MentionedContent from "./Mentioned";
import DiscussionPostedContent from "./Group/DiscussionPosted";
import ChallengeClosed from "./Challenges/ChallengeClosed";
import RequestOpen from "./Challenges/RequestOpen";
import ProjectIdeaCommented from "./Project/ProjectIdeaCommented";
import ProjectIdeaReplied from "./Project/ProjectIdeaReplied";
import ProjectAssignedAssignee from "./Project/ProjectAssignedAssignee";
import ProjectAssignedManager from "./Project/ProjectAssignedManager";
import ProjectIdeaMoved from "./Project/ProjectIdeaMoved";
import AssessorAssignedContent from "./AssessorAssigned";
import GroupInvitedContent from "./Group/Invited";
import GroupJoinRequestedContent from "./Group/JoinRequested";
import IdeaSubmittedContent from "./Idea/Submitted";
import IdeaCommentedContent from "./Idea/Commented";
import AddedAsCollaboratorContent from "./Idea/CollaboratorAdded";
import CommentReacted from "./Idea/CommentReacted";
import DeadlineApproaching from "./Idea/DeadlineApproaching";
import DeadlineSet from "./Idea/DeadlineSet";
import PollClosed from "./PollClosed";
import AnnouncementReport from "./AnnouncementReport";
import IdeaCommentReply from "./Idea/CommentReply";
import GroupDiscussionReactedContent from "./Group/DiscussionReacted";
import GroupDiscussionRepliedContent from "./Group/DiscussionReplied";
import { ImageWithFallback } from "components/lib/ImageWithFallback";

import JoinRequestedActions from "./Group/JoinRequestedActions";
import InvitedActions from "./Group/InvitedActions";
import { t } from "i18next";
import { IdeaBusinessProfileInvited, IdeaBusinessProfileInvitedActions } from "./IdeaBusinessProfileInvited";

const mentions = [
  {
    type: "mentionedGroupDiscussion",
    message: "mentioned you in a group discussion",
    link: "groups",
    location: "discussion",
    id: "group",
  },
  {
    type: "mentionedIdeaComment",
    message: `mentioned you in ${t("generic.ideaWithArticle")} comment`,
    link: "ideas",
    location: "",
    id: "idea",
  },
  {
    type: "mentionedIdeaField",
    message: `mentioned you in ${t("generic.ideaWithArticle")} text`,
    link: "ideas",
    location: "",
    id: "idea",
  },
  {
    type: "mentionedIdeaProjectComment",
    message: "mentioned you in a project board comment",
    link: "challenges",
    location: "board",
  },
];

const notificationAllowlist = [
  "ideaCommented",
  "ideaSubmitted",
  "ideaVoted",
  "collaboratorAdded",
  "mentionedGroupDiscussion",
  "mentionedIdeaComment",
  "mentionedIdeaField",
  "mentionedIdeaProjectComment",
  "closingSoon",
  "stageChanged",
  "challengeClosed",
  "requestOpenChallenge",
  "discussionPosted",
  "unlockedAchievement",
  "projectIdeaCommented",
  "projectIdeaReplied",
  "projectIdeaMoved",
  "projectAssignedAssignee",
  "projectAssignedManager",
  "assessorAssigned",
  "groupJoinRequested",
  "groupInvited",
  "ideaCommentReply",
  "reactedIdeaComment",
  "reactedGroupDiscussion",
  "repliedGroupDiscussion",
  "pollClosed",
  "announcementReport",
  "ideaBusinessProfileInvited",
  "ideaDeadlineApproaching",
  "ideaDeadlineSet",
];

const actionableNotifications = ["groupJoinRequested", "groupInvited", "ideaBusinessProfileInvited"];

export const NotificationLocation = (notification) => {
  switch (notification.notificationType) {
    case "ideaBusinessProfileInvited":
      return window.location.pathname + `?businessProfileId=${notification.ideaBusinessProfile}`;
    case "ideaCommented":
    case "ideaCommentReply":
    case "reactedIdeaComment":
      return `/ideas/${notification.idea}?comment=${notification.events[0]?.commentId}#comments`;
    case "collaboratorAdded":
    case "ideaVoted":
      return `/ideas/${notification.idea || notification.events[0].idea}`;
    case "stageChanged":
    case "closingSoon":
    case "challengeClosed":
    case "requestOpenChallenge":
      return `/challenges/${notification.challenge}`;
    case "assessorAssigned":
      return `/challenges/${notification.challenge}/assessments`;
    case "ideaSubmitted":
    case "ideaDeadlineSet":
    case "ideaDeadlineApproaching": {
      const { events = [] } = notification;
      if (events.length > 1) {
        return `/challenges/${events[0].ownerIdea.challenge}/ideas`;
      }
      return `/ideas/${events[0].idea}`;
    }
    case "reactedGroupDiscussion":
    case "repliedGroupDiscussion":
    case "discussionPosted":
      return `/groups/${notification.group}/discussion`;
    case "mentionedGroupDiscussion":
    case "mentionedIdeaComment":
    case "mentionedIdeaField": {
      const mentionedInfo = mentions.find((m) => m.type === notification.notificationType);
      return `/${mentionedInfo.link}/${notification[mentionedInfo.id]}/${mentionedInfo.location}${notification.queryString ? `?${notification.queryString}` : ""}`;
    }
    case "mentionedIdeaProjectComment":
      return `/challenges/${notification.challenge}/board?idea=${notification.events[0].idea}`;
    case "unlockedAchievement":
      return "/achievements";
    case "projectIdeaCommented":
    case "projectIdeaReplied":
    case "projectIdeaMoved":
    case "projectAssignedAssignee":
    case "projectAssignedManager":
      return `/challenges/${notification.challenge ?? notification.ownerChallenge?._id}/board?idea=${notification.idea}`;
    case "pollClosed":
      return `/admin/polls/${notification.context.pollId}/results`;
    case "groupJoinRequested":
    case "groupInvited":
      return "/";
    case "announcementReport":
      return notification.context.logLink;
    default:
      return null;
  }
};

const AvatarImage = (source) => (
  <ImageWithFallback
    style={{
      width: 50,
      height: 50,
      objectFit: "cover",
      borderRadius: 10,
    }}
    src={source}
    fallbackSrc={util.avatarUrl()}
  />
);

export const NotificationAvatarImage = (notification, user) => {
  switch (notification.notificationType) {
    case "ideaCommented":
    case "ideaVoted":
    case "collaboratorAdded":
    case "mentionedGroupDiscussion":
    case "mentionedIdeaComment":
    case "projectIdeaCommented":
    case "projectIdeaReplied":
    case "projectIdeaMoved":
    case "projectAssignedAssignee":
    case "projectAssignedManager":
    case "mentionedIdeaProjectComment":
    case "reactedIdeaComment":
    case "ideaBusinessProfileInvited":
    case "ideaCommentReply":
      return AvatarImage(util.avatarUrl(notification?.events[0]?.ownerUser || {}));
    case "mentionedIdeaField":
      return AvatarImage(util.avatarUrl(notification?.events[0]?.user || {}));
    case "closingSoon":
    case "stageChanged":
    case "challengeClosed":
    case "requestOpenChallenge":
    case "ideaSubmitted":
    case "assessorAssigned":
      return AvatarImage(util.challengeImage(notification.ownerChallenge));
    case "ideaDeadlineApproaching":
    case "ideaDeadlineSet":
      return AvatarImage(util.ideaCoverImage(notification.events[0].ownerIdea));
    case "discussionPosted":
    case "reactedGroupDiscussion":
    case "repliedGroupDiscussion":
    case "groupJoinRequested":
    case "groupInvited":
      return AvatarImage(util.groupCoverImage(notification.ownerGroup));
    case "unlockedAchievement":
      return AvatarImage(util.avatarUrl(user));
    default:
      return null;
  }
};

export const NotificationContent = (notification) => {
  switch (notification.notificationType) {
    case "ideaCommented":
      return <IdeaCommentedContent events={notification.events} ownerIdea={notification.ownerIdea} />;
    case "reactedIdeaComment":
      return <CommentReacted events={notification.events} ownerIdea={notification.ownerIdea} />;
    case "ideaCommentReply":
      return <IdeaCommentReply events={notification.events} ownerIdea={notification.ownerIdea} />;
    case "ideaDeadlineApproaching":
      return <DeadlineApproaching events={notification.events} ownerIdea={notification.ownerIdea} />;
    case "ideaDeadlineSet":
      return <DeadlineSet events={notification.events} ownerIdea={notification.ownerIdea} />;
    case "closingSoon":
      return <ClosingSoonContent ownerChallenge={notification.ownerChallenge} />;
    case "stageChanged":
      return <StageChangedContent ownerChallenge={notification.ownerChallenge} />;
    case "requestOpenChallenge":
      return <RequestOpen events={notification.events} ownerChallenge={notification.ownerChallenge} />;
    case "challengeClosed":
      return <ChallengeClosed ownerChallenge={notification.ownerChallenge} />;
    case "discussionPosted":
      return <DiscussionPostedContent ownerGroup={notification.ownerGroup} events={notification.events} />;
    case "ideaSubmitted":
      return <IdeaSubmittedContent events={notification.events} ownerChallenge={notification.ownerChallenge} />;
    case "ideaVoted":
      return <IdeaVotedContent events={notification.events} ownerIdea={notification.ownerIdea} />;
    case "collaboratorAdded":
      return <AddedAsCollaboratorContent events={notification.events} ownerIdea={notification.ownerIdea} />;
    case "mentionedGroupDiscussion":
    case "mentionedIdeaComment":
    case "mentionedIdeaField":
    case "mentionedIdeaProjectComment":
      return (
        <MentionedContent
          notification={notification}
          mentions={mentions}
          events={notification.events}
          notificationType={notification.notificationType}
        />
      );
    case "unlockedAchievement":
      return <UnlockedAchievementContent events={notification.events} />;
    case "projectIdeaCommented":
      return (
        <ProjectIdeaCommented
          events={notification.events}
          ownerIdea={notification.ownerIdea}
          ownerChallenge={notification.ownerChallenge}
        />
      );
    case "projectIdeaReplied":
      return (
        <ProjectIdeaReplied
          events={notification.events}
          ownerIdea={notification.ownerIdea}
          ownerChallenge={notification.ownerChallenge}
        />
      );
    case "projectIdeaMoved":
      return (
        <ProjectIdeaMoved
          events={notification.events}
          ownerIdea={notification.ownerIdea}
          ownerChallenge={notification.ownerChallenge}
        />
      );
    case "projectAssignedAssignee":
      return (
        <ProjectAssignedAssignee
          events={notification.events}
          ownerIdea={notification.ownerIdea}
          ownerChallenge={notification.ownerChallenge}
        />
      );
    case "projectAssignedManager":
      return (
        <ProjectAssignedManager
          events={notification.events}
          ownerIdea={notification.ownerIdea}
          ownerChallenge={notification.ownerChallenge}
        />
      );
    case "assessorAssigned":
      return <AssessorAssignedContent events={notification.events} ownerChallenge={notification.ownerChallenge} />;
    case "groupJoinRequested":
      return <GroupJoinRequestedContent events={notification.events} ownerGroup={notification.ownerGroup} />;
    case "groupInvited":
      return <GroupInvitedContent events={notification.events} ownerGroup={notification.ownerGroup} />;
    case "reactedGroupDiscussion":
      return <GroupDiscussionReactedContent events={notification.events} ownerGroup={notification.ownerGroup} />;
    case "repliedGroupDiscussion":
      return <GroupDiscussionRepliedContent events={notification.events} ownerGroup={notification.ownerGroup} />;
    case "pollClosed":
      return <PollClosed context={notification.context} />;
    case "announcementReport":
      return (
        <AnnouncementReport
          context={notification.context}
          ownerChallenge={notification.ownerChallenge}
          ownerGroup={notification.ownerGroup}
        />
      );
    case "ideaBusinessProfileInvited":
      return (
        <IdeaBusinessProfileInvited
          events={notification.events}
          ownerIdeaBusinessProfile={notification.ownerIdeaBusinessProfile}
        />
      );
    default:
      return null;
  }
};

export const NotificationActions = ({ notification, ...rest }) => {
  switch (notification.notificationType) {
    case "groupJoinRequested":
      return <JoinRequestedActions events={notification.events} {...rest} />;
    case "groupInvited":
      return <InvitedActions events={notification.events} {...rest} />;
    case "ideaBusinessProfileInvited":
      return <IdeaBusinessProfileInvitedActions events={notification.events} {...rest} />;
    default:
      return null;
  }
};

function NotificationItem({ notification, user, markNotificationAsInteracted, invitations, onInvitationHandled }) {
  const { notificationType, ownerGroup, interacted } = notification;

  if (!notificationType) return null;
  if (!(notificationAllowlist.indexOf(notificationType) > -1)) {
    return null;
  }
  if (notificationType === "discussedPosted" && !ownerGroup) {
    return null;
  }

  if (notification.ownerChallenge && notification.ownerChallenge === null)
    notification.ownerChallenge = { name: "Undefined" };
  if (notification.ownerGroup && notification.ownerGroup === null) notification.ownerGroup = { name: "Undefined" };
  if (notification.ownerIdea && notification.ownerIdea === null) notification.ownerIdea = { name: "Undefined" };

  return (
    <Menu.Item
      fitted
      link
      as={Link}
      to={NotificationLocation(notification)}
      style={{
        borderBottom: "1px solid",
        borderColor: "#E9EBEE",
        padding: ".833em 1em",
        borderRadius: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        textOverflow: "ellipsis",
        backgroundColor: interacted ? null : "#e9ebee",
        marginTop: 5,
        marginBottom: 5,
        width: "99%",
        color: "black",
      }}
      onClick={() => markNotificationAsInteracted(notification._id)}
    >
      <div>{NotificationAvatarImage(notification, user)}</div>
      <div style={{ marginLeft: 10, flex: 1 }}>
        <p
          style={{
            textAlign: "left",
            marginBottom: 0,
            lineHeight: "15px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {NotificationContent(notification)}
          {notification.additionalEvents ? ` + ${notification.additionalEvents} more` : null}
        </p>
        <p style={{ color: "gray", marginTop: 3 }}>{moment(notification.createdAt).fromNow()}</p>
      </div>
      {actionableNotifications.indexOf(notificationType) > -1 ? (
        <div>
          <NotificationActions
            invitations={invitations}
            notification={notification}
            onAccept={onInvitationHandled}
            onReject={onInvitationHandled}
          />
        </div>
      ) : null}
    </Menu.Item>
  );
}

export default withTranslation()(NotificationItem);
