import React, { useState, useCallback } from "react";
import { HoverAction, HoverActionsContentBox, HoverActionButton } from "components/lib/HoverActions";
import EmojiChooser from "components/lib/Emoji/EmojiChooser";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { useAppSelector } from "store";
import util from "utils/utils";
import { OpenAPI } from "simplydo/interfaces";
import constants from "utils/constants";
import api from "api";

const EmojiChooserContainer = styled.div`
  width: 100%;
  .emoji-mart {
    border: none;
    width: 100% !important;
  }
`;

type LabelActionProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
  onUpdateIdea: (idea: Partial<OpenAPI.Schemas["Idea"]>) => void;
};

const LabelAction = ({ idea, onUpdateIdea }: LabelActionProps) => {
  const user = useAppSelector((state) => state.user);
  const [emojiChooserOpen, setEmojiChooserOpen] = useState(false);

  const labelIdea = useCallback(
    (label, isApplied) => {
      const stamps = idea?.stamps ?? [];
      let existingIndex = stamps.indexOf(label);
      if (label in constants.legacyStampMapper) {
        existingIndex = stamps.indexOf(constants.legacyStampMapper[label]);
        if (existingIndex < 0) {
          existingIndex = stamps.indexOf(label);
        }
      }
      if (isApplied && existingIndex === -1) stamps.push(label);
      if (!isApplied && existingIndex > -1) stamps.splice(existingIndex, 1);
      api.ideas.updateStatus(
        idea._id,
        { stamps },
        () => {
          onUpdateIdea({ stamps });
        },
        () => {},
      );
    },
    [idea, onUpdateIdea],
  );

  if (
    !util.canManageChallenge(user, idea.ownerChallenge) &&
    !util.hasPermission(user, "challenge.editIdeaLabels", idea.challenge)
  ) {
    return null;
  }

  return !emojiChooserOpen ? (
    <HoverAction icon="paint brush" onClick={() => setEmojiChooserOpen(true)}>
      Apply a label
    </HoverAction>
  ) : (
    <HoverActionsContentBox>
      <HoverActionButton onClick={() => setEmojiChooserOpen(false)}>
        <Icon name="paint brush" style={{ height: "1.1em" }} />
        <span>Apply a label</span>
        <Icon name="close" style={{ height: "1.1em" }} onClick={() => setEmojiChooserOpen(false)} />
      </HoverActionButton>
      <div className="content-box">
        <EmojiChooserContainer>
          <EmojiChooser
            onComplete={(em) => labelIdea(em, true)}
            existing={idea?.stamps}
            onRemoveExisting={(em) => labelIdea(em, false)}
          />
        </EmojiChooserContainer>
      </div>
    </HoverActionsContentBox>
  );
};

export default LabelAction;
