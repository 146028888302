import { useState, useCallback } from "react";
import { Form, Button, Card, Modal, Divider, Icon } from "semantic-ui-react";
import styled from "styled-components";
import api from "api";
import { OpenAPI } from "simplydo/interfaces";
import toast from "react-hot-toast";

import { countries } from "utils/countries";
import useThrottle from "utils/useThrottle";
import { PlaceholderItem, PlaceholderStack } from "components/lib/UI";

const CompanyCardHeader = styled(Card.Header)`
  font-size: 1em;
  color: black;
  font-weight: 600;
`;

const CompanyCardMeta = styled(Card.Meta)`
  font-size: 0.8em;
  color: grey;
`;

type BusinessProfileSearcherProps = {
  forType: string;
  forId: string;
  creditsafeEnabled: boolean;
  onSelect: (company: Partial<OpenAPI.Schemas["IdeaBusinessProfile"]>) => void;
  onClose?: () => void;
};

type CreditsafeSearchResult = {
  id: string;
  name: string;
  address: {
    street: string;
    city: string;
    postCode: string;
  };
  phoneNumbers: string[];
  status: string;
  regNo: string;
  country: string;
  vatNo: string[];
};

export const BusinessProfileSearcher = ({
  forType,
  forId,
  creditsafeEnabled,
  onSelect,
  onClose,
}: BusinessProfileSearcherProps) => {
  const [loading, setLoading] = useState(false);

  const [searchResults, setSearchResults] = useState<Array<CreditsafeSearchResult>>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [country, setCountry] = useState("GB");
  const [companyName, setCompanyName] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [selectedCompany, setSelectedCompany] = useState<Partial<OpenAPI.Schemas["IdeaBusinessProfile"]> | null>(null);

  const apiSearch = useThrottle(
    (name, number, countryCode) => {
      if ((!name && !number) || (number && number.length <= 1)) {
        setSearchResults([]);
        setIsSearching(false);
        return;
      }
      if (number.toString().replace(" ", "").length > 8) {
        toast.error("Company registration is longer than the required maximum length of 8");
        setIsSearching(false);
        return;
      }

      api.verifications.searchForCompanies(
        countryCode,
        number,
        name,
        forType,
        forId,
        (data) => {
          setIsSearching(false);
          setSearchResults(data.companies);
        },
        (err) => {
          setIsSearching(false);
          toast.error(err.message);
        },
        undefined,
      );
    },
    400,
    [creditsafeEnabled],
  );

  const searchForCreditsafeCompanies = useCallback(
    (name, number, countryCode) => {
      if (!creditsafeEnabled) {
        return;
      }
      if (!countryCode) {
        return;
      }
      setIsSearching(true);
      apiSearch(name, number, countryCode);
    },
    [creditsafeEnabled, apiSearch],
  );

  const onClickCompany = useCallback((company) => {
    setSelectedCompany((prev) =>
      prev?.creditsafeCompanyId === company.id
        ? null
        : {
            name: company.name,
            address: {
              city: company?.address?.city ?? "",
              country: company.country ?? "",
              streetAddress: company.address?.street ?? "",
              postcode: company.address?.postCode ?? "",
            },
            phoneNumber: company.phoneNumbers?.length ? company.phoneNumbers[0] : "",
            creditsafeCompanyId: company.id,
            creditsafeRegNo: company.regNo,
            vatNo: company?.vatNo?.length ? company.vatNo[0] : "",
          },
    );
  }, []);

  if (!creditsafeEnabled) {
    return null;
  }

  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={true}>
      <Modal.Header>Search for your organisation</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <Form.Field>
            <label>Organisation name</label>
            <Form.Input
              required
              placeholder="Type your organisation name.."
              value={companyName}
              loading={isSearching}
              onChange={(e, { value }) => {
                setCompanyName(value);
                searchForCreditsafeCompanies(value, companyNumber, country);
              }}
            />
          </Form.Field>

          <Form.Field>
            <label>Registration country (required)</label>
            <Form.Select
              search
              value={country}
              onChange={(e, { value }) => {
                setCountry(value as string);
                searchForCreditsafeCompanies(companyName, companyNumber, value as string);
              }}
              placeholder="Select one"
              options={countries.map((c) => ({ key: c.iso2, flag: c.iso2.toLowerCase(), value: c.iso2, text: c.name }))}
            />
          </Form.Field>

          <Form.Field>
            <label>Registration number</label>
            <Form.Input
              value={companyNumber}
              loading={isSearching}
              onChange={(e) => {
                setCompanyNumber(e.target.value);
                searchForCreditsafeCompanies(companyName, e.target.value, country);
              }}
              placeholder="12345678"
            />
          </Form.Field>

          <Divider />
          <div>
            {searchResults?.length > 0 ? (
              <Card.Group itemsPerRow={3}>
                {searchResults.slice(0, 10).map((company) => {
                  const isSelected = selectedCompany?.creditsafeCompanyId === company.id;

                  return (
                    <Card key={company.id} onClick={() => onClickCompany(company)} style={{ padding: 8 }} fluid>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "stretch",
                        }}
                      >
                        <div>
                          <CompanyCardHeader>{company.name}</CompanyCardHeader>
                          <CompanyCardMeta>Reg No: {company.regNo}</CompanyCardMeta>
                          <CompanyCardMeta>{`${company?.address?.city || "City unknown"}, ${company.country || "Country unknown"} `}</CompanyCardMeta>
                        </div>
                        <Button
                          content={isSelected ? "Selected" : "This is my organisation"}
                          icon={isSelected ? "check" : undefined}
                          style={{ marginTop: 5 }}
                          secondary={isSelected}
                        />
                      </div>
                    </Card>
                  );
                })}
              </Card.Group>
            ) : isSearching ? (
              <Card.Group itemsPerRow={3}>
                {Array.from({ length: 6 }).map((_, index) => {
                  return (
                    <Card key={index} style={{ padding: 8 }} fluid>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "stretch",
                        }}
                      >
                        <PlaceholderStack gap={8}>
                          <PlaceholderStack gap={4}>
                            <PlaceholderItem height={24} />
                            <PlaceholderItem height={16} />
                            <PlaceholderItem height={16} />
                          </PlaceholderStack>
                          <PlaceholderItem height={48} />
                        </PlaceholderStack>
                      </div>
                    </Card>
                  );
                })}
              </Card.Group>
            ) : null}
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancel"
          onClick={() => {
            onClose?.();
          }}
        />
        <Button
          primary={!selectedCompany}
          onClick={() => {
            // @ts-ignore
            onSelect({
              name: companyName || "Default business profile",
            });
          }}
        >
          <Icon name="pencil" />
          Enter details manually
        </Button>
        {selectedCompany ? (
          <Button
            loading={loading}
            primary
            onClick={() => {
              setLoading(true);
              const chosenCompany = {
                ...selectedCompany,
              };

              onSelect(chosenCompany);
              setLoading(false);
            }}
          >
            <Icon name="play" />
            Proceed with {selectedCompany.name}
          </Button>
        ) : null}
      </Modal.Actions>
    </Modal>
  );
};
