import React, { useState } from "react";
import ExternalLinkModal from "components/lib/ExternalLinkModal";
import Remirror, { RemirrorProps } from "./Remirror";

export const BasicRichText = ({ settings, ...otherProps }: RemirrorProps) => {
  const [followExternalLink, setFollowExternalLink] = useState(null);

  return (
    <>
      <ExternalLinkModal
        open={followExternalLink !== null}
        href={followExternalLink}
        onClose={() => {
          setFollowExternalLink(null);
        }}
        onFollow={() => {
          setFollowExternalLink(null);
        }}
      />
      <Remirror
        settings={{
          ...settings,
          onExternalLink: (href) => {
            setFollowExternalLink(href);
          },
        }}
        {...otherProps}
      />
    </>
  );
};
