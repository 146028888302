import { OpenAPI } from "../interfaces";

export const getUserDomain = (user: OpenAPI.Schemas["User"]) => {
  if (!user) {
    return;
  }

  if (user.emails.length === 0) {
    return;
  }

  const email = user.emails[0];
  if (!email.verified) {
    return;
  }

  return email.address.split("@")[1];
};

export const isCommonDomain = (domain: string) => {
  return commonDomainProviders.includes(domain.split(".")[0]);
};

export const commonDomainProviders = [
  "gmail",
  "yahoo",
  "outlook",
  "hotmail",
  "aol",
  "icloud",
  "zoho",
  "mail",
  "gmx",
  "protonmail",
  "rocketmail",
  "googlemail",
  "live",
  "rediffmail",
  "rediff",
  "msn",
  "att",
  "sbcglobal",
  "verizon",
  "comcast",
  "charter",
  "optonline",
  "earthlink",
  "cox",
  "frontier",
  "windstream",
  "btinternet",
  "talktalk",
  "sky",
  "virginmedia",
  "blueyonder",
  "ntlworld",
  "tiscali",
  "zen",
  "plus",
  "uk2",
  "web",
  "freenet",
  "t-online",
  "orange",
  "sfr",
  "laposte",
  "libero",
  "virgilio",
  "alice",
  "tin",
  "wanadoo",
  "free",
  "online",
  "ziggo",
  "xs4all",
  "rogers",
  "bell",
  "sympatico",
  "telus",
  "shaw",
  "cogeco",
  "mts",
  "eastlink",
  "sasktel",
  "videotron",
  "bigpond",
  "optusnet",
  "iinet",
  "dodo",
  "internode",
  "tpg",
  "westnet",
  "adam",
  "primus",
  "netspace",
  "qq",
  "163",
  "126",
  "sina",
  "naver",
  "hanmail",
  "daum",
  "yahoo",
  "mail",
  "yandex",
  "uol",
  "terra",
  "bol",
  "oi",
  "speedy",
  "fibertel",
  "arnet",
  "prodigy",
  "telmex",
  "maktoob",
  "arabia",
  "naseej",
  "sawasdee",
  "dubaimail",
  "emarati",
  "mweb",
  "vodamail",
  "webmail",
  "kenyaweb",
  "afrihost",
  "fastmail",
  "hushmail",
  "runbox",
  "tutanota",
  "startmail",
  "yopmail",
  "temp-mail",
  "email",
  "inbox",
];
