import { Dropdown, Image, Icon, DropdownMenu, DropdownHeader } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import StyledDropdownItem from "components/lib/UI/StyledDropdownItem";

const BusinessProfileDropdownItem = ({
  businessProfile,
  selected,
}: {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  selected?: boolean;
}) => {
  const { name, imageUrl, address = {}, _id } = businessProfile;
  const { country, city } = address;
  return (
    <StyledDropdownItem
      key={_id}
      text={name}
      image={
        imageUrl ? (
          <Image
            src={imageUrl}
            style={{ borderRadius: 2, overflow: "hidden", height: 28, width: 28, objectFit: "contain" }}
          />
        ) : (
          <Icon name="building" size="large" />
        )
      }
      description={city || country ? `${city || ""}${city && country ? ", " : ""}${country}` : null}
      selected={selected}
    />
  );
};

type IProfileSelector = {
  selectedBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  isLoading: boolean;
  existingProfiles: OpenAPI.Schemas["IdeaBusinessProfile"][];
  joinableProfiles?: OpenAPI.Schemas["IdeaBusinessProfile"][];
  joinProfile?: (profileId: any) => void;
  selectBusinessProfile: (ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
  deselectBusinessProfile: () => void;
};

const BusinessProfileSelector = ({
  selectedBusinessProfile,
  isLoading,
  existingProfiles,
  joinableProfiles,
  joinProfile,
  selectBusinessProfile,
  deselectBusinessProfile,
}: IProfileSelector) => {
  return (
    <Dropdown
      selection
      fluid
      clearable
      placeholder="Select an organisation profile.."
      loading={isLoading}
      // @ts-ignore
      text={selectedBusinessProfile ? <BusinessProfileDropdownItem businessProfile={selectedBusinessProfile} /> : null}
      value={selectedBusinessProfile?._id}
      onChange={(e, { value }) => {
        if (!value) {
          deselectBusinessProfile();
        }
      }}
    >
      <DropdownMenu>
        {joinableProfiles?.length > 0 ? (
          <>
            <DropdownHeader content="Organisations you can join" />
            {joinableProfiles.map((joinableProfile) => (
              <Dropdown.Item
                onClick={(e, { value }) => {
                  if (!value) {
                    deselectBusinessProfile();
                  } else {
                    joinProfile?.(value);
                    selectBusinessProfile(joinableProfile);
                  }
                }}
                key={joinableProfile._id}
                value={joinableProfile._id}
                text={joinableProfile.name}
                content={<BusinessProfileDropdownItem businessProfile={joinableProfile} />}
              />
            ))}
          </>
        ) : null}
        {existingProfiles?.length > 0 ? (
          <>
            <DropdownHeader content="Your organisations" />
            {existingProfiles.map((businessProfile) => (
              <Dropdown.Item
                onClick={(e, { value }) => {
                  if (!value) {
                    deselectBusinessProfile();
                  } else {
                    selectBusinessProfile(businessProfile);
                  }
                }}
                key={businessProfile._id}
                value={businessProfile._id}
                text={businessProfile.name}
                content={<BusinessProfileDropdownItem businessProfile={businessProfile} />}
              />
            ))}
          </>
        ) : null}
      </DropdownMenu>
    </Dropdown>
  );
};

export default BusinessProfileSelector;
