import React, { useEffect, useState, useCallback, ReactElement } from "react";
import { Popup, Button, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ChooserPopup = styled(Popup)`
  z-index: 5;
  max-width: 400px !important;
  .menu {
    max-width: 400px !important;
    min-width: 300px !important;
  }
`;

type IFrameChooserProps = {
  trigger?: ReactElement;
  isBasic?: boolean;
  isOpen?: boolean;
  onClose: () => void;
  onOpen?: () => void;
  onComplete?: (IFrameURL: string) => void;
};

const IFrameChooser = ({ trigger, isOpen, onClose, onOpen, onComplete, isBasic }: IFrameChooserProps) => {
  const [open, setOpen] = useState(false);
  const [IFrameURL, setIFrameURL] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (open !== isOpen) {
      setOpen(isOpen);
    }
  }, [open, isOpen]);

  const togglePopup = () => {
    if (!open && onOpen) onOpen();
    if (open && onClose) onClose();
    setOpen(!open);
  };

  const insertIFrame = useCallback(() => {
    onComplete(IFrameURL);
    setIFrameURL("");
  }, [IFrameURL, onComplete]);

  return (
    <ChooserPopup
      mountNode={document.getElementById("semantic-modal-mount-node")}
      on="click"
      open={open}
      position="bottom left"
      trigger={
        trigger ? (
          React.cloneElement(trigger, { onClick: togglePopup })
        ) : (
          <Button
            size="small"
            basic={isBasic}
            secondary
            fluid
            icon="film"
            content="Enter an iFrame URL"
            onClick={togglePopup}
          />
        )
      }
      content={
        <div>
          <h5 style={{ margin: 0 }}>Insert an iframe</h5>
          <p style={{ margin: 0 }}>
            <small>
              Please only insert the URL portion of the iframe. At this time, we currently allow the following sources
              for iframes: YouTube, Vimeo, PowerBI
            </small>
          </p>
          <Input
            size="small"
            fluid
            placeholder="Enter embed code or link to iframe..."
            onChange={(e) => setIFrameURL(e.target.value)}
            action={<Button size="tiny" primary content="Insert" onClick={insertIFrame} />}
          />
          <div style={{ textAlign: "left", marginTop: 20 }}>
            <Button basic size="tiny" content={t("generic.cancel")} onClick={togglePopup} />
          </div>
        </div>
      }
    />
  );
};

export default IFrameChooser;
