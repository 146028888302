import React, { useEffect, useMemo, useState } from "react";
import { Container, Divider, Grid, Loader, Segment } from "semantic-ui-react";

import api from "api";
import actions from "actions";
import util from "utils/utils";

import AgreeToTerms from "components/lib/Entry/AgreeToTerms";
import VerifyEmail from "components/lib/Entry/VerifyEmail";
import InitialProfileSetup from "components/lib/Entry/InitialProfileSetup";
import OrgTagChooser from "components/lib/Choosers/Tags/OrgTagChooser";

import CustomOnboarding from "./lib/CustomOnboarding";

import AppRouting, { StyledDashboard, StyledDashboardContent } from "./app/AppRouting";

import { useAppDispatch, useAppSelector } from "store";
import CreateBusinessProfile from "./lib/Entry/CreateBusinessProfile";
import { SimpleNavBar } from "./lib/UI/NavBar";
import { Footer } from "./lib/UI";
import { styled, keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import Color from "color";
import useTheme from "theme/useTheme";

const slideIn = (offsetMargin: boolean) => keyframes`
 0% { margin-top: -20%; opacity: 0; }
 100% { margin-top: ${offsetMargin ? "0" : "-10%"}; opacity: 1; }
`;

const TransitionSegment = styled(Segment)`
  opacity: 0;
  animation-name: ${({ theme, fluid }) => slideIn(theme.sizes.isMobile || fluid)};
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
`;

const MaxContent = styled.div<{ $scrolling?: boolean }>`
  position: relative;
  min-height: 40px;
  ${({ $scrolling }) => $scrolling && "max-height: 70vh;"}
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
`;

// Better visual gradient, source https://css-tricks.com/easing-linear-gradients/
const scrimGradient = (colorStr: string) => {
  const color = Color(colorStr);

  return `radial-gradient(
    60vw 60vw at 100% 100%,
    ${color.rgb()} 0%,
    ${color.alpha(0.738).rgb()} 19%,
    ${color.alpha(0.541).rgb()} 34%,
    ${color.alpha(0.382).rgb()} 47%,
    ${color.alpha(0.278).rgb()} 56.5%,
    ${color.alpha(0.194).rgb()} 65%,
    ${color.alpha(0.126).rgb()} 73%,
    ${color.alpha(0.075).rgb()} 80.2%,
    ${color.alpha(0.042).rgb()} 86.1%,
    ${color.alpha(0.021).rgb()} 91%,
    ${color.alpha(0.008).rgb()} 95.2%,
    ${color.alpha(0.002).rgb()} 98.2%,
    transparent 100%
  )`;
};

type WrapProps = {
  content: React.ReactNode;
  hideName?: boolean;
  fluid?: boolean;
  excludeWelcomeText?: boolean;
  scrolling?: boolean;
};

export const Wrap = ({ content, hideName, fluid, excludeWelcomeText, scrolling = true }: WrapProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useAppSelector((state) => state.user);
  const urlOrganisation = useAppSelector((state) => state.organisations.urlOrganisation);
  const organisation = user?.ownerOrganisation || urlOrganisation;

  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  // Fetch initial organisation by code if present in the URL
  useEffect(() => {
    const code = util.code();
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get("lang");
    i18n.changeLanguage(lang || "en");
    if (code) {
      api.organisations.getByCode(
        code,
        (newOrganisation) => {
          i18n.changeLanguage(lang || newOrganisation.defaultLanguage || "en");
          dispatch(actions.organisations.receiveUrlOrganisation(newOrganisation));
        },
        () => {},
      );
    }
  }, [dispatch, i18n]);

  return (
    <StyledDashboard>
      <SimpleNavBar />
      <StyledDashboardContent
        style={{
          position: "relative",
          paddingTop: scrolling ? 0 : 80,
          paddingBottom: scrolling ? 0 : 80,
        }}
      >
        <div
          style={{
            transition: "opacity 0.3s",
            opacity: organisation?.primaryColour ? 1 : 0,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: scrimGradient(organisation?.primaryColour || "#fff"),
          }}
        />
        <Container fluid style={{ justifyContent: "center" }}>
          <Grid stackable centered>
            <Grid.Column computer={fluid ? 12 : 6} tablet={fluid ? 12 : 10}>
              <TransitionSegment raised padded fluid={fluid}>
                {!excludeWelcomeText ? (
                  <>
                    <h1>
                      <span role="img" aria-label="Wave" style={{ marginRight: 12 }}>
                        👋
                      </span>
                      {!hideName && user?.profile?.firstName
                        ? t("entry.enrol.welcome_name", { firstName: user?.profile?.firstName })
                        : t("entry.enrol.welcome")}
                    </h1>
                    <Divider />
                  </>
                ) : null}
                <MaxContent $scrolling={scrolling}>{content}</MaxContent>
              </TransitionSegment>
            </Grid.Column>
          </Grid>
        </Container>
      </StyledDashboardContent>
      {!theme.sizes.isMobile ? <Footer /> : null}
    </StyledDashboard>
  );
};

const AppOnboarding = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const userId = user?._id;

  const [resumingSession, setResumingSession] = useState(true);
  const userHasVerifiedEmail = util.hasVerifiedEmail(user, user?.ownerOrganisation);

  useEffect(() => {
    if (userId && userHasVerifiedEmail) {
      api.users.getOrganisations(
        userId,
        (orgs) => {
          dispatch(actions.user.updateAvailableOrganisations(orgs));
        },
        () => {},
      );
    }
  }, [userId, userHasVerifiedEmail, dispatch]);

  useEffect(() => {
    const now = new Date();
    const ensureElapsed = () => {
      const later = new Date();
      const diff = later.getTime() - now.getTime();

      setTimeout(
        () => {
          setResumingSession(false);
        },
        Math.max(0, 500 - diff),
      );
    };
    setResumingSession(true);
    api.auth.resumeSession(ensureElapsed, ensureElapsed);
  }, []);

  const shouldShowTagChooser = useMemo(() => util.shouldShowInterestsChooser(user), [user]);

  const onboardScreen = useMemo(() => {
    if (resumingSession) {
      return {
        done: false,
        content: <Wrap key="load" hideName content={<Loader active inline="centered" />} />,
      };
    }
    if (user && !util.hasAgreedTerms(user)) {
      return {
        done: false,
        content: <Wrap key="terms" content={<AgreeToTerms />} />,
      };
    }
    if (user && !userHasVerifiedEmail && window.location.pathname !== "/verify-email") {
      return {
        done: false,
        content: <Wrap key="verify" content={<VerifyEmail />} />,
      };
    }
    if (user && (!user.profile?.firstName || !user.profile?.lastName)) {
      return {
        done: false,
        content: <Wrap key="profile" content={<InitialProfileSetup />} />,
      };
    }
    if (shouldShowTagChooser) {
      return {
        done: false,
        content: <Wrap fluid content={<OrgTagChooser key="tags" />} />,
      };
    }
    if (user && user.needsBusinessProfile) {
      return {
        done: false,
        content: <Wrap key="bizprofile" fluid content={<CreateBusinessProfile />} />,
      };
    }
    if (user && !user.hasCompletedOrganisationOnboarding) {
      return {
        done: false,
        content: <Wrap key="onboarding" content={<CustomOnboarding />} />,
      };
    }
    return {
      done: true,
      content: <Wrap key="load" hideName content={<Loader active inline="centered" />} />,
    };
  }, [user, resumingSession, userHasVerifiedEmail, shouldShowTagChooser]);

  const visibilityDelayMs = 200;
  const fadeOutDurationMs = 500;
  const [onboardingVisible, setOnboardingVisible] = useState(true);
  const [onboardingMounted, setOnboardingMounted] = useState(true);
  useEffect(() => {
    if (onboardScreen.done) {
      const vTimeout = setTimeout(() => {
        setOnboardingVisible(false);
      }, visibilityDelayMs);

      const oTimeout = setTimeout(() => {
        setOnboardingMounted(false);
      }, visibilityDelayMs + fadeOutDurationMs);

      return () => {
        clearTimeout(vTimeout);
        clearTimeout(oTimeout);
      };
    } else {
      setOnboardingVisible(true);
      setOnboardingMounted(true);
    }
  }, [onboardScreen.done]);

  return (
    <>
      {onboardingMounted ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            backgroundColor: "#fff",
            opacity: onboardingVisible ? 1 : 0,
            transition: `opacity ${fadeOutDurationMs / 1000}s`,
          }}
        >
          {onboardScreen.content}
        </div>
      ) : null}
      {onboardScreen.done ? <AppRouting /> : null}
    </>
  );
};

export default AppOnboarding;
