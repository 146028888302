import React, { useMemo } from "react";
import { Divider, Label, Icon, Image, Segment } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import TagModalChooser from "../../Choosers/Tags/TagModalChooser";
import CompaniesHouseIcon from "src/images/companiesHouse-icon.png";

import constants from "utils/constants";
import ExternalLinkModal from "../../ExternalLinkModal";
import { countries, regions } from "utils/countries";

export const businessTypeOptions = [
  { key: "public", value: "public", text: "Public Sector" },
  { key: "private", value: "private", text: "Private Sector" },
  { key: "notForProfit", value: "notForProfit", text: "Not For-Profit" },
  { key: "academic", value: "academic", text: "Academic" },
];

export const businessSizeOptions = [
  { key: "micro", value: "micro", text: "Micro <10 Employees" },
  { key: "small", value: "small", text: "Small <50 Employees" },
  { key: "medium", value: "medium", text: "Medium <250 Employees" },
  { key: "large", value: "large", text: "Large >250 Employees" },
];

export const businessStatusOptions = [
  { key: "preStartup", value: "preStartup", text: "Pre-start-up" },
  { key: "startup", value: "startup", text: "Start up < 1 year" },
  { key: "established_small", value: "established_small", text: "Established 1 - 5 years" },
  { key: "established_medium", value: "established_medium", text: "Established 6 - 10 years" },
  { key: "established_large", value: "established_large", text: "Established > 10 years" },
];

type OverviewProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
};

export const Overview = ({ businessProfile }: OverviewProps) => {
  const { address, ownerTags, foundingYear } = businessProfile || {};
  const { country, city, streetAddress, postcode, region } = address || {};
  const size = useMemo(
    () => businessSizeOptions.find((option) => option.value === businessProfile?.size)?.text,
    [businessProfile?.size],
  );
  const type = useMemo(
    () => businessTypeOptions.find((option) => option.value === businessProfile?.type)?.text,
    [businessProfile?.type],
  );
  const status = useMemo(
    () => businessStatusOptions.find((option) => option.value === businessProfile?.status)?.text,
    [businessProfile?.status],
  );
  const fullAddress = useMemo(
    () =>
      [
        streetAddress,
        city,
        countries.find((c) => c.iso2 === country)?.name,
        regions.find((r) => r.value === region)?.name,
        postcode,
      ].filter((addressSegment) => !!addressSegment),
    [country, city, streetAddress, region, postcode],
  );

  return (
    <>
      <div style={{ display: "flex", gap: 24 }}>
        {businessProfile?.imageUrl && (
          <div
            style={{
              display: "flex",
              alignSelf: "flex-start",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 4,
              overflow: "hidden",
              maxHeight: 150,
              width: 150,
            }}
          >
            <Image style={{ objectFit: "contain" }} src={businessProfile?.imageUrl} alt={businessProfile?.name} />
          </div>
        )}
        <div style={{ flex: 1 }}>
          <h4>About</h4>
          {businessProfile?.websiteUrl && (
            <p style={{ marginBottom: 7 }}>
              <Icon name="world" />
              <ExternalLinkModal href={businessProfile.websiteUrl}>{businessProfile.websiteUrl}</ExternalLinkModal>
            </p>
          )}

          {fullAddress && fullAddress.length > 0 ? (
            <p style={{ marginBottom: 7 }}>
              <Icon name="map pin" />
              {fullAddress.join(", ")}
            </p>
          ) : null}

          {businessProfile?.phoneNumber && (
            <p style={{ marginBottom: 7 }}>
              <Icon name="phone" />
              {businessProfile?.phoneNumber}
            </p>
          )}

          {size && (
            <p style={{ marginBottom: 7 }}>
              <Icon name="users" />
              {size}
            </p>
          )}

          {(status || foundingYear) && (
            <p style={{ marginBottom: 7 }}>
              <Icon name="building" />
              {foundingYear ? `Founded: ${foundingYear}` : ""}
              {status ? `${foundingYear ? " | " : ""}${status}` : ""}
            </p>
          )}

          {type && (
            <p style={{ marginBottom: 7 }}>
              <Icon name="industry" />
              {type}
            </p>
          )}
        </div>
      </div>
      <Divider hidden />
      {!businessProfile?.creditsafeRegNoSkipped && businessProfile?.creditsafeRegNo && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5, fontWeight: "bold" }}>
          <p style={{ marginBottom: 0, marginRight: 5 }}>Company registration number</p>
          <Label size="small" content={businessProfile?.creditsafeRegNo} />
        </div>
      )}
      {!businessProfile?.vatNoSkipped && businessProfile?.vatNo && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5, fontWeight: "bold" }}>
          <p style={{ marginBottom: 0, marginRight: 5 }}>VAT number</p>
          <Label size="small" content={businessProfile?.vatNo} />
        </div>
      )}
      {businessProfile?.accreditations && businessProfile?.accreditations?.length > 0 && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5, fontWeight: "bold" }}>
          <p style={{ marginBottom: 0, marginRight: 5 }}>Accreditations</p>
          {businessProfile?.accreditations.map((accreditation) => (
            <Label
              key={accreditation}
              size="small"
              content={constants.accreditations.find((acc) => acc.key === accreditation)?.text || accreditation}
            />
          ))}
        </div>
      )}
      {businessProfile?.tags && businessProfile?.tags?.length > 0 && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5, fontWeight: "bold", marginTop: "-8px" }}>
          <p style={{ marginBottom: 0, marginRight: 10 }}>Tags</p>
          <TagModalChooser tags={ownerTags} canEdit={false} />
        </div>
      )}
      <Divider hidden />
      <p>{businessProfile?.description}</p>
      {businessProfile?.companiesHouse?.isAvailable ? (
        <Segment>
          <b>Companies House check</b>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 5,
              gap: 8,
            }}
          >
            <Image src={CompaniesHouseIcon} style={{ width: 50, height: 50, objectFit: "contain" }} />
            <Icon name="check circle" color="blue" size="large" />
            <div>
              The company number "{businessProfile?.creditsafeRegNo}" is registered as "
              {businessProfile.companiesHouse?.company?.company_name}"<br />
              <a
                href={`https://beta.companieshouse.gov.uk${businessProfile?.companiesHouse?.company?.links?.self}`}
                target="_blank"
                rel="noreferrer"
              >
                View company profile on Companies House
                <Icon
                  name="external"
                  size="tiny"
                  style={{ lineHeight: 1, verticalAlign: "text-top", marginTop: 5, marginLeft: 2 }}
                />
              </a>
            </div>
          </div>
        </Segment>
      ) : null}
    </>
  );
};
