import React from "react";
import { HoverAction } from "components/lib/HoverActions";
import { OpenAPI } from "simplydo/interfaces";

type DownloadCoverImageActionProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
};

const DownloadCoverImageAction = ({ idea }: DownloadCoverImageActionProps) => {
  if (idea?.ownerChallenge?.preventIdeaCoverImages || !idea?.coverImage) {
    return null;
  }

  return (
    <HoverAction
      as="a"
      href={idea.coverImageUrl}
      target="_blank"
      rel="noopener noreferrer"
      download={idea?.coverImage}
      icon="download"
    >
      Download cover image
    </HoverAction>
  );
};

export default DownloadCoverImageAction;
