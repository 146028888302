import SearchParams from "simplydo/src/searchParams";
import { OpenAPI } from "simplydo/interfaces";

type IRole = OpenAPI.Schemas["Role"];

export const roles = (api) => ({
  get(
    forType: string,
    forId: string,
    params: {
      permissions?: string[];
      permissionMode?: "all" | "any" | "exact";
    },
    success,
    fail,
  ) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/roles/${forType}/${forId}/roles${queryParams}`, null, success, fail);
  },
  addPermissionsToRole(forType: string, forId: string, roleId: string, permissions: string[], success, fail) {
    api.authenticatedRequest(
      "POST",
      `/roles/${forType}/${forId}/roles/${roleId}/permissions`,
      { permissions },
      success,
      fail,
    );
  },
  removePermissionsFromRole(forType: string, forId: string, roleId: string, permissions: string[], success, fail) {
    api.authenticatedRequest(
      "DELETE",
      `/roles/${forType}/${forId}/roles/${roleId}/permissions`,
      { permissions },
      success,
      fail,
    );
  },
  assignRole(forType: string, forId: string, roleId: string, userIds: string[], success, fail) {
    api.authenticatedRequest(
      "POST",
      `/roles/${forType}/${forId}/roles/${roleId}/assign`,
      { users: userIds },
      success,
      fail,
    );
  },
  unassignRole(forType: string, forId: string, roleId: string, userIds: string[], success, fail) {
    api.authenticatedRequest(
      "DELETE",
      `/roles/${forType}/${forId}/roles/${roleId}/assign`,
      { users: userIds },
      success,
      fail,
    );
  },
  createRole(forType: string, forId: string, data: Partial<IRole>, success, fail) {
    api.authenticatedRequest("POST", `/roles/${forType}/${forId}/roles`, data, success, fail);
  },
  deleteRole(forType: string, forId: string, roleId: string, success, fail) {
    api.authenticatedRequest("DELETE", `/roles/${forType}/${forId}/roles/${roleId}`, null, success, fail);
  },
  updateRole(forType: string, forId: string, roleId: string, data: Partial<IRole>, success, fail) {
    api.authenticatedRequest("POST", `/roles/${forType}/${forId}/roles/${roleId}`, data, success, fail);
  },
  addDefaultRole(forType: string, forId: string, roleId: string, success, fail) {
    api.authenticatedRequest("POST", `/roles/${forType}/${forId}/roles/${roleId}/default`, null, success, fail);
  },
  removeDefaultRole(forType: string, forId: string, roleId: string, success, fail) {
    api.authenticatedRequest("DELETE", `/roles/${forType}/${forId}/roles/${roleId}/default`, null, success, fail);
  },
  getPotentialRoleUsers(
    forType: string,
    forId: string,
    roleId,
    params: { query?: string; page?: number; limit?: number },
    success,
    fail,
  ) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest(
      "GET",
      `/roles/${forType}/${forId}/roles/${roleId}/users/potential${queryParams}`,
      null,
      success,
      fail,
    );
  },
  getAssignedRoleUsers(forType: string, forId: string, roleId, params, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/roles/${forType}/${forId}/roles/${roleId}/users/assigned?page=${params.page}&limit=${params.limit}`,
      null,
      success,
      fail,
    );
  },
  getUsers(
    forType: string,
    forId: string,
    params: {
      permissions?: string[];
      permissionMode?: "all" | "any" | "exact";
    },
    success: (data: OpenAPI.GET<"/roles/{for_type}/{for_id}/users">["response"]) => void,
    fail,
  ) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/roles/${forType}/${forId}/users${queryParams}`, null, success, fail);
  },
  getForUser(forType: string, forId: string, userId: string, success, fail) {
    api.authenticatedRequest("GET", `/roles/${forType}/${forId}/users/${userId}`, null, success, fail);
  },
  removeUserFromAllRoles(forType: string, forId: string, userId: string, success, fail) {
    api.authenticatedRequest("DELETE", `/roles/${forType}/${forId}/users/${userId}`, null, success, fail);
  },
});
