import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Modal, Button } from "semantic-ui-react";
import { CheckboxHeader } from "./UI";
import { OpenAPI } from "simplydo/interfaces";
import util from "utils/utils";
import { useAppSelector } from "store";

type ConfirmAnnouncementModalProps = {
  handleConfirm: (sendAnnouncement: Array<string>) => void;
  modalOpen: boolean;
  announcements: Array<OpenAPI.Schemas["Announcement"]>;
};

const ConfirmAnnouncementModal = ({ handleConfirm, modalOpen, announcements }: ConfirmAnnouncementModalProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const user = useAppSelector((state) => state.user);
  const [announcementsToTrigger, setAnnouncementsToTrigger] = useState<Array<string>>(
    announcements.map((announcement) => announcement._id),
  );

  useEffect(() => {
    setAnnouncementsToTrigger(announcements.map((announcement) => announcement._id));
  }, [announcements]);

  const toUserConditionMap = {
    userThatTriggered: "User that triggered the announcement",
    customUsers: "A custom set of users",
    ideaOwner: `Users that own or collaborate on the ${t("generic.idea")}`,
  };
  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={modalOpen}>
      <Modal.Header>Publish lane update</Modal.Header>
      <Modal.Content>
        <p>
          There is{" "}
          {util.pluralise(announcements.length, "a project lane announcement", "project lane announcements", false)}{" "}
          configured when {t("generic.ideas")} are moved to this lane.{" "}
          {util.hasPermission(user, "challenge.viewSettings", params.id) ||
          util.hasPermission(user, "org.manageChallenges", user?.organisation) ? (
            <>
              You can review announcements in the{" "}
              <Link to={`/challenges/${params.id}/settings/announcements`} target="_blank">
                {t("generic.challenge")} settings
              </Link>
              .
            </>
          ) : null}
        </p>
        <p>Configured {util.pluralise(announcements.length, "announcement", "announcements", false)}:</p>
        {announcements.map((announcement) => (
          <CheckboxHeader
            header={announcement.subject}
            as="h4"
            description={
              <span>
                {/* @ts-ignore */}
                Recipients: <b>{toUserConditionMap[announcement.toUserCondition || "userThatTriggered"]}</b>
              </span>
            }
            key={announcement._id}
            willHideChildren
            checked={announcementsToTrigger.includes(announcement._id)}
            onChange={() => {
              if (announcementsToTrigger.includes(announcement._id)) {
                setAnnouncementsToTrigger(announcementsToTrigger.filter((a) => a !== announcement._id));
              } else {
                setAnnouncementsToTrigger([...announcementsToTrigger, announcement._id]);
              }
            }}
          />
        ))}
        <br />
        <p>Do you want to inform the recipients above about the progression of the moved {t("generic.ideas")}?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => handleConfirm([])}>Just move</Button>
        <Button primary onClick={() => handleConfirm(announcementsToTrigger)} disabled={!announcementsToTrigger.length}>
          Send {util.pluralise(announcementsToTrigger.length, "announcement", "announcements", false)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmAnnouncementModal;
