import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Popup, Icon } from "semantic-ui-react";
import util from "utils/utils";
import actions from "actions";
import useTheme from "theme/useTheme";
import styled from "styled-components";
import api from "api";
import { OpenAPI } from "simplydo/interfaces";

import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "store";

import SelectableDropdown, { SelectableDropdownOptions } from "components/lib/SelectableDropdown";
import Emoji from "components/lib/Emoji/Emoji";
import SlidingPane from "../SlidingModal";

export const ChallengeFilterKeys = [
  "ideaFilter",
  "tagFilter",
  "ideaIncludes",
  "ideaOrder",
  "ideaOrderDirection",
  "ideaPage",
  "ideaFieldChoices",
  "adminHasCommented",
  "projectBoardFilter",
];

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .filter-info {
    font-weight: bold;
    margin-bottom: 10px;
  }
  ${({ theme }) =>
    theme.sizes.isMobile &&
    `
    padding: 10px;
  `}
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  margin-top: 15px;
  margin-bottom: 3px;
  margin-left: 10px;
  font-weight: bold;
`;

const FilterDropdown = styled(SelectableDropdown)`
  &&&& {
    padding: 2px 0;
    min-height: 0;
    margin-top: 0;
    font-size: 13px;
  }
`;

const ListItem = styled.div<{ $selected?: boolean; $clearable?: boolean }>`
  display: flex;
  flex: 1;
  max-width: 100%;
  padding: 5px 10px 3px 10px;
  border-radius: 5px;
  ${({ $selected, $clearable }) =>
    $selected
      ? `
    background: #d2dde5;
    ${
      $clearable
        ? `
      &:hover {
        background: #c2cdd5;
      }
    `
        : ""
    }
  `
      : `
    &:hover {
      background: #d2dde5;
    }
  `}
  cursor: pointer;

  > .filter-option-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;

    > .filter-option {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      gap: 10px;

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      > i {
        text-align: right;
        margin-top: -6px;
      }
    }
  }
`;

// Issue with dropdown in popup content, therefore we need to render the popup content directly
const ContentWrapper = ({ drawPopup, trigger, ...props }) => {
  if (drawPopup) {
    return <Popup trigger={trigger} {...props} />;
  }
  return <>{trigger}</>;
};

type SelectableListProps = {
  options: SelectableDropdownOptions;
  selected?: string;
  onChange: (option: SelectableDropdownOptions[number]) => void;
  clearable?: boolean;
  style?: React.CSSProperties;
  onOptionChange?: (options: string[]) => void;
  selectedValues?: string[];
};

const SelectableList = ({
  options,
  selected,
  onChange,
  clearable,
  style = {},
  onOptionChange,
  selectedValues,
}: SelectableListProps) => {
  const [expanded, setExpanded] = useState(options.length < 5 || clearable);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: 2,
        ...style,
      }}
    >
      {(expanded ? options : options.slice(0, 5)).map((option) => (
        <ListItem
          key={option.value as string}
          $selected={clearable || option.value === selected}
          onClick={() => {
            onChange(option);
          }}
          $clearable={clearable}
        >
          <ContentWrapper
            drawPopup={!option.values}
            // @ts-ignore
            disabled={!option.tooltip && (!option.text?.length || option.text?.length < 25)}
            position="right center"
            offset={[-1, 10]}
            trigger={
              <div className="filter-option-wrapper">
                <div className="filter-option">
                  <span>{option.text}</span>
                  {clearable ? <Icon name="close" color="grey" /> : null}
                </div>
                {option.values ? (
                  <FilterDropdown
                    single
                    value={selectedValues.find((c) => c.startsWith(option.value as string))}
                    placeholder="Select a value"
                    onChange={(selection) => {
                      const otherChoices = selectedValues.filter(
                        (choice) => !choice.startsWith(option.value as string),
                      );
                      onOptionChange(selection ? [...otherChoices, selection] : otherChoices);
                    }}
                    options={option.values.map((opt) => ({
                      key: `${option.value}+${opt}`,
                      value: `${option.value}+${opt}`,
                      text: opt,
                    }))}
                  />
                ) : null}
              </div>
            }
            content={option.tooltip ?? option.text}
          />
        </ListItem>
      ))}
      {!clearable && options.length > 5 ? (
        <ListItem onClick={() => setExpanded((prev) => !prev)} style={{ color: "#2185d0" }}>
          {expanded ? "- Show less" : "+ Show more"}
        </ListItem>
      ) : null}
    </div>
  );
};

type ChallengeIdeasResponse = OpenAPI.GET<"/challenges/{id}/ideas/filters">["response"];

type ChallengeIdeaFiltersProps = {
  challenge: OpenAPI.GET<"/challenges/{id}">["response"];
  popularTags?: OpenAPI.Schemas["Tag"][];
  allStamps?: string[];
  availableGroups?: ChallengeIdeasResponse["availableGroups"];
  availableOrganisations?: ChallengeIdeasResponse["availableOrganisations"];
  availableSortParameters: ChallengeIdeasResponse["availableSortParameters"];
  availableFilterParameters: ChallengeIdeasResponse["availableFilterParameters"];
  availableImpacts?: ChallengeIdeasResponse["availableImpacts"];
  allProjectAssignees?: OpenAPI.Schemas["User"][];
};

function ChallengeIdeaFilters({
  challenge,
  popularTags = [],
  allStamps = [],
  availableGroups = [],
  availableOrganisations = [],
  availableSortParameters,
  availableFilterParameters,
  availableImpacts = [],
  allProjectAssignees = [],
}: ChallengeIdeaFiltersProps) {
  const dispatch = useAppDispatch();
  const {
    tagFilter,
    ideaIncludes,
    ideaOrder,
    groupFilter,
    orgFilter,
    stampsFilter,
    ideaOrderDirection,
    ideaFieldChoices: fieldChoices,
    adminHasCommented,
    projectBoardFilter,
    filtersVisible,
    ideaViewType,
    projectBoardLanesFilter,
    impactFilter,
  } = useAppSelector((state) => state.challenges);
  const user = useAppSelector((state) => state.user);
  const updateFilters = useCallback((data) => dispatch(actions.challenges.updateIdeaFilters(data)), [dispatch]);
  const { t } = useTranslation();

  const ideaFilters = [
    {
      type: "default",
      label: "generic.default",
    },
    {
      type: "voteCount",
      label: "order.votes",
      showIfVotingEnabled: true,
    },
    {
      type: "completeness",
      label: "order.completeness",
    },
    {
      type: "updatedAt",
      label: "order.updatedAt",
    },
    {
      type: "createdAt",
      label: "order.createdAt",
    },
    {
      type: "assessmentScore",
      label: "order.assessmentScore",
      challengeManager: true,
    },
    {
      type: "score",
      label: "order.smartScore",
      challengeManager: true,
    },
    {
      type: "positive",
      label: "order.positive",
      badgeColor: "grey",
      tooltip: `Based on ${t("generic.idea")} comments`,
      challengeManager: true,
    },
    {
      type: "negative",
      label: "order.negative",
      badgeColor: "grey",
      tooltip: `Based on ${t("generic.idea")} comments`,
      challengeManager: true,
    },
  ];

  const theme = useTheme();

  const canManageChallenge = useMemo(() => util.canManageChallenge(user, challenge), [user, challenge]);

  const challengeId = challenge?._id;
  const defaultLaneName = challenge?.projectBoard?.defaultLane?.name;
  const [allChallengeProjectLanes, setAllChallengeProjectLanes] = useState([]);
  const getChallengeProjectLanes = useCallback(() => {
    api.boards.getProjectLanes(
      challengeId,
      (data) => {
        setAllChallengeProjectLanes([{ _id: "default", name: defaultLaneName ?? "Default" }, ...data.lanes]);
      },
      () => {},
    );
  }, [challengeId, defaultLaneName]);
  useEffect(() => {
    if (filtersVisible) {
      getChallengeProjectLanes();
    }
  }, [getChallengeProjectLanes, filtersVisible]);

  const [selectedFilterParameters, setSelectedFilterParameters] = useState([]);
  const [usedFilterParameters, unusedFilterParameters] = useMemo(() => {
    const nextUsedFilterParameters = [];
    const nextUnusedFilterParameters = [];

    availableFilterParameters?.fields?.forEach((param) => {
      if (selectedFilterParameters.includes(param._id)) {
        nextUsedFilterParameters.push(param);
      } else {
        nextUnusedFilterParameters.push(param);
      }
    });

    return [nextUsedFilterParameters, nextUnusedFilterParameters];
  }, [selectedFilterParameters, availableFilterParameters]);

  const extraSortOptions = useMemo(() => {
    const { impacts = [], sliders = [] } = availableSortParameters;

    const impactsMappedToOptions = impacts.map((param) => ({
      key: `${param.value}`,
      value: `${param.value}`,
      text: `Impact: ${param.name}`,
    }));

    const slidersMappedToOptions = sliders.map((param) => ({
      key: `${param.value}`,
      value: `${param.value}`,
      text: `Slider: ${param.name}`,
    }));

    return impactsMappedToOptions.concat(slidersMappedToOptions);
  }, [availableSortParameters]);

  const filterProjectAssignment = useMemo(
    () => [
      { key: "assignedToMe", value: "assignedToMe", text: "Assigned to me" },
      { key: "unassigned", value: "unassigned", text: "Not assigned to anyone" },
      ...allProjectAssignees.map((assignee) => ({
        key: assignee._id,
        value: `assignedTo+${assignee._id}`,
        text: `Assigned to ${assignee.profile.fullName}`,
      })),
    ],
    [allProjectAssignees],
  );

  if (
    user &&
    (challenge?.ideaVisibility === "users" ||
      canManageChallenge ||
      util.hasPermission(user, "challenge.viewIdeas", challenge?._id))
  ) {
    const sortOptions = [
      ...ideaFilters
        .filter(
          (f) =>
            !(
              f.challengeManager &&
              !(canManageChallenge || util.hasPermission(user, "challenge.viewIdeas", challenge?._id))
            ),
        )
        .filter(
          (f) =>
            !(
              f.showIfVotingEnabled &&
              !canManageChallenge &&
              !util.hasPermission(user, "challenge.viewIdeas", challenge?._id) &&
              challenge?.voteCountVisibility !== "users"
            ),
        )
        .map((f) => ({
          key: f.type,
          text: t(f.label),
          value: f.type,
          tooltip: f.tooltip,
        })),
      ...extraSortOptions,
    ];

    const filterTags = popularTags.map((pTag) => ({
      key: pTag._id,
      value: pTag._id,
      text: pTag.value,
    }));

    const filterGroups: {
      key: string;
      value?: string;
      text?: string;
      type?: string;
      isDivider?: boolean;
    }[] = availableGroups.map((group) => ({
      key: group._id,
      text: group.name,
      value: group._id,
      type: "group",
    }));
    if (filterGroups.length) {
      filterGroups.splice(0, 0, { isDivider: true, text: "Groups", key: "groups-divider" });
    }

    const filterOrganisations = availableOrganisations.map((organisation) => ({
      key: organisation._id,
      text: organisation.name,
      value: organisation._id,
      type: "organisation",
    }));

    const filterStamps = allStamps.map((stamp) => ({
      key: stamp,
      value: stamp,
      // @ts-ignore
      text: <Emoji emoji={{ id: stamp }} size={17} />,
    }));

    const filterAuthors =
      filterOrganisations.length > 1
        ? filterGroups.concat([{ isDivider: true, text: "Organisations", key: "org-divider" }, ...filterOrganisations])
        : filterGroups;

    const impactOptions = availableImpacts.flatMap((impact) => {
      let entries: SelectableDropdownOptions = [
        {
          isDivider: true,
          text: `${impact.name} impact`,
          key: `${impact._id}-divider`,
        },
        {
          key: impact._id,
          value: impact._id,
          // description: impact.description,
          text: impact.type === "dropdown" ? `All ${impact.name}s` : impact.name,
        },
      ];

      if (impact.type === "dropdown") {
        entries = entries.concat(
          impact.options?.map((option) => ({
            key: `${impact._id}+${option}`,
            value: `${impact._id}+${option}`,
            text: `-> ${option}`,
          })) ?? [],
        );
      }

      return entries;
    });

    return (
      <SlidingPane
        isOpen={filtersVisible}
        from="left"
        width={400}
        duration={250}
        onClose={() => updateFilters({ filtersVisible: false })}
      >
        <div style={{ padding: 16 }}>
          <div
            style={{ color: "#2185d0", cursor: "pointer" }}
            onClick={() => {
              updateFilters({ filtersVisible: false });
            }}
          >
            {"<"} Hide filters
          </div>
          <div>
            <OptionsContainer theme={theme}>
              <RowFlex>
                {ideaViewType !== "board" ? (
                  <>
                    <SectionHeader>Sort</SectionHeader>
                    <SelectableList
                      selected={ideaOrder}
                      options={sortOptions}
                      onChange={({ value }) => updateFilters({ ideaOrder: value })}
                    />
                    <FilterDropdown
                      single
                      clearable={false}
                      value={ideaOrderDirection}
                      style={{ marginTop: 5 }}
                      options={[
                        { key: "asc", value: "asc", text: "Sort Ascending" },
                        { key: "desc", value: "desc", text: "Sort Descending" },
                      ]}
                      onChange={(value) => updateFilters({ ideaOrderDirection: value })}
                    />
                  </>
                ) : null}

                {canManageChallenge ||
                (challenge.ideaVisibility === "users" && !challenge.ideaVisibilityLimits) ||
                util.hasPermission(user, "challenge.viewIdeas", challenge?._id) ||
                util.hasPermission(user, "challenge.viewProjectBoard", challenge?._id) ? ( // Project board auto-enables submitted filter so needs to be able to disable this
                  <>
                    <SectionHeader>Status</SectionHeader>
                    <SelectableList
                      selected={ideaIncludes || "all"}
                      options={[
                        { key: "all", value: "all", text: "All" },
                        { key: "draft", value: "draft", text: "Draft" },
                        { key: "submitted", value: "submitted", text: "Submitted" },
                      ]}
                      onChange={({ value }) => updateFilters({ ideaIncludes: value === "all" ? null : value })}
                    />
                  </>
                ) : null}

                {availableImpacts.length ? (
                  <>
                    <SectionHeader>Impact</SectionHeader>
                    <FilterDropdown
                      searchable
                      value={undefined}
                      placeholder="Search impacts..."
                      onChange={(selections) => updateFilters({ impactFilter: [...impactFilter, ...selections] })}
                      options={impactOptions}
                    />
                    <SelectableList
                      style={{ marginTop: 5 }}
                      clearable
                      options={impactOptions.filter((impact) => impactFilter.includes(impact.key))}
                      onChange={({ value }) =>
                        updateFilters({ impactFilter: impactFilter.filter((impact) => impact !== value) })
                      }
                    />
                  </>
                ) : null}

                {popularTags?.length ? (
                  <>
                    <SectionHeader>Tags</SectionHeader>
                    <FilterDropdown
                      searchable
                      value={undefined}
                      placeholder="Search tags..."
                      onChange={(selections) => updateFilters({ tagFilter: [...tagFilter, ...selections] })}
                      options={filterTags.filter((tag) => !tagFilter.includes(tag.value))}
                    />
                    <SelectableList
                      style={{ marginTop: 5 }}
                      clearable
                      options={tagFilter.map((tag) => filterTags.find((pTag) => pTag.value === tag))}
                      onChange={({ value }) => updateFilters({ tagFilter: tagFilter.filter((tag) => tag !== value) })}
                    />
                  </>
                ) : null}
                {(availableGroups?.length > 1 || availableOrganisations?.length > 1) &&
                (canManageChallenge || util.hasPermission(user, "challenge.viewIdeas", challenge?._id)) ? (
                  <>
                    <SectionHeader>Author groups</SectionHeader>
                    <FilterDropdown
                      value={undefined}
                      searchable
                      placeholder="Search groups..."
                      onChange={(selection, empty, option) =>
                        updateFilters(
                          option.type === "group"
                            ? { groupFilter: [...groupFilter, option.value] }
                            : { orgFilter: [...orgFilter, option.value] },
                        )
                      }
                      options={filterAuthors.filter(
                        (author) => !groupFilter.includes(author.value) && !orgFilter.includes(author.value),
                      )}
                    />
                    <SelectableList
                      style={{ marginTop: 5 }}
                      clearable
                      options={groupFilter.concat(orgFilter).map((a) => filterAuthors.find((pTag) => pTag.value === a))}
                      onChange={({ value, type }) =>
                        updateFilters(
                          type === "group"
                            ? { groupFilter: groupFilter.filter((g) => g !== value) }
                            : { orgFilter: orgFilter.filter((o) => o !== value) },
                        )
                      }
                    />
                  </>
                ) : null}

                {allStamps.length ? (
                  <>
                    <SectionHeader>Stamps</SectionHeader>
                    <FilterDropdown
                      value={undefined}
                      placeholder="Search stamp..."
                      onChange={(selections) => updateFilters({ stampsFilter: [...stampsFilter, ...selections] })}
                      options={filterStamps}
                    />
                    <SelectableList
                      style={{ marginTop: 5 }}
                      clearable
                      options={stampsFilter.map((tag) => filterStamps.find((pTag) => pTag.value === tag))}
                      onChange={({ value }) =>
                        updateFilters({ stampsFilter: stampsFilter.filter((tag) => tag !== value) })
                      }
                    />
                  </>
                ) : null}

                {canManageChallenge ||
                util.hasPermission(user, "challenge.viewProjectBoard", challenge?._id) ||
                challenge?.projectManagementVisibility === "users" ? (
                  <>
                    <SectionHeader>Project assignment</SectionHeader>
                    <FilterDropdown
                      value={undefined}
                      searchable
                      placeholder="Search assignee..."
                      options={filterProjectAssignment}
                      onChange={(selection, empty, option) =>
                        updateFilters({ projectBoardFilter: [...projectBoardFilter, option.value] })
                      }
                    />
                    <SelectableList
                      style={{ marginTop: 5 }}
                      clearable
                      options={filterProjectAssignment.filter((assignee) =>
                        projectBoardFilter.includes(assignee.value),
                      )}
                      onChange={({ value }) =>
                        updateFilters({
                          projectBoardFilter: projectBoardFilter.filter((assignee) => assignee !== value),
                        })
                      }
                    />

                    <SectionHeader>Project lane</SectionHeader>
                    <FilterDropdown
                      value={undefined}
                      searchable
                      placeholder="Search lane..."
                      onChange={(selections) =>
                        updateFilters({ projectBoardLanesFilter: [...projectBoardLanesFilter, ...selections] })
                      }
                      options={allChallengeProjectLanes.map((lane) => ({
                        key: lane._id,
                        value: lane._id,
                        text: lane.name,
                      }))}
                    />
                    <SelectableList
                      style={{ marginTop: 5 }}
                      clearable
                      options={allChallengeProjectLanes
                        .filter((lane) => projectBoardLanesFilter.includes(lane._id))
                        .map((lane) => ({
                          key: lane._id,
                          value: lane._id,
                          text: lane.name,
                        }))}
                      onChange={({ value }) =>
                        updateFilters({
                          projectBoardLanesFilter: projectBoardLanesFilter.filter((lane) => lane !== value),
                        })
                      }
                    />
                  </>
                ) : null}

                {util.canManageChallenge(user, challenge) ||
                util.hasPermission(user, "challenge.manageIdeaComments", challenge?._id) ? (
                  <>
                    <SectionHeader>Admin comments</SectionHeader>
                    <SelectableList
                      selected={adminHasCommented || "all"}
                      options={[
                        { key: "all", value: "all", text: "All" },
                        { key: "commented", value: "commented", text: "Only with" },
                        { key: "notCommented", value: "notCommented", text: "Only without" },
                      ]}
                      onChange={({ value }) => updateFilters({ adminHasCommented: value === "all" ? null : value })}
                    />
                  </>
                ) : null}

                {unusedFilterParameters.length || usedFilterParameters.length ? (
                  <>
                    <Popup
                      trigger={
                        <SectionHeader>
                          Content <Icon name="question circle outline" size="small" />
                        </SectionHeader>
                      }
                      content={`Filter ${t("generic.ideas")} by the content in their responses.`}
                      position="right center"
                      offset={[-1, 10]}
                    />
                    <FilterDropdown
                      value={undefined}
                      style={{ marginBottom: 5 }}
                      placeholder="Add filter..."
                      onChange={(selections) =>
                        setSelectedFilterParameters([...selectedFilterParameters, ...selections])
                      }
                      options={unusedFilterParameters.map((param) => ({
                        key: param._id,
                        value: param._id,
                        text: param.name,
                      }))}
                    />
                    <SelectableList
                      clearable
                      options={usedFilterParameters.map((param) => ({
                        key: param._id,
                        value: param._id,
                        text: param.name,
                        values: param.options,
                      }))}
                      onChange={({ value }) => {
                        setSelectedFilterParameters(selectedFilterParameters.filter((param) => param !== value));
                        updateFilters({
                          ideaFieldChoices: fieldChoices.filter((choice) => !choice.startsWith(value)),
                        });
                      }}
                      onOptionChange={(optionValues) => updateFilters({ ideaFieldChoices: optionValues })}
                      selectedValues={fieldChoices}
                    />
                  </>
                ) : null}
              </RowFlex>
            </OptionsContainer>
          </div>
        </div>
      </SlidingPane>
    );
  }
  return null;
}

export default ChallengeIdeaFilters;
