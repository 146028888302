import React, { useEffect, useState, useCallback, Dispatch, SetStateAction } from "react";
import { useAppSelector } from "store";
import { Modal, Button, Card, Accordion, Icon } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import { useTranslation } from "react-i18next";

type IProjectBoardSetup = {
  forId: string;
  lanes: OpenAPI.Schemas["ProjectLane"][];
  dataLoaded?: boolean;
  setLanes: Dispatch<SetStateAction<OpenAPI.Schemas["ProjectLane"][]>>;
  setupModalOpen: boolean;
  setSetupModalOpen: Dispatch<SetStateAction<boolean>>;
  laneFilter?: string[];
  canManageBoard?: boolean;
};

const ProjectBoardSetup = ({
  forId,
  lanes = [],
  dataLoaded,
  setLanes,
  setupModalOpen,
  setSetupModalOpen,
  laneFilter,
  canManageBoard,
}: IProjectBoardSetup) => {
  // We use this flag to ensure the modal doesn't reopen once it's been closed
  const [setupHandled, setSetupHandled] = useState<boolean>(false);
  const [myChallenges, setMyChallenges] = useState<OpenAPI.Schemas["Challenge"][]>([]);
  const [importing, setImporting] = useState<boolean>(false);
  const [selectedChallengeId, setSelectedChallengeId] = useState<string>("");
  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user);
  const userId = user?._id;

  const { t } = useTranslation();

  const getChallenges = useCallback(() => {
    api.users.getChallenges(
      userId,
      { forType: "projectBoard", query: "" },
      (data) => {
        setMyChallenges(data.challenges);
      },
      () => {},
    );
  }, [userId]);

  const importLanes = useCallback(() => {
    setImporting(true);
    api.boards.importProjectLanes(
      forId,
      { fromBoardId: selectedChallengeId },
      (data) => {
        if (data.success) {
          setLanes(data.lanes);
          toast.success("Lanes imported successfully.");
          setImporting(false);
          setSetupModalOpen(false);
        } else {
          toast.error(data.message);
        }
        setImporting(false);
      },
      (err) => {
        setImporting(false);
        toast.error(err.message);
      },
    );
  }, [forId, selectedChallengeId, setLanes, setSetupModalOpen]);

  useEffect(() => {
    getChallenges();
  }, [getChallenges]);

  useEffect(() => {
    if (
      (canManageBoard || util.hasPermission(user, "challenge.editProjectBoard", forId)) &&
      !lanes.length &&
      dataLoaded &&
      !setupHandled &&
      !laneFilter?.length
    ) {
      setSetupModalOpen(true);
      setSetupHandled(true);
    }
  }, [lanes, dataLoaded, setSetupModalOpen, setupHandled, laneFilter?.length, canManageBoard, forId, user]);

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={setupModalOpen}
      onClose={() => setSetupModalOpen(false)}
    >
      <Modal.Header>Welcome to the {t("generic.idea")} board</Modal.Header>
      <Modal.Content>
        <p>
          This {t("generic.idea")} board is not setup yet. You may import the lane structure from another{" "}
          {t("generic.challenge")} you manage, or start from scratch.
        </p>
        <Accordion>
          <Accordion.Title active={accordionOpen} onClick={() => setAccordionOpen((prevOpen) => !prevOpen)}>
            <Icon name="dropdown" />
            Select {t("generic.challengeWithArticle")} to import from
          </Accordion.Title>
          <Accordion.Content active={accordionOpen}>
            <Card.Group itemsPerRow={4} centered>
              {myChallenges.map((c) => (
                <Card
                  key={c._id}
                  onClick={() => setSelectedChallengeId((prevSelected) => (prevSelected === c._id ? "" : c._id))}
                >
                  <Button
                    icon="check"
                    color={selectedChallengeId === c._id ? "violet" : null}
                    style={{
                      position: "absolute",
                      top: 5,
                      left: 5,
                      width: 50,
                    }}
                  />
                  <div
                    style={{
                      height: 150,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundImage: `url(${util.mixinCssUrlFallback(util.challengeImage(c), util.challengeImage())})`,
                    }}
                  />
                  <Card.Content>
                    <Card.Header>{c.name}</Card.Header>
                    <Card.Meta>Copy the lane structure from this {t("generic.challenge")}</Card.Meta>
                    <Card.Meta style={{ marginTop: 10 }}>
                      <Link
                        className="with-border"
                        style={{ textDecorationLine: "underline" }}
                        to={`/challenges/${c._id}/board`}
                        target="_blank"
                      >
                        View {t("generic.idea")} board
                      </Link>
                    </Card.Meta>
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </Accordion.Content>
        </Accordion>
      </Modal.Content>
      <Modal.Actions>
        {selectedChallengeId ? (
          <Button
            icon="cloud download"
            content={`Import from selected ${t("generic.challenge")}`}
            color="green"
            loading={importing}
            onClick={() => importLanes()}
          />
        ) : (
          <Button icon="plus" content="Start from scratch" onClick={() => setSetupModalOpen(false)} />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default ProjectBoardSetup;
