import React from "react";
import { Message, Input, Divider, Button } from "semantic-ui-react";
import toast from "react-hot-toast";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import api from "api";
import actions from "actions";
import { useNavigate } from "react-router-dom";
import SearchParams from "simplydo/src/searchParams";

function ResetPassword({ password, setPassword, loading, setLoading, error, setError, t }) {
  const navigate = useNavigate();

  const resetPassword = () => {
    const params = new SearchParams(window.location.search);
    const token = params.get("token");
    setLoading(true);
    setError("");
    api.auth.updatePasswordWithToken(
      token,
      password,
      () => {
        setLoading(false);
        toast(t("entry.resetPassword.success"));
        navigate("/");
      },
      (err) => {
        setLoading(false);
        setError(err.message);
      },
    );
  };

  return (
    <div style={{ maxWidth: 450, display: "block", margin: "0px auto" }}>
      <h2>{t("entry.resetPassword.title")}</h2>
      <p>{t("entry.resetPassword.info")}</p>
      <Divider hidden />
      {error && <Message warning>{error}</Message>}
      <Input
        fluid
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        autoFocus
        placeholder={t("entry.login.password")}
      />
      <div style={{ marginTop: 30, textAlign: "right" }}>
        <Button primary content={t("entry.resetPassword.change")} onClick={resetPassword} loading={loading} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { password, loading, error } = state.entry;
  return { password, loading, error };
};
const mapDispatchToProps = (dispatch) => ({
  setPassword: (p) => dispatch(actions.entry.updatePassword(p)),
  setLoading: (l) => dispatch(actions.entry.updateLoading(l)),
  setError: (e) => dispatch(actions.entry.updateError(e)),
});
export const ResetPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

export default withTranslation()(ResetPasswordContainer);
