/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Modal, Input, Loader, Segment, Button, Icon, Grid, Message, Popup } from "semantic-ui-react";
import useTheme from "theme/useTheme";
import { toast } from "react-hot-toast";
import IdeasImage from "src/images/ideas.png";
import { useAppSelector } from "store";
import util from "utils/utils";
import api from "api";
import { OpenAPI } from "simplydo/interfaces";
import ConfigurableTable from "components/lib/ConfigurableTable";
import useThrottle from "utils/useThrottle";
import { ImageWithFallback } from "../../ImageWithFallback";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

const ExtraContent = styled(Modal.Content)`
  &:empty {
    display: none !important;
  }
`;

const persistentTokenMessages = {
  idea: {
    title: "ideas.teammates.link.title",
    description: "ideas.teammates.link.description",
    warning: "ideas.teammates.link.warning",
  },
  group: {
    title: "groups.members.link.title",
    description: "groups.members.link.description",
    warning: "groups.members.link.warning",
  },
  ideaBusinessProfile: {
    title: "ideaBusinessProfiles.members.link.title",
    description: "ideaBusinessProfiles.members.link.description",
    warning: "ideaBusinessProfiles.members.link.warning",
  },
};
type AvailablePersistentTokenTypes = keyof typeof persistentTokenMessages;

const defaultEnabledFeatures: {
  search?: boolean;
  invite?: boolean;
  persistentToken?: boolean;
} = {
  search: true,
  invite: true,
  persistentToken: false,
};

type ChoosableUser = (
  | OpenAPI.Schemas["User"]
  | {
      _id: string;
      isEmailInvitee: true;
      profile: { fullName: string };
    }
) & {
  isInAudience?: boolean;
  isEmailInvitee?: boolean;
};

export type ExtraContentFunctionProps = {
  unpickUser: (userId: string) => void;
  users: Array<ChoosableUser>;
};

type UserChooserProps = {
  forType?: "assessor" | "idea" | "group" | "projectInvited" | "projectAssignee" | "challenge" | "ideaBusinessProfile";
  forId?: string;
  forEntity?: "invitation";

  enabledFeatures?: typeof defaultEnabledFeatures;
  single?: boolean;
  clearOnComplete?: boolean;
  selectedUsers?: ChoosableUser[];

  subtitle?: string;
  confirm?: string;
  isLoading?: boolean;
  trigger?: React.ReactElement;
  beforeContent?: React.ReactNode | ((extraProps: ExtraContentFunctionProps) => React.ReactNode);
  afterContent?: React.ReactNode | ((extraProps: ExtraContentFunctionProps) => React.ReactNode);

  searchFunction: (search: string, success: (users: OpenAPI.Schemas["User"][]) => void, failure: () => void) => void;
  isOpen?: boolean;
  onComplete: (users: Partial<ChoosableUser>[]) => void;
  onClose?: () => void;
};

const SearchInfoText = "Search for an existing user by name or email address";
const InviteInfoText = "Invite a new user using their email address";

const SearchPlaceholderText = "Enter a search term";
const InvitePlaceholderText = "Enter an email address to invite";

const UserChooser = ({
  selectedUsers = [],
  trigger,
  subtitle,
  searchFunction,
  confirm,
  isLoading,
  onComplete,
  forType,
  forId,
  enabledFeatures = defaultEnabledFeatures,
  isOpen,
  onClose,
  clearOnComplete,
  single,
  forEntity = "invitation",
  beforeContent,
  afterContent,
}: UserChooserProps) => {
  const { t } = useTranslation();
  const [potentialUsers, setPotentialUsers] = useState([]);
  const [potentialUserSearch, setPotentialUserSearch] = useState("");
  const [chosenUsers, setChosenUsers] = useState(selectedUsers);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isOpen ?? false);
  const theme = useTheme();
  const user = useAppSelector((state) => state.user);

  /*
    Persistent invitation token management
  */
  const [persistentToken, setPersistentToken] = useState<OpenAPI.Schemas["PersistentToken"] | null>(null);
  const [persistentTokenLoading, setPersistentTokenLoading] = useState(false);
  const [persistentTokenError, setPersistentTokenError] = useState<string>("");
  const [persistentTokenCreating, setPersistentTokenCreating] = useState(false);
  const [persistentTokenInvalidating, setPersistentTokenInvalidating] = useState(false);
  const persistentTokenLinkRef = useRef(null);

  const persistentTokenMessageData = useMemo(
    () => persistentTokenMessages[forType] as (typeof persistentTokenMessages)[AvailablePersistentTokenTypes],
    [forType],
  );

  if (!persistentTokenMessageData && enabledFeatures.persistentToken) {
    throw new Error(
      `Invalid forType ${forType} for persistent token usage. To enable persistent tokens a "title" and "message" have to be provided.`,
    );
  }

  const createPersistentToken = useCallback(() => {
    setPersistentTokenCreating(true);
    setPersistentTokenError("");
    api.persistentTokens.create(
      {
        forType,
        forId,
        forEntity,
      },
      (data: OpenAPI.POST<"/persistent-tokens">["response"]) => {
        setPersistentTokenCreating(false);
        setPersistentToken(data.persistentToken);
        setPersistentTokenError("");
      },
      (err) => {
        setPersistentTokenCreating(false);
        setPersistentTokenError(err.message);
      },
    );
  }, [forEntity, forId, forType]);

  const getPersistentToken = useCallback(() => {
    setPersistentTokenLoading(true);
    setPersistentTokenError("");
    api.persistentTokens.get(
      forType,
      forId,
      forEntity,
      (data: OpenAPI.GET<`/persistent-tokens/{for_type}/{for_id}/{for_entity}`>["response"]) => {
        setPersistentTokenLoading(false);
        if (data.exists) {
          setPersistentToken(data.persistentToken);
        } else {
          createPersistentToken();
        }
      },
      (err) => {
        setPersistentTokenLoading(false);
        setPersistentTokenError(err.message);
      },
    );
  }, [forType, forId, forEntity, createPersistentToken]);

  const invalidatePersistentToken = useCallback(() => {
    util
      .confirm(
        "Are you sure you want to regenerate this link?",
        "Anyone with the previous link will no longer be able to use it. Only the most recent link will work.",
      )
      .then(() => {
        setPersistentTokenInvalidating(true);
        setPersistentTokenError("");
        api.persistentTokens.invalidate(
          persistentToken._id,
          (data: OpenAPI.POST<"/persistent-tokens/{id}/invalidate">["response"]) => {
            setPersistentTokenInvalidating(false);
            setPersistentToken(data.persistentToken);
            toast.success("New link generated");
          },
          (err) => {
            setPersistentTokenInvalidating(false);
            setPersistentTokenError(err.message);
          },
        );
      })
      .catch(() => {});
  }, [persistentToken]);

  useEffect(() => {
    if (open && enabledFeatures.persistentToken) {
      getPersistentToken();
    }
  }, [enabledFeatures.persistentToken, getPersistentToken, open]);

  const copyTokenLink = useCallback(() => {
    if (persistentTokenLinkRef.current) {
      // @ts-ignore
      persistentTokenLinkRef.current.select();
      document.execCommand("copy");
      toast.success("Link copied to clipboard");
    }
  }, [persistentTokenLinkRef]);

  const debouncedSearch = useThrottle(searchFunction, 400, [searchFunction]);

  useEffect(() => {
    if (!enabledFeatures.search) return;
    if (!open && !isOpen) {
      if (potentialUserSearch !== "") {
        setPotentialUserSearch("");
        setPotentialUsers([]);
      }
      return;
    }

    if (!potentialUserSearch) {
      setPotentialUsers([]);
    } else {
      setLoading(true);
      debouncedSearch(
        potentialUserSearch,
        (users) => {
          setPotentialUsers(users);
          setLoading(false);
        },
        () => {
          setPotentialUsers([]);
          setLoading(false);
        },
      );
    }
  }, [potentialUserSearch, debouncedSearch, isOpen, open, enabledFeatures.search]);

  useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(!!isOpen);
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    if (isOpen === undefined) {
      setOpen(false);
    }
    onClose && onClose();
  }, [onClose, isOpen]);

  const pickUser = useCallback(
    (u) => {
      if (single) {
        setChosenUsers([u]);
      } else {
        setChosenUsers((prev) => prev.filter((c) => c._id !== u._id).concat(u));
      }
    },
    [single],
  );

  const unpickUser = useCallback((userId) => {
    setChosenUsers((prev) => prev.filter((u) => u._id !== userId));
  }, []);

  const finish = () => {
    onComplete?.(chosenUsers);

    if (isOpen === undefined) {
      setOpen(false);
    }
    onClose?.();

    if (clearOnComplete) {
      setPotentialUserSearch("");
      setPotentialUsers([]);
      setChosenUsers([]);
    }
  };

  const inviteUser = useCallback(() => {
    // If search is not allowed we should always use the email directly rather than resolve the account
    if (enabledFeatures.search) {
      // check if a user with the email holds an account
      api.users.findByEmail(
        { email: potentialUserSearch, forType, forId },
        (data) => {
          if (data?.user) {
            pickUser(data.user);
          } else {
            // Validate email entered
            if (!util.validateEmail(potentialUserSearch)) {
              toast.error("Please enter a valid email address");
              return;
            }
            setChosenUsers([
              ...chosenUsers,
              {
                isEmailInvitee: true,
                _id: potentialUserSearch,
                profile: { fullName: potentialUserSearch },
                isInAudience: false,
              },
            ]);
          }
          setPotentialUserSearch("");
        },
        () => {},
      );
    } else {
      // Validate email entered
      if (!util.validateEmail(potentialUserSearch)) {
        toast.error("Please enter a valid email address");
        return;
      }
      setChosenUsers([
        ...chosenUsers,
        {
          isEmailInvitee: true,
          _id: potentialUserSearch,
          profile: { fullName: potentialUserSearch },
          isInAudience: false,
        },
      ]);
    }
  }, [enabledFeatures.search, potentialUserSearch, forType, forId, pickUser, chosenUsers]);

  const shouldShowEmailInvitationButton = useMemo(
    () =>
      (util.validateEmail(potentialUserSearch) && !potentialUsers?.length && enabledFeatures.invite) ||
      (enabledFeatures.invite && !enabledFeatures.search),
    [enabledFeatures.invite, enabledFeatures.search, potentialUserSearch, potentialUsers?.length],
  );

  const chosenIds = useMemo(() => chosenUsers.map((c) => c._id), [chosenUsers]);
  const potentialIds = useMemo(() => potentialUsers.map((c) => c._id), [potentialUsers]);
  const filteredChosenUsers = useMemo(
    () => chosenUsers.filter((p) => !potentialIds.includes(p._id)),
    [potentialIds, chosenUsers],
  );

  const beforeContentResolved = useMemo(() => {
    if (typeof beforeContent === "function") {
      return beforeContent({ users: chosenUsers, unpickUser: unpickUser });
    }
    return beforeContent;
  }, [beforeContent, chosenUsers, unpickUser]);

  const afterContentResolved = useMemo(() => {
    if (typeof afterContent === "function") {
      return afterContent({ users: chosenUsers, unpickUser: unpickUser });
    }
    return afterContent;
  }, [afterContent, chosenUsers, unpickUser]);

  return (
    <>
      {trigger
        ? isOpen === undefined
          ? React.cloneElement(trigger, { onClick: () => setOpen(!open) })
          : trigger
        : null}

      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={open}
        dimmer="inverted"
        size={enabledFeatures.persistentToken ? "large" : "tiny"}
        onClose={handleClose}
        data-testid="simplydo-user-chooser"
        className={theme.sizes.isComputer && "no-scroll"}
      >
        <Modal.Header>
          {enabledFeatures.invite ? t("userchooser.search.external") : t("userchooser.search.internal")}
          {subtitle && <p style={{ fontSize: "1rem", fontWeight: "normal" }}>{subtitle}</p>}
        </Modal.Header>
        {beforeContentResolved ? <ExtraContent>{beforeContentResolved}</ExtraContent> : null}
        <Modal.Content>
          <Grid>
            <Grid.Row columns={enabledFeatures.persistentToken && !theme.sizes.isMobile ? 2 : 1} divided>
              {enabledFeatures.persistentToken ? (
                <Grid.Column stretched style={{ marginBottom: theme.sizes.isMobile && 20 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <span>
                      <b>{t(persistentTokenMessageData.title)}</b>
                      <p>{t(persistentTokenMessageData.description)}</p>
                    </span>

                    {persistentTokenMessageData?.warning ? (
                      <Message warning style={{ margin: 0 }}>
                        {t(persistentTokenMessageData.warning)}
                      </Message>
                    ) : null}

                    {persistentTokenLoading || persistentTokenCreating ? <Loader active inline="centered" /> : null}

                    {persistentTokenError ? (
                      <Message error>
                        <Message.Header>Error</Message.Header>
                        <p>{persistentTokenError}</p>
                      </Message>
                    ) : null}

                    {persistentToken ? (
                      <>
                        <Input
                          ref={persistentTokenLinkRef}
                          loading={persistentTokenInvalidating}
                          fluid
                          value={
                            import.meta.env.DEV
                              ? `localhost:4000/join/${persistentToken.token}`
                              : `${user?.ownerOrganisation?.code}.simplydo.co.uk/join/${persistentToken.token}`
                          }
                          readOnly
                          action={
                            <Button
                              icon="copy"
                              data-tooltip="Copy link"
                              onClick={copyTokenLink}
                              disabled={persistentTokenInvalidating}
                            />
                          }
                        />

                        <Button
                          fluid
                          content="Regenerate link"
                          data-tooltip="Regenerate this link to invalidate the previous one, meaning it can no longer be used"
                          onClick={invalidatePersistentToken}
                          loading={persistentTokenInvalidating}
                          icon="redo"
                          disabled={persistentTokenInvalidating}
                        />
                      </>
                    ) : null}
                  </div>
                </Grid.Column>
              ) : null}
              <Grid.Column>
                <p style={{ marginBottom: 8 }}>
                  <b>Find and select {single ? "a user" : "users"}</b>
                  <p>
                    {enabledFeatures.search && enabledFeatures.invite
                      ? `${SearchInfoText}, or ${InviteInfoText.toLowerCase()}`
                      : enabledFeatures.search
                        ? SearchInfoText
                        : InviteInfoText}
                    .
                  </p>
                </p>
                <Input
                  loading={loading}
                  placeholder={
                    enabledFeatures.search && enabledFeatures.invite
                      ? `${SearchPlaceholderText}, or ${InvitePlaceholderText.toLowerCase()}`
                      : enabledFeatures.search
                        ? SearchPlaceholderText
                        : InvitePlaceholderText
                  }
                  fluid
                  value={potentialUserSearch}
                  onChange={(e) => setPotentialUserSearch(e.target.value)}
                  action={
                    shouldShowEmailInvitationButton
                      ? {
                          content: "Add invitation",
                          onClick: inviteUser,
                          style: {
                            backgroundColor: theme.secondaryColour,
                            color: theme.shouldBeWhiteOnSecondary ? "white" : "black",
                          },
                        }
                      : null
                  }
                  style={{ marginBottom: 5 }}
                />
                {!chosenUsers?.length && !potentialUsers?.length ? (
                  <Segment textAlign="center">
                    <img
                      src={IdeasImage}
                      style={{
                        maxHeight: 100,
                        maxWidth: "80%",
                        display: "block",
                        margin: "5px auto",
                      }}
                      alt="No users chosen yet"
                    />
                    {potentialUserSearch && !loading ? (
                      <>
                        <h4 style={{ marginBottom: 4 }}>{t("userchooser.search.none.found")}</h4>
                        <span>{t("userchooser.search.info")}</span>
                      </>
                    ) : (
                      <>
                        <h4 style={{ marginBottom: 4 }}>{t("userchooser.search.none.selected")}</h4>
                        <span>{t("userchooser.search.none.info")}</span>
                      </>
                    )}
                  </Segment>
                ) : (
                  <>
                    <ConfigurableTable
                      preventSelectAll={single}
                      selectedKeys={chosenIds}
                      tableKey="userChooser"
                      data={filteredChosenUsers.concat(potentialUsers)}
                      onSelect={(item, selected) => {
                        if (!item) {
                          if (selected) {
                            setChosenUsers(filteredChosenUsers.concat(potentialUsers));
                          } else {
                            setChosenUsers([]);
                          }
                          return;
                        }
                        if (selected) {
                          pickUser(item);
                        } else {
                          unpickUser(item._id);
                        }
                      }}
                      columns={[
                        {
                          key: "avatar",
                          name: "",
                          settingName: "Avatar",
                          center: true,
                          width: 20,
                          render: ({ item }) =>
                            item.isEmailInvitee ? (
                              <Icon name="mail" size="large" color="grey" />
                            ) : (
                              <ImageWithFallback
                                style={{ objectFit: "cover" }}
                                avatar
                                src={util.avatarUrl(item)}
                                fallbackSrc={util.avatarUrl()}
                              />
                            ),
                          sortable: true,
                        },
                        {
                          key: "fullName",
                          name: "Name",
                          render: ({ item }) => item.profile.fullName,
                          sortable: true,
                        },
                        {
                          key: "role",
                          name: "Role",
                          render: ({ item }) =>
                            item.iconRoles?.length ? (
                              <Popup
                                content={item.iconRoles[0].name}
                                trigger={
                                  <Icon
                                    name={item.iconRoles[0].icon.name}
                                    color={item.iconRoles[0].icon.colour}
                                    style={{ marginLeft: 5 }}
                                  />
                                }
                              />
                            ) : null,
                        },
                        {
                          key: "jobTitle",
                          name: "Job Title",
                          render: ({ item }) => item.profile.jobTitle,
                          sortable: true,
                        },
                        {
                          key: "department",
                          name: "Department",
                          render: ({ item }) => item.profile.department,
                          sortable: true,
                        },
                      ]}
                    />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        {afterContentResolved ? <ExtraContent>{afterContentResolved}</ExtraContent> : null}
        <Modal.Actions>
          <Button onClick={() => handleClose()} content={t("generic.cancel")} />
          <Button
            disabled={chosenUsers?.length === 0}
            primary
            content={confirm || `Continue with ${util.pluralise(chosenUsers.length, "user", "users")}`}
            onClick={finish}
            loading={isLoading}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default UserChooser;
