import { OpenAPI } from "simplydo/interfaces";
import { getUserDomain, isCommonDomain } from "simplydo/src/users";

import util from "utils/utils";
import { Button, Divider, Icon, Message, Segment, Table } from "semantic-ui-react";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "store";
import api, { asMutation } from "api";
import toast from "react-hot-toast";
import actions from "actions";
import { useMutation } from "@tanstack/react-query";
import { CheckboxHeader } from "components/lib/UI";
import moment from "moment";

type SettingsProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  closeModal: () => void;
  updateProfile?: (businessProfile: Partial<OpenAPI.Schemas["IdeaBusinessProfile"]>) => void;
  onDelete?: (ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
};

export const Settings = ({ businessProfile, updateProfile, onDelete, closeModal }: SettingsProps) => {
  const user = useAppSelector((state) => state.user);
  const userDomain = getUserDomain(user);
  const dispatch = useAppDispatch();

  const deleteProfileMutation = useMutation({
    mutationKey: ["delete", "users", "profile", businessProfile._id],
    mutationFn: asMutation(api.users.deleteProfile),
    onSuccess: ({ success, reason }) => {
      // Attempt to delete the profile.
      // If the profile is attached to another idea, we alert the user, and don't continue with this action
      if (!success) {
        toast(reason);
      } else {
        api.queryClient.invalidateQueries({
          queryKey: ["get", "users", "profiles"],
        });
        dispatch(actions.user.updatePermissions(businessProfile._id, []));
        closeModal();
        onDelete?.(businessProfile);
      }
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const deleteProfile = useCallback(() => {
    util
      .confirm(
        `Really delete the organisation profile ${businessProfile.name}?`,
        `Deleting the organisation profile ${businessProfile.name} is permanent and cannot be undone.`,
      )
      .then(() => {
        deleteProfileMutation.mutate({
          params: [businessProfile._id],
        });
      })
      .catch(() => {});
  }, [businessProfile, deleteProfileMutation]);

  return (
    <div
      style={{
        display: "flex",
        gap: 12,
        flexDirection: "column",
      }}
    >
      {userDomain && !isCommonDomain(userDomain) ? (
        <Segment>
          <h3>Settings</h3>
          Manage settings within this business profile.
          <Divider />
          <h4 style={{ marginBottom: 0 }}>Email domains</h4>
          Let other users automatically join this business profile when they sign up using the same email domain.
          <CheckboxHeader
            style={{ marginTop: 12 }}
            header={`Open for other @${userDomain} users`}
            description={`Users with the same domain can join this profile.`}
            checked={!!businessProfile.emailDomains?.find((domain) => domain.domain === userDomain)}
            as="h5"
            onChange={(checked) => {
              const domains = businessProfile.emailDomains || [];

              updateProfile({
                emailDomains: checked
                  ? [...domains, { domain: userDomain }]
                  : domains.filter((d) => d.domain !== userDomain),
              });
            }}
          />
          {businessProfile.emailDomains?.length ? (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Domain</Table.HeaderCell>
                  <Table.HeaderCell>Added on</Table.HeaderCell>
                  <Table.HeaderCell collapsing></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {businessProfile.emailDomains.map((domain) => (
                  <Table.Row key={domain.domain}>
                    <Table.Cell>@{domain.domain}</Table.Cell>
                    <Table.Cell>{moment(domain.addedAt).format("DD/MM/yyyy HH:mm")}</Table.Cell>
                    <Table.Cell>
                      <Button
                        basic
                        icon
                        onClick={() => {
                          util
                            .confirm("Remove domain", `Really remove the domain @${domain}?`)
                            .then(() => {
                              updateProfile({
                                emailDomains: businessProfile.emailDomains?.filter((d) => d !== domain),
                              });
                            })
                            .catch(() => {});
                        }}
                      >
                        <Icon name="trash" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : null}
        </Segment>
      ) : null}
      <Segment>
        <h3>Delete profile</h3>
        Delete this profile if it is no longer needed.
        <Message warning>
          Deleting this profile will not remove it from any ideas. Once deleted, the profile cannot be edited or
          recovered.
        </Message>
        <Button
          disabled={
            deleteProfileMutation.isError ||
            !util.hasPermission(user, "ideaBusinessProfile.deleteProfile", businessProfile._id)
          }
          inverted
          onClick={() => {
            deleteProfile();
          }}
          color="red"
        >
          <Icon name="trash" />
          Delete profile
        </Button>
      </Segment>
    </div>
  );
};
