import React, { useCallback, useMemo } from "react";
import { Divider, Form, Grid, Header, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import FieldHeader from "components/ideas/FieldHeader";
import { BodyContainer } from "./styles";

import ShortText from "./ShortText";
import LongText from "./LongText";
import RichText from "./RichText";
import Dropdown from "./Dropdown";
import Slider from "./Slider";
import OptionMultiple from "./OptionMultiple";
import Image from "./Image";
import Video from "./Video";
import File from "./File";
import MultiImage from "./MultiImage";
import MultiFile from "./MultiFile";
import InfoText from "./InfoText";
import CompanyReport from "./CompanyReport";
import Assessment from "./Assessment";
import { TextFields } from "..";
import { fieldVisibilityCheck } from "./fieldCondition";

const FieldHeaderContainer = styled.div`
  margin-bottom: 5px;
  min-height: 30px;
`;

const WordLimitContainer = styled.div`
  margin-top: 5px;
  p {
    margin: 0;
    font-size: 10px;
    font-weight: ${({ $exceedingWordLimit }) => ($exceedingWordLimit ? 700 : 400)};
    color: ${({ $exceedingWordLimit }) => ($exceedingWordLimit ? "red" : "black")};
  }
`;

const errorMessages = {
  required: "Please enter a value",
  wordLimit: "You are currently exceeding the word limit",
};

const requiredMessages = {
  dropdown: "Please select a value",
  optionMultiple: "Please select an option",
  image: "Please select an image",
  video: "Please upload a video",
  file: "Please upload a file",
  multiimage: "Please upload an image",
  multifile: "Please upload a file",
  slider: "Please enter a value",
  creditsafereport: "Please select a value",
};

const IdeaCustomField = withTranslation()((props) => {
  const {
    user,
    idea,
    field,
    section,
    isEditing,
    updateIdea,
    externalChanges,
    emptyRequiredField,
    t,
    inputRefs,
    handleKeyDown,
    setFieldLoaded,
    assessment,
    updateAssessment,
  } = props;

  const ideaId = idea?._id;
  const challengeId = idea?.challenge;
  const isAssessingSection = section.type === "assessment" && idea?.isAssessor;

  /*
    The API returns a list of incorrect fields (i.e. over the word limit, over character limit, empty when required). We check whether this current field is in the incorrect fields
  */
  const incorrectFieldInfo = useMemo(
    () => idea?.submissibilityStatus?.detail?.[section.id]?.incorrectFields?.find((i) => i.id === field.id),
    [idea?.submissibilityStatus?.detail, field, section.id],
  );

  /*
    When the user presses submit, if there's an incorrect field, we highlight that field and scroll to it
  */
  const highlightFieldRequired = useMemo(
    () => field.required && emptyRequiredField && incorrectFieldInfo,
    [field, emptyRequiredField, incorrectFieldInfo],
  );

  /*
    Get a plain english error message from the incorrect field info
  */
  const errorMessage = useMemo(() => {
    if (!incorrectFieldInfo) return null;
    if (incorrectFieldInfo.type === "required") {
      return requiredMessages[field.type] || errorMessages.required;
    }
    return errorMessages[incorrectFieldInfo.type];
  }, [incorrectFieldInfo, field]);

  /*
    Find an external change from collaboration users
  */
  const externalChange = useMemo(() => (externalChanges?.templated || {})[field.id], [externalChanges, field.id]);

  const fieldValue = useMemo(() => {
    if (isAssessingSection) {
      if (field.type === "assessment") {
        return assessment?.assessment?.[field.id]?.score;
      }

      return assessment?.assessment?.[field.id]?.value;
    }
    return idea?.templated && idea.templated[field.id];
  }, [idea, field, isAssessingSection, assessment]);
  /*
    Word counts are calculated server side as they use a more complicated approach to remove whitespace, punctuation etc. These are returned to the client on each save
  */
  const wordCount = useMemo(() => {
    const wordCountItem = idea?.submissibilityStatus?.wordCounts?.find((w) => w.field === field.id);
    const updatedWordCount = wordCountItem?.count || 0;
    return updatedWordCount;
  }, [field.id, idea?.submissibilityStatus?.wordCounts]);

  const getCustomFieldComponent = useCallback((fieldType) => {
    switch (fieldType) {
      case "shorttext":
        return ShortText;
      case "longtext":
        return LongText;
      case "richtext":
        return RichText;
      case "dropdown":
        return Dropdown;
      case "slider":
        return Slider;
      case "optionMultiple":
        return OptionMultiple;
      case "image":
        return Image;
      case "video":
        return Video;
      case "file":
        return File;
      case "multiimage":
        return MultiImage;
      case "multifile":
        return MultiFile;
      case "creditsafereport":
        return CompanyReport;
      case "assessment":
        return Assessment;
      default:
        return null;
    }
  }, []);

  const textFocusProps = useMemo(
    () =>
      TextFields.includes(field.type)
        ? {
            inputRef: inputRefs.current[field.id],
            handleKeyDown: (event) => {
              handleKeyDown(event, field.id);
            },
          }
        : {},
    [handleKeyDown, field.id, field.type, inputRefs],
  );

  const handleAssessmentUpdate = useCallback(
    (value, path) => {
      // By default this is "templated" when updateIdea is called, but in assessments we want to update the current `assessment` value.
      path[0] = "assessment";

      // If the path is only 2 items long, we need to add the "value" key to the path so that the resulting object is { assessment: { [field.id]: { value: value } } } which leaves room in the object to add other keys like assessor "notes"
      if (path.length === 2) {
        path.push("value");
      }
      updateAssessment(value, path);
    },
    [updateAssessment],
  );

  const handleFieldUpdate = isAssessingSection ? handleAssessmentUpdate : updateIdea;

  /*
    Info text and Row components required no additional styling - we display these directly
    All other fields, we get the relevant component, include all the common props, then render them alongside any character limits etc in the render body
  */
  const CustomFieldComponent = useMemo(() => {
    if (field.type === "infotext") {
      if (isEditing || isAssessingSection) {
        return <InfoText key={field.id} field={field} />;
      }
    }
    if (field.type === "row") return <BodyContainer key={field.id} computer={16} />;

    const SwitchComponent = getCustomFieldComponent(field.type);

    if (!SwitchComponent) return <React.Fragment key={field.id} />;
    return (
      <>
        <SwitchComponent
          key={field.id}
          field={field}
          ideaId={ideaId}
          challengeId={challengeId}
          user={user}
          fieldValue={fieldValue}
          errorMessage={errorMessage}
          highlightFieldRequired={highlightFieldRequired}
          isEditing={isEditing || (isAssessingSection && !assessment?.isSubmitted)}
          updateIdea={handleFieldUpdate}
          setFieldLoaded={setFieldLoaded}
          {...textFocusProps}
        />
        {isAssessingSection ? (
          <Form>
            <Divider hidden />
            {assessment?.isSubmitted ? (
              <p>
                <Header size="tiny">Justification or notes</Header>
                {assessment?.assessment?.[field.id]?.notes ?? ""}
              </p>
            ) : (
              <Form.TextArea
                label="Justification or notes"
                width="16"
                placeholder="Add context and reasoning to your evaluation..."
                value={assessment?.assessment?.[field.id]?.notes ?? ""}
                onChange={(e, { value }) => handleFieldUpdate(value, ["assessment", field.id, "notes"])}
              />
            )}
          </Form>
        ) : null}
      </>
    );
  }, [
    field,
    isEditing,
    isAssessingSection,
    getCustomFieldComponent,
    ideaId,
    challengeId,
    user,
    fieldValue,
    errorMessage,
    highlightFieldRequired,
    handleFieldUpdate,
    setFieldLoaded,
    assessment,
    textFocusProps,
  ]);

  const isVisible = fieldVisibilityCheck(idea, field);
  if (!isVisible) {
    return null;
  }

  if (field.type === "infotext" || field.type === "row") return CustomFieldComponent;

  return (
    <BodyContainer
      key={field.id}
      data-testid={`idea-field-${field.id}`}
      className={`field-${field.type}`}
      computer={field.width || 16}
      style={{
        boxShadow: highlightFieldRequired ? "0.5px 0.5px 5px rgb(233 11 11 / 94%)" : undefined,
        borderRadius: 5,
      }}
    >
      <FieldHeaderContainer>
        <FieldHeader
          ideaId={ideaId}
          field={field}
          externalChange={externalChange}
          isEditing={isEditing}
          isAssessing={isAssessingSection && !assessment?.isSubmitted}
          updateIdea={updateIdea}
          importingContentEnabled={!idea?.ownerChallenge?.preventIdeaContentImport}
        />
      </FieldHeaderContainer>
      {isEditing || (isAssessingSection && !assessment?.isSubmitted) ? (
        CustomFieldComponent
      ) : (
        <div style={{ borderLeft: "3px solid #cccccc", paddingLeft: 15, marginLeft: 5 }}>{CustomFieldComponent}</div>
      )}
      {field.wordLimit && isEditing ? (
        <WordLimitContainer $exceedingWordLimit={field.wordLimit - wordCount < 0}>
          <p>{t("ideas.actions.wordsRemaining", { count: field.wordLimit - wordCount })}</p>
        </WordLimitContainer>
      ) : null}
      {isEditing && highlightFieldRequired ? (
        <Label size="small" pointing color="red" basic>
          {errorMessage}
        </Label>
      ) : null}
    </BodyContainer>
  );
});

const IdeaCustomSection = (props) => {
  const { section } = props;

  return (
    <Grid stackable centered data-testid={`idea-section-${section.id}`}>
      {section.fields?.map((field, idx) => (
        <IdeaCustomField key={idx} field={field} {...props} />
      ))}
    </Grid>
  );
};

export default withTranslation()(IdeaCustomSection);
