import React, { useState, useCallback } from "react";
import { Container, Loader, Message, Icon, Button, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import api from "api";
import constants from "utils/constants";
import styled from "styled-components";
import SearchParams from "simplydo/src/searchParams";

import PageLoadError from "components/lib/PageLoadError";

const StyledMessage = styled(Message)`
  && {
    font-size: 1.5rem;
  }
`;

const StyledContainer = styled(Container)`
  && {
    padding-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const StyledLink = styled(Link)`
  margin-top: 20px;
  font-size: 1.2rem;
  text-decoration-line: underline;
`;

export const UnsubscribeNotifications = ({ user = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [didUnsubscribe, setDidUnsubscribe] = useState(false);
  const [hasClickedUnsubscribe, setHasClickedUnsubscribe] = useState(false);
  const [error, setError] = useState("");

  const searchParams = new SearchParams(window.location.search);
  const userId = searchParams.has("user") ? searchParams.get("user") : user?._id;
  const subscription = searchParams.get("subscription");
  const token = searchParams.get("token");
  const eventInfo = constants.subscriptions.find((s) => s.key === subscription)?.text;

  const unsubscribeFromNotification = useCallback(() => {
    setLoading(true);
    setError("");
    api.users.unsubscribeFromNotification(
      userId,
      subscription,
      token,
      ({ didUnsubscribe: serverDidUnsubscribe }) => {
        setHasClickedUnsubscribe(true);
        setSuccess(true);
        setLoading(false);
        setDidUnsubscribe(serverDidUnsubscribe);
      },
      (err) => {
        setLoading(false);
        setHasClickedUnsubscribe(true);
        setError(err.message);
      },
    );
  }, [userId, subscription, token]);

  if (!eventInfo) {
    return (
      <StyledContainer>
        <StyledMessage negative>
          We could not unsubscribe you from this type of email. Please contact an administrator.
        </StyledMessage>
      </StyledContainer>
    );
  }
  if (!userId && !user?._id) return <PageLoadError title="Please login to unsubscribe from this kind of message!" />;
  return (
    <StyledContainer>
      {!hasClickedUnsubscribe ? (
        <Segment>
          <h4>{`Are you sure you want to unsubscribe from the notification "${eventInfo}"?`}</h4>
          <p>Please confirm by clicking the button below.</p>
          <Button onClick={() => unsubscribeFromNotification()}>Unsubscribe</Button>
        </Segment>
      ) : (
        <div>
          {loading ? (
            <Loader />
          ) : (
            <Segment>
              {success ? (
                <>
                  {didUnsubscribe ? (
                    <StyledMessage success>
                      <Icon name="check" size="huge" />
                      You will no longer receive an email when <b>"{eventInfo}"</b>
                    </StyledMessage>
                  ) : (
                    <StyledMessage>
                      <Icon name="exclamation" />
                      You already unsubscribed from <b>"{eventInfo}"</b>
                    </StyledMessage>
                  )}
                </>
              ) : (
                <StyledMessage negative>{error}</StyledMessage>
              )}
              {user ? (
                <StyledLink to="/preferences/notifications">Review all notification preferences</StyledLink>
              ) : null}
            </Segment>
          )}
        </div>
      )}
    </StyledContainer>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(UnsubscribeNotifications);
