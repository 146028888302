import { useMutation, useQuery } from "@tanstack/react-query";
import api, { asMutation, asQuery } from "api";
import { useCallback, useState } from "react";
import { Button, Divider, Icon, Message, Image, Grid, Segment, Loader } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import { getUserDomain } from "simplydo/src/users";
import { useAppDispatch, useAppSelector } from "store";
import { BusinessProfileSearcher } from "components/ideas/Components/IdeaBusinessProfile/BusinessProfileSearcher";
import useTheme from "theme/useTheme";
import { EditOverview } from "../IdeaBusinessProfileModal/Tabs/EditOverview";
import toast from "react-hot-toast";
import actions from "actions";
import { useTranslation } from "react-i18next";
import util from "utils/utils";
import { useUserReload } from "utils/useUserReload";
import styled from "styled-components";

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .company-info {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    span {
      display: block;
      margin: 0;
    }
    .description {
      opacity: 0.7;
    }
  }
`;

const StyledCoverImage = styled.div<{ $image: string }>`
  border-radius: 3px;
  min-width: ${({ theme }) => (theme.sizes.isMobile ? "100px" : "120px")};
  min-height: ${({ theme }) => (theme.sizes.isMobile ? "100px" : "120px")};
  max-width: ${({ theme }) => (theme.sizes.isMobile ? "100px" : "200px")};
  max-height: ${({ theme }) => (theme.sizes.isMobile ? "100px" : "200px")};
  border: 3px solid white;
  margin-right: 15px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: center center;
  background-color: white;
  background-image: url(${({ $image }) => $image});
  background-size: cover;
  background-repeat: no-repeat;
`;

const CreateBusinessProfile = () => {
  const user = useAppSelector((state) => state.user);
  const userDomain = getUserDomain(user);
  const dispatch = useAppDispatch();
  const reloadUser = useUserReload();

  const { t } = useTranslation();
  const theme = useTheme();

  const [searcherOpen, setSearcherOpen] = useState(false);

  const [newProfile, setNewProfile] = useState<OpenAPI.Schemas["IdeaBusinessProfile"] | null>(null);
  const newProfileQuery = useQuery({
    queryKey: ["get", "users", "profiles", newProfile?._id],
    queryFn: asQuery(api.users.getProfile, { params: [newProfile?._id] }),
    enabled: !!newProfile?._id,
    initialData: { ideaBusinessProfile: newProfile },
  });

  const profileQuery = useQuery({
    queryKey: ["users", "profiles"],
    queryFn: asQuery(api.users.getProfiles),
  });

  const updateProfileMutation = useMutation({
    mutationKey: ["post", "users", "profiles", newProfileQuery.data?.ideaBusinessProfile?._id],
    mutationFn: asMutation(api.users.updateProfile),
    onSuccess: ({ ideaBusinessProfile }) => {
      api.queryClient.setQueryData(["get", "users", "profiles", ideaBusinessProfile._id], {
        ideaBusinessProfile,
      });
    },
    onError: () => {
      toast.error("Failed to update profile");
    },
  });

  const createProfileMutation = useMutation({
    mutationKey: ["post", "users", "profiles"],
    mutationFn: asMutation(api.users.createProfile),
    onSuccess: ({ ideaBusinessProfile }) => {
      dispatch(
        actions.user.updatePermissions(ideaBusinessProfile._id, [
          "ideaBusinessProfile.viewDetails",
          "ideaBusinessProfile.editDetails",
          "ideaBusinessProfile.viewIdeas",
          "ideaBusinessProfile.editIdeas",
          "ideaBusinessProfile.viewMembers",
          "ideaBusinessProfile.editMembers",
          "ideaBusinessProfile.deleteProfile",
        ]),
      );
    },
    onError: () => {
      toast.error("Failed to create profile");
    },
  });

  const joinProfileMutation = useMutation({
    mutationKey: ["post", "users", "profiles", "join"],
    mutationFn: asMutation(api.users.joinProfile),
    onSuccess: () => {},
    onError: () => {
      toast.error("Failed to join profile");
    },
  });

  const joinProfile = useCallback(
    (profileId) => {
      joinProfileMutation.mutate(
        {
          params: [profileId],
        },
        {
          onSuccess: () => {
            // Invalidate the profiles query to refetch the updated list of profiles
            reloadUser();
            api.queryClient.invalidateQueries({ queryKey: ["get", "users", "profiles"] });
          },
        },
      );
    },
    [joinProfileMutation, reloadUser],
  );

  const createBusinessProfile = useCallback(
    (profile) => {
      createProfileMutation.mutate(
        {
          params: [profile],
        },
        {
          onSuccess: ({ ideaBusinessProfile }) => {
            setNewProfile(ideaBusinessProfile);
          },
        },
      );
    },
    [createProfileMutation],
  );

  if (profileQuery.isLoading) {
    return <Loader active inline="centered" />;
  }

  const {
    name,
    address = {},
    creditsafeRegNo,
    foundingYear,
    vatNo,
    phoneNumber,
    websiteUrl,
    websiteUrlSkipped,
    type,
    status,
    size,
    vatNoSkipped,
    creditsafeRegNoSkipped,
  } = newProfileQuery?.data?.ideaBusinessProfile || {};

  const { country, city, streetAddress, postcode, region } = address || {};

  const currentYear = new Date().getFullYear();
  const requiredInfoFields = [
    { label: "Name", value: name },
    {
      label: "Your business' website",
      value: websiteUrl,
      skipped: websiteUrlSkipped,
    },
    { label: "Phone number", value: phoneNumber },
    { label: "Street address", value: streetAddress },
    { label: "Country", value: country },
    { label: "Region", value: region, skipped: country !== "GB" },
    { label: "City", value: city },
    { label: "Postcode", value: postcode },
    { label: "Organisation size", value: size },
    { label: "Organisation type", value: type },
    { label: "Organisation status", value: status },
    {
      label: "Organisation founding year",
      value: foundingYear && foundingYear > 999 && foundingYear <= currentYear ? foundingYear : "",
    },
    { label: "Company registration number", value: creditsafeRegNo, skipped: creditsafeRegNoSkipped },
    { label: "VAT registration number", value: vatNo, skipped: vatNoSkipped },
  ];
  const isMissingRequiredInfo = requiredInfoFields.some((field) => !field.value && !field.skipped);

  return (
    <div>
      <div style={{ display: theme.sizes.isMobile ? "grid" : "flex", alignItems: "center", gap: 5 }}>
        <StyledCoverImage $image={user?.needsBusinessProfile?.coverImageUrl || user?.ownerOrganisation?.darkLogoUrl} />
        <div>
          <h3>Please tell us which organisation you belong to</h3>
          <p>
            In order to proceed, you need to associate yourself with an organisational profile. If one already exists
            for you, we’ll suggest it below. Otherwise, you can create a new one.
          </p>
        </div>
      </div>

      {newProfileQuery?.data?.ideaBusinessProfile ? (
        <div>
          <h3>Update details</h3>
          <p>
            Please complete the information below to represent your organisation in SimplyDo. This information is used
            to identify your organisation and to provide context for your {t("generic.ideas")}.
          </p>

          <EditOverview businessProfile={newProfileQuery?.data?.ideaBusinessProfile} />
          <Divider />
          {isMissingRequiredInfo ? (
            <Message
              icon="exclamation triangle"
              warning
              size="small"
              content={
                <>
                  <Message.Header>Your organisation profile is missing required information</Message.Header>
                  <p>
                    The following parts of your organisation's profile are still missing or incomplete. Please make sure
                    to complete them before submitting your {t("generic.idea")}.
                  </p>
                  <ul>
                    {requiredInfoFields.map((field) => {
                      if (field.value || field.skipped) {
                        return null;
                      }
                      return <li key={field.label}>{field.label}</li>;
                    })}
                  </ul>
                </>
              }
            />
          ) : null}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              primary
              disabled={isMissingRequiredInfo}
              onClick={() => {
                setNewProfile(null);
                updateProfileMutation.mutate({
                  params: [newProfile._id, newProfileQuery.data?.ideaBusinessProfile],
                });
                dispatch(
                  actions.user.receiveUser({
                    needsBusinessProfile: false,
                  }),
                );
              }}
            >
              <Icon name="save" />
              Save profile & continue
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
          <Divider hidden />
          {userDomain && profileQuery.data?.joinableProfiles.length !== 0 ? (
            <>
              <div style={{ flex: 1 }}>
                <h3>Join an existing profile</h3>
                <p>
                  Based on your email address, we’ve matched you with the following organisations. You can select one of
                  these instead of creating one from scratch.
                </p>
                <Grid columns={2} stackable>
                  <Grid.Row>
                    {profileQuery.data?.joinableProfiles?.map((profile) => (
                      <Grid.Column key={profile._id}>
                        <Segment key={profile._id} style={{ margin: 0, marginTop: 10 }}>
                          <ItemContainer>
                            {profile?.imageUrl ? (
                              <Image
                                src={profile?.imageUrl}
                                style={{
                                  borderRadius: 10,
                                  overflow: "hidden",
                                  height: 50,
                                  width: 50,
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              <Icon name="building" size="large" />
                            )}
                            <div className="company-info">
                              <h5 style={{ margin: 0 }}>{profile?.name}</h5>
                              <p style={{ margin: 0 }}>
                                {profile?.address?.city ? `${profile?.address?.city || ""}` : null}
                              </p>
                              <p style={{ margin: 0 }}>{profile?.websiteUrl ? `${profile?.websiteUrl || ""}` : null}</p>
                            </div>
                          </ItemContainer>
                          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                            <Button primary content="Join this profile" onClick={() => joinProfile(profile._id)} />
                          </div>
                        </Segment>
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              </div>
              <Divider horizontal content="or" />
            </>
          ) : null}
          <div style={{ flex: 1 }}>
            <h3>Create a new profile for your organisation</h3>
            <p>Register your organisation by creating and joining a new profile.</p>
            <Button
              primary={profileQuery?.data?.joinableProfiles?.length === 0}
              content="Create a new organisational profile"
              onClick={() => {
                if (profileQuery?.data?.joinableProfiles?.length !== 0) {
                  util
                    .confirm(
                      "Confirm creating a new profile",
                      "We were able to identify an existing profile that you can join based on your email address. Are you sure you want to create a new profile instead of joining an existing one?",
                    )
                    .then(() => {
                      setSearcherOpen(true);
                    })
                    .catch(() => {});
                } else {
                  setSearcherOpen(true);
                }
              }}
            />
            {searcherOpen ? (
              <BusinessProfileSearcher
                creditsafeEnabled
                forType="org"
                forId={user.ownerOrganisation?._id}
                onSelect={(profile) => {
                  setSearcherOpen(false);
                  createBusinessProfile(profile);
                }}
                onClose={() => {
                  setSearcherOpen(false);
                }}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateBusinessProfile;
