import React, { useMemo, useEffect, useCallback, useState } from "react";
import { Input, Dropdown } from "semantic-ui-react";
import util from "utils/utils";
import { useAppSelector, useAppDispatch } from "store";
import { OpenAPI } from "simplydo/interfaces";
import styled from "styled-components";
import toast from "react-hot-toast";
import api from "api";
import actions from "actions";
import ProductTour from "components/lib/ProductTour";
import CircularButtonWithNumber from "./CircularButtonWithNumber";
import { StyledNavBarSubSegment } from "./UI/NavBar";
import useThrottle from "utils/useThrottle";

const StyledDropdown = styled(Dropdown)`
  .menu.visible {
    right: -6px !important;
    margin: 10px 0 0 !important;
    width: 300px;
  }
`;

const StyledDropdownItem = styled(Dropdown.Item)<{ $clickable?: boolean }>`
  ${({ $clickable }) =>
    $clickable
      ? `
    cursor: pointer;
    &:hover {
      background-color: #e9ebee;
    }
  `
      : ""}
  img {
    height: 30px;
    width: 30px !important;
    object-fit: contain;
  }
`;

const OrgListItem = ({
  organisation,
  onClick,
  showCode,
  isRecent,
}: {
  organisation: OpenAPI.Schemas["Organisation"];
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  showCode?: boolean;
  isRecent?: boolean;
}) => (
  <StyledDropdownItem
    $clickable={!!onClick}
    onClick={onClick}
    image={isRecent ? null : organisation.darkLogoUrl}
    content={organisation.name}
    description={showCode ? organisation.code : null}
  />
);

const OrgSwitcherTray = ({ style }: { style?: React.CSSProperties }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [orgSearch, setOrgSearch] = useState("");
  const [orgLoading, setOrgLoading] = useState(false);
  const [recentOrgs, setRecentOrgs] = useState<OpenAPI.Schemas["Organisation"][]>([]);
  const [orgs, setOrgs] = useState<OpenAPI.Schemas["Organisation"][]>([]);
  const [superadminOrgSelectorVisible, setSuperadminOrgSelectorVisible] = useState(true);
  const dispatch = useAppDispatch();
  const user: OpenAPI.GET<"/users/me">["response"] & { availableOrganisations?: OpenAPI.Schemas["Organisation"][] } =
    useAppSelector((state) => state.user);

  useEffect(() => {
    const recentOrgsLocalStorage = util.localStorageIsSupported()
      ? JSON.parse(localStorage.getItem("recentOrgs") || "[]")
      : [];
    setRecentOrgs(recentOrgsLocalStorage.slice(0, 5));
  }, []);

  const showOrgSwitcherTray = useMemo(
    () =>
      util.canAccessSuperadminOrgList(user) ||
      (user?.availableOrganisations?.length && user?.availableOrganisations?.length > 1),
    [user],
  );

  const fetchOrganisations = useThrottle(
    () => {
      setOrgLoading(true);
      api.organisations.getAll(
        {
          page: 1,
          query: orgSearch,
          selectedTypes: [],
          selectedTags: [],
        },
        (data) => {
          setOrgs(data.organisations);
          setOrgLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setOrgLoading(false);
        },
      );
    },
    400,
    [orgSearch],
    { useLeadingCall: true },
  );

  const switchOrganisation = useCallback(
    (orgId, addToRecents = true) => {
      api.users.switchOrganisation(
        user?._id,
        orgId,
        (data) => {
          if (util.localStorageIsSupported() && addToRecents) {
            // Recently switched organisations are stored in local storage, to a maximum of 5
            // Only add the organisation to the list if it's not already in the list
            const newRecentOrgs = recentOrgs.filter((org) => org._id !== orgId);
            newRecentOrgs.unshift(data.organisation);
            setRecentOrgs(newRecentOrgs.slice(0, 5));
            localStorage.setItem("recentOrgs", JSON.stringify(newRecentOrgs.slice(0, 5)));
          }

          dispatch(actions.user.switchOrganisation(data.organisation));
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [dispatch, recentOrgs, user?._id],
  );

  useEffect(() => {
    if (popupOpen) {
      fetchOrganisations();
    }
  }, [fetchOrganisations, popupOpen]);

  if (!user) {
    return null;
  }
  if (!showOrgSwitcherTray) {
    return null;
  }
  return (
    <>
      <ProductTour tour="orgSwitcher" isReady={!!user?.availableOrganisations?.length} />
      <StyledNavBarSubSegment>
        <StyledDropdown
          on="click"
          open={popupOpen}
          onClose={() => setPopupOpen(false)}
          icon={null}
          pointing="top right"
          closeOnChange={false}
          trigger={
            <div onClick={() => setPopupOpen(true)}>
              <CircularButtonWithNumber
                image={util.orgFavicon(user.ownerOrganisation)}
                count={user?.availableOrganisations?.length}
                style={style}
                labelProps={{
                  color: "grey",
                }}
                className="org-switcher-tour-step-1"
                square
              />
            </div>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Header content="Current Organisation" />
            <OrgListItem organisation={user.ownerOrganisation} showCode />
            <Dropdown.Header content="Switch Organisation" />
            {user?.availableOrganisations?.length ? (
              <>
                {user.availableOrganisations
                  .filter((organisation) => organisation._id !== user.ownerOrganisation._id)
                  .map((organisation) => (
                    <OrgListItem
                      key={organisation._id}
                      organisation={organisation}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        switchOrganisation(organisation._id);
                      }}
                    />
                  ))}
              </>
            ) : null}
            {util.hasPermission(user, "super.switchOrg", user?.ownerOrganisation?._id) &&
            superadminOrgSelectorVisible ? (
              <>
                <Dropdown.Divider />
                <Dropdown.Header content="Superadmin Org Selector" />
                <Dropdown.Item disabled>Recent orgs</Dropdown.Item>
                {recentOrgs.map((organisation) => (
                  <OrgListItem
                    key={organisation._id}
                    organisation={organisation}
                    isRecent
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      switchOrganisation(organisation._id, false);
                    }}
                  />
                ))}
                <Input
                  value={orgSearch}
                  onChange={(e, { value }) => setOrgSearch(value)}
                  onKeyDownCapture={(e) => {
                    // The dropdown component stops propagation if space if pressed - if a key down happens in this components we always want to stop propagation here at the search
                    if (e.key === " ") {
                      e.stopPropagation();
                    }
                  }}
                  autoFocus
                  placeholder="Search for org"
                  onFocus={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  loading={orgLoading}
                />
                {orgs.map((organisation) => (
                  <OrgListItem
                    key={organisation._id}
                    organisation={organisation}
                    showCode
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      switchOrganisation(organisation._id);
                    }}
                  />
                ))}
                <Dropdown.Item
                  content="Hide superadmin orgs this session"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSuperadminOrgSelectorVisible(false);
                  }}
                />
              </>
            ) : null}
          </Dropdown.Menu>
        </StyledDropdown>
      </StyledNavBarSubSegment>
    </>
  );
};

export default OrgSwitcherTray;
