import React, { useCallback, useMemo, useState } from "react";
import { Form, Segment, Message, Input, Button, Checkbox, Icon, Divider } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import util from "utils/utils";
import api from "api";
import toast from "react-hot-toast";
import { datePlusDays } from "./Calendar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ChallengeChooser } from "components/lib/Choosers";
import { CheckboxHeader } from "components/lib/UI";

const Settings = ({ challenge, calendar, setCalendar }) => {
  const { t } = useTranslation();
  const [shiftDays, setShiftDays] = useState(0);
  const [shiftForward, setShiftForward] = useState(true);
  const challengeId = challenge._id;

  const from = useMemo(() => (calendar?.start ? new Date(calendar.start) : new Date()), [calendar]);
  const nextMonth = useMemo(() => new Date(from), [from]);
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  const to = useMemo(() => (calendar?.end ? new Date(calendar.end) : nextMonth), [calendar, nextMonth]);

  const [isImporting, setIsImporting] = useState(false);
  const [importStart, setImportStart] = useState(from.toISOString().split("T")[0]);
  const [importAnnouncements, setImportAnnouncements] = useState(true);
  const [importDates, setImportDates] = useState(true);
  const [clearCurrent, setClearCurrent] = useState(true);

  const importCalendar = useCallback(
    (challengeId) => {
      setIsImporting(true);
      api.challenges.importCalendar(
        challengeId,
        challenge._id,
        {
          startDate: importStart,
          includeAnnouncements: importAnnouncements,
          includeDates: importDates,
          clearCurrent,
        },
        (response) => {
          setIsImporting(false);
          setCalendar(response.calendar);
          toast.success("Calendar events imported successfully");
        },
        (error) => {
          setIsImporting(false);
          toast.error(error.message);
        },
      );
    },
    [importStart, importAnnouncements, importDates, challenge, setCalendar, clearCurrent],
  );

  // Calculate min and max dates for the date pickers
  const toMinDate = datePlusDays(from, 1);
  const fromMaxDate = datePlusDays(to, -1);

  const shiftPlanner = useCallback(() => {
    api.challenges.shiftCalendarEvents(
      challengeId,
      { shiftDays, shiftForward },
      ({ calendar }) => {
        setCalendar(calendar);
        toast.success("Planner events shifted successfully");
      },
      (error) => {
        toast.error(error.message);
      },
    );
  }, [shiftDays, challengeId, shiftForward, setCalendar]);

  const setFrom = useCallback(
    (value: string) => {
      api.challenges.updateCalendar(
        challengeId,
        { start: value },
        () => {
          setCalendar((prev) => ({ ...prev, start: value }));
        },
        (error) => {
          toast.error(error.message);
        },
      );
    },
    [challengeId, setCalendar],
  );

  const setTo = useCallback(
    (value: string) => {
      api.challenges.updateCalendar(
        challengeId,
        { end: value },
        () => {
          setCalendar((prev) => ({ ...prev, end: value }));
        },
        (error) => {
          toast.error(error.message);
        },
      );
    },
    [challengeId, setCalendar],
  );

  const eventsOutsideRange = useMemo(() => {
    return calendar?.events
      .filter((event) => event.id !== "openPeriod")
      .filter((event) => moment(event.from) < moment(calendar?.start) || moment(event.from) > moment(calendar?.end));
  }, [calendar]);

  return (
    <Segment style={{ marginTop: 0 }}>
      <div>
        <Form>
          <Form.Group>
            <Form.Field>
              <label>The calendar will span from:</label>
              <DateInput
                value={from.toISOString().split("T")[0]}
                dateFormat="YYYY-MM-DD"
                onChange={(e, { value }) => setFrom(value)}
                maxDate={fromMaxDate.toISOString().split("T")[0]}
              />
            </Form.Field>

            <Form.Field>
              <label>to:</label>
              <DateInput
                value={to.toISOString().split("T")[0]}
                dateFormat="YYYY-MM-DD"
                onChange={(e, { value }) => setTo(value)}
                minDate={toMinDate.toISOString().split("T")[0]}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        {eventsOutsideRange?.length > 0 ? (
          <Message
            warning
            size="small"
            header="Configuration issue"
            content={
              <>
                {eventsOutsideRange ? (
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    {eventsOutsideRange.map((event) => (
                      <div key={event._id}>
                        The event <strong>{event.title}</strong> on the {moment(event.from).format("DD/MM/YYYY")} is
                        currently outside of the planner date range.
                      </div>
                    ))}
                  </div>
                ) : null}
              </>
            }
            style={{ marginBottom: 20 }}
          />
        ) : null}
        <h5 style={{ marginBottom: 5 }}>Import from another {t("generic.challenge")}</h5>
        <p>Import the calendar events from another {t("generic.challenge")}.</p>
        <ChallengeChooser
          single
          ignores={[challengeId]}
          onComplete={(selected) => {
            importCalendar(selected[0]._id);
          }}
          forType="planner"
          trigger={
            <Button secondary loading={isImporting}>
              <Icon name="download" />
              Import events
            </Button>
          }
          subtitle={`Select a ${t("generic.challenge")} to import events from. This includes the deadline, assessment deadline and all calendar events.`}
          afterContent={
            <Segment>
              <h3>Configure import</h3>
              <h4 style={{ marginBottom: 0 }}>Import start date</h4>
              <p>
                Select the date to import the events to. This will maintain the same distance between events from the
                original challenge but shift all events to start from this date.
              </p>
              <DateInput
                value={importStart}
                dateFormat="YYYY-MM-DD"
                onChange={(e, { value }) => setImportStart(value)}
              />
              <Divider section />
              <CheckboxHeader
                as="h4"
                header="Include announcements"
                description={`Also import announcements when importing events from the selected ${t("generic.challenge")}.`}
                checked={importAnnouncements}
                onChange={() => setImportAnnouncements(!importAnnouncements)}
              />
              <CheckboxHeader
                as="h4"
                header="Import dates & deadlines"
                description={`Also import the open date, deadline and assessment deadline from the ${t("generic.challenge")}.`}
                checked={importDates}
                onChange={() => setImportDates(!importDates)}
              />
              <Divider />
              <CheckboxHeader
                as="h4"
                header="Clear current events"
                description={`Clear all current events before importing the events from the selected ${t("generic.challenge")}.`}
                checked={clearCurrent}
                onChange={() => setClearCurrent(!clearCurrent)}
              />
            </Segment>
          }
        />
        <h5 style={{ marginBottom: 5 }}>Calendar shifting</h5>
        <p>
          Use this setting to shift all calendar events in an increment of days. This will include moving the challenge
          open date, close date, announcement send dates, and all calendar events.
        </p>
        <Checkbox
          toggle
          label={shiftForward ? "Shift events forward" : "Shift events backward"}
          checked={shiftForward}
          onChange={() => setShiftForward(!shiftForward)}
          style={{ display: "block", marginBottom: 15 }}
        />
        <Input
          style={{ marginRight: 5 }}
          type="number"
          onChange={(e, { value }) => setShiftDays(parseInt(value))}
          value={shiftDays}
        />
        <Button onClick={shiftPlanner} disabled={shiftDays === 0}>
          {`Shift events ${shiftForward ? "forward" : "backward"} ${util.pluralise(shiftDays, "day", "days")}`}
        </Button>
      </div>
    </Segment>
  );
};

export default Settings;
