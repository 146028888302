import React, { useState, useMemo } from "react";
import { Button, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { IdeaLikeHandler } from "components/lib/Ideas";
import { useAppSelector } from "store";
import IdeaCardView from "components/lib/Cards/IdeaCard";
import TagModalChooser from "components/lib/Choosers/Tags/TagModalChooser";
import actions from "actions";
import util from "utils/utils";
import { useTranslation } from "react-i18next";

export const labels = [
  { colour: "green", text: "Green" },
  { colour: "yellow", text: "Yellow" },
  { colour: "orange", text: "Orange" },
  { colour: "red", text: "Red" },
  { colour: "purple", text: "Purple" },
  { colour: "blue", text: "Blue" },
  { colour: "pink", text: "Pink" },
];

// const entityIcons = { PERSON: 'user', LOCATION: 'map pin', ORGANIZATION: 'building', COMMERCIAL_ITEM: 'box', EVENT: 'calendar alternate outline', DATE: 'calendar outline', QANTITY: 'numbered list', TITLE: 'font', OTHER: 'tag' };

const IdeaCard = ({
  idea,
  ideasVotedFor,
  challenge,
  user,
  addTag,
  unTag,
  voteForIdea,
  listView,
  pinIdea,
  labelIdea,
  onSelectIdea,
  selectedIdeas,
  ideaActions,
  onUpdateIdea,
  removeAssessors,
  onClosePreview,
  invitations,
  setPreviewOpen,
  onRemoveExternalInvitation,
  fetchIdeas,
  hasPinnedIdea,
  allChallengeProjectLanes,
  updateProjectLane,
  loading,
  basicLoading,
}) => {
  const ideaOrder = useAppSelector((state) => state.challenges.ideaOrder);
  const ideaId = idea?._id;
  const usedVotes = parseInt(challenge.voteLimit, 10) === ideasVotedFor;
  const activeImpactSort = useMemo(
    () => !!challenge && idea?.impacts?.find((impact) => `impact+${impact.impactId}` === ideaOrder),
    [ideaOrder, idea, challenge],
  );
  const { t } = useTranslation();

  const [tagsOpen, setTagsOpen] = useState(false);
  const openProps = tagsOpen ? { open: tagsOpen } : {};
  return (
    <IdeaCardView
      challenge={challenge}
      isYours={util.canEditIdea(user, idea)}
      key={ideaId}
      idea={idea}
      usedVotes={usedVotes}
      voteForIdea={voteForIdea}
      invitations={invitations}
      fetchIdeas={fetchIdeas}
      footer={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            marginBottom: 10,
          }}
        >
          {activeImpactSort ? (
            <Button
              icon={activeImpactSort.ownerImpact?.icon}
              size="tiny"
              color="purple"
              label={{
                basic: true,
                pointing: "left",
                content: activeImpactSort.annualisedValue ?? activeImpactSort.value,
                color: "purple",
              }}
              style={{ marginLeft: 14 }}
            />
          ) : null}
          {challenge?.tagList?.length > 0 && (
            <Popup
              on="hover"
              hoverable
              style={{ zIndex: 1000 }}
              trigger={
                <Button
                  icon="hashtag"
                  size="tiny"
                  label={{ basic: true, pointing: "left", content: idea.tags?.length ?? 0 }}
                  style={{ marginRight: 5, marginLeft: 14 }}
                />
              }
              content={
                <div>
                  <h4>Tags</h4>
                  <TagModalChooser
                    trigger={
                      <Button compact icon="hashtag" content={t("tags.edit")} size="tiny" style={{ marginLeft: 3 }} />
                    }
                    tags={idea.ownerTags}
                    availableTags={challenge.tagList.filter((tag) => !tag?.disabledEntities?.includes("ideas"))}
                    addTag={addTag}
                    removeTag={unTag}
                    canEdit={
                      util.canManageChallenge(user, challenge) ||
                      util.hasPermission(user, "challenge.editIdeaTags", challenge?._id)
                    }
                    onStateChange={(state) => setTagsOpen(state)}
                  />
                </div>
              }
              {...openProps}
            />
          )}
          {!challenge?.preventIdeaComments && (
            <Button
              as={Link}
              to={`/ideas/${idea._id}#comments`}
              color={idea.commentCount ? "blue" : "grey"}
              icon="comments"
              size="tiny"
              label={idea.commentCount ? { basic: true, pointing: "left", content: idea.commentCount } : null}
              style={{ marginLeft: 5, marginRight: 5 }}
            />
          )}

          {challenge.votingVisibility === "users" ? (
            <IdeaLikeHandler
              idea={idea}
              challenge={challenge}
              onLikeIdea={voteForIdea}
              trigger={
                <Button
                  disabled={usedVotes && !idea.votedFor}
                  color={idea.votedFor ? "green" : "grey"}
                  icon="thumbs up"
                  style={{ marginLeft: 5 }}
                  size="tiny"
                  label={
                    util.hasPermission(user, "challenge.viewIdeas", challenge?._id) ||
                    util.canManageChallenge(user, challenge) ||
                    challenge.voteCountVisibility === "users"
                      ? { basic: true, pointing: "left", content: idea.voteCount || 0 }
                      : null
                  }
                />
              }
            />
          ) : null}
        </div>
      }
      pinIdea={pinIdea}
      projectManagement={idea?.projectManagement}
      labels={labels}
      addTag={addTag}
      unTag={unTag}
      labelIdea={labelIdea}
      onSelect={onSelectIdea}
      isSelected={selectedIdeas.indexOf(idea._id) > -1}
      onHeaderClicked={() => onSelectIdea(idea)}
      removeAssessors={removeAssessors}
      ideaActions={ideaActions}
      listView={listView}
      onUpdateIdea={onUpdateIdea}
      assessmentEnabled={challenge?.ideaTemplateData?.assessmentEnabled}
      onClosePreview={onClosePreview}
      setPreviewOpen={setPreviewOpen}
      onRemoveExternalInvitation={onRemoveExternalInvitation}
      hasPinnedIdea={hasPinnedIdea}
      allChallengeProjectLanes={allChallengeProjectLanes}
      updateProjectLane={updateProjectLane}
      loading={loading}
      basicLoading={basicLoading}
    />
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
  updateFilters: (data) => dispatch(actions.challenges.updateIdeaFilters(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdeaCard);
