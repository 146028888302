import { Icon, Loader, Menu, Message, Pagination } from "semantic-ui-react";
import moment from "moment";
import styled from "styled-components";
import { useState } from "react";
import { StyledPagincationContainer } from "./Notifications";
import { EmptyBox } from "../UI";
import { OpenAPI } from "simplydo/interfaces";

const StyledDownloadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  &:hover {
    background-color: rgb(233, 235, 238);
    border-radius: 5px;
  }
`;

const DownloadsContainer = styled.div`
  max-height: 500px;
  overflow: auto;
  padding-right: 5;
`;

type DownloadsProps = {
  downloadCompleted: boolean;
  downloadsQuery: { data: OpenAPI.GET<"/users/{user_id}/downloads">["response"] };
};

const FileIconMap = {
  pdf: "file pdf outline",
  doc: "file word outline",
  docx: "file word outline",
  xls: "file excel outline",
  xlsx: "file excel outline",
  ppt: "file powerpoint outline",
  pptx: "file powerpoint outline",
  jpg: "file image outline",
  jpeg: "file image outline",
  png: "file image outline",
  gif: "file image outline",
  mp4: "file video outline",
  mp3: "file audio outline",
  zip: "file archive outline",
  rar: "file archive outline",
  txt: "file text outline",
  other: "file outline",
};

const downloadTypeMap = {
  ideaPdfExport: "Idea PDF export",
  challengePdfExport: "Challenge engagement report",
  challengeIdeaPdfExport: "Challenge idea PDF export",
  assessmentXlsxExport: "Challenge assessment report",
  userAssessmentXlsxExport: "User assessment report",
  ideadDocxExport: "Idea docx export",
  pollPdfExport: "Poll results report",
};

const Downloads = ({ downloadCompleted, downloadsQuery }: DownloadsProps) => {
  const [page, setPage] = useState(1);
  if (!(downloadsQuery?.data?.pendingDownloads?.length > 0 || downloadsQuery?.data?.downloads?.length > 0)) {
    return <EmptyBox message="You have not created any downloads" />;
  }
  return (
    <div>
      <DownloadsContainer>
        {downloadsQuery?.data?.pendingDownloads?.length > 0 && (
          <>
            {!downloadCompleted && (
              <Message info content="Your download has started, we will notify you once it is completed." />
            )}
            <h5>Pending downloads</h5>
            {downloadsQuery?.data?.pendingDownloads.map((download) => (
              <Menu.Item
                key={download._id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textOverflow: "ellipsis",
                }}
                fitted
                link
              >
                <div style={{ display: "flex", justifyContent: "space-between", width: "98%" }}>
                  <p>
                    <Icon name="file pdf outline" style={{ marginRight: 5 }} />
                    {download.context.displayName}
                  </p>
                  <Loader active inline size="tiny" />
                </div>
              </Menu.Item>
            ))}
          </>
        )}
        {downloadsQuery?.data?.downloads?.length > 0 && (
          <>
            {downloadCompleted && (
              <Message info succes content="Your download has finished, you can download it by clicking below." />
            )}
            <h5>Completed downloads</h5>
            {downloadsQuery?.data?.downloads.map((download) => (
              <Menu.Item
                key={download._id}
                style={{
                  display: "flex",
                  textOverflow: "ellipsis",
                  marginBottom: 5,
                  color: "black",
                  cursor: download.status != "failed" && "pointer",
                  paddingBottom: 5,
                  borderBottom: "1px solid rgba(34,36,38,.15)",
                }}
                onClick={
                  download.url
                    ? () => {
                        const element = document.createElement("a");
                        element.setAttribute("href", download.url);
                        element.setAttribute("download", download.context.displayName ?? "download.pdf");
                        element.setAttribute("target", "_blank");
                        element.style.display = "none";
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                      }
                    : null
                }
              >
                <StyledDownloadContainer>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon
                      name={
                        download?.context?.displayName?.includes(".")
                          ? FileIconMap[download?.context?.displayName?.split(".")[1]]
                          : null
                      }
                      size="large"
                      style={{ marginRight: 6 }}
                    />
                    <div style={{ maxWidth: 420 }}>
                      <p
                        style={{
                          marginBottom: 4,
                          maxWidth: "100%",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {download.context.displayName}
                      </p>
                      <p style={{ color: "grey", fontSize: 12, marginBottom: 0 }}>
                        {downloadTypeMap[download.context.downloadType]}
                      </p>
                      <span style={{ color: "grey", fontSize: 12 }}>
                        {moment(download.createdAt) > moment().subtract(1, "day")
                          ? moment(download.createdAt).fromNow()
                          : moment(download.createdAt).format("DD/MM/YYYY")}
                      </span>
                      {download.status == "failed" ? (
                        <p style={{ color: "red", fontSize: 12 }}>Download failed</p>
                      ) : null}
                    </div>
                  </div>
                  <Icon name={download.status == "success" ? "download" : "exclamation"} />
                </StyledDownloadContainer>
              </Menu.Item>
            ))}
          </>
        )}
      </DownloadsContainer>
      <StyledPagincationContainer>
        <Pagination
          totalPages={Math.ceil(downloadsQuery?.data?.total / 10)}
          activePage={page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={(e, { activePage }) => setPage(activePage as number)}
        />
      </StyledPagincationContainer>
    </div>
  );
};

export default Downloads;
