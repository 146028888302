import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "store";
import { Button, Card, Divider, Loader, Message } from "semantic-ui-react";
import { useNavigate, useLocation } from "react-router-dom";
import SearchParams from "simplydo/src/searchParams";
import api from "api";
import toast from "react-hot-toast";
import actions from "actions";

const CompleteVerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [attemptedRetry, setAttemptedRetry] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailVerified, setEmailVerified] = useState(false);

  const onEmailsUpdate = useCallback((emails) => dispatch(actions.user.updateEmails(emails)), [dispatch]);
  const userId = user?._id;

  const token = useMemo(() => {
    const params = new SearchParams(location.search);
    return params.get("token");
  }, [location.search]);

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    api.auth.verifyEmail(
      token,
      ({ success, attemptedRetry, emails }) => {
        if (!isMounted) return;

        setEmails(emails);
        if (success) {
          toast.success("Email address verified successfully");
          setEmailVerified(true);
          if (userId) {
            onEmailsUpdate(emails);
            navigate("/");
          }
        } else {
          setAttemptedRetry(attemptedRetry);
        }
        setLoading(false);
      },
      (err) => {
        if (!isMounted) return;

        setLoading(false);
        if (err.message === "This email is already verified") {
          navigate("/");
          toast("Your email is already verified");
        } else {
          setErrorMessage(err.message);
        }
      },
    );
    return () => {
      isMounted = false;
    };
  }, [userId, token, onEmailsUpdate, navigate]);

  return (
    <div>
      <h1>Email verification</h1>
      <Divider />
      {loading ? <Card.Meta>We are verifying your email address...</Card.Meta> : null}
      {emailVerified ? (
        <Message info>
          <Message.Header>Email verified</Message.Header>
          <p>Your email address has been verified successfully. You can now close this tab.</p>
        </Message>
      ) : null}
      {loading && <Loader centered active inline />}
      {errorMessage ? <Message color="red">{errorMessage}</Message> : null}

      {!loading && !emailVerified ? (
        <>
          <Message color="orange">
            This email verification link has expired. Email verifications are only valid for one day.
          </Message>
          {attemptedRetry ? (
            <Message color="green">
              <p>We have sent you a new link to:</p>
              <p>{emails[0].address}</p>
              <p>You can close this tab now.</p>
            </Message>
          ) : (
            <Button
              primary
              content="Re-send verification"
              onClick={() => {
                setErrorMessage("");
                setAttemptedRetry(true);
                setLoading(true);
                api.auth.requestEmailVerification(
                  token,
                  () => {
                    setLoading(false);
                  },
                  (err) => {
                    setLoading(false);
                    setAttemptedRetry(false);
                    setErrorMessage(err.message);
                  },
                );
              }}
            />
          )}
        </>
      ) : null}
      {!loading ? (
        <>
          <Divider />
          <Button content={user ? "Go to homepage" : "Go to login"} onClick={() => navigate("/")} />
        </>
      ) : null}
    </div>
  );
};

export default CompleteVerifyEmail;
