import React, { useState, useCallback } from "react";
import { Form, Button, Divider } from "semantic-ui-react";
import toast from "react-hot-toast";
import actions from "actions";
import api from "api";
import { useAppDispatch, useAppSelector } from "store";
import SettingActions from "../SettingActions";

const InitialProfileSetup = () => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState(user?.profile?.firstName);
  const [lastName, setLastName] = useState(user?.profile?.lastName);

  const saveProfile = useCallback(() => {
    setSaving(true);
    const profile = { ...user.profile, firstName, lastName };
    api.users.update(
      user._id,
      { profile },
      (newUser) => {
        setSaving(false);
        dispatch(
          actions.user.receiveUser({
            ...user,
            profile: newUser.profile,
          }),
        );
      },
      (err) => {
        toast.error(err.message);
        setSaving(false);
      },
    );
  }, [firstName, lastName, user, dispatch]);

  return (
    <>
      <h3>You are about to join {user?.ownerOrganisation?.name}!</h3>
      <p>We just need to know a few more details about you. To continue, please let others know who you are.</p>
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            label="First name"
            placeholder="First name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Form.Input
            fluid
            label="Last name"
            placeholder="Last name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Group>
        <Divider hidden />
        <SettingActions>
          <Button
            onClick={saveProfile}
            loading={saving}
            disabled={!firstName || !lastName}
            primary
            content="Save profile"
          />
        </SettingActions>
      </Form>
    </>
  );
};

export default InitialProfileSetup;
