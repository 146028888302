import React, { useState, useCallback, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Grid, Button, Divider } from "semantic-ui-react";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import actions from "actions";
import { useAppSelector, useAppDispatch } from "store";

import { SideNavLink } from "components/lib/SideNav";
import { Sticky } from "components/lib/UI";
import Prompt from "utils/Prompt";

import AccountSettings from "./Account";
import NotificationsSettings from "./Notifications";
import CommunicationSettings from "./Communication";
import PasswordSetings from "./Password";
import ProfileSettings from "./Profile";
import ApiSettings from "./ApiSettings";

const UserSettings = () => {
  const [user, setUser] = useState({});
  const [unsaved, setUnsaved] = useState(false);
  const [_loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [_errorMessage, setErrorMessage] = useState("");
  const contextRef = useRef();

  const { t } = useTranslation();

  const location = useLocation();
  const path = location.pathname;

  const remoteUser = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const onProfileUpdated = useCallback((profile) => dispatch(actions.user.updateProfile(profile)), [dispatch]);
  const onUpdateTags = useCallback((tags) => dispatch(actions.user.updateTags(tags)), [dispatch]);

  useEffect(() => {
    setLoading(true);
    setErrorMessage("");
    api.users.get(
      remoteUser._id,
      (user) => {
        setUnsaved(false);
        setUser(user);
        setLoading(false);
      },
      (err) => {
        setErrorMessage(err.message);
        setLoading(false);
      },
    );
  }, [remoteUser._id]);

  const updateProfile = (label, value) => {
    setUnsaved(true);
    setUser((prev) => {
      const validFields = ["github", "linkedin", "instagram", "twitter", "facebook"];
      if (validFields.includes(label) && util.validateUrl(value)) {
        const urlPath = util.getUrlPath(value);
        if (!urlPath) {
          // pass
        } else {
          // Split path, and remove any empty values, which would be a result of trailing/preceding slashes
          // Then take final value, to get the account id
          const splitPath = urlPath.split("/").filter((part) => part !== "");
          value = splitPath[splitPath.length - 1];
        }
      } else if (validFields.includes(label)) {
        // Split if issue accidentally includes the entire path. For example, they may try and do "/in/xniall" for linkedin
        const splitValue = value.split("/").filter((part) => part !== "");
        if (splitValue.length > 1) {
          value = splitValue[splitValue.length - 1];
        }
      }
      return {
        ...prev,
        profile: {
          ...prev.profile,
          [label]: value,
        },
      };
    });
  };

  const toggleTag = (tag, checked) => {
    const { ownerTags = [] } = user;

    if (!checked) {
      api.tags.untagSingle("users", tag._id, user._id, (newTag) => {
        const updatedTags = Object.assign([], ownerTags);
        updatedTags.splice(
          ownerTags.findIndex((ft) => ft._id === newTag._id),
          1,
        );
        onUpdateTags(updatedTags);
        setUser({ ...user, tags: updatedTags.map((t) => t._id), ownerTags: updatedTags });
      });
    } else {
      api.tags.tagSingle(
        "users",
        tag._id,
        user._id,
        (newTag) => {
          const updatedTags = Object.assign([], ownerTags);
          updatedTags.push(newTag);
          onUpdateTags(updatedTags);
          setUser({ ...user, tags: updatedTags.map((t) => t._id), ownerTags: updatedTags });
        },
        (err) => toast.error(err.message),
      );
    }
  };

  const saveProfile = () => {
    setSaving(true);
    const profile = user && user.profile;
    api.users.update(
      user._id,
      { profile },
      (user) => {
        toast.success(t("generic.typeUpdated", { type: "Profile" }));
        setUser(user);
        setSaving(false);
        setUnsaved(false);
        onProfileUpdated(user.profile);
      },
      (err) => {
        toast.error(err.message);
        setSaving(false);
      },
    );
  };

  return (
    <Container style={{ marginTop: 20, marginBottom: 20 }}>
      <Helmet title={"Settings"} />
      <Prompt
        when={location.pathname.match(/^\/preferences\/profile$/) && unsaved}
        message={"Your profile changes are unsaved. Really leave this page?"}
      />
      <div>
        <Button
          basic
          floated="right"
          icon="arrow left"
          content={t("users.settings.viewProfile")}
          as={Link}
          to={`/users/${user._id}`}
        />
      </div>
      <Divider clearing hidden />

      <div ref={contextRef}>
        <Grid stackable>
          <Grid.Column computer={4}>
            <Sticky top={60}>
              <h2 style={{ marginTop: 0 }}>{t("generic.settings")}</h2>
              <SideNavLink
                active={path === "/preferences"}
                to="/preferences"
                iconLeft="settings"
                content={t("users.settings.sections.account")}
              />
              <SideNavLink
                active={path === "/preferences/profile"}
                to="profile"
                iconLeft="address card outline"
                content={t("users.settings.sections.profile")}
              />
              {!remoteUser?.ownerOrganisation?.preventEmailChange && (
                <SideNavLink
                  active={path === "/preferences/email" || path === "/preferences/communication"}
                  to="communication"
                  iconLeft="at"
                  content="Communication"
                />
              )}
              {!remoteUser?.ssoUser && (
                <SideNavLink
                  active={path === "/preferences/password"}
                  to="password"
                  iconLeft="lock"
                  content={t("users.settings.sections.password")}
                />
              )}
              <SideNavLink
                active={path === "/preferences/notifications"}
                to="notifications"
                iconLeft="bell"
                content={t("users.settings.sections.notifications")}
              />
              <SideNavLink
                active={path === "/preferences/connections"}
                to="connections"
                iconLeft="plug"
                content={t("users.settings.sections.api")}
              />
              <Grid>
                <Grid.Row only="mobile">
                  <div
                    style={{
                      borderBottom: "solid grey 1px",
                      minWidth: "100%",
                      minHeight: "20px",
                      marginBottom: "5px",
                    }}
                  ></div>
                </Grid.Row>
              </Grid>
              {path === "/preferences/profile" && (
                <React.Fragment>
                  <Divider hidden />
                  {unsaved && (
                    <Button
                      style={{ marginBottom: "5px" }}
                      loading={saving}
                      fluid
                      primary
                      icon="save"
                      content={t("generic.saveChanges")}
                      onClick={saveProfile}
                    />
                  )}
                </React.Fragment>
              )}
            </Sticky>
          </Grid.Column>
          <Grid.Column computer={12}>
            <Routes>
              <Route path="/" element={<AccountSettings />} />
              <Route
                path="profile"
                element={
                  <ProfileSettings
                    saveProfile={saveProfile}
                    toggleTag={toggleTag}
                    updateProfile={updateProfile}
                    profileUser={user}
                    saving={saving}
                  />
                }
              />
              {!remoteUser?.ownerOrganisation?.preventEmailChange && (
                <>
                  <Route path="email" element={<CommunicationSettings />} />
                  <Route path="communication" element={<CommunicationSettings />} />
                </>
              )}
              {!remoteUser?.ssoUser && <Route path="password" element={<PasswordSetings />} />}
              <Route path="notifications" element={<NotificationsSettings />} />
              <Route path="connections" element={<ApiSettings />} />
            </Routes>
          </Grid.Column>
        </Grid>
      </div>
    </Container>
  );
};

export default UserSettings;
