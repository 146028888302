import api from "api";
import { HoverAction } from "components/lib/HoverActions";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useAppSelector } from "store";
import util from "utils/utils";
import PinIdeaHelper from "../../PinIdeaHelper";
import { useTranslation } from "react-i18next";
import { OpenAPI } from "simplydo/interfaces";

type PinActionProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
  onUpdateIdea: (idea: Partial<OpenAPI.GET<"/ideas/{id}">["response"]>) => void;
};

const PinAction = ({ idea, onUpdateIdea }: PinActionProps) => {
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const ideaId = idea?._id;

  const pinIdea = useCallback(() => {
    api.ideas.updateStatus(
      ideaId,
      { isPinned: !idea?.isPinned },
      (ideaStatus) => {
        onUpdateIdea({ isPinned: ideaStatus.isPinned });
      },
      (err) => toast.error(err.message),
    );
  }, [ideaId, idea, onUpdateIdea]);

  if (!util.canManageChallenge(user, idea.ownerChallenge)) {
    return null;
  }
  if (!util.hasPermission(user, "challenge.pinIdeas", idea.challenge)) {
    return null;
  }
  return (
    <HoverAction
      icon="pin"
      onClick={pinIdea}
      popupContent={<PinIdeaHelper challenge={idea.ownerChallenge} />}
      popupPosition="right center"
    >
      {idea?.isPinned ? `Un-pin ${t("generic.idea")}` : `Pin ${t("generic.idea")}`}
    </HoverAction>
  );
};

export default PinAction;
