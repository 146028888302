import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Form, Button, Divider, Card, Icon } from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "store";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import api from "api";
import actions from "actions";
import util from "utils/utils";

type INewChallengeModalWrapper = {
  newChallengeOpen: boolean;
  closeModal: () => void;
};

const NewModal = ({ newChallengeOpen, closeModal }: INewChallengeModalWrapper) => {
  const [creatingChallengeType, setCreatingChallengeType] = useState<"" | "manual" | "genai">("manual");
  const [name, setName] = useState<string>("");
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createChallenge = useCallback(() => {
    api.challenges.create(
      name,
      ({ _id }) => {
        dispatch(actions.user.onFollow("challenge", _id));
        navigate(`/challenges/${_id}/settings`);
      },
      (err) => toast.error(err.message),
    );
  }, [name, navigate, dispatch]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13 && newChallengeOpen && name !== "") {
        createChallenge();
        e.stopPropagation();
        e.preventDefault();
        return true;
      }
    },
    [createChallenge, name, newChallengeOpen],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (util.organisationFeaturesEnabled(user, ["openAI"])) {
      setCreatingChallengeType("");
    }
  }, [user, newChallengeOpen]);

  const onCloseModal = useCallback(() => {
    setCreatingChallengeType("");
    setName("");
    closeModal();
  }, [closeModal]);

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={newChallengeOpen}
      onOpen={() => setCreatingChallengeType("manual")}
      onClose={onCloseModal}
    >
      <Modal.Header>Create {t("generic.challenge")}</Modal.Header>
      {!creatingChallengeType ? (
        <Modal.Content>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
            <Card
              fluid
              header={
                <Card.Header>
                  <Icon name="edit" />
                  Manually create {t("generic.challengeWithArticle")}
                </Card.Header>
              }
              description={`Use this option to create a blank ${t("generic.challenge")}, allowing you to start from scratch for each field.`}
              onClick={() => setCreatingChallengeType("manual")}
              style={{ margin: "0 20px 0 0" }}
            />
            <Divider vertical content="Or" style={{ height: "50px" }} />
            <Card
              fluid
              header={
                <Card.Header>
                  <Icon name="magic" />
                  Magic {t("generic.challenge")} generator
                </Card.Header>
              }
              description={`SimplyDo will ask a few quick questions to help create your ${t("generic.challenge")}. SimplyDo won’t produce a ready-to-go ${t("generic.challenge")}, but it will get you to a start-point from which you can continue editing.`}
              onClick={() => navigate("/challenges/new/genai")}
              style={{ margin: "0 0 0 20px" }}
            />
          </div>
        </Modal.Content>
      ) : (
        <>
          {creatingChallengeType === "manual" ? (
            <Modal.Content>
              <p>What would you like to call your {t("generic.challenge")}? You can always change this later.</p>
              <Form style={{ marginBottom: 10 }}>
                <Form.Input
                  onKeyDown={(e) => handleKeyDown(e)}
                  placeholder={`${t("common:capitalise", { key: "generic.challenge" })} name...`}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form>
            </Modal.Content>
          ) : null}
        </>
      )}

      <Modal.Actions>
        <Button content={t("generic.cancel")} onClick={() => onCloseModal()} />
        {creatingChallengeType === "manual" && (
          <Button icon="check" content={`Create ${t("generic.challenge")}`} primary onClick={() => createChallenge()} />
        )}
      </Modal.Actions>
    </Modal>
  );
};

export default NewModal;
