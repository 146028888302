import React, { useState, useEffect } from "react";
import useMention from "./useMention";
import Dropdown from "./Dropdown";
import { Preset } from "../../presets/types";

const mapToName = (list) =>
  list.reduce((p, c) => {
    p[c.name] = c;
    return p;
  }, {});

export type RemirrorMention = {
  [key: string]: any;
  name: string;
  priority: number;
  appendText?: string;
};

export type RemirrorMentionProps = {
  mentions: Array<RemirrorMention>;
  config: Preset;

  // Does not directly return mentions but just acts as a trigger to load mentions which are passed in via state
  onSuggest: (kind: Array<string>, query: string) => void;
  onMentionSelect?: (mention: RemirrorMention) => void;
};

/**
 * The mention components
 *
 * @param mentions - a map of name to available mentions
 * @param config - the configuration objects used to initialise the mention extension
 * @param onMentionSelect - Will receive the selected mention as a parameter
 */
const Mentions = ({ mentions, config, onSuggest, onMentionSelect }: RemirrorMentionProps) => {
  const [mappedConfig, setMappedConfig] = useState<Record<Preset[number]["name"], Preset[number]>>(() =>
    mapToName(config),
  );

  useEffect(() => {
    setMappedConfig(mapToName(config));
  }, [config]);

  const mentionProps = useMention({
    allMentions: mentions,
    config: mappedConfig,
    onSuggest,
    onMentionSelect,
  });
  return <Dropdown {...mentionProps} />;
};

export default Mentions;
