import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Loader, Label, Checkbox, Button } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import api from "api";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import styled from "styled-components";
import toast from "react-hot-toast";
import actions from "actions";
import useTheme from "theme/useTheme";
import { useAppDispatch, useAppSelector } from "store";

export const OnboardingContainer = styled.div<{ $isPreview: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $isPreview }) =>
    $isPreview &&
    `
    margin: 3rem;
  `}

  .progress-labels {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    > .ui.label {
      margin: 0 0.5rem;
    }
  }
  .agree-to-terms {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
  }
`;

const ProgressLabel = ({ isActive, colour }) => (
  <Label
    empty
    circular
    size="large"
    color={!isActive ? "grey" : null}
    style={{ backgroundColor: isActive && colour }}
  />
);

type CustomOnboardingProps = {
  isPreview?: boolean;
};

export const Onboarding = ({ isPreview, organisationOnboarding, completeOnboarding, completing }) => {
  const user = useAppSelector((state) => state.user);
  const [userHasAgreedToScreen, setUserHasAgreedToScreen] = useState(false);
  const theme = useTheme();
  const organisationId = user?.ownerOrganisation?._id;

  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    setUserHasAgreedToScreen(false);
  }, [activePage]);

  const nextPageAvailable = activePage < organisationOnboarding.screens.length - 1;
  const prevPageAvailable = activePage !== 0;

  const currentScreen = useMemo(
    () => organisationOnboarding.screens[activePage],
    [organisationOnboarding.screens, activePage],
  );

  const userCanProgress = useMemo(
    () =>
      currentScreen &&
      (!currentScreen.requireUserAgreement || (currentScreen.requireUserAgreement && userHasAgreedToScreen)),
    [currentScreen, userHasAgreedToScreen],
  );

  return (
    <OnboardingContainer $isPreview={isPreview}>
      <div style={{ maxWidth: "100%", overflow: "hidden" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "relative",
            left: `-${activePage * 100}%`,
            transition: "left 0.5s",
          }}
        >
          {organisationOnboarding.screens.map((screen, index) => (
            <div key={index} style={{ maxWidth: "100%", flex: "1 0 100%", maxHeight: 300, overflow: "auto" }}>
              <RichTextViewer forType="onboardings" forId={organisationId} content={screen.content} />
            </div>
          ))}
        </div>
      </div>
      <div
        className="agree-to-terms"
        style={{ visibility: !currentScreen?.requireUserAgreement ? "hidden" : "visible" }}
      >
        <Checkbox
          checked={userHasAgreedToScreen}
          onClick={() => setUserHasAgreedToScreen(!userHasAgreedToScreen)}
          label="I have read and agree to the above terms."
        />
      </div>
      <div className="progress-labels">
        {prevPageAvailable ? (
          <Button
            labelPosition="left"
            icon="chevron left"
            content="Prev"
            onClick={() => setActivePage((prevPage) => prevPage - 1)}
          />
        ) : null}
        {nextPageAvailable ? (
          <Button
            labelPosition="right"
            icon="chevron right"
            content="Next"
            primary
            onClick={() => setActivePage((prevPage) => prevPage + 1)}
            disabled={!userCanProgress}
          />
        ) : null}
        {!nextPageAvailable && !isPreview ? (
          <Button
            labelPosition="right"
            icon="flag checkered"
            content="Finish"
            primary
            onClick={completeOnboarding}
            loading={completing}
            disabled={!userCanProgress}
          />
        ) : null}
      </div>
      <div className="progress-labels">
        {organisationOnboarding.screens.map((screen, index) => (
          <ProgressLabel isActive={activePage === index} colour={theme?.primaryColour} key={index} />
        ))}
      </div>
    </OnboardingContainer>
  );
};

const CustomOnboarding = ({ isPreview }: CustomOnboardingProps) => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [completing, setCompleting] = useState(false);
  const organisationId = user?.ownerOrganisation?._id;
  const [organisationOnboarding, setOrganisationOnboarding] = useState({
    screens: [],
    isEnabled: false,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    api.organisations.getOnboarding(
      organisationId,
      ({ onboardingData, found }) => {
        if (!isMounted) return;

        setLoading(false);
        if (found) {
          setOrganisationOnboarding(onboardingData);
        }
      },
      (err) => {
        if (!isMounted) return;

        toast.error(err.message);
        setLoading(false);
      },
    );
    return () => {
      isMounted = false;
    };
  }, [organisationId]);

  const completeOnboarding = useCallback(() => {
    setCompleting(true);
    api.organisations.trackOnboardingCompletion(
      organisationId,
      () => {
        dispatch(actions.user.trackOnboardingCompletion());
        setCompleting(false);
      },
      (err) => {
        toast.error(err.message);
        setCompleting(false);
      },
    );
  }, [organisationId, dispatch]);

  if (loading) {
    return <Loader active inline="centered" />;
  }
  if (!organisationOnboarding.screens.length) return null;
  return (
    <>
      {!isPreview ? <Helmet title={`Welcome to ${user.ownerOrganisation.name}`} /> : null}
      <Onboarding
        isPreview={isPreview}
        organisationOnboarding={organisationOnboarding}
        completeOnboarding={completeOnboarding}
        completing={completing}
      />
    </>
  );
};

export default CustomOnboarding;
