import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  KeyboardEvent,
  useCallback,
  useImperativeHandle,
  useMemo,
} from "react";
import { Remirror, ReactFrameworkOutput, ThemeProvider, useRemirror } from "@remirror/react";
import { Extension, RemirrorJSON, htmlToProsemirrorNode, prosemirrorNodeToHtml, InvalidContentHandler } from "remirror";
import { HistoryExtension, TextExtension, PositionerExtension } from "remirror/extensions";
import "remirror/styles/all.css";
// Instead of the above import, we _could_ use the following import to use a wrapped styled-component
// However, because in this case the styling is only loaded when the component renders it needs to be parsed by the browser just-in-time.
// This adds a fairly long delay as the "CSS parsing" operation is quite expensive AND BLOCKING, instead importing the CSS directly is much faster and there is no delay when loading it.
// import { AllStyledComponent } from "@remirror/styles/styled-components";

import sanitizeHtml from "sanitize-html";
import useThrottle from "utils/useThrottle";

const allowedTags = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "blockquote",
  "p",
  "a",
  "ul",
  "ol",
  "nl",
  "li",
  "b",
  "i",
  "strong",
  "em",
  "strike",
  "code",
  "hr",
  "br",
  "div",
  "table",
  "thead",
  "caption",
  "tbody",
  "tr",
  "th",
  "td",
  "pre",
  "iframe",
  "u",
  "s",
  "img",
  "video",
  "span",
];

export const DEFAULT_CONTENT = "<p></p>";

export const sanitiseValue = (value) =>
  sanitizeHtml(value, {
    allowedTags,
    allowedAttributes: {
      a: ["href", "name", "rel", "target", "auto", "class", "data-mention-atom-id", "data-mention-atom-name", "style"],
      img: ["src", "width", "height"],
      div: ["style"], // alignment
      span: ["src", "name", "class", "data-ext", "data-node", "style"], // file
      i: ["class"],
      iframe: ["src", "allowfullscreen", "width", "height", "title", "frameborder", "allow", "referrerpolicy"],
      video: ["src", "allowfullscreen", "width", "height"],
    },
    allowedIframeDomains: ["youtube.com", "vimeo.com", "powerbi.com"],
  });

export type RemirrorInnerEditorProps = {
  value: string;
  onChange?: (html: string, textValue: string) => void;
  onChangeJson?: (jsonValue: RemirrorJSON) => void;
  onFocus?: (event: any) => void;
  onBlur?: (html: string, textValue: string) => void;
  emptyReturnValue?: string | null | undefined;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  placeholder?: string | null;
  editorRef?: React.RefObject<any>;

  children?: ReactNode;
  extensions?: Extension[];
};

/* Remirror has an inbuilt "html" string handler which is just mapped to `htmlToProsemirrorNode` exported from `remirror` package. However, the `preserveWhitespace` option defaults to `false` and it cannot be overwritten by a prop.

This is an issue, because it removes leading/trailing whitespaces when we rebuild the state after passing a new value. We only update when the value has changed but in some case (e.g., saving an idea) the API respondes with urls that have a changed signature.

References:
  - https://github.com/remirror/remirror/blob/a0cc38df84d3de7d3fd72ae1804803bf4b76b0de/packages/remirror__core-utils/src/core-utils.ts#L1380
  - https://github.com/remirror/remirror/blob/a0cc38df84d3de7d3fd72ae1804803bf4b76b0de/packages/remirror__core/src/builtins/helpers-extension.ts#L72
*/
const htmlStringHandler = (props) => htmlToProsemirrorNode({ ...props, preserveWhitespace: true });

const EditorWrapper = ({
  value,
  onChange,
  onChangeJson,
  onBlur,
  onFocus,
  emptyReturnValue = DEFAULT_CONTENT,
  extensions,
  children,
  readOnly,
  placeholder,
  editorRef: propRef,
  handleKeyDown,
}: RemirrorInnerEditorProps) => {
  const _localRef = useRef<ReactFrameworkOutput<Extension>>();
  const [editorRef] = useState<typeof _localRef>(propRef ?? _localRef);

  const outsideValueSanitised = useMemo(() => sanitiseValue(value || DEFAULT_CONTENT), [value]);
  // Maintain a previous HTML state to prevent unnecessary updates
  const previousHTML = useRef<string | undefined>(outsideValueSanitised);

  const [allExtensions] = useState<() => Extension[]>(() =>
    ([...extensions, new PositionerExtension({}), new HistoryExtension({}), new TextExtension()] as Extension[]).filter(
      (extension) => !!extension,
    ),
  );

  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    // Automatically remove all invalid nodes and marks.
    return transformers.remove(json, invalidContent);
  }, []);

  const {
    manager,
    state: remirrorState,
    getContext,
  } = useRemirror({
    extensions: allExtensions,
    content: sanitiseValue(value || DEFAULT_CONTENT),
    stringHandler: htmlStringHandler,
    selection: "end",
    onError,
  });

  useImperativeHandle(editorRef, () => getContext(), [getContext]);

  useEffect(() => {
    // If the outside value is the same as the current value then we don't need to update the editor
    // This is especially important to prevent an infinite loop when not all props are nicely memoized
    const outsideHTML = outsideValueSanitised;
    if (outsideHTML === previousHTML.current) {
      return;
    }

    // If the outside content has changed but is the same as the current content then we don't need to update
    // We still want to update the previousHTML ref though becaused this `prosemirrorNodeToHtml` call can be expensive
    const prevState = manager.getState();
    const currentHTML = sanitiseValue(prosemirrorNodeToHtml(prevState.doc));
    if (currentHTML === outsideHTML || outsideHTML === previousHTML.current) {
      previousHTML.current = outsideHTML;
      return;
    }

    const contentState: {
      content: string;
      selection?: typeof prevState.selection;
    } = {
      content: outsideHTML,
    };
    if (!readOnly && outsideHTML !== DEFAULT_CONTENT) {
      if (prevState.selection) {
        contentState.selection = prevState.selection;
      }
    }

    // Only once we are sure the state was actually changed from the outside do we update the editor
    const newState = manager.createState(contentState);
    manager.view.updateState(newState);
    previousHTML.current = outsideHTML;
  }, [outsideValueSanitised, manager, readOnly]);

  const onBlurHandler = useCallback(
    ({ helpers }) => {
      if (readOnly) return;
      if (onBlur) {
        const html = helpers.getHTML();
        if (html === DEFAULT_CONTENT) {
          onBlur(emptyReturnValue, "");
        } else {
          const text = helpers.getText();
          const sanitised = sanitiseValue(html);
          onBlur(sanitised, text);
        }
      }
    },
    [readOnly, onBlur, emptyReturnValue],
  );

  // We slightly throttle updates to prevent too many updates
  // This shouldn't be necessary but it's a good idea to have it here just in case we don't build in proper debouncing/handling in the components that end up using the remirror editor
  const onUpdate = useThrottle(
    (html, text) => {
      onChange?.(html, text);
      previousHTML.current = html;
    },
    250,
    [onChange],
  );

  const onUpdateJSON = useThrottle(
    (change) => {
      if (onChangeJson) {
        onChangeJson(change.helpers.getJSON());
      }
    },
    250,
    [onChangeJson],
  );

  const onChangeHandler = useCallback(
    (change) => {
      // External change handler is only called when the actual HTML content changes
      // for this a few checks are necessary to reduce unnecessary calls
      const { tr } = change;

      const metaHistory = tr?.getMeta("history$");

      if (!tr) return;
      if (tr.steps.length === 0) return;
      if (change.internalUpdate) return; // don't report internal updates
      if (metaHistory) return; // don't report history updates (which are disabled but still register as a transaction)
      if (readOnly) return; // read only never reports

      // Even though the remirror change parameter has a `helpers.toHTML()` method, it doesnt actually return the HTML of the change but rather the state of the document BEFORE the change
      // Therefore we need to get the HTML from the transaction document
      const html = prosemirrorNodeToHtml(tr.doc);
      const sanitised = sanitiseValue(html);
      if (outsideValueSanitised === sanitised) return;

      onUpdateJSON(change);

      // Is get html doing some sanitisation?!
      if (html === DEFAULT_CONTENT) {
        onUpdate(emptyReturnValue, "");
      } else {
        const text = tr.doc.textContent;
        onUpdate(sanitised, text);
      }
    },
    [emptyReturnValue, onUpdate, onUpdateJSON, readOnly, outsideValueSanitised],
  );

  return (
    <ThemeProvider>
      <Remirror
        initialContent={remirrorState}
        manager={manager}
        editable={!readOnly}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlurHandler}
        onKeyDown={handleKeyDown}
        onChange={onChangeHandler}
      >
        <div
          style={{
            flex: 1,
            alignSelf: "stretch",
            position: "relative",
            wordBreak: "break-all",
            minHeight: "100px !important",
          }}
        >
          {children}
        </div>
      </Remirror>
    </ThemeProvider>
  );
};

export default EditorWrapper;
