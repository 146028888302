import { useRef, useEffect, useCallback } from "react";

// This exists to remove the issues encountered with non-memorised functions
// Previously, using setTimeout on a callback function would remake the function and lose its ref in the component
// Therefore it would run everytime, as the timeout would be cleared
// This saves the function in a callback
// And the timeout is never lost, so the throttling happens correctly

type UseThrottleDeps = any[];
type UseThrottleOptions = {
  useLeadingCall?: boolean;
};

const useThrottle = <T extends (...args: any[]) => any, Params extends Parameters<T>>(
  cb: (...args: Params) => ReturnType<T>,
  delay: number,
  deps: UseThrottleDeps = [],
  options: UseThrottleOptions = {
    useLeadingCall: false,
  },
) => {
  const { useLeadingCall } = options;

  const cbRef = useRef(cb);
  const timeoutRef = useRef(null);
  const leadingCallRef = useRef(false);

  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => {
    cbRef.current = cb;
  }, [cb]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    (...args: Params) => {
      if (useLeadingCall && !leadingCallRef.current) {
        leadingCallRef.current = true;
        cbRef.current(...args);
        return;
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      timeoutRef.current = setTimeout(() => {
        if (cbRef.current) {
          cbRef.current(...args);
        }
        timeoutRef.current = null;
      }, delay);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [delay, ...deps],
  );
};

export default useThrottle;
