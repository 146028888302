import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Container, Popup, Button, Dropdown } from "semantic-ui-react";
import { Sticky } from "components/lib/UI";
import util from "utils/utils";
import moment from "moment";
import IdeaPdfExport from "components/lib/IdeaPdfExport";
import api from "api";
import { useTrayStatus } from "components/lib/Notifications";

const IdeaToolbarDiv = styled.div`
  min-height: ${({ theme }) => (theme.sizes.isMobile ? 30 : 40)}px;
  background-color: #f0f0f0;
  border-bottom: 1px solid lightgray;
  top: 50px;
  width: 100%;
`;

const ToolbarContainer = styled(Container)`
  padding: 5px 0 5px 0;
  display: flex;
  flex-direction: row-reverse !important;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $verticalAlign }) => $verticalAlign || "flex-start"};
  align-items: ${({ $horizontalAlign }) => $horizontalAlign || "center"};
  flex-wrap: wrap;
`;

const IdeaEditToolbar = ({
  t,
  idea,
  user,
  isEditing,
  undoChanges,
  redoChanges,
  canUndo,
  canRedo,
  savedAt,
  deleteIdea,
}) => {
  const theme = useContext(ThemeContext);
  const [_, setTrayStatus] = useTrayStatus();

  const downloadIdeaDocxReport = () => {
    const ideaName = idea?.name || "Idea";
    api.users.createDownload(
      user._id,
      {
        downloadType: "ideaDocxExport",
        displayName: `${ideaName} export.docx`,
        idea: idea._id,
      },
      (download) => {
        api.queryClient.setQueryData(["get", "users", "downloads"], (prev) => ({
          ...prev,
          pendingDownloads: (prev?.pendingDownloads || []).concat([download]),
        }));
        setTrayStatus({ open: true, activeIndex: 1 });
      },
      () => {},
    );
  };
  if (!isEditing) return null;
  return (
    <Sticky top={theme.sizes.isMobile ? 90 : 100} style={{ zIndex: 90 }}>
      <IdeaToolbarDiv>
        <ToolbarContainer>
          <HorizontalDiv $verticalAlign="space-between">
            <HorizontalDiv />
            <HorizontalDiv>
              <Popup
                content={t("ideas.undo")}
                on="hover"
                trigger={
                  <Button
                    size="mini"
                    icon="undo"
                    onClick={undoChanges}
                    disabled={!canUndo}
                    style={{ marginRight: 10 }}
                    basic
                  />
                }
              />
              <Popup
                content={t("ideas.redo")}
                on="hover"
                trigger={
                  <Button
                    size="mini"
                    icon="redo"
                    onClick={redoChanges}
                    disabled={!canRedo}
                    style={{ marginRight: 10 }}
                    basic
                  />
                }
              />
              <Dropdown icon="settings" direction="left">
                <Dropdown.Menu>
                  {user ? (
                    <IdeaPdfExport
                      trigger={<Dropdown.Item icon="file pdf" text={t("ideas.download.pdf")} />}
                      ideas={[idea]}
                      challenge={idea.ownerChallenge}
                    />
                  ) : null}
                  {user && !idea.isSubmitted ? (
                    <Dropdown.Item
                      icon="file word"
                      text={t("ideas.download.word")}
                      onClick={() => downloadIdeaDocxReport()}
                    />
                  ) : null}
                  {!idea?.ownerChallenge?.preventIdeaCoverImages && idea?.coverImage ? (
                    <Dropdown.Item
                      as="a"
                      href={idea.coverImageUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      content={t("ideas.download.coverImage")}
                      icon="download"
                    />
                  ) : null}
                  <Dropdown.Item icon="trash" basic text={t("ideas.delete")} onClick={() => deleteIdea(!user)} />
                </Dropdown.Menu>
              </Dropdown>
              {util.canEditIdea(user, idea) && !idea.isSubmitted && !theme.sizes.isMobile && (
                <Button
                  style={{ marginLeft: 10 }}
                  size="mini"
                  icon="save"
                  content={
                    savedAt ? t("generic.savedAt", { time: moment(savedAt).format("hh:mma") }) : t("generic.save")
                  }
                  onClick={() => {
                    toast(t("ideas.autosave.warning"));
                  }}
                />
              )}
            </HorizontalDiv>
          </HorizontalDiv>
        </ToolbarContainer>
      </IdeaToolbarDiv>
    </Sticky>
  );
};

export default withTranslation()(IdeaEditToolbar);
