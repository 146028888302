import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Checkbox, Divider, Form, Icon, Message, Modal } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import api, { asMutation, asQuery } from "api";
import util from "utils/utils";
import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import RichText from "components/lib/Editors/RichText";
import QuestionList from "./QuestionList";
import { ChallengeChooser } from "components/lib/Choosers";
import toast from "react-hot-toast";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import { CheckboxHeader } from "components/lib/UI";
import TimezoneExplainer from "components/lib/TimezoneExplainer";
import styled from "styled-components";
import { DateInput } from "components/lib/DateInputs";
import { TimeInput } from "semantic-ui-calendar-react";
import moment from "moment";

const SubmissionInputDiv = styled.div`
  display: ${({ theme }) => (theme.sizes.isMobile ? "block" : "flex")};
  flex-direction: row;
  align-items: center;
`;

type QuestionsProps = {
  challenge: OpenAPI.Schemas["Challenge"];
  updateChallenge?: (challenge: OpenAPI.Schemas["Challenge"]) => void;
  nonAdminView?: boolean;
};

const QuestionModal = ({
  question,
  onClose,
  canManageQuestions,
}: {
  question: OpenAPI.Schemas["Question"];
  onClose: () => void;
  canManageQuestions: boolean;
}) => {
  const { t } = useTranslation();
  const [questionData, setQuestionData] = useState(question);
  const [hasConfirmed, setHasConfirmed] = useState(canManageQuestions);

  const createQuestionMutation = useMutation({
    mutationKey: ["challenges", question.challenge, "questions"],
    mutationFn: asMutation(api.challenges.createQuestion)!,
    onSuccess: (result) => {
      api.queryClient.setQueryData(["challenge", question.challenge, "questions"], (prev: any) => ({
        ...prev,
        questions: [...prev.questions, result.question],
      }));
    },
  });

  const updateQuestionMutation = useMutation({
    mutationKey: ["challenges", question.challenge, "questions", question._id],
    mutationFn: asMutation(api.challenges.updateQuestion)!,
    onSuccess: (result) => {
      api.queryClient.setQueryData(["challenge", question.challenge, "questions"], (prev: any) => ({
        ...prev,
        questions: prev.questions.map((q) => (q._id === question._id ? result.question : q)),
      }));
    },
  });

  return (
    <Modal open={!!question} mountNode={document.getElementById("semantic-modal-mount-node")}>
      <Modal.Header>{question._id ? "Edit Question" : "Ask a new question"}</Modal.Header>.
      <Modal.Content>
        {!canManageQuestions ? (
          <>
            <div style={{ marginBottom: 16 }}>
              Before you ask a question, please check if it has already been asked and answered. If you have a new
              question, please provide as much context as possible to give the {t("generic.challenge")} managers the
              best chance of answering your question.
            </div>
            <Checkbox
              toggle
              checked={hasConfirmed}
              onChange={(e, data) => {
                setHasConfirmed(data.checked);
              }}
              label="I confirm that I have read the current list of clarification questions and responses, and that my question is new."
              style={{ marginBottom: 16 }}
            />
          </>
        ) : null}
        <Form>
          <Form.TextArea
            label="Question"
            placeholder={`In detail, what would you like an explanation for? Please provide as much context as possible to give ${t("generic.challenge")} managers the best chance of answering your question.`}
            value={questionData.question}
            onChange={(e) => {
              setQuestionData((prev) => ({ ...prev, question: e.target.value }));
            }}
          />
          {canManageQuestions ? (
            <>
              <h5>Answer (only available to {t("generic.challenge")} managers)</h5>
              <RichText
                autoHideMenu={false}
                forType="challenge"
                forId={question.challenge}
                emptyReturnValue={""}
                placeholder="Please provide an answer to the question above."
                value={questionData.answer}
                onChange={(data) => {
                  setQuestionData((prev) => ({ ...prev, answer: data }));
                }}
              />
            </>
          ) : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          primary
          disabled={!hasConfirmed}
          loading={createQuestionMutation.isPending || updateQuestionMutation.isPending}
          onClick={() => {
            if (question._id) {
              updateQuestionMutation.mutate({
                params: [
                  question.challenge,
                  question._id,
                  {
                    question: questionData.question,
                    answer: questionData.answer,
                  },
                ],
              });
            } else {
              createQuestionMutation.mutate({
                params: [
                  question.challenge,
                  {
                    question: questionData.question,
                    answer: questionData.answer,
                  },
                ],
              });
            }
            onClose();
          }}
        >
          {questionData?._id ? "Save" : "Ask question"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const Questions = ({ challenge, updateChallenge, nonAdminView }: QuestionsProps) => {
  const { t } = useTranslation();
  const challengeId = challenge._id;
  const [deadlineDate, setDeadlineDate] = useState("");
  const [deadlineTime, setDeadlineTime] = useState("00:00");
  const [questionsIntro, setQuestionsIntro] = useState(challenge.questionsIntro);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const questionQuery = useQuery({
    queryKey: ["challenge", challenge._id, "questions"],
    queryFn: asQuery(api.challenges.getQuestions, {
      params: [challenge._id],
    })!,
  });
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!challenge.questionDeadline) {
      setDeadlineDate(moment().add(7, "d").format("YYYY-MM-DD"));
    } else {
      setDeadlineDate(moment(challenge.questionDeadline).format("YYYY-MM-DD"));
      setDeadlineTime(moment(challenge.questionDeadline).format("HH:mm"));
    }
  }, [challenge]);

  const updateChallengeMutation = useMutation({
    mutationKey: ["challenges", challenge._id],
    mutationFn: asMutation(api.challenges.update)!,
  });

  const copyQuestionsFromChallengeMutation = useMutation({
    mutationKey: ["challenges", challengeId, "questions", "copy"],
    mutationFn: asMutation(api.challenges.copyQuestions)!,
    onSuccess: () => {
      toast.success("Questions copied successfully");
      api.queryClient.invalidateQueries({ queryKey: ["challenge", challengeId] });
    },
  });

  const canManageQuestions =
    !nonAdminView &&
    (util.hasPermission(user, "challenge.manageQuestions", challenge._id) ||
      util.hasPermission(user, "org.manageChallenges", challenge.organisation));

  const nonPublicQuestions = useMemo(
    () => (user ? questionQuery.data?.questions?.filter((q) => !q.published) : []),
    [user, questionQuery.data],
  );
  const publishedQuestions = useMemo(
    () => questionQuery.data?.questions?.filter((q) => q.published),
    [questionQuery.data],
  );

  const setNewDeadline = useCallback(
    (enable, deadlineDate, deadlineTime) => {
      const dateAndTime = `${deadlineDate} ${deadlineTime}`;
      updateChallengeMutation.mutate({
        // @ts-ignore
        params: [challengeId, { questionDeadline: enable ? moment(dateAndTime).toDate() : null }],
      });
      // @ts-ignore
      updateChallenge?.({ ...challenge, questionDeadline: enable ? moment(dateAndTime).toDate() : null });
    },
    [challengeId, updateChallengeMutation, updateChallenge, challenge],
  );

  const updateDeadlineDate = useCallback(
    (deadlineDate) => {
      if (challenge.questionDeadline) {
        setDeadlineDate(deadlineDate);
        setNewDeadline(challenge.questionDeadline, deadlineDate, deadlineTime);
      } else setDeadlineDate(deadlineDate);
    },
    [challenge.questionDeadline, setNewDeadline, deadlineTime],
  );

  const updateDeadlineTime = useCallback(
    (deadlineTime) => {
      if (challenge.questionDeadline) {
        setDeadlineTime(deadlineTime);
        setNewDeadline(challenge.questionDeadline, deadlineDate, deadlineTime);
      } else setDeadlineTime(deadlineTime);
    },
    [challenge.questionDeadline, deadlineDate, setNewDeadline],
  );

  return (
    <div>
      {questionToEdit ? (
        <QuestionModal
          question={questionToEdit}
          onClose={() => setQuestionToEdit(null)}
          canManageQuestions={canManageQuestions}
        />
      ) : null}
      <h3>Clarification questions</h3>
      {!nonAdminView && canManageQuestions ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 10,
          }}
        >
          <h4>Introduction text</h4>
          <p>
            You may want to add some information to this page explaining the purpose of clarification questions to
            users.
          </p>
          <RichText
            forType="challenge"
            forId={challengeId}
            value={questionsIntro}
            emptyReturnValue={""}
            onChange={(data) => {
              setQuestionsIntro(data);
            }}
            placeholder={
              challenge.questionSubmissionsEnabled
                ? `Default: If you have any questions about this ${t("generic.challenge")}, please ask them here. The ${t("generic.challenge")} managers will do their best to answer them as soon as possible. If you have a question that you think may be useful to others, please ask it here.`
                : `Default: Here you can find commonly asked questions about this ${t("generic.challenge")}. This list may be periodically updated with new information by the ${t("generic.challenge")} managers.`
            }
          />
          <Button
            secondary
            disabled={(!questionsIntro && !challenge?.questionsIntro) || questionsIntro === challenge?.questionsIntro}
            content="Save intro"
            size="small"
            icon="save"
            style={{
              alignSelf: "flex-end",
              marginTop: 12,
            }}
            onClick={() => {
              updateChallenge?.({ ...challenge, questionsIntro });
              updateChallengeMutation.mutate({
                params: [challengeId, { questionsIntro }],
              });
            }}
          />
          <CheckboxHeader
            checked={!!challenge.questionDeadline}
            header="Question deadline"
            onChange={() => setNewDeadline(!challenge.questionDeadline, deadlineDate, deadlineTime)}
            willHideChildren
            style={{ marginTop: 10 }}
            as="h5"
            description={`Set a deadline date for submission of new questions to this ${t("generic.challenge")}. If a deadline date is
            set, question submission will automatically close at this point.`}
          >
            <SubmissionInputDiv>
              New questions will be disabled on{" "}
              <DateInput
                clearable
                name="date"
                placeholder="Date"
                value={deadlineDate}
                minDate={moment().toDate()}
                dateFormat="YYYY-MM-DD"
                iconPosition="left"
                onChange={(event, { value }) => updateDeadlineDate(moment(value).format("YYYY-MM-DD"))}
                onClear={() => updateDeadlineDate(moment().format("YYYY-MM-DD"))}
                style={{ marginLeft: 10, marginRight: 10 }}
              />
              {" at "}
              <TimeInput
                clearable
                name="time"
                placeholder="Time"
                value={deadlineTime}
                iconPosition="left"
                onChange={(event, { value }) => updateDeadlineTime(value)}
                onClear={() => updateDeadlineTime("00:00")}
                style={{ marginLeft: 10, marginRight: 10 }}
              />
            </SubmissionInputDiv>
            <TimezoneExplainer style={{ marginTop: 10 }} />
          </CheckboxHeader>
        </div>
      ) : challenge.questionsIntro ? (
        <>
          <RichTextViewer value={challenge.questionsIntro} />
          <Divider hidden />
        </>
      ) : challenge.questionSubmissionsEnabled ? (
        <p>
          If you have any questions about this {t("generic.challenge")}, please ask them here. The{" "}
          {t("generic.challenge")} managers will do their best to answer them as soon as possible. If you have a
          question that you think may be useful to others, please ask it here.
        </p>
      ) : (
        <p>
          Here you can find commonly asked questions about this {t("generic.challenge")}. This list may be periodically
          updated with new information by the {t("generic.challenge")} managers.
        </p>
      )}
      {challenge.questionDeadline ? (
        <Message info size="small">
          <p>
            <b>Question deadline:</b> This {t("generic.challenge")} will be closed to new questions at{" "}
            <b>{moment(challenge.questionDeadline).format("HH:mm on DD/MM/YY")}</b>. You cannot submit new questions
            after this date.
          </p>
        </Message>
      ) : null}
      {user && (challenge.questionSubmissionsEnabled || canManageQuestions) ? (
        <div>
          <Button
            style={{
              marginBottom: 16,
            }}
            primary
            onClick={() => {
              setQuestionToEdit({
                challenge: challenge._id,
                title: "",
                content: "",
                answer: "",
              });
            }}
          >
            <Icon name="plus" />
            {canManageQuestions ? "Add a new question" : "Ask a new question"}
          </Button>
          {canManageQuestions ? (
            <ChallengeChooser
              trigger={
                <Button
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <Icon name="copy" />
                  Copy questions from another challenge
                </Button>
              }
              single
              onComplete={(fromChallenge) =>
                copyQuestionsFromChallengeMutation.mutate({ params: [challengeId, fromChallenge[0]?._id] })
              }
              forType="questions"
              ignores={[challengeId]}
              beforeContent={`Choose ${t("generic.challengeWithArticle")} to copy questions from. You can view, edit and delete them after copying.`}
              isLoading={copyQuestionsFromChallengeMutation.isPending}
              confirm={`Copy from this ${t("generic.challenge")}`}
            />
          ) : null}
        </div>
      ) : null}
      {nonPublicQuestions?.length > 0 ? (
        <div style={{ marginBottom: 15, marginTop: 15 }}>
          <h3>
            {canManageQuestions ? `Non-public questions (${nonPublicQuestions.length})` : "Questions awaiting answer"}
          </h3>
          <QuestionList
            questions={nonPublicQuestions}
            challenge={challenge}
            setQuestionToEdit={canManageQuestions ? setQuestionToEdit : undefined}
          />
        </div>
      ) : null}
      <h3>{canManageQuestions ? `Public questions (${publishedQuestions?.length || 0})` : "All questions"}</h3>
      {publishedQuestions?.length === 0 ? (
        <Message info style={{ marginTop: 0 }}>
          <Message.Header>No questions yet</Message.Header>
          <Message.Content>
            Questions will start appearing in this place once they get approved by {t("generic.challengeWithArticle")}{" "}
            manager.
          </Message.Content>
        </Message>
      ) : (
        <QuestionList
          questions={publishedQuestions}
          challenge={challenge}
          setQuestionToEdit={canManageQuestions ? setQuestionToEdit : undefined}
        />
      )}
    </div>
  );
};

export default Questions;
