import React, { useEffect, useState, useCallback } from "react";
import { Message, Divider, Button, Popup, Input } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "store";
import toast from "react-hot-toast";
import api from "api";
import { OpenAPI } from "simplydo/interfaces";
import actions from "actions";
import util from "utils/utils";
import { useUserReload } from "utils/useUserReload";
import DotPulse from "../UI/DotPulse";

const VerifyEmail = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tryAgain, setTryAgain] = useState(10);
  const [editingEmail, setEditingEmail] = useState<string>("");
  const [editingEmailOpen, setEditingEmailOpen] = useState<boolean>(false);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const reloadUser = useUserReload();

  useEffect(() => {
    const tryAgainTimer = setInterval(() => setTryAgain((prev) => Math.max(prev - 1, 0)), 1000);
    return () => {
      clearInterval(tryAgainTimer);
    };
  }, []);

  // Ping at regular intervals to user endpoint to see if they have verified their email
  useEffect(() => {
    const userMeInterval = setInterval(() => {
      if (document.visibilityState === "visible") {
        api.users.me(
          null,
          (userResponse: OpenAPI.GET<"/users/me">["response"]) => {
            if (util.hasVerifiedEmail(userResponse, userResponse.organisation)) {
              clearInterval(userMeInterval);
              dispatch(actions.user.receiveUser(userResponse));
            }
          },
          () => {},
        );
      }
    }, 2000);
    return () => {
      clearInterval(userMeInterval);
    };
  }, [dispatch]);

  const resendVerificationEmail = useCallback(() => {
    setLoading(true);
    api.auth.requestEmailVerification(
      null,
      () => {
        setLoading(false);
        toast.success("Verification email sent");
      },
      (err) => {
        setLoading(false);
        toast.error(err.message);
      },
    );
  }, []);

  const updateEmailAddress = useCallback(() => {
    api.auth.updateEmailAddress(
      { email: editingEmail },
      () => {
        setLoading(false);
        setTryAgain(30);
        setEditingEmail("");
        setEditingEmailOpen(false);
        reloadUser();
        resendVerificationEmail();
      },
      (err) => {
        setLoading(false);
        toast.error(err.message);
      },
    );
  }, [editingEmail, resendVerificationEmail, reloadUser]);

  return (
    <>
      <h3>To continue, please verify your email address</h3>
      <p>
        We've sent an email to the address that you provided. Please follow the instructions in that email to complete
        the process.
      </p>
      <div style={{ display: "flex", gap: 8 }}>
        <Input size="small" label="Your email address" readOnly value={user?.emails[0]?.address} />
        <Popup
          on="click"
          open={editingEmailOpen}
          onClose={() => setEditingEmailOpen(false)}
          trigger={
            <Button
              size="small"
              basic
              content="Not your email?"
              onClick={() => {
                setEditingEmailOpen(true);
                setEditingEmail(user?.emails[0]?.address);
              }}
              loading={loading}
            />
          }
          content={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <b>Update your email address</b>
              <p>
                If you entered an incorrect email address, please enter your new email address below and click "Save"
              </p>
              <Input
                value={editingEmail}
                style={{ marginRight: 5 }}
                onChange={(e, { value }) => {
                  setEditingEmail(value);
                }}
                action={
                  <Button
                    size="small"
                    content="Save"
                    onClick={() => {
                      if (editingEmail === user?.emails[0]?.address) {
                        setEditingEmailOpen(false);
                        return;
                      }
                      updateEmailAddress();
                    }}
                  />
                }
              />
            </div>
          }
        />
      </div>
      <Divider section hidden />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 16,
        }}
      >
        <p>
          <strong>Waiting for your email address to be verified</strong>
        </p>
        <DotPulse size={15} />
      </div>
      <Divider section hidden />

      <div>
        <Divider />
        <h5>Didn't get an email?</h5>
        <p>
          If you still have not received an email, you can try sending another email. Please keep in mind that sending a
          new email may take a few minutes and you will no longer be able to use the link in the previous email.
        </p>
        <Message size="small">Please always remember to check your spam folder!</Message>
        <div style={{ display: "flex" }}>
          <Button
            size="small"
            content={"Send another verification email" + (tryAgain > 0 ? ` (${tryAgain})` : "")}
            onClick={() => {
              resendVerificationEmail();
              setTryAgain(30);
            }}
            loading={loading}
            disabled={tryAgain > 0}
          />
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
