import { useEditorView } from "@remirror/react";
import { useEffect, useMemo, useState } from "react";

export const useFocusState = () => {
  const view = useEditorView();
  const [isFocused, setIsFocused] = useState(view.focused);

  const semanticModalMount = useMemo(() => document.getElementById("semantic-modal-mount-node"), []);

  useEffect(() => {
    const onFocus = () => {
      setIsFocused(true);
    };
    view.dom.addEventListener("focus", onFocus);

    let lastTarget = null;
    const onBlur = (e) => {
      // view.dom is the remirror editor
      // view.dom.parentNode is the remirror editor wrapper
      // view.dom.parentNode.parentNode is the layout container
      // view.dom.parentNode.parentNode.parentNode is the full remirror parent
      const fullRemirrorParent = view.dom?.parentNode?.parentNode?.parentNode;
      if (!fullRemirrorParent) {
        return;
      }

      // relatedTarget for chrome, explicitOriginalTarget for firefox
      const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement;
      const consumeLastTarget = lastTarget;
      lastTarget = null;

      setTimeout(() => {
        if (fullRemirrorParent.contains(newTarget) || semanticModalMount.contains(newTarget)) {
          return;
        }
        if (
          consumeLastTarget &&
          (fullRemirrorParent.contains(consumeLastTarget) || semanticModalMount.contains(consumeLastTarget))
        ) {
          return;
        }
        setIsFocused(false);
      }, 0);
    };
    view.dom.addEventListener("blur", onBlur);

    const onPointerDown = (e) => {
      lastTarget = e.target;
    };
    document.addEventListener("pointerdown", onPointerDown, { capture: true });

    return () => {
      view.dom.removeEventListener("focus", onFocus);
      view.dom.removeEventListener("blur", onBlur);
      document.removeEventListener("pointerdown", onPointerDown, { capture: true });
    };
  }, [view.dom, semanticModalMount]);

  return isFocused;
};
