import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Icon, Dropdown, Container, Button, Label } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import moment from "moment-timezone";
import util from "utils/utils";

import { Banner, PlaceholderStack, PlaceholderItem } from "components/lib/UI";
import { UserChip, ProfileChip, AnonymousChip } from "components/lib/Chips";
import Tag, { TagContainer } from "components/lib/Tag";
import { useAppSelector } from "store";
import useTheme from "theme/useTheme";

const StyledCoverImage = styled.div`
  border-radius: 3px;
  min-width: ${({ theme }) => (theme.sizes.isMobile ? "100px" : "200px")};
  max-height: ${({ theme }) => (theme.sizes.isMobile ? "100px" : "200px")};
  max-width: ${({ theme }) => (theme.sizes.isMobile ? "100px" : "200px")};
  min-height: ${({ theme }) => (theme.sizes.isMobile ? "100px" : "200px")};
  border: 3px solid white;
  margin-right: 15px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: center center;
  background-image: url(${({ $image }) => $image});
`;

export const StyledContainer = styled(Container)`
  ${({ theme, fluid }) =>
    theme.sizes.isComputer
      ? `
    padding: 60px 0px 30px;
    ${
      fluid &&
      `
      padding: 1rem 3rem;
    `
    }
  `
      : `
    padding: 10px 0;
  `}
`;

// Also used in group header
export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
  justify-content: space-between;
  align-items: ${({ theme }) => (theme.sizes.isMobile ? "flex-start" : "flex-end")};
  ${({ $isPreview }) =>
    $isPreview &&
    `
    padding: 0 2rem;
  `}
  margin-top: 3px;
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
  align-items: ${({ theme }) => (theme.sizes.isMobile ? "center" : "flex-start")};
  ${({ theme }) =>
    theme.sizes.isMobile &&
    `
    margin-top: 5px;
  `}
  width: 100%;
  h1,
  h5,
  span:not(.dropdown .text) {
    margin: 0;
    color: white;
  }
  .titles {
    margin-bottom: 1rem;
    .meta {
      display: block;
      font-size: 1.1em;
      margin-bottom: 2.5px;
    }
  }
  .owner {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${({ $user }) => $user && "1rem"};
    color: white;
  }
  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .sector {
    text-transform: uppercase;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  min-width: 200px;
  flex-direction: row;
  justify-content: flex-end;
  .ui.button {
    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  }
  ${({ theme }) =>
    theme.sizes.isMobile &&
    `
    margin-bottom: 1rem;
  `}
`;

function ChallengeHeader({
  challenge = {
    name: "Challenge",
    ownerOrganisation: { name: "Simply Do" },
    postedBy: { profile: { fullName: "User" } },
  },
  myIdeas,
  newIdea,
  isPreview,
  isIdeasPage,
  redirectToLogin,
  loading,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const canMakeIdea = util.canCreateIdea(user, challenge);
  const { name } = challenge;

  const myUnsubmittedIdeas = useMemo(() => myIdeas?.filter((idea) => !idea.isSubmitted), [myIdeas]);
  return (
    <Banner marginless image={challenge.bannerImageUrl}>
      <StyledContainer fluid={isIdeasPage}>
        {loading ? (
          <PlaceholderStack gap={5}>
            <PlaceholderItem width="100%" height="50px" />
            <PlaceholderItem width="100%" height="30px" />
            <PlaceholderItem width="100%" height="20px" />
          </PlaceholderStack>
        ) : (
          <>
            <div className="overlay" />
            <div className="overlay-body">
              <TagContainer>
                {challenge.ownerTags && challenge.ownerTags.map((tag) => <Tag key={tag._id} tag={tag} />)}
              </TagContainer>
              <HeaderContainer $isPreview={isPreview}>
                <HeaderInfo $user={user}>
                  {challenge.coverImageUrl && (
                    <StyledCoverImage $image={util.getThumbnail(challenge, "coverImage", "500x500")} />
                  )}
                  <div className="info">
                    <div className="titles">
                      <h1 style={{ marginBottom: 3 }} className="challenge-tour-step-1">
                        {name}
                      </h1>
                      {challenge.tagline ? (
                        <span style={{ marginBottom: 5 }} className="meta">
                          {challenge.tagline}
                        </span>
                      ) : null}
                      {challenge.ownerOrganisation ? (
                        <h5>
                          {" "}
                          {t("challenge.managedBy")} {challenge.ownerOrganisation.name}
                        </h5>
                      ) : null}
                    </div>
                    <div>
                      <div className="owner">
                        <span className="posted-by" style={{ marginRight: 5 }}>
                          {t("challenge.postedBy")}
                        </span>
                        {challenge.anonymous && <AnonymousChip />}
                        {!challenge.anonymous &&
                          challenge.postedBy &&
                          (challenge.profile ? (
                            <ProfileChip profile={challenge.postedBy} inverted />
                          ) : (
                            <UserChip user={challenge.postedBy} inverted />
                          ))}
                      </div>
                      {!user && (
                        <div style={{ marginBottom: "2rem" }}>
                          {!challenge.stage && (
                            <Label color="yellow" style={{ marginRight: 10 }}>
                              {t("challenge.stages.draft.title")}
                            </Label>
                          )}
                          {challenge.stage === "published" && (
                            <Label color="green" style={{ marginRight: 10 }}>
                              {t("challenge.stages.published.title")}
                            </Label>
                          )}
                          {challenge.stage === "closed" && (
                            <Label color="red" style={{ marginRight: 10 }}>
                              {t("generic.closed")}
                            </Label>
                          )}
                        </div>
                      )}
                      {challenge.deadline ? (
                        <>
                          <span>
                            <Icon name="calendar" />
                            {t("challenge.deadline")}: {moment(challenge.deadline).format("HH:mm on DD/MM/YYYY")}
                          </span>
                        </>
                      ) : null}
                    </div>
                    <HeaderActions>
                      {(canMakeIdea || !user) &&
                      (challenge.stage === "published" ||
                        util.canManageChallenge(user, challenge) ||
                        util.hasPermission(user, "challenge.editSettings", challenge?._id)) ? (
                        <Button
                          size={theme.sizes.isMobile ? "mini" : null}
                          icon="plus"
                          content={challenge?.buttonText ? challenge.buttonText : t("challenge.ideas.createYourIdea")}
                          primary={myUnsubmittedIdeas?.length === 0}
                          inverted={myUnsubmittedIdeas?.length > 0}
                          onClick={canMakeIdea ? newIdea : () => redirectToLogin()}
                        />
                      ) : null}
                      {user && myUnsubmittedIdeas?.length > 0 && (
                        <React.Fragment>
                          {myUnsubmittedIdeas?.length === 1 && (
                            <Button
                              size={theme.sizes.isMobile ? "mini" : null}
                              icon="lightbulb"
                              content={t("challenge.continueIdea")}
                              primary
                              as={Link}
                              to={`/ideas/${myUnsubmittedIdeas[0]._id}`}
                            />
                          )}
                          {myUnsubmittedIdeas?.length > 1 && (
                            <Dropdown
                              icon={null}
                              direction="left"
                              pointing="top right"
                              trigger={
                                <Button
                                  size={theme.sizes.isMobile ? "mini" : null}
                                  icon="lightbulb"
                                  content={t("challenge.continueIdea")}
                                  primary
                                />
                              }
                            >
                              <Dropdown.Menu>
                                {myUnsubmittedIdeas.map((i, index) => (
                                  <Dropdown.Item key={index} as={Link} to={`/ideas/${i._id}`}>
                                    {i.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </React.Fragment>
                      )}
                    </HeaderActions>
                  </div>
                </HeaderInfo>
              </HeaderContainer>
            </div>
          </>
        )}
      </StyledContainer>
    </Banner>
  );
}

export default ChallengeHeader;
