import React, { cloneElement, useState } from "react";
import { Icon, Modal } from "semantic-ui-react";
import ReportContent from "./ReportContent";
import { useTranslation } from "react-i18next";
import AfterContentButton from "../SideNav/AfterContentButton";

type ReportContentChipProps = {
  forType: string;
  forId: string;
  inverted?: boolean;
  trigger?: React.ReactElement<any>;
  style?: React.CSSProperties;
  isCollapsed?: boolean;
};

const ReportContentChip = ({ forType, forId, inverted, style, trigger, isCollapsed }: ReportContentChipProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Content style={{ marginBottom: 10 }}>
          <ReportContent
            forType={forType}
            forId={forId}
            reportUrl={window.location.href}
            onComplete={() => setOpen(false)}
          />
        </Modal.Content>
      </Modal>
      {trigger ? (
        cloneElement(trigger, { onClick: () => setOpen(true) })
      ) : (
        <AfterContentButton onClick={() => setOpen(true)} $inverted={inverted} style={style}>
          <Icon size="small" name="flag" />
          {!isCollapsed ? t("report.content.title") : null}
        </AfterContentButton>
      )}
    </>
  );
};

export default ReportContentChip;
