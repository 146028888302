import actions from "actions";
import util from "utils/utils";
import { OpenAPI } from "simplydo/interfaces";

// @ts-ignore
import { setInterpolationDictionary } from "src/i18n";

type UserMe = OpenAPI.GET<"/users/me">["response"] & {
  hideDashboardText?: boolean;
  hideSecurityRecommendations?: boolean;
  recommendTwoFactor?: boolean;
  ghostingAs?: string;
  hasAcceptedInvitation?: boolean;
  ownerTags?: OpenAPI.GET<"/tags">["response"];
};
const initialState: UserMe = null;

function user(state = initialState, action): UserMe {
  switch (action.type) {
    case actions.user.RECEIVE_USER:
      setInterpolationDictionary(action.user?.ownerOrganisation?.terminology);
      return { ...state, ...action.user };
    case actions.user.LOGOUT:
      return null;
    case actions.user.CREATE_GROUP: {
      const existingGroups = Object.assign([], state?.groups || []);
      existingGroups.push({ _id: action.id, name: action.name });
      const existingPermissions = { ...state.permissions };
      if (!existingPermissions[action.id]) {
        existingPermissions[action.id] = [];
      }
      existingPermissions[action.id].push("group.editSettings");
      return { ...state, groups: existingGroups, permissions: existingPermissions };
    }
    case actions.user.JOIN_GROUP: {
      const s = { ...state };
      const existingGroups = Object.assign([], s?.groups || []);
      existingGroups.push({ _id: action.id, name: action.name });
      return {
        ...s,
        groups: existingGroups,
        groupsAvailable: true,
      };
    }
    case actions.user.LEAVE_GROUP: {
      const s = { ...state };
      const existingGroups = Object.assign([], s.groups);
      const existingPermissions = { ...s.permissions };
      return {
        ...s,
        groups: existingGroups.filter((g) => g._id !== action.id),
        permissions: {
          ...existingPermissions,
          [action.id]: [],
        },
      };
    }
    case actions.user.UPDATE_TERMS_AGREEMENT: {
      return { ...state, legalAgreements: action.legalAgreements };
    }
    case actions.user.UPDATE_PROFILE: {
      return { ...state, profile: { ...state.profile, ...action.profile } };
    }
    case actions.user.UPDATE_TAGS: {
      return { ...state, tags: action.tags.map((t) => t._id), ownerTags: action.tags };
    }
    case actions.user.UPDATE_PUBLIC_IDEAS: {
      return { ...state, showIdeasOnProfile: !state.showIdeasOnProfile };
    }
    case actions.user.UPDATE_EMAILS: {
      return { ...state, emails: action.emails };
    }
    case actions.user.UPDATE_PHONE_NUMBERS: {
      return { ...state, phoneNumbers: action.phoneNumbers };
    }
    case actions.user.TOGGLE_EVENT_SUBSCRIPTION: {
      return { ...state, eventSubscriptions: action.subscriptions || {} };
    }
    case actions.user.SWITCH_ORGANISATION: {
      setInterpolationDictionary(action.organisation.terminology);
      // We temporarily maintain organisation and ownerOrganisation separately
      return { ...state, organisation: action.organisation, ownerOrganisation: action.organisation };
    }
    case actions.user.UPDATE_ORGANISATION: {
      if (!state.organisation) return state;
      const s = { ...state };
      // We ignore these errors so the user/me/ type is compatible with the organisation field being a string
      // This should always be considered to be the case now even though currently the API is currently still returning an object
      // Always user `ownerOrganisation` for the full object
      // @ts-ignore
      s.organisation = { ...s.organisation };
      // @ts-ignore
      s.organisation[action.field] = action.value;

      s.ownerOrganisation = { ...s.ownerOrganisation };
      s.ownerOrganisation[action.field] = action.value;
      return s;
    }
    case actions.user.ADD_ENGAGEMENT: {
      if (!state) return state;
      const { engagements = [] } = state;
      if (!engagements.includes(action.engagement)) {
        const existingEngagements = Object.assign([], engagements || []);
        existingEngagements.push(action.engagement);
        return { ...state, engagements: existingEngagements };
      }
      return state;
    }
    case actions.user.UPDATE_PERMISSIONS: {
      return {
        ...state,
        permissions: {
          ...(state.permissions || {}),
          [action.scope]: action.permissions,
        },
      };
    }
    case actions.user.UPDATE_ENABLED_SERVICES: {
      return { ...state, enabledServices: action.services };
    }
    case actions.user.UPDATE_EMAIL_ADDRESS: {
      return { ...state, emails: [{ address: action.email }] };
    }
    case actions.user.UPDATE_FEATURE_FLAGS: {
      return { ...state, featureFlags: action.featureFlags };
    }
    case actions.user.UPDATE_TABLE_PREFS: {
      return { ...state, tablePreferences: { ...state.tablePreferences, [action.key]: action.preferences } };
    }
    case actions.user.GHOST: {
      return { ...state, ghostingAs: action.id };
    }
    case actions.user.UNGHOST: {
      return { ...state, ghostingAs: null };
    }
    case actions.user.HIDE_DASHBOARD_TEXT: {
      return { ...state, hideDashboardText: true };
    }
    case actions.user.SHOW_DASHBOARD_TEXT: {
      return { ...state, hideDashboardText: false };
    }
    case actions.user.HIDE_SECURITY_RECOMMENDATIONS: {
      return { ...state, hideSecurityRecommendations: true };
    }
    case actions.user.ENABLE_MFA: {
      return { ...state, recommendTwoFactor: false };
    }
    case actions.user.UPDATE_AVAILABLE_ORGANISATIONS: {
      return { ...state, availableOrganisations: action.organisations };
    }
    case actions.user.ON_FOLLOW: {
      const { context, id, manual } = action;
      const existingFollowing = state?.following || [];
      const userIsFollowing = util.userIsFollowingContext(state, context, id);
      const userIsFollowingContextObject = util.userIsFollowingContextObject(state, context, id);
      if (!userIsFollowing) {
        if (!userIsFollowingContextObject.unfollowed || (userIsFollowingContextObject.unfollowed && manual)) {
          userIsFollowingContextObject.following = true;
          userIsFollowingContextObject.unfollowed = false;
          const updatedFollowing = [userIsFollowingContextObject, ...existingFollowing.filter((f) => f._id !== id)];
          return { ...state, following: updatedFollowing };
        }
      }
      return state;
    }
    case actions.user.ADD_IDEA_ID: {
      const { ideaId } = action;
      const existingIdeaIds = Object.assign([], state?.myIdeaIds || []);
      const existingFollowing = Object.assign([], state?.following || []);
      existingFollowing.push({
        type: "idea",
        _id: ideaId,
        following: true,
        createdAt: new Date(),
        reason: "ideaCreated",
      });
      if (existingIdeaIds.indexOf(ideaId) === -1) {
        existingIdeaIds.push(ideaId);
      }
      return { ...state, myIdeaIds: existingIdeaIds, following: existingFollowing };
    }
    case actions.user.ADD_COMPANY: {
      return { ...state, companies: [...(state.companies || []), action.company] };
    }
    case actions.user.TRACK_ONBOARDING_COMPLETION: {
      return { ...state, hasCompletedOrganisationOnboarding: true };
    }
    case actions.user.UPDATE_INNOVATION_INTELLIGENCE: {
      return {
        ...state,
        innovationIntelligence: {
          ...(state.innovationIntelligence ?? {}),
          [action.searchType]: action.searches,
        },
      };
    }
    default:
      return state;
  }
}

export default user;
