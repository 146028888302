import SearchParams from "simplydo/src/searchParams";

export const announcements = (api) => ({
  get(entity, id, page, success, fail) {
    api.authenticatedRequest("GET", `/${entity}s/${id}/announcements?page=${page || 1}`, null, success, fail);
  },
  getAudience(entity, id, data, success, fail) {
    api.authenticatedRequest("POST", `/${entity}s/${id}/announcements/audience`, data, success, fail);
  },
  getTemplates(entity, id, success, fail) {
    api.authenticatedRequest("GET", `/${entity}s/${id}/announcements/templates`, null, success, fail);
  },
  getLogs(entity, id, params, success, fail) {
    const queryParams = new SearchParams(params).toString();
    api.authenticatedRequest("GET", `/${entity}s/${id}/announcements/logs?${queryParams}`, null, success, fail);
  },
  create(entity, id, data, success, fail) {
    api.authenticatedRequest("POST", `/${entity}s/${id}/announcements`, data, success, fail);
  },
  update(entity, id, announcementId, data, success, fail) {
    api.authenticatedRequest("PUT", `/${entity}s/${id}/announcements/${announcementId}`, data, success, fail);
  },
  delete(entity, id, announcementId, success, fail) {
    api.authenticatedRequest("DELETE", `/${entity}s/${id}/announcements/${announcementId}`, null, success, fail);
  },
  copy(
    data: {
      fromEntity: string;
      fromEntityId: string;
      fromEntityType: string;
      toEntity: string;
      toEntityId: string;
      toEntityType: string;
    },
    success,
    fail,
  ) {
    api.authenticatedRequest("POST", `/announcements/copy`, data, success, fail);
  },
});
