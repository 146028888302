import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Container, Divider, Icon, Input, Loader, Message, Segment, Tab } from "semantic-ui-react";

import { OpenAPI } from "simplydo/interfaces";
import api from "api";
import HomeInvitations from "components/home/Invitations";
import { Banner } from "components/lib/UI";
import InviteCard from "components/home/InviteCard";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "store";
import { HomeInvitation } from "components/home/InviteCard/types";
import SearchParams from "simplydo/src/searchParams";
import { useTranslation } from "react-i18next";

type InvitationType = OpenAPI.Schemas["Invitation"]["forType"];
type InvitationsByType = {
  [key in InvitationType]: OpenAPI.GET<"/invitations">["response"]["invitations"];
};

type TypeGroupDescription = {
  [key in InvitationType]: {
    name: string;
    description: string;
  };
};

const TokenInvitation = ({
  token,
  setInvitationCallback,
}: {
  token: string;
  setInvitationCallback?: (i: HomeInvitation) => void;
}) => {
  const [invitation, setInvitation] = useState(null);
  const [loading, setLoading] = useState(true);
  const user = useAppSelector((state) => state.user);
  const userEmail = useMemo(() => (user?.emails?.length > 0 ? user.emails[0].address : ""), [user]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      return;
    }

    api.invitations.findByToken(
      token,
      (data: OpenAPI.GET<"/invitations/find_by_token">["response"]) => {
        const userEmail = user?.emails?.length > 0 ? user.emails[0].address : "";
        const userEmailIsVerified = user?.emails?.length > 0 ? !!user.emails[0].verified : false;
        const matchesCurrentUserEmail =
          data.invitation.invitationType === "email" && data.invitation.invitee === userEmail;
        const matchesCurrentUserAlias =
          data.invitation.invitationType === "email" && data.invitation.alias === userEmail;

        // Automatically redirect the user to the relevant page if they are logged in and the invitation is for them
        if (data.invitation.acceptedBy && (matchesCurrentUserEmail || matchesCurrentUserAlias)) {
          switch (data.invitation.forType) {
            case "group":
            case "requestJoinGroup":
              navigate(`/groups/${data.invitation.forId}`);
              break;
            case "idea":
            case "requestJoinIdea":
              navigate(`/ideas/${data.invitation.forId}`);
              break;
            case "projectInvited":
              navigate(
                `/challenges/${data.invitation.idea.challenge}/ideas?queryView=board&idea=${data.invitation.forId}`,
              );
              break;
            case "ideaAssessor":
              navigate(`/challenges/${data.invitation.forId}/assessments/assigned`);
              break;
            default:
              navigate(`/invitations`);
          }
        }
        // If the invitation matches the current email it would already be displayed in the user's notifications (as long as their email is verified)
        // Aliases don't need to be verified so we know the user has access to those invitations
        if (!matchesCurrentUserAlias && (!userEmailIsVerified || !matchesCurrentUserEmail)) {
          const invitationData = {
            ...data.invitation,
            canAccept: user ? data.invitation.canAccept : false,
            canRemove: false,
          };
          setInvitation(invitationData);
          setInvitationCallback && setInvitationCallback(invitationData);
        }

        setLoading(false);
      },
      () => {
        setLoading(false);
        navigate(`/invitations`);
      },
    );
  }, [token, user, navigate, setInvitationCallback]);

  const acceptInvite = useCallback(
    (invitation: HomeInvitation, onSuccess: (i: HomeInvitation) => void, onError: (i: HomeInvitation) => void) => {
      if (!token) {
        return;
      }
      api.invitations.acceptToken(
        token,
        () => {
          setInvitation(null);
          onSuccess(invitation);
          // unset token
          if (invitation.invitationType === "email" && invitation.invitee !== userEmail) {
            navigate(`/invitations?reload=true`);
          } else {
            navigate(`/invitations`);
          }
        },
        () => onError(invitation),
      );
    },
    [token, navigate, userEmail],
  );

  if (!token) {
    return null;
  }

  if (!loading && !invitation) {
    return null;
  }

  return (
    <Container>
      {loading ? (
        <Loader active inverted content="Loading" />
      ) : (
        <Message info icon>
          <Icon name="linkify" />
          <div>
            <p>You are seeing this invitation because it was shared with you via an email or link.</p>
            <Segment>
              <InviteCard
                acceptInvite={acceptInvite}
                rejectInvite={() => {}} // unused for token invites
                removeInvite={() => {}} // unused for token invites
                invitation={invitation}
              />
            </Segment>
          </div>
        </Message>
      )}
    </Container>
  );
};

const InvitationList = ({
  accepted = false,
  createdBySelf = false,
}: {
  accepted?: boolean;
  createdBySelf?: boolean;
}) => {
  const [invitations, setInvitations] = useState<OpenAPI.GET<"/invitations">["response"]["invitations"]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => new SearchParams(location.search), [location.search]);

  const { t } = useTranslation();

  const invitationGroups: TypeGroupDescription = {
    group: {
      name: "Group membership",
      description:
        "Manage the membership of your groups. This includes requests to join your groups and invitations to join others.",
    },
    requestJoinGroup: {
      // unused
      name: "",
      description: "",
    },
    requestJoinIdea: {
      // unused
      name: "",
      description: "",
    },
    idea: {
      name: `${t("common:capitalise", { key: "generic.idea" })} collaboration`,
      description: `Only members of an ${t("generic.idea")} team can modify its contents. You can manage invitations and requests to collaborate on the following ${t("generic.ideas")} to help them grow.`,
    },
    ideaBusinessProfile: {
      name: "Business profile membership",
      description: "You were invited to join the following business profiles.",
    },
    ideaAssessor: {
      name: `${t("common:capitalise", { key: "generic.idea" })} assessment`,
      description: `You were asked to assess ${t("generic.ideas")} in the following challenges.`,
    },
    projectInvited: {
      name: "Project board assignment",
      description: `You were assigned to ${t("generic.ideas")} in the following projects:`,
    },
  };

  const filteredInvitations = useMemo(() => {
    const invitationsByType = invitations.reduce((acc, invitation) => {
      // Everything group is one section
      let forType = invitation.forType;
      if (forType === "requestJoinGroup") {
        forType = "group";
      }
      if (forType === "requestJoinIdea") {
        forType = "idea";
      }
      if (createdBySelf !== invitation.createdByCurrentUser) {
        return acc;
      }
      if (!acc[forType]) {
        acc[forType] = [];
      }
      acc[forType].push(invitation);
      return acc;
    }, {} as InvitationsByType);

    if (!search) {
      return invitationsByType;
    }

    const lowerSearch = search.toLowerCase();
    return Object.keys(invitationsByType).reduce((acc, forType) => {
      if (!invitationsByType[forType]) {
        return acc;
      }
      const filtered = invitationsByType[forType].filter((invitation) => {
        if (invitation.forType === "group") {
          if (invitation.group.name.toLowerCase().includes(lowerSearch)) {
            return true;
          }
        } else if (invitation.forType === "ideaBusinessProfile") {
          if (invitation.ideaBusinessProfile?.name?.toLowerCase()?.includes(lowerSearch)) {
            return true;
          }
        } else if (invitation.forType === "idea") {
          if (invitation.idea.name.toLowerCase().includes(lowerSearch)) {
            return true;
          }
        } else if (invitation.forType === "ideaAssessor" || invitation.forType === "projectInvited") {
          if (invitation.challenge.name.toLowerCase().includes(lowerSearch)) {
            return true;
          }
        } else if (invitation?.inviterUser?.profile?.fullName?.toLowerCase().includes(lowerSearch)) {
          return true;
        }
        return false;
      });
      if (filtered.length > 0) {
        acc[forType] = filtered;
      }
      return acc;
    }, {} as InvitationsByType);
  }, [search, invitations, createdBySelf]);

  const reloadInvitations = useCallback(
    (withLoader = true) => {
      if (withLoader) {
        setLoading(true);
      }
      const apiRequest = accepted ? api.invitations.getAccepted : api.invitations.get;
      apiRequest(
        (data: OpenAPI.GET<"/invitations">["response"]) => {
          setInvitations(data.invitations);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [accepted],
  );

  useEffect(() => {
    reloadInvitations();
  }, [reloadInvitations]);

  useEffect(() => {
    const reload = params.get("reload");
    if (reload) {
      // remove from params
      reloadInvitations(false);
      params.delete("reload");
      navigate({
        search: params.toString(),
      });
    }
  }, [params, navigate, reloadInvitations]);

  if (loading) {
    return <Loader active />;
  }

  return (
    <>
      <Input icon="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
      <Divider hidden />
      {Object.keys(filteredInvitations).length === 0 ? (
        <Message>No invitations to show.</Message>
      ) : (
        Object.keys(invitationGroups).map((forType: InvitationType) =>
          filteredInvitations[forType] && filteredInvitations[forType].length !== 0 ? (
            <div key={forType}>
              <h3 style={{ marginBottom: 5 }}>{invitationGroups[forType].name}</h3>
              <p>{invitationGroups[forType].description}</p>
              <HomeInvitations
                invitations={filteredInvitations[forType]}
                hideTitle
                showCreatedBySelf={createdBySelf}
                removeInvitation={(invitation: OpenAPI.GET<"/invitations">["response"]["invitations"][0]) => {
                  setInvitations((prevInvitations) =>
                    prevInvitations.map((i) => {
                      if (i._id === invitation._id) {
                        invitation.canAccept = false;
                        invitation.canRemove = false;
                        return invitation;
                      }
                      return i;
                    }),
                  );
                }}
              />
            </div>
          ) : null,
        )
      )}
    </>
  );
};

const Invitations = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useMemo(() => new SearchParams(location.search), [location.search]);
  const token = useMemo(() => params.get("invite_token"), [params]);

  return (
    <div>
      <Banner marginless>
        <Container>
          <h1 style={{ color: "#3b3b3b", margin: 0 }}>Your invitations</h1>
          <p>Manage access to your {t("generic.ideas")} and groups.</p>
        </Container>
      </Banner>
      <Divider hidden />
      <Container>
        {token ? <TokenInvitation token={token} /> : null}
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={[
            {
              menuItem: "Invitations",
              render: () => <InvitationList />,
            },
            {
              menuItem: "Sent by you",
              render: () => <InvitationList createdBySelf={true} />,
            },
            {
              menuItem: "Previously accepted",
              render: () => <InvitationList accepted={true} />,
            },
          ]}
        />
      </Container>
    </div>
  );
};

export const LoggedOutInvitation = () => {
  const location = useLocation();
  const params = useMemo(() => new SearchParams(location.search), [location.search]);
  const token = useMemo(() => params.get("invite_token"), [params]);
  const [invitation, setInvitation] = useState<HomeInvitation>(null);
  const invitationEmailParam = useMemo(() => {
    if (invitation?.invitationType === "email") {
      return `&email=${invitation.invitee}`;
    }
    return "";
  }, [invitation]);

  return (
    <div>
      <Banner marginless>
        <Container>
          <h1 style={{ color: "#3b3b3b", margin: 0 }}>Pending invitation</h1>
          <p>Login or register to accept this invitation.</p>
        </Container>
      </Banner>
      <Divider hidden />
      <Container>
        {token ? (
          <>
            <div style={{ pointerEvents: "none" }}>
              <TokenInvitation token={token} setInvitationCallback={setInvitation} />
            </div>

            <Divider hidden />
            <div>
              However, you are currently not logged in. Only logged in users can interact with the content of the Simply
              Do platform.
            </div>
            <Message info>
              <Button
                as={Link}
                to={`/login?then=${encodeURIComponent(`/invitations?invite_token=${token}`)}${invitationEmailParam}`}
              >
                Login now
              </Button>{" "}
              to accept this invitation.
              <p>
                If you do not have an account yet,{" "}
                <Link
                  to={`/login?then=${encodeURIComponent(`/invitations?invite_token=${token}`)}${invitationEmailParam}`}
                >
                  register here
                </Link>{" "}
                instead
              </p>
            </Message>
          </>
        ) : (
          <div>
            <Message error icon>
              <Icon name="exclamation triangle" />
              <div>
                <p>
                  You followed a link to an invitation. However, it seems like the invitation has expired or was already
                  accepted.
                </p>
                <p>
                  If you think this is a mistake or if you need help, please contact the person who sent you the
                  invitation.
                </p>
              </div>
            </Message>
            <Message info>
              You can still choose to{" "}
              <Button as={Link} to={`/login`}>
                Login now
              </Button>
              <p>
                If you do not have an account yet, <Link to={`/login`}>register here</Link> instead
              </p>
            </Message>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Invitations;
