import React, { useState, useEffect, useCallback, SetStateAction, Dispatch, useMemo } from "react";
import { Divider, Form, Button, Popup } from "semantic-ui-react";
import util from "utils/utils";
import { OpenAPI } from "simplydo/interfaces";
import toast from "react-hot-toast";
import api from "api";
import RoleEditorIconChooser from "./IconChooser";
import { CheckboxHeader } from "components/lib/UI";
import { useTranslation } from "react-i18next";

type RoleEditorPermissionsProps = {
  forType: "organisation" | "group" | "global" | "ideaBusinessProfile" | "challenge";
  setRoles: Dispatch<SetStateAction<OpenAPI.Schemas["Role"][]>>;
  canSetRolesAsDefault: boolean;
  roleId: string;
  usingRole: OpenAPI.Schemas["Role"] | undefined;
  forId: string;
  roles: OpenAPI.Schemas["Role"][];
};

const RoleEditorPermissions = ({
  forType,
  setRoles,
  canSetRolesAsDefault,
  roleId,
  usingRole,
  forId,
  roles,
}: RoleEditorPermissionsProps) => {
  const [updatedRoleName, setUpdatedRoleName] = useState<string>("");
  // Setting default role state
  const [_settingDefaultRole, setSettingDefaultRole] = useState<string>("");
  useEffect(() => {
    setUpdatedRoleName(usingRole?.name ?? "");
  }, [usingRole?.name]);

  const { t } = useTranslation();
  const typeNames = useMemo(
    () => ({
      organisation: "organisation",
      group: "group",
      global: "global",
      ideaBusinessProfile: "business profile",
      challenge: t("generic.challenge"),
    }),
    [t],
  );

  const updateRole = useCallback(
    (data: Partial<OpenAPI.Schemas["Role"]>) => {
      api.roles.updateRole(
        forType,
        forId,
        roleId,
        data,
        ({ role }) => {
          toast.success("Role updated");
          setRoles((prevRoles) => {
            const newRoles = [...prevRoles];
            const index = newRoles.findIndex((role) => role._id === roleId);
            newRoles[index] = role;
            return newRoles;
          });
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [forType, roleId, forId, setRoles],
  );

  const setRoleAsDefault = useCallback(
    (roleId: string) => {
      const usingRole = roles.find((role) => role._id === roleId);
      util
        .confirm(
          usingRole.isDefaultRole ? "Revoke default role" : "Make role default",
          usingRole.isDefaultRole
            ? `Removing "${usingRole?.name}" as a default role from this ${typeNames[forType]} will immediately remove all associated permissions from all users.`
            : `All users in this ${typeNames[forType]} will be permanently granted all permissions of the "${usingRole?.name}" role as long as it remains default, please be mindful of the level of permissions granted when proceeding.`,
        )
        .then(() => {
          const apiFunc = usingRole.isDefaultRole ? api.roles.removeDefaultRole : api.roles.addDefaultRole;
          setSettingDefaultRole(roleId);
          apiFunc(
            forType,
            forId,
            roleId,
            () => {
              setSettingDefaultRole("");
              setRoles((prevRoles) =>
                prevRoles.map((role) => {
                  if (role._id === roleId) {
                    return {
                      ...role,
                      isDefaultRole: role.isDefaultRole ? false : true,
                    };
                  }
                  return role;
                }),
              );
            },
            (err) => {
              toast.error(err.message);
              setSettingDefaultRole("");
            },
          );
        })
        .catch(() => {});
    },
    [roles, forType, forId, setRoles, typeNames],
  );

  const saveRoleName = useCallback(() => {
    updateRole({ name: updatedRoleName });
  }, [updateRole, updatedRoleName]);

  return (
    <>
      <Form>
        <Form.Input
          label="Role name"
          placeholder="Role name"
          value={updatedRoleName ?? usingRole?.name}
          onChange={(e) => setUpdatedRoleName(e.target.value)}
          fluid
          action={<Button primary content="Save" onClick={() => saveRoleName()} />}
        />
      </Form>
      <Divider hidden />
      <span>
        <b>Icon</b>
      </span>
      <span>
        Role icons are displayed next to users names across SimplyDo, if they have this role assigned to them.
      </span>
      <RoleEditorIconChooser containerStyle={{ margin: "10px 0 0" }} role={usingRole} updateRole={updateRole} />

      <Divider hidden />
      {canSetRolesAsDefault && !usingRole?.preventUserEditing ? (
        <>
          <Popup
            hoverable
            content="This role is assigned to every user by default."
            trigger={
              <CheckboxHeader
                header="Set as a default role"
                description={`Assign this role and all its permissions to all users in this ${typeNames[forType]}.`}
                as="h5"
                checked={usingRole?.isDefaultRole}
                onChange={() => setRoleAsDefault(roleId)}
              />
            }
          />
        </>
      ) : null}
    </>
  );
};

export default RoleEditorPermissions;
