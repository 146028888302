import React, { useCallback, useRef, useState } from "react";

import Help from "./Help";
import { presets } from "./presets/presets";

import { getMentions } from "./getMentions";
import { BasicRichText } from "./BasicRichText";
import { RemirrorProps } from "./Remirror";
import { RemirrorMention } from "./Remirror/Mentions";

export type RichTextProps = {
  forType?: string;
  forId?: string;
  preset?: keyof typeof presets;
  extraData?: any;
  fullMentionLinks?: boolean;
  onChange?: (html: string, textValue: string, extraData: any) => void;
} & Omit<RemirrorProps, "onChange" | "mentions" | "onSuggest">;

// forType and forId are required for uploads, however in cases such as the "simple" preset no upload is possible therefore they don't need to be added
// This is currently not enforced by the type system but can be gathered by the various examples of us using this component across the app
const RichText = ({
  forType,
  forId,
  preset = "full",
  onChange,
  extraData,
  fullMentionLinks,
  ...rest
}: RichTextProps) => {
  const [mentionState, setMentionState] = useState<{
    mentions: Array<RemirrorMention>;
    lastKind: string;
    lastQuery: string;
  }>(null);
  const timeout = useRef(null);

  const getThrottledMentions = useCallback((kind, query) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    timeout.current = setTimeout(() => {
      getMentions(kind, query)
        .then((result) => {
          setMentionState({
            lastKind: kind,
            lastQuery: query,
            mentions: result,
          });
          timeout.current = null;
        })
        .catch(() => {});
    }, 300);
  }, []);

  const [chooser, setChooser] = useState({});

  const setChooserType = useCallback(
    (kind, open, cmd, text) => setChooser((prev) => ({ ...prev, [kind]: { open, cmd, text } })),
    [],
  );

  const handleChange = useCallback(
    (html: string, textValue: string) => {
      if (onChange) {
        return onChange(html, textValue, extraData);
      }
    },
    [onChange, extraData],
  );

  return (
    <BasicRichText
      {...rest}
      options={presets[preset].options}
      mentions={mentionState?.mentions}
      onSuggest={getThrottledMentions}
      extraMenuItems={presets[preset].getMenu(chooser, setChooserType, forType, forId)}
      HelpText={<Help />}
      onChange={handleChange}
      settings={{
        uploadOptions: { forType, forId },
        fullMentionLinks,
      }}
    />
  );
};

type RichTextFieldProps = {
  updateIdea: (value: string, field: string | string[]) => void;
  field: any;
} & Omit<RichTextProps, "onChange">;

// Ideas are a bit poorly optimised when it comes to updates, by wrapping the handlers a bit differently for fields we save a decent amount of unnecessary updates
export const RichTextField = React.memo(({ updateIdea, field, ...props }: RichTextFieldProps) => {
  const onChange = useCallback((html: string) => updateIdea(html, ["templated", field.id]), [updateIdea, field.id]);

  return (
    <RichText
      stickyMenu={true}
      stickyMenuOffset={140}
      emptyReturnValue={null}
      historyEnabled={false}
      onChange={onChange}
      {...props}
    />
  );
});

export default RichText;
