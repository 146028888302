import React, { useState, useMemo } from "react";
import toast from "react-hot-toast";
import { Modal, Button, Message, Input, Icon, Form, Divider } from "semantic-ui-react";
import { Sticky, PaginationWithLimiter, CheckboxHeader } from "components/lib/UI";
import api from "api";
import util from "utils/utils";
import { useTranslation } from "react-i18next";
import { DeleteNameContainer, DeleteNameItem } from "./Styles";
import { DateTimeInput } from "components/lib/DateInputs";
import moment from "moment";

const updateIdea = (setIdeaState, ideaId, data) => {
  setIdeaState((prevState) => ({
    ...prevState,
    ideas: Object.assign(
      [],
      prevState.ideas.map((i) => {
        if (i._id !== ideaId) return i;
        return { ...i, ...data };
      }),
    ),
  }));
};

const legacyStampMapper = {
  green_apple: "green",
  full_moon: "yellow",
  large_orange_diamond: "orange",
  red_circle: "red",
  aquarius: "purple",
  large_blue_circle: "blue",
  ribbon: "pink",
  green: "green_apple",
  yellow: "full_moon",
  orange: "large_orange_diamond",
  red: "red_circle",
  purple: "aquarius",
  blue: "large_blue_circle",
  pink: "ribbon",
};

const labelIdea = (ideas, ideaId, label, isApplied, toggleStamp, setIdeaState) => {
  const originalIdeas = Object.assign([], ideas);
  const idea = originalIdeas.find((i) => i._id === ideaId);
  if (!idea) {
    return;
  }
  const { stamps = [] } = idea;
  let existingIndex = stamps.indexOf(label);
  if (label in legacyStampMapper) {
    existingIndex = stamps.indexOf(legacyStampMapper[label]);
    if (existingIndex < 0) existingIndex = stamps.indexOf(label);
  }
  if (isApplied && existingIndex === -1) stamps.push(label);
  if (!isApplied && existingIndex > -1) stamps.splice(existingIndex, 1);
  toggleStamp(label);
  setIdeaState((prevState) => ({
    ...prevState,
    ideas: Object.assign(
      [],
      prevState.ideas.map((i) => {
        if (i._id !== ideaId) return i;
        return { ...i, stamps };
      }),
    ),
  }));
  api.ideas.updateStatus(
    ideaId,
    { stamps },
    () => {
      toast.success("Label applied");
    },
    () => {
      setIdeaState((prevState) => ({ ...prevState, ideas: originalIdeas }));
      toast.error("Failed to apply label");
    },
  );
};

const updateAssessors = (apiFunc, challengeId, ideaIds, userIds, ideas, setIdeaState) => {
  apiFunc(
    challengeId,
    ideaIds,
    userIds,
    ({ ideas: assessorIdeas }) => {
      const updatedIdeas = Object.assign([], ideas).map((idea) => {
        const updatedIdea = assessorIdeas.find((i) => i._id === idea._id);
        if (!updatedIdea) return idea;
        return { ...idea, assessments: updatedIdea.assessments };
      });
      setIdeaState((prevState) => ({ ...prevState, ideas: updatedIdeas }));
      toast.success("Assessors updated");
    },
    (err) => toast.error(err.message),
  );
};

const bulkUpdateAssessors = (users, challengeId, assessmentAssignIdeas, ideas, setIdeaState) => {
  const existingUsers = users.filter((u) => !u.isEmailInvitee);
  if (!existingUsers.length) return;
  updateAssessors(
    api.challenges.assignAssessors,
    challengeId,
    assessmentAssignIdeas,
    existingUsers.map((u) => u._id),
    ideas,
    setIdeaState,
  );
};

const bulkRemoveAssessors = (ideaIds, challengeId, userIds, setAssessmentAssignIdeas, ideas, setIdeaState) => {
  setAssessmentAssignIdeas([]);
  updateAssessors(api.challenges.unassignAssessors, challengeId, ideaIds, userIds, ideas, setIdeaState);
};

const bulkUpdateStatus = (challengeId, selectedIdeas, setIdeaState, statusKey, status) => {
  api.challenges.updateIdeasStatus(challengeId, { ideas: selectedIdeas, [statusKey]: status }, ({ ideas }) => {
    toast(`${selectedIdeas.length} ideas updated`);
    setIdeaState((prevState) => ({
      ...prevState,
      ideas: prevState.ideas.map((i) => {
        const updatedIdea = ideas.find((u) => u._id === i._id);
        if (!updatedIdea) return i;
        return { ...i, ...updatedIdea };
      }),
    }));
  });
};

const voteForIdea = (setIdeaState, ideaId, ideas, ideasVotedFor, addVote, onFollow) => {
  const originalIdeas = Object.assign([], ideas);
  const oldIdeasVotedFor = ideasVotedFor;
  setIdeaState((prevState) => ({
    ...prevState,
    ideas: originalIdeas.map((i) => {
      if (i._id !== ideaId) return i;
      const newIdea = { ...i };
      if (!newIdea.voteCount) newIdea.voteCount = 0;
      newIdea.votedFor = !!addVote;
      newIdea.voteCount += addVote ? 1 : -1;
      return newIdea;
    }),
  }));
  api.ideas.vote(
    ideaId,
    addVote,
    ({ addedVotesFor, removedVotesFor }) => {
      originalIdeas.forEach((idea) => {
        if (addedVotesFor.indexOf(idea._id) > -1) {
          api.journey.record("ideas", ideaId, "addedIdeaVote");
          onFollow("challenge", idea.challenge);
        }
        if (removedVotesFor.indexOf(idea._id) > -1) {
          api.journey.record("ideas", ideaId, "removedIdeaVote");
        }
        const countIdeasVoted = addedVotesFor.length - removedVotesFor.length;
        setIdeaState((prevState) => ({ ...prevState, ideasVotedFor: prevState.ideasVotedFor + countIdeasVoted }));
      });
    },
    (err) => {
      setIdeaState((prevState) => ({ ...prevState, ideas: originalIdeas, ideasVotedFor: oldIdeasVotedFor }));
      toast.error(err.message);
    },
  );
};

const addTag = (setIdeaState, updateTagList, ideas, idea, tag) => {
  api.tags.tagSingle("ideas", tag._id, idea._id, (updatedTag) => {
    setIdeaState(
      (prevState) => ({
        ...prevState,
        ideas: prevState.ideas.map((i) => {
          if (i._id !== idea._id) return i;
          const updatedIdea = { ...i };
          if (!updatedIdea.tags) {
            updatedIdea.tags = [];
            updatedIdea.ownerTags = [];
          }
          updatedIdea.tags = [...updatedIdea.tags, updatedTag._id];
          updatedIdea.ownerTags = [...updatedIdea.ownerTags, updatedTag];
          return updatedIdea;
        }),
      }),
      (err) => toast.error(err.message),
    );

    updateTagList(updatedTag);
  });
};

const unTag = (setIdeaState, updateTagList, idea, tag) => {
  api.tags.untagSingle("ideas", tag._id, idea._id, (updatedTag) => {
    const ownerTags = [...idea.ownerTags];
    const cIndex = ownerTags.findIndex((t) => t._id === updatedTag._id);
    ownerTags.splice(cIndex, 1);

    setIdeaState((prevState) => ({
      ...prevState,
      ideas: prevState.ideas.map((i) => {
        if (i._id !== idea._id) return i;
        const updatedIdea = { ...i };
        updatedIdea.tags = Object.assign(
          [],
          updatedIdea.tags.filter((t) => t !== tag._id),
        );
        updatedIdea.ownerTags = Object.assign(
          [],
          updatedIdea.ownerTags.filter((t) => t._id !== tag._id),
        );
        return updatedIdea;
      }),
    }));

    updateTagList(updatedTag);
  });
};

const Pagination = ({ limit, page, total, updateFilters, limitValues }) => (
  <Sticky
    bottom={15}
    style={{
      textAlign: "center",
      zIndex: 10,
      display: "flex",
      justifyContent: "center",
      marginTop: 10,
    }}
  >
    <PaginationWithLimiter
      limit={limit}
      page={page}
      total={total}
      limitValues={limitValues}
      updateLimit={(value) => updateFilters({ ideaLimit: value })}
      updatePage={(value) => updateFilters({ ideaPage: value })}
    />
  </Sticky>
);

const IdeaDeletionModal = ({ ideasToDelete = [], setIdeasToDelete, setIdeaState, setSelectedIdeas }) => {
  const [deleteName, setDeleteName] = useState("");
  const { t } = useTranslation();

  // Get all child ideas from all ideas to delete by flattening
  const allChildIdeas = useMemo(() => {
    const allChildren = [];
    ideasToDelete.forEach((idea) => {
      if (idea.ownerChildren) {
        allChildren.push(...idea.ownerChildren);
      }
    });
    return allChildren;
  }, [ideasToDelete]);

  const bulkDelete = (setIdeaState, ideas, success) => {
    util
      .confirm(
        `Delete ${t("generic.ideas")}`,
        `Are you absolutely sure you want to permanently delete these ${t("generic.ideas")}?`,
      )
      .then(() => {
        api.ideas.bulkDelete(
          ideas,
          ({ childrenToReturn }) => {
            setIdeaState((prevState) => ({
              ...prevState,
              ideas: [...prevState.ideas.filter((i) => ideas.indexOf(i._id) === -1), ...childrenToReturn],
            }));
            success();
          },
          (err) => toast.error(err.message),
        );
      })
      .catch(() => {});
  };

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={!!ideasToDelete.length}
      onClose={() => setIdeasToDelete([])}
    >
      <Modal.Header>Delete {t("generic.ideas")}</Modal.Header>
      <Modal.Content>
        <p>You are choosing to delete the following {t("generic.ideas")}:</p>
        <ul>
          {ideasToDelete.map((idea) => (
            <li key={idea._id}>
              {idea.children ? <Icon name="sitemap" /> : null}
              <b>
                {idea.name}
                {idea.owner ? ` - ${idea.owner.profile.fullName}` : ""}
              </b>
            </li>
          ))}
        </ul>
        {allChildIdeas.length ? (
          <>
            <p>
              The following child {t("generic.ideas")} will be removed from the deleted merged {t("generic.ideas")}, and
              returned to the {t("generic.challenge")} as individual {t("generic.ideas")}.
            </p>
            <ul>
              {allChildIdeas.map((idea) => (
                <li>
                  <b>{idea.name}</b>
                </li>
              ))}
            </ul>
            <p>
              Any comments left on the merged {t("generic.ideas")} will be deleted, but any comments left on the
              original {t("generic.ideas")} will be returned to them.
            </p>
          </>
        ) : null}
        <Message error>
          This action is irreversible. These {t("generic.ideas")} will be permanently deleted, and cannot be recovered.
          Please make sure this action is correct, and double check the {t("generic.ideas")} you have chosen.
        </Message>
        <DeleteNameContainer>
          <DeleteNameItem>
            <p>Type the word 'DELETE' below to confirm you understand this.</p>
            <Input placeholder="DELETE" value={deleteName} onChange={(e) => setDeleteName(e.target.value)} />
          </DeleteNameItem>
        </DeleteNameContainer>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setIdeasToDelete([])}>Cancel</Button>
        <Button
          disabled={deleteName !== "DELETE"}
          color="red"
          onClick={() =>
            bulkDelete(
              setIdeaState,
              ideasToDelete.map((i) => i._id),
              () => {
                setSelectedIdeas((prev) => prev.filter((p) => ideasToDelete.map((i) => i._id).indexOf(p._id) === -1));
                setIdeasToDelete([]);
              },
            )
          }
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const IdeaDeadlineModal = ({ deadlineIdeas = [], setDeadlineIdeas, setIdeaState }) => {
  const { t } = useTranslation();
  const [note, setNote] = useState("");
  const [shouldUnsubmit, setShouldUnsubmit] = useState(true);
  const [notifyAuthors, setNotifyAuthors] = useState(true);
  const now = new Date();
  const defaultDeadline = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
  const [deadline, setDeadline] = useState(defaultDeadline);

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={!!deadlineIdeas.length}
      onClose={() => setDeadlineIdeas([])}
    >
      <Modal.Header>Set {t("generic.idea")} deadline</Modal.Header>
      <Modal.Content>
        <p>You are setting a deadline for the following {t("generic.ideas")}:</p>
        <ul>
          {deadlineIdeas.map((idea) => (
            <li key={idea._id}>
              <b>{idea.name}</b>
              {idea.owner ? ` by ${idea.owner.profile.fullName}` : ""}
            </li>
          ))}
        </ul>
        <Divider />
        <b>Deadline</b>
        <p>
          The {util.pluralise(deadlineIdeas, t("generic.idea"), t("generic.ideas"), false)} will be submissible until
          the chosen date, irrespective of the deadline chosen for this {t("generic.challenge")}.
        </p>
        <DateTimeInput
          value={deadline}
          onChange={(e, { value }) => setDeadline(moment(value, "DD-MM-YYYY HH:mm").toISOString())}
          // minDate={now}
        />
        <Divider hidden />
        <b>Notes</b>
        <p>
          The context of these notes will be sent to the {t("generic.idea")} authors once you have set the new deadline.
          You can choose to provide details for the authors regarding their submission or any other relevant
          information.
        </p>
        <Form>
          <Form.TextArea placeholder="Add a note (optional)" value={note} onChange={(e) => setNote(e.target.value)} />
        </Form>
        <Divider hidden />
        <CheckboxHeader
          as="h5"
          header={`Unsubmit ${t("generic.ideas")}`}
          description={`If ${t("generic.ideas")} are submitted they will return to their draft format.`}
          checked={shouldUnsubmit}
          onChange={() => setShouldUnsubmit((prev) => !prev)}
        />
        <CheckboxHeader
          as="h5"
          header="Notify authors"
          description="Authors will be notified of the new deadline and any notes you have added. This will also include the changed submission status (if applicable)."
          checked={notifyAuthors}
          onChange={() => setNotifyAuthors((prev) => !prev)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setDeadlineIdeas([])}>Cancel</Button>
        <Button
          basic
          onClick={() => {
            util
              .confirm("Remove deadline", "Are you sure you want to remove the deadline from these ideas?")
              .then(() => {
                api.ideas.removeDeadline(
                  deadlineIdeas.map((i) => i._id),
                  ({ ideas }) => {
                    toast.success("Deadlines removed");
                    setDeadlineIdeas([]);
                    setIdeaState((prevState) => ({
                      ...prevState,
                      ideas: prevState.ideas.map((i) => ideas.find((u) => u._id === i._id) ?? i),
                    }));
                  },
                  (err) => toast.error(err.message),
                );
              })
              .catch(() => {});
          }}
        >
          Remove deadline
        </Button>
        <Button
          primary
          onClick={() => {
            api.ideas.setDeadline(
              {
                ideaIds: deadlineIdeas.map((i) => i._id),
                deadlineData: {
                  deadline: moment(deadline).toISOString(),
                  note,
                },
                shouldUnsubmit,
                notifyAuthors,
              },
              ({ ideas }) => {
                toast.success("Deadline set");
                setDeadlineIdeas([]);
                setIdeaState((prevState) => ({
                  ...prevState,
                  ideas: prevState.ideas.map((i) => ideas.find((u) => u._id === i._id) ?? i),
                }));
              },
              (err) => toast.error(err.message),
            );
          }}
        >
          Set deadline
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export {
  updateIdea,
  labelIdea,
  updateAssessors,
  bulkUpdateAssessors,
  bulkRemoveAssessors,
  bulkUpdateStatus,
  voteForIdea,
  addTag,
  unTag,
  Pagination,
  IdeaDeletionModal,
  IdeaDeadlineModal,
};
