import React, { useState, useEffect, useCallback } from "react";
import { Modal, Loader, Icon, Label, Button, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { OpenAPI } from "simplydo/interfaces";
import ConfigurableTable from "components/lib/ConfigurableTable";
import { ImageWithFallback } from "components/lib/ImageWithFallback";
import util from "utils/utils";
import api from "api";

type IDashboardAssessmentIdea = OpenAPI.Schemas["Idea"];

type UserAssessmentsModalProps = {
  user: OpenAPI.GET<"/users/me">["response"];
  assessorId: string;
  isOpen: boolean;
  onClose: () => void;
  challenge: OpenAPI.Schemas["Challenge"] & OpenAPI.Schemas["AssignChallengeAssessmentDetail"];
  unsubmitAssessment: (ideaId, assessorId) => void;
  unsubmittingAssessment: string;
};

const UserAssessmentsModal = ({
  user,
  assessorId,
  isOpen,
  onClose,
  challenge,
  unsubmitAssessment,
  unsubmittingAssessment,
}: UserAssessmentsModalProps) => {
  const [ideas, setIdeas] = useState<IDashboardAssessmentIdea[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [assessor, setAssessor] = useState<OpenAPI.Schemas["User"]>(null);
  const { t } = useTranslation();

  const getAssessor = useCallback(() => {
    setLoading(true);
    api.challenges.getAssessor(
      challenge._id,
      assessorId,
      ({ ideas: newIdeas, assessor }) => {
        setIdeas(newIdeas);
        setLoading(false);
        setAssessor(assessor);
      },
      () => {
        setLoading(false);
      },
    );
  }, [assessorId, challenge._id]);

  useEffect(() => {
    if (unsubmittingAssessment) {
      const [ideaId, assessorId] = unsubmittingAssessment.split("-");
      setIdeas((ideas) =>
        ideas.map((idea) => {
          if (idea._id === ideaId) {
            const updatedAssesments = idea.assessments.map((assessment) => {
              if (assessment.user === assessorId) {
                return {
                  ...assessment,
                  isSubmitted: false,
                };
              }
              return assessment;
            });
            idea.assessments = updatedAssesments;
          }
          return idea;
        }),
      );
    }
  }, [unsubmittingAssessment, ideas]);

  useEffect(() => {
    if (isOpen) getAssessor();
  }, [isOpen, getAssessor]);

  const ideaTemplate = challenge.ideaTemplateData;

  if (!ideaTemplate) return null;
  const hasConflictOfInterest = ideas.some(
    (idea) => idea.assessments?.length && idea.assessments[0].conflictOfInterest?.hasConflictOfInterest,
  );

  const doneIdeas = ideas.map((idea) => {
    const { _id, name, assessments = [] } = idea;
    const userAssessment = assessments[0];
    const absoluteScore = userAssessment?.assessment
      ? Object.values(userAssessment.assessment)
          .map((value) => value.score ?? 0)
          .reduce((a, b) => a + b, 0)
      : 0;
    const absoluteTotalScore = ideaTemplate.body
      .flatMap((section) =>
        section.type === "assessment"
          ? section.fields
              .filter((field) => field.type === "assessment")
              .map((field) => field?.options?.availableScore ?? 0)
          : [],
      )
      .reduce((a, b) => a + b, 0);

    return {
      _id,
      name,
      absoluteScore,
      absoluteTotalScore,
      userAssessment,
      idea,
    };
  });
  if (!assessor) return null;
  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={isOpen}
      onClose={onClose}
      size="fullscreen"
    >
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <>
          <Modal.Header>
            <h3>Assessments for {assessor?.profile?.fullName} </h3>
          </Modal.Header>
          <Modal.Content>
            <ConfigurableTable
              data={doneIdeas}
              tableKey="assessorIdeas"
              columns={[
                {
                  key: "idea",
                  name: t("common:capitalise", { key: "generic.idea" }),
                  render: ({ item }) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ImageWithFallback
                        style={{
                          width: 25,
                          height: 25,
                          objectFit: "cover",
                          borderRadius: 5,
                          marginRight: 5,
                        }}
                        src={util.ideaCoverImage(item)}
                        fallbackSrc={util.ideaCoverImage()}
                        alt={`${t("common:capitalise", { key: "generic.idea" })} cover`}
                      />
                      <Link to={`/ideas/${item._id}`} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                        {item.name}
                      </Link>
                    </div>
                  ),
                },
                {
                  name: "Status",
                  key: "status",
                  width: 100,
                  render: ({ item }) => {
                    return (
                      <>
                        {item?.userAssessment.startedAt || item?.userAssessment.isSubmitted ? (
                          <>
                            {item?.userAssessment.isSubmitted ? (
                              <Label size="tiny" color="green">
                                Submitted
                              </Label>
                            ) : (
                              <Label size="tiny" color="orange">
                                In progress
                              </Label>
                            )}
                          </>
                        ) : (
                          <Label size="tiny" color="red">
                            Not started
                          </Label>
                        )}
                      </>
                    );
                  },
                },
                hasConflictOfInterest && {
                  key: "conflictOfInterest",
                  name: "Conflict of Interest",
                  width: 230,
                  render({ item }) {
                    return (
                      <div>
                        {item.userAssessment?.conflictOfInterest?.hasConflictOfInterest ? (
                          <div style={{ display: "flex" }}>
                            <Icon color="red" name="exclamation triangle" />
                            <b>Conflict declared</b>
                            {item?.userAssessment.conflictOfInterest?.notes ? (
                              <Popup
                                trigger={
                                  <Label style={{ marginLeft: 5 }} size="tiny">
                                    Conflict notes
                                  </Label>
                                }
                                content={item?.userAssessment.conflictOfInterest.notes}
                              />
                            ) : null}
                          </div>
                        ) : (
                          <i>-</i>
                        )}
                      </div>
                    );
                  },
                },
                {
                  name: "Total",
                  key: "total",
                  render: ({ item }) => {
                    return (
                      <>
                        {!item?.userAssessment?.conflictOfInterest?.hasConflictOfInterest ? (
                          <span>
                            <strong>
                              {item?.absoluteScore}/{item?.absoluteTotalScore}
                            </strong>
                          </span>
                        ) : (
                          <i>-</i>
                        )}
                      </>
                    );
                  },
                },
                {
                  name: "Weighted %",
                  key: "weighted",
                  width: 100,
                  render: ({ item }) => {
                    return (
                      <>
                        {!item?.userAssessment?.conflictOfInterest?.hasConflictOfInterest ? (
                          <span>
                            <strong>{Math.round(item?.userAssessment.score || 0)}%</strong>
                          </span>
                        ) : (
                          <i>-</i>
                        )}
                      </>
                    );
                  },
                },
                ...ideaTemplate.body
                  .filter((x) => x.type === "assessment")
                  .map((section) => ({
                    key: section.id,
                    name: section.name,
                    compactTitle: true,
                    width: 150,
                    render({ item }) {
                      if (item?.userAssessment?.conflictOfInterest?.hasConflictOfInterest) {
                        return <i>-</i>;
                      }
                      const hasScoreInEveryField = section.fields
                        .filter((field) => field.type === "assessment")
                        .every((field) => typeof item?.userAssessment?.assessment?.[field.id]?.score === "number");
                      if (!hasScoreInEveryField) {
                        if (item?.userAssessment?.isSubmitted) {
                          return (
                            <i>
                              This section was not part of the assessment at the time when this assessment was
                              submitted. It is not considered in the final score.
                            </i>
                          );
                        }
                        return <i>Not fully assessed</i>;
                      }
                      const sectionAvailableScore = section.fields
                        .filter((field) => field.type === "assessment")
                        .map((field) => field?.options?.availableScore ?? 0)
                        .reduce((a, b) => a + b, 0);
                      const userSectionAssessmentScore = section.fields.reduce((acc, field) => {
                        const userFieldAssessment = item.userAssessment?.assessment?.[field.id];
                        return acc + (userFieldAssessment?.score || 0);
                      }, 0);
                      const userSectionAssessmentNotes = section.fields
                        .map((field) => {
                          const userFieldAssessment = item.userAssessment?.assessment?.[field.id];
                          if (userFieldAssessment?.notes) return userFieldAssessment.notes;
                        })
                        .filter((note) => note);
                      return (
                        <Popup
                          trigger={
                            <span>
                              {userSectionAssessmentScore || 0}/{sectionAvailableScore}
                            </span>
                          }
                          content={
                            <div style={{ width: 600, maxWidth: "fit-content" }}>
                              <h5>Assessor notes</h5>
                              {userSectionAssessmentNotes.length === 0 ? (
                                <i>No notes</i>
                              ) : (
                                <ul>
                                  {userSectionAssessmentNotes.map((note, idx) => (
                                    <li key={idx}>{note}</li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          }
                        />
                      );
                    },
                  })),
              ]}
              actions={(actionItems) =>
                (util.canManageChallenge(user, challenge) ||
                  util.hasPermission(user, "challenge.editAssessments", challenge?._id)) &&
                actionItems[0].userAssessment.isSubmitted
                  ? [
                      {
                        name: "Unsubmit",
                        onClick: ([item]) => unsubmitAssessment(item._id, assessorId),
                      },
                    ]
                  : []
              }
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>Close</Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};
export default UserAssessmentsModal;
