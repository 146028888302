import React, { useCallback, cloneElement, isValidElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Popup, Icon } from "semantic-ui-react";
import { UserChip } from "./Chips";
import ExternalLinkModal from "components/lib/ExternalLinkModal";
import { OpenAPI } from "simplydo/interfaces";
import useTheme from "theme/useTheme";
import { useAppSelector } from "store";

const hostNameSplit = window.location.host.split(".");
const withoutSubdomain = hostNameSplit.slice(1).join(".") || window.location.host;
const localRegex = new RegExp(
  `((^| )(http://www.https://www.|http://|https://)(([a-z]{1,15}.)?${withoutSubdomain}(/| |$)))`,
  "gi",
);

export const regex =
  // @ts-ignore
  /\((?<type>[@#]|\$[a-z]+)\)\[(?<label>(?:[.a-z,!?<> '_#@0-9\u00C0-\u024F]|-|\(|\))+)\]\(_id:(?<id>[a-z0-9#]+)\)/gim;

// Complex URL regex found on web
const urlRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gim;

export const replaceString = "RegexSplitPolyfillFix4thMay2021";

type FormattedCommentProps = {
  children: string | Array<string | ReactNode> | ReactNode;
  noLink?: boolean;
  mentionStyle?: React.CSSProperties;
  linkStyle?: React.CSSProperties;
  mentionedUsers?: Record<string, OpenAPI.Schemas["User"]>;
  mentionedUserAccess?: Record<string, boolean>;
};

const FormattedComment = ({
  children,
  noLink = false,
  mentionStyle,
  linkStyle,
  mentionedUsers,
  mentionedUserAccess,
}: FormattedCommentProps) => {
  const theme = useTheme();
  const user = useAppSelector((state) => state.user);

  const returnFormattedComment = useCallback(
    (comment) => {
      // Use regex exec to support old browsers
      const allMatches = [];
      // const compiled = new RegExp(/\((?<type>[@#]|\$[a-z]+)\)\[(?<label>[.a-z \(\)\'\\-\_#@0-9\u00C0-\u024F]+)\]\(_id:(?<id>[a-z0-9#]+)\)/, 'gim')

      let match = regex.exec(comment);
      while (match !== null) {
        allMatches.push(match);
        match = regex.exec(comment);
      }

      // https://github.com/zloirock/core-js/issues/751
      // Using .split() with regex is broken with current Polyfill, so we replace in the comment with a random string
      // Then split on that

      // Mapped Replaced Comment is just the text, without the mentions
      const mappedReplacedComment = comment.replace(regex, replaceString).split(replaceString);
      // Has Matches indicates if there are any mentions
      const hasMatches = allMatches.length;

      // If there are no matches, we don't need to do anything
      let commentWithMatches = mappedReplacedComment;
      if (hasMatches) {
        // Else we create a new array
        commentWithMatches = [];
        // The comment is split on each mention. Therefore we know, after each item in the array, there *must* be a mention
        for (let i = 0; i < mappedReplacedComment.length; i += 1) {
          // A mention follows each item of text. Therefore, if `i == 0`, there is no mention
          if (i > 0) {
            // The mention after the first text, will be the first mention in the matches array. So we do i - 1 as our access
            const matchItems = allMatches[i - 1];
            // The match item is an array with the following items
            // Full match string
            // @ symbol
            // Match string to be displayed
            // Match _id to link to
            for (let y = 0; y < matchItems.length; y += 1) {
              // We only want to push the last 3 items
              if (y > 0) {
                commentWithMatches.push(matchItems[y]);
              }
            }
          }
          const text = mappedReplacedComment[i];
          commentWithMatches.push(text);
        }
      }

      return commentWithMatches.map((word, index) => {
        // Adjust index to ignore the 3 captured groups

        if (index % 4) return "";
        const adjustedIndex = index / 4;
        if (adjustedIndex < allMatches.length) {
          const { type, label, id } = allMatches[adjustedIndex].groups;
          const linkTo = (() => {
            switch (type) {
              case "@":
                return "users";
              case "$ideas":
              case "$challenges":
              case "$groups":
                return type.slice(1);
              case "#":
                return "tags";
              default:
                return null;
            }
          })();

          if (!linkTo) {
            return word;
          }

          // If we specify whether a user can access a comment, then we know they can't, we display a helpful message so other commenters know
          const userCanAccess = mentionedUserAccess === undefined ? true : mentionedUserAccess?.[id];

          const mentionUser = mentionedUsers?.[id];

          return (
            <React.Fragment key={index}>
              {word}
              {mentionUser ? (
                <span style={{ color: user?._id === mentionUser?._id ? theme.secondaryColour : theme.primaryColour }}>
                  <UserChip user={mentionUser} compact="very" />
                </span>
              ) : (
                <Link
                  to={`/${linkTo}/${id}`}
                  style={userCanAccess ? linkStyle : { color: "gray", opacity: 0.8, textDecorationLine: "underline" }}
                >
                  {label}
                </Link>
              )}
              {!userCanAccess ? (
                <Popup
                  trigger={
                    <Icon
                      name="question circle"
                      style={{
                        color: "gray",
                        opacity: 0.8,
                        height: 20,
                        marginLeft: 3,
                      }}
                    />
                  }
                  hoverable
                  on="hover"
                  position="top center"
                  content="This user has been mentioned, but is unable to access this page. They will not receive notification of this mention or any further comments."
                />
              ) : null}
            </React.Fragment>
          );
        }

        if (noLink) return word;
        // Map remaining comment across newlines and whitespace, then test each word for a link and insert as necessary
        const splitSentence = word.split("\n").map((sentence, i) =>
          sentence.split(" ").map((w, j) => {
            const padded = j === 0 ? "" : " ";
            // Without further research, there is an issue where a comment with multiple links after another would fail this check if the urlRegex is only tested once (???)
            // Therefore we just test twice and hope for the best
            if (urlRegex.test(w) || urlRegex.test(w)) {
              // Return URL as both hyperlink and the text
              const isLocal = w.match(localRegex);
              if (isLocal) {
                return (
                  <a href={w} key={i + j} target="_blank" rel="noopener noreferrer" style={mentionStyle}>
                    {padded}
                    {w}
                  </a>
                );
              }

              return (
                <ExternalLinkModal href={w} key={i + j}>
                  {padded + w}
                </ExternalLinkModal>
              );
            }
            return padded + w;
          }),
        );
        const fullResult = [];
        splitSentence.forEach((s) => {
          fullResult.push(s);
          fullResult.push("\n");
        });
        return fullResult;
      });
    },
    [
      noLink,
      mentionedUserAccess,
      linkStyle,
      mentionStyle,
      mentionedUsers,
      theme.primaryColour,
      theme.secondaryColour,
      user?._id,
    ],
  );

  const getFormattedComponent = (comment: string | Array<string | ReactNode> | ReactNode, index = 0) => {
    if (typeof comment === "string") {
      // Base return of recursion, if it's a raw string we can split into words and handle any linkable substrings
      return returnFormattedComment(comment);
    }
    if (Array.isArray(comment)) {
      // If it's an array of elements, we handle each element individually
      return comment.map((c, key) => getFormattedComponent(c, key));
    }
    if (isValidElement(comment)) {
      // If it's a react element, we get it's child (String or Element) and recurse
      return cloneElement(comment, { key: index }, getFormattedComponent(comment.props.children));
    }
  };

  return getFormattedComponent(children);
};

export default FormattedComment;
