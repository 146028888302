import React, { useEffect, useState, Suspense, useMemo } from "react";
import { I18nextProvider } from "react-i18next";
import { Helmet } from "react-helmet";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import appI18n from "../i18n";

import { ThemeProvider } from "styled-components";
import api from "api";
import actions from "actions";
import util from "utils/utils";
import websocketApi from "api/websocket";
import useTheme from "theme/useTheme";
import toast from "react-hot-toast";

import ErrorBoundary from "components/lib/Error/ErrorBoundary";
import MessageMoles from "components/lib/Messaging/MessageMolesContainer";
import GoogleHelper from "./lib/GoogleHelper";

import EventSubscriptions from "./EventSubscriptions";
import EdgeSuggestionModal from "./lib/EdgeSuggestionModal";
import { MountPoint } from "./lib/Modal/MountPoint";

import Toaster from "./app/Toaster";
import SuspenseLoading from "./app/SuspenseLoading";
import { useChatwoot } from "./app/useChatwoot";

import { serviceWorkerBridge } from "../serviceWorkerBridge";
import { AutoBusinessProfileModal } from "./lib/IdeaBusinessProfileModal/AutoBusinessProfileModal";
import { QueryClientProvider } from "@tanstack/react-query";
import AppStyling from "./AppStyling";
import AppOnboarding from "./AppOnboarding";
import { useAppDispatch, useAppSelector } from "store";

// Forcefully disconnect the websocket when the user leaves the page
window.addEventListener("beforeunload", () => {
  websocketApi.disconnect();
});

const ReloadAppWidget = () => (
  <div>
    <p style={{ fontWeight: "bold", marginBottom: 5 }}>A new version of SimplyDo is available</p>
    <p style={{ fontSize: 17 }}>
      To load the most recent version simply refresh the page. Please make sure you have do not have any unsaved
      changes.
    </p>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        primary
        icon="refresh"
        onClick={() => {
          if (util.localStorageIsSupported()) {
            localStorage.setItem("lastPromptHash", api.webVersion);
          }
          window.location.reload();
        }}
        content="Refresh"
      />
    </div>
  </div>
);

function App() {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const urlOrganisation = useAppSelector((state) => state.organisations.urlOrganisation);
  const selectedMessageThreads = useAppSelector((state) => state.messages.selectedMessageThreads);
  const clearUtm = useAppSelector((state) => state.auth.clearUtm);

  const navigate = useNavigate();
  const location = useLocation();
  const ownerOrganisation = user?.ownerOrganisation || urlOrganisation;
  const [params, setParams] = useSearchParams();
  const utmLinkReferrer = params.get("utm-link");
  const utmChallengeReferrer = params.get("utm-challenge");

  const theme = useTheme();
  const appName = useMemo(() => ownerOrganisation?.appName || "Simply Do", [ownerOrganisation?.appName]);
  const [promptReload, setPromptReload] = useState(true);
  const innoIntelEnabled = useMemo(
    () =>
      util.organisationFeaturesEnabled(user, ["innovationIntelligence"]) &&
      util.hasPermission(user, "org.innovationIntelligence", user.ownerOrganisation._id),
    [user],
  );

  // Initialise Chatwoot plugin for support chat
  useChatwoot();

  // Set up service worker bridge with navigation actions
  useEffect(() => {
    serviceWorkerBridge.navigator = {
      push: navigate,
    };
  }, [navigate]);

  // UTM is a value associated with a custom URL. We attach these to our sdi.click links, to allow tracking analytics. We can then attribute sign-ups, clicks and views to specific sdi.click links and track timing
  useEffect(() => {
    if (utmLinkReferrer) {
      dispatch(actions.auth.setUtmLink(utmLinkReferrer));
    }
    if (utmChallengeReferrer) {
      dispatch(actions.auth.setUtmChallenge(utmChallengeReferrer));
    }
  }, [utmLinkReferrer, utmChallengeReferrer, dispatch]);

  // Clear utm's after a referral has been tracked
  // Used to clear utms after login, resume session and register
  useEffect(() => {
    if (clearUtm) {
      params.delete("utm-challenge");
      params.delete("utm-link");
      setParams(params);
      dispatch(actions.auth.setClearUtm(false));
    }
  }, [params, setParams, dispatch, clearUtm]);

  // Check for new version of the app, prompt the user to refresh if one is available
  useEffect(() => {
    if (!api?.webVersion) {
      return;
    }

    if (util.localStorageIsSupported()) {
      const lastHash = localStorage.getItem("lastPromptHash");
      if (lastHash === api.webVersion) {
        setPromptReload(false);
        return;
      }
    }

    if (import.meta.env.VITE_STAGE !== "Dev" && import.meta.env.VITE_GIT_SHA !== api.webVersion && promptReload) {
      setPromptReload(false);
      toast(<ReloadAppWidget />, { duration: 15000, style: { minWidth: "600px" } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptReload, api.webVersion]);

  /*
    Track page views when the page changes, and scroll to top if moving to challenge/idea pages
  */
  useEffect(() => {
    const regExr = new RegExp(/\/challenges|ideas\/([A-z0-9]+)\w+/g);
    if (!regExr.test(window.location.pathname)) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <ErrorBoundary theme={theme} pathName={location.pathname}>
      <I18nextProvider i18n={appI18n} defaultNS={"translation"}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={api.queryClient}>
            <AppStyling theme={theme}>
              <DndProvider backend={HTML5Backend}>
                <EventSubscriptions />
                <EdgeSuggestionModal />
                <Helmet defaultTitle={appName} titleTemplate={`%s | ${appName}`}>
                  {import.meta.env.VITE_CSP && (
                    <meta httpEquiv="Content-Security-Policy" content={`${import.meta.env.VITE_CSP}`} />
                  )}
                  <link rel="shortcut icon" type="image/png" href={util.orgFavicon(ownerOrganisation)} />
                </Helmet>
                <Suspense fallback={<SuspenseLoading />}>
                  <AppOnboarding />
                </Suspense>
                <MessageMoles selectedMessageThreads={selectedMessageThreads} />
                <Toaster />
                {innoIntelEnabled && (
                  <GoogleHelper
                    displayOnPages={["^/innovationintelligence"]}
                    containerStyle={{
                      bottom: 20,
                      right: 20,
                    }}
                  />
                )}
                <AutoBusinessProfileModal />
                <MountPoint />

                <div id="semantic-modal-mount-node" />
              </DndProvider>
            </AppStyling>
          </QueryClientProvider>
        </ThemeProvider>
      </I18nextProvider>
    </ErrorBoundary>
  );
}

export default App;
