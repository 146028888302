import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Container, ContainerProps, Divider, Message, Segment } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";
import { useParams } from "react-router-dom";
import api from "api";
import { PlaceholderStack, PlaceholderItem } from "components/lib/UI";
import RichText from "components/lib/Editors/RichText";
import Login from "components/entry/Login";

type CustomPageProps = {
  pageId?: string;
  containerProps?: ContainerProps;
};

type PageResponse = OpenAPI.GET<"/pages/{page_id}">["response"];

const CustomPage = ({
  pageId: propPageId,
  containerProps = {
    style: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
}: CustomPageProps) => {
  const urlOrganisation: OpenAPI.Schemas["Organisation"] = useAppSelector(
    (state) => state.organisations?.urlOrganisation,
  );
  const user = useAppSelector((state) => state.user);

  const { pageId: urlPageId } = useParams<{ pageId: string }>();
  const pageId = useMemo(() => propPageId ?? urlPageId, [propPageId, urlPageId]);

  const [page, setPage] = useState<PageResponse["page"] | null>(null);
  const [loading, setLoading] = useState(true);
  const [didFail, setDidFail] = useState(false);
  const [tryAuth, setTryAuth] = useState(false);

  const getPage = useCallback(() => {
    setDidFail(false);
    api.pages.get(
      pageId,
      (data: PageResponse) => {
        setPage(data.page);
        setLoading(false);
      },
      (err) => {
        if (err?.status === 403) {
          setTryAuth(true);
        }
        setDidFail(true);
        setLoading(false);
      },
    );
  }, [pageId]);

  useEffect(() => {
    getPage();
  }, [getPage]);

  if (!loading && didFail && tryAuth && !user) {
    return (
      <Container {...containerProps}>
        <Segment style={{ maxWidth: 700, width: 700, alignSelf: "center", padding: 50 }}>
          <Message info style={{ alignSelf: "center", marginBottom: 20 }}>
            This content is restricted to registered users, please log in to view this page.
          </Message>
          {/* @ts-ignore */}
          <Login embedded />
        </Segment>
      </Container>
    );
  }

  return (
    <Container {...containerProps}>
      {!loading && didFail ? (
        <Message error>
          <Message.Header>You are unable to access this page</Message.Header>
          <Message.Content>
            This page has been restricted to certain viewers only. Please contact an admin if you think this is in error
          </Message.Content>
        </Message>
      ) : (
        <>
          {loading ? (
            <PlaceholderStack gap={10} style={{ marginBottom: 20 }}>
              <PlaceholderItem height={30} />
            </PlaceholderStack>
          ) : (
            <h1>{page?.title ?? "New page"}</h1>
          )}

          <Divider />

          {loading ? (
            <PlaceholderStack gap={10} style={{ marginBottom: 30 }}>
              <PlaceholderItem height={30} fluid />
              <PlaceholderItem height={30} fluid />
              <PlaceholderItem height={30} fluid />
              <PlaceholderItem height={30} fluid />
              <PlaceholderItem height={30} fluid />
            </PlaceholderStack>
          ) : (
            <RichText
              readOnly
              value={page?.content ?? ""}
              forType="organisation"
              forId={user ? user.ownerOrganisation._id : urlOrganisation?._id}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default CustomPage;
