import api, { asQuery } from "api";
import moment from "moment";
import { useState } from "react";
import { Button, Icon, Pagination, Table, Popup, Loader, Message } from "semantic-ui-react";

import { EmailLogModal } from "components/lib/EmailLogModal";
import { useQuery } from "@tanstack/react-query";
import { OpenAPI } from "simplydo/interfaces";
import { UserChip } from "components/lib/Chips";

type CommsManagementProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
};

const CommsManagement = ({ idea }: CommsManagementProps) => {
  const [page, setPage] = useState(1);
  const logsQuery = useQuery({
    queryKey: ["ideas", idea?._id, "logs", "email", page],
    queryFn: asQuery(api.ideas.getEmailLogs, {
      params: [idea?._id, page],
    }),
  });
  const [isLoadingLog, setIsLoadingLog] = useState(null);
  const [activeLog, setActiveLog] = useState(null);

  return (
    <div>
      <EmailLogModal log={activeLog} onClose={() => setActiveLog(null)} />
      <h1>Email communication</h1>
      {logsQuery.isLoading ? (
        <Loader active inline="centered" />
      ) : !logsQuery?.data?.logs ? (
        <Message>No email logs found.</Message>
      ) : (
        <>
          <Table compact size="small">
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell>Subject</Table.HeaderCell> */}
                <Table.HeaderCell>Recipient</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {logsQuery?.data?.logs.map((log) => (
                <Table.Row key={log._id}>
                  {/* <Table.Cell>{log.subject}</Table.Cell> */}
                  <Table.Cell>
                    {log.ownerUser ? <UserChip user={log.ownerUser} compact="very" /> : log.recipientEmail}
                  </Table.Cell>
                  <Table.Cell>{moment(log.sentAt || log.createdAt).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                  <Table.Cell collapsing>
                    {log.sent && (log.events?.delivered || log.events?.opened) ? (
                      <span>
                        <Icon name="check circle" color="green" />{" "}
                        {(log.events?.opened && "Opened") || (log.events?.delivered && "Delivered")}
                      </span>
                    ) : !log.sent || log.events?.bounced || log.events?.dropped ? (
                      <Popup
                        trigger={
                          <span>
                            <Icon name="warning circle" color="red" />
                            {log.events?.bounced ? "Bounced" : "Undeliverable"}
                          </span>
                        }
                        content="We were unable to deliver this message. Please double check the recipient's email address and try again. If this issue persists please contact us."
                      />
                    ) : (
                      <Popup
                        trigger={
                          <span>
                            <Icon name="check circle outline" color="green" /> Sent
                          </span>
                        }
                        content="This email was sent but we have not received any further delivery information."
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Button
                      loading={isLoadingLog === log._id}
                      onClick={() => {
                        setIsLoadingLog(log._id);
                        api.ideas.getEmailLog(
                          idea?._id,
                          log._id,
                          (response) => {
                            setIsLoadingLog(null);
                            setActiveLog(response.log);
                          },
                          () => {
                            setIsLoadingLog(null);
                          },
                        );
                      }}
                    >
                      View
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Pagination
            activePage={page}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            totalPages={logsQuery?.data?.totalPages || 1}
            onPageChange={(e, { activePage }) => {
              setPage(activePage as number);
            }}
          />
        </>
      )}
    </div>
  );
};

export default CommsManagement;
