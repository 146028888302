import api from "api";
import { OpenAPI } from "simplydo/interfaces";

type ChallengeTemplate = OpenAPI.GET<"/challenges/{id}/template">["response"];

const getTagOptions = (
  entityType: string,
  entityId: string,
  callback: (tags: { _id: string; name: string }[]) => void,
) => {
  api.tags.getAllForEntity(
    entityType,
    entityId,
    true,
    (tags) => {
      callback(tags.map((tag) => ({ _id: tag._id, name: tag.value })));
    },
    () => {},
  );
};

export const organisationStandardConditions = [
  {
    value: "hasTag",
    text: "admin.announcements.recipients.conditions.userTag",
    subConditions: [
      {
        key: "tags",
        type: "dropdown",
        placeholder: "Select a tag",
        getOptions: (orgId, callback) => {
          getTagOptions("organisation", orgId, callback);
        },
      },
    ],
  },
  {
    value: "hasCreatedIdea",
    text: "admin.announcements.recipients.conditions.hasCreatedIdea",
  },
];

export const organisationAutomaticConditions = [
  { value: "register", text: "admin.announcements.form.options.register" },
  { value: "createChallenge", text: "admin.announcements.form.options.createChallenge" },
  { value: "createIdea", text: "admin.announcements.form.options.createIdea" },
  { value: "referredTo", text: "admin.announcements.form.options.referredTo" },
  { value: "referredFrom", text: "admin.announcements.form.options.referredFrom" },
];

export const challengeStandardConditions = [
  { value: "following", text: "challenge.settings.announcements.recipients.conditions.following" },
  { value: "started", text: "challenge.settings.announcements.recipients.conditions.started" },
  { value: "submitted", text: "challenge.settings.announcements.recipients.conditions.submitted" },
  { value: "notSubmitted", text: "challenge.settings.announcements.recipients.conditions.notSubmitted" },
  { value: "pinned", text: "challenge.settings.announcements.recipients.conditions.pinned" },
  { value: "notPinned", text: "challenge.settings.announcements.recipients.conditions.notPinned" },
  {
    value: "stamp",
    text: "challenge.settings.announcements.recipients.conditions.hasStamp",
    subConditions: [{ key: "stamps", type: "stamps" }],
  },
  {
    value: "notStamp",
    text: "challenge.settings.announcements.recipients.conditions.notHasStamp",
    subConditions: [{ key: "stamps", type: "stamps" }],
  },
  { value: "audience", text: "challenge.settings.announcements.recipients.conditions.audience" },
  {
    value: "lane",
    text: "challenge.settings.announcements.recipients.conditions.lane",
    subConditions: [
      {
        key: "lanes",
        type: "dropdown",
        placeholder: "Select the lane you would like to target",
        getOptions: (challengeId, callback) => {
          api.boards.getProjectLanes(
            challengeId,
            ({ lanes, defaultLane }) => {
              if (defaultLane) {
                callback([defaultLane, ...lanes]);
              } else {
                callback(lanes);
              }
            },
            () => {},
          );
        },
      },
    ],
  },
  {
    value: "userHasTag",
    text: "challenge.settings.announcements.recipients.conditions.userTag",
    subConditions: [
      {
        key: "tags",
        type: "dropdown",
        getOptions: (challengeId, callback) => {
          getTagOptions("challenge", challengeId, callback);
        },
      },
    ],
  },
  {
    value: "userInGroup",
    text: "challenge.settings.announcements.recipients.conditions.userGroup",
    subConditions: [
      {
        key: "group",
        type: "dropdown",
        getOptions: (challengeId, callback) => {
          api.challenges.getPostable(
            challengeId,
            ({ groups }) => {
              callback(groups.filter((group) => group.isCurrentlyInChallengeAudience));
            },
            () => {},
          );
        },
      },
    ],
  },
  { value: "projectAssigned", text: "challenge.settings.announcements.recipients.conditions.projectAssigned" },
  {
    value: "projectAssignedNonCompleted",
    text: "challenge.settings.announcements.recipients.conditions.projectAssignedNonCompleted",
  },
  { value: "assessor", text: "challenge.settings.announcements.recipients.conditions.assessor" },
];

export const challengeAutomaticConditions = [
  { value: "createIdea", text: "challenge.settings.announcements.recipients.automatic.starts" },
  { value: "submitIdea", text: "challenge.settings.announcements.recipients.automatic.submits" },
  {
    value: "submittedAllAssessmentsForChallenge",
    text: "challenge.settings.announcements.recipients.automatic.assessmentsSubmitted",
  },
  {
    value: "submitIdeaWithConditions",
    text: "challenge.settings.announcements.recipients.automatic.ideaFieldCondition",
    subConditions: [
      {
        key: "ideaFields",
        placeholder: "Select a field",
        getOptions: (challengeId, callback) => {
          api.challenges.getTemplate(
            challengeId,
            (template: ChallengeTemplate) => {
              const fields = template.body.flatMap((section) =>
                section.fields.map((field) => ({
                  ...field,
                  section: `in ${section.name}`,
                })),
              );
              callback(fields);
            },
            () => {},
          );
        },
      },
    ],
  },
  {
    value: "moveToLane",
    text: "challenge.settings.announcements.recipients.automatic.moveToLane",
    subConditions: [
      {
        key: "moveToLane",
        placeholder: "Select a field",
        getOptions: (challengeId, callback) => {
          api.boards.getProjectLanes(
            challengeId,
            ({ lanes, defaultLane }) => {
              const formattedLanes = lanes.map((lane) => ({
                key: lane._id,
                text: lane.name,
                value: lane._id,
              }));
              if (defaultLane) {
                callback([...formattedLanes]);
              } else {
                callback(formattedLanes);
              }
            },
            () => {},
          );
        },
      },
    ],
  },
];

export const groupStandardConditions = [
  {
    value: "hasTag",
    text: "groups.announcements.recipients.conditions.userTag",
    subConditions: [
      {
        key: "tags",
        type: "dropdown",
        placeholder: "Select a tag",
        getOptions: (groupId, callback) => {
          getTagOptions("group", groupId, callback);
        },
      },
    ],
  },
];

export const groupAutomaticConditions = [{ value: "joinGroup", text: "groups.announcements.joinsGroup" }];
