import React, { useState, useEffect, useCallback, useMemo } from "react";
import "remirror/styles/all.css";

import Menu from "./menu";
import Mentions, { RemirrorMentionProps } from "./Mentions";
import createExtension from "./extensions";
import EditorWrapper, { RemirrorInnerEditorProps } from "./editor";
import { DefaultPreset } from "./extensions/default";
import ReactTextEditor from "./view";
import FloatingLinkToolbar, { checkLocalLink } from "./FloatingLinkToolbar";
import { Preset } from "../presets/types";
import { useNavigate } from "react-router-dom";

const getExtensions = (options, settings, readOnly, navigate) => {
  const order = [];

  const combinedOptions = options.reduce((p, { name, config = {} }) => {
    if (!order.includes(name)) {
      order.push(name);
    }

    // Workaround for injecting `uploadHandler` for images during paste actions
    if ("uploadFile" in config && "uploadOptions" in settings) {
      // @ts-ignore
      config.uploadHandler = (files) => {
        const promises = files.map(
          ({ file, progress }, i) =>
            () =>
              new Promise((resolve) => {
                config
                  // @ts-ignore
                  .uploadFile(file, settings.uploadOptions)
                  .then((src) => {
                    progress((i + 1) / files.length);
                    resolve({ src, name: file.name, fileName: file.name });
                  })
                  .then((result) => Promise.resolve(result))
                  .catch((err) => {
                    console.error(err);
                  });
              }),
        );

        return promises;
      };
    }

    // Workaround for image resizing not respecting Remirror `editable`
    if (name === "image") {
      // @ts-ignore
      config.enableResizing = !readOnly;
    }

    // Set fullMentionLinks to true to get full links instead of relative links
    if (name === "mention") {
      if (settings.fullMentionLinks) {
        // @ts-ignore
        const relativeFormatMention = config.formatMention ?? ((item) => item);
        // @ts-ignore
        config.formatMention = (item) => {
          const formatted = relativeFormatMention(item);
          return {
            ...formatted,
            href: window.location.origin + formatted.href,
          };
        };
      }
    }

    const prev = p[name];
    if (!prev) {
      p[name] = [config];
    } else {
      p[name] = [...prev, config];
    }
    return p;
  }, {});

  const initialisedExtensions = order.map((name) => {
    const extension = createExtension(name, combinedOptions[name]);

    if (name === "link") {
      extension.addHandler("onClick", (event, data) => {
        if (!readOnly) {
          return false;
        }
        const localLink = checkLocalLink(data.href);
        if (localLink.isLocal) {
          navigate(localLink.path);
        } else {
          settings.onExternalLink?.(data.href);
        }

        return true;
      });
    }
    if (name === "mention") {
      extension.addHandler("onClick", (event, data) => {
        if (!readOnly) {
          return false;
        }
        const atomName = data?.node?.attrs["data-mention-atom-name"];
        const atomId = data?.node?.attrs["data-mention-atom-id"];
        if (!atomName || !atomId) {
          return false;
        }
        navigate(`/${atomName}/${atomId}`);

        return true;
      });
    }

    return extension;
  });
  return { initialisedExtensions, combinedOptions };
};

export type RemirrorMenuItem = {
  name: string;
  onClick?: (attrs: { name: string; commands: () => void; selectedText: string; isActive: boolean }) => void;
  active?: boolean;
  forceEnable?: boolean;
};

export type RemirrorProps = {
  extraMenuItems?: Array<RemirrorMenuItem>;
  HelpText?: React.ReactNode;
  stickyMenu?: boolean;
  stickyMenuOffset?: number;
  options?: Preset;

  hideMenu?: boolean;
  autoHideMenu?: boolean;
  noShadow?: boolean;
  settings?: {
    uploadOptions?: { forType: string; forId: string };
    uploadFile?: (file: File, options: { forType: string; forId: string }) => Promise<string>;
    onExternalLink?: (url: string) => void;
    fullMentionLinks?: boolean;
  };
  historyEnabled?: boolean;
} & Omit<RemirrorInnerEditorProps, "children" | "extensions"> &
  Omit<RemirrorMentionProps, "config">;

const Editor = ({
  value,
  onChange,
  onChangeJson,
  onFocus,
  onBlur,
  emptyReturnValue,
  extraMenuItems,
  HelpText,
  stickyMenu = false,
  stickyMenuOffset = 0,
  options = DefaultPreset,
  mentions,
  onSuggest,
  onMentionSelect,
  hideMenu,
  autoHideMenu = true,
  noShadow,
  readOnly = false,
  placeholder = null,
  settings = {},
  historyEnabled = true,
  editorRef,
  handleKeyDown,
}: RemirrorProps) => {
  const navigate = useNavigate();

  const [extensions, setExtensions] = useState(() => getExtensions(options, settings, readOnly, navigate));
  const [enabledExtensions, setEnabledExtensions] = useState([]);
  const { initialisedExtensions, combinedOptions } = extensions;

  useEffect(() => {
    setExtensions(getExtensions(options, settings, readOnly, navigate));
    setEnabledExtensions(options.map((o) => o.name));
  }, [options, settings, readOnly, navigate]);

  const focusHandler = useCallback(
    (val) => {
      onFocus && onFocus(val);
    },
    [onFocus],
  );

  const blurHandler = useCallback(
    (html, textValue) => {
      onBlur && onBlur(html, textValue);
    },
    [onBlur],
  );

  const realValue = useMemo(() => value?.split("\n")?.join("<p>") ?? emptyReturnValue, [value, emptyReturnValue]);

  return (
    <EditorWrapper
      placeholder={placeholder}
      readOnly={readOnly}
      value={realValue}
      onChange={onChange}
      onChangeJson={onChangeJson}
      onBlur={blurHandler}
      onFocus={focusHandler}
      emptyReturnValue={emptyReturnValue}
      extensions={initialisedExtensions}
      editorRef={editorRef}
      handleKeyDown={handleKeyDown}
    >
      {!readOnly && !hideMenu ? (
        <Menu
          extraMenuItems={extraMenuItems}
          HelpText={HelpText}
          sticky={stickyMenu}
          stickyOffset={stickyMenuOffset}
          historyEnabled={historyEnabled}
          autoHideMenu={autoHideMenu}
        />
      ) : null}
      {!readOnly && enabledExtensions.includes("mention") ? (
        <Mentions
          mentions={mentions}
          config={combinedOptions.mention}
          onMentionSelect={onMentionSelect}
          onSuggest={onSuggest}
        />
      ) : null}
      {!readOnly && !hideMenu && enabledExtensions.includes("link") ? <FloatingLinkToolbar /> : null}
      <ReactTextEditor noShadow={noShadow} />
    </EditorWrapper>
  );
};

// export default React.memo(Editor);
export default Editor;
