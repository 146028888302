import React, { useState, useEffect, useCallback } from "react";
import { Message, Loader, Divider, Icon } from "semantic-ui-react";
import api from "api";
import styled from "styled-components";
import SearchParams from "simplydo/src/searchParams";

const SuccessfulAuthorisationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    border-radius: 5px;
    border: 1px solid #e9ebee;
    background-color: #f8f8f8;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 50px 10px;
    min-width: ${({ theme }) => (theme.sizes.isMobile ? undefined : "400px")};
    > h5,
    > span,
    > p {
      margin: 0;
      display: block;
    }
    > h5 {
      font-size: 1.6em;
    }
    > span {
      font-size: 1.1em;
    }
    i.icon {
      font-size: 4em;
    }
  }
`;

const AuthoriseToken = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const authoriseToken = useCallback(() => {
    const params = new SearchParams(window.location.search);
    const token = params.get("token");
    const phrase = params.get("phrase");
    setLoading(true);
    api.auth.authoriseToken(
      token,
      phrase,
      () => {
        setSuccess(true);
        setLoading(false);
      },
      (err) => {
        setErrorMessage(err.message);
        setLoading(false);
      },
    );
  }, []);

  useEffect(() => {
    authoriseToken();
  }, [authoriseToken]);

  if (success) {
    return (
      <SuccessfulAuthorisationContainer>
        <div>
          <Icon name="check circle" color="green" size="huge" />
          <h5>SimplyDo has authorised your login</h5>
          <p>
            Please now return to the
            <b>browser tab</b> or
            <b>mobile app</b> from where you began to authorise your login.
          </p>
        </div>
      </SuccessfulAuthorisationContainer>
    );
  }
  return (
    <div>
      <h1>SimplyDo is authorising your login</h1>
      <Divider />
      {loading && <Loader centered active inline="centered" />}
      {errorMessage && <Message color="orange">Unable to authorise your token at this time: {errorMessage}</Message>}
      {success ? (
        <Message success>
          <h3>SimplyDo has authorised your login</h3>
          <p>
            Please now return to the
            <b>browser tab</b> or
            <b>mobile app</b> from where you began to authorise your login.
          </p>
        </Message>
      ) : null}
    </div>
  );
};

export default AuthoriseToken;
