import React, { useEffect, useState } from "react";
import { Message, Loader, Divider } from "semantic-ui-react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import api from "api";

import { useSearchParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "store";
import actions from "actions";

const LoginWithToken = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");
    const next = searchParams.get("next");
    setLoading(true);

    if (next) {
      const nextSplit = next.split("?");
      if (nextSplit.length > 1) {
        const nextParams = new URLSearchParams(nextSplit[1]);
        const utmLinkReferrer = nextParams.get("utm-link");
        if (utmLinkReferrer) {
          dispatch(actions.auth.setUtmLink(utmLinkReferrer));
        }
      }
    }

    api.auth.loginWithToken(
      token,
      () => {
        setLoading(false);
        navigate(next || "/");
      },
      (err) => {
        toast.error(err.message);
        setLoading(false);
        setErrorMessage(err.message);
      },
    );
  }, [searchParams, dispatch, navigate]);

  return (
    <div>
      <h1>{t("entry.login.title")}</h1>
      <Divider />
      {loading && <p>{t("entry.login.token.wait")}</p>}
      {loading && <Loader centered active inline />}
      {errorMessage && (
        <Message color="orange">
          {t("entry.login.token.failed")} {errorMessage}
        </Message>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const LoginWithTokenContainer = connect(mapStateToProps)(LoginWithToken);

export default LoginWithTokenContainer;
