import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Menu, Loader, Icon, Pagination } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import api from "api";
import util from "utils/utils";
import { EmptyBox } from "components/lib/UI";
import NotificationItem from "./NotificationItems";
import { useAppSelector } from "store";
import { OpenAPI } from "simplydo/interfaces";
import { useTrayStatus } from "./index";

type NotificationTrayProps = {
  page: number;
  total: number;
  loading: boolean;
  invitations: OpenAPI.GET<`/invitations`>["response"]["invitations"];
  acceptableInvitations: OpenAPI.GET<`/invitations`>["response"]["invitations"];
  notifications: OpenAPI.GET<`/notifications`>["response"]["notifications"];
  getNotifications: (page: number) => void;
  setInvitations: React.Dispatch<React.SetStateAction<OpenAPI.GET<`/invitations`>["response"]["invitations"]>>;
  setNotifications: React.Dispatch<React.SetStateAction<OpenAPI.GET<`/notifications`>["response"]["notifications"]>>;
};

export const StyledPagincationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 7px;
`;

const StyledContent = styled.div`
  margin: 0 !important;
  min-width: ${({ theme }) => (theme.sizes.isMobile ? "100%" : "500px")} !important;
  max-height: ${({ theme }) => (theme.sizes.isMobile ? null : "500px")} !important;
  overflow: auto;
`;

const Notifications = ({
  acceptableInvitations,
  invitations,
  setInvitations,
  setNotifications,
  loading,
  notifications,
  page,
  total,
  getNotifications,
}: NotificationTrayProps) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const [_, setTrayStatus] = useTrayStatus();

  const onInvitationHandled = useCallback(
    (invitationId) => {
      setInvitations((prev) => prev.filter((i) => i._id !== invitationId));
    },
    [setInvitations],
  );

  const markAsInteracted = useCallback(
    (notificationId) => {
      setTrayStatus({ open: false });
      api.notifications.markAsInteracted(
        notificationId,
        () => {
          setNotifications((prev) =>
            prev.map((notification) => {
              if (notification._id === notificationId) {
                return { ...notification, interacted: true };
              }
              return notification;
            }),
          );
        },
        () => {},
      );
    },
    [setTrayStatus, setNotifications],
  );

  return (
    <div style={{ maxHeight: 600 }}>
      {loading ? <Loader active /> : null}
      {!loading && acceptableInvitations.length ? (
        <Menu.Item
          style={{
            borderBottom: "1px solid",
            borderColor: "#E9EBEE",
            padding: ".833em 1em",
            borderRadius: 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textOverflow: "ellipsis",
            marginTop: 5,
            marginBottom: 5,
            width: "99%",
            gap: 10,
            color: "black",
          }}
          fitted
          link
          as={Link}
          to="/invitations"
        >
          <Icon name="paper plane" size="big" style={{ width: 50, margin: 0 }} />
          <div>
            <h4>Invitations</h4>
            <div>
              There {util.pluralise(acceptableInvitations.length, "is", "are", false)}{" "}
              {util.pluralise(acceptableInvitations.length, "invitation", "invitations")} waiting for you.
            </div>
          </div>
        </Menu.Item>
      ) : null}
      {!loading && notifications.length ? (
        <>
          <StyledContent>
            {notifications.map((notification) => (
              // @ts-ignore
              <NotificationItem
                notification={notification}
                user={user}
                key={notification._id}
                markNotificationAsInteracted={markAsInteracted}
                invitations={invitations}
                onInvitationHandled={onInvitationHandled}
              />
            ))}
          </StyledContent>
          <StyledPagincationContainer>
            <Pagination
              totalPages={Math.ceil(total / 30)}
              activePage={page}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              onPageChange={(e, { activePage }) => getNotifications(activePage as number)}
            />
          </StyledPagincationContainer>
        </>
      ) : null}
      {!loading && !notifications.length ? (
        <div>
          <EmptyBox>
            <h5>{t("notifications.empty")}</h5>
          </EmptyBox>
        </div>
      ) : null}
    </div>
  );
};

export default Notifications;
