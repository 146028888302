import React, { useState, useEffect, useCallback, ReactElement, useMemo } from "react";
import { Link } from "react-router-dom";
import { Modal, Input, Loader, Segment, Button } from "semantic-ui-react";
import { useAppSelector } from "store";
import api from "api";
import util from "utils/utils";
import useThrottle from "utils/useThrottle";

import IdeasImage from "src/images/ideas.png";
import ConfigurableTable from "../ConfigurableTable";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ImageWithFallback } from "../ImageWithFallback";
import { OpenAPI } from "simplydo/interfaces";
import { styled } from "styled-components";

const ExtraContent = styled(Modal.Content)`
  &:empty {
    display: none !important;
  }
`;

type ChoosableChallenge = OpenAPI.Schemas["Challenge"];

export type ExtraContentFunctionProps = {
  challenges: Array<ChoosableChallenge>;
};

type ChallengeChooserProps = {
  forType?: string;

  createNewAction?: () => void;
  single?: boolean;
  ignores?: string[];

  trigger?: ReactElement;
  subtitle?: string;
  confirm?: string;
  isLoading?: boolean;
  beforeContent?: React.ReactNode | ((extraProps: ExtraContentFunctionProps) => React.ReactNode);
  afterContent?: React.ReactNode | ((extraProps: ExtraContentFunctionProps) => React.ReactNode);

  onComplete: (challenges: Array<ChoosableChallenge>) => void;
  isOpen?: boolean;
  onClose?: () => void;
};

const ChallengeChooser = ({
  trigger,
  ignores,
  createNewAction,
  subtitle,
  isLoading,
  onComplete,
  isOpen,
  confirm,
  onClose,
  forType,
  beforeContent,
  afterContent,
  single,
}: ChallengeChooserProps) => {
  const user = useAppSelector((state) => state.user);
  const [challenges, setChallenges] = useState([]);
  const [selectedChallenges, setSelectedChallenges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(isOpen ?? false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoIgnore = useMemo(() => ignores, [ignores.join(",")]);

  const [challengeSearchState, setChallengesSearchState] = useState({
    page: 1,
    limit: 10,
    query: "",
    forType: forType,
  });
  const userId = user?._id;
  const { t } = useTranslation();

  const beforeContentResolved = useMemo(() => {
    if (typeof beforeContent === "function") {
      return beforeContent({ challenges: selectedChallenges });
    }
    return beforeContent;
  }, [beforeContent, selectedChallenges]);

  const afterContentResolved = useMemo(() => {
    if (typeof afterContent === "function") {
      return afterContent({ challenges: selectedChallenges });
    }
    return afterContent;
  }, [afterContent, selectedChallenges]);

  const getChallenges = useThrottle(
    (searchState) => {
      setLoading(true);
      api.users.getChallenges(
        userId,
        searchState,
        (data) => {
          setLoading(false);
          let { challenges: newChallenges } = data;
          if (memoIgnore?.length > 0) newChallenges = newChallenges.filter((c) => memoIgnore.indexOf(c._id) === -1);
          setChallenges(newChallenges);
        },
        () => {
          setLoading(false);
        },
      );
    },
    400,
    [userId, memoIgnore],
    { useLeadingCall: true },
  );

  const updateSearch = useCallback(
    (searchState: typeof challengeSearchState) => {
      setChallengesSearchState(searchState);
      getChallenges(searchState);
    },
    [getChallenges],
  );

  useEffect(() => {
    getChallenges({
      page: 1,
      limit: 10,
      query: "",
      forType: forType,
    });
  }, [getChallenges, forType]);

  useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(!!isOpen);
    }
  }, [isOpen]);

  const finish = useCallback(() => {
    onComplete?.(selectedChallenges);

    if (isOpen === undefined) {
      setOpen(false);
    }
    // A controlled loading component should not auto-close immediately
    if (isLoading === undefined) {
      onClose?.();
    }

    setSelectedChallenges([]);
    setChallengesSearchState((prev) => ({
      ...prev,
      page: 1,
      limit: 10,
      query: "",
    }));
  }, [selectedChallenges, isOpen, onClose, onComplete, isLoading]);

  const onClickChallenge = useCallback(
    (challenge) => {
      if (single) {
        setSelectedChallenges((prev) => {
          if (prev.find((c) => c._id === challenge._id)) {
            return [];
          }
          return [challenge];
        });
      } else {
        setSelectedChallenges((prev) => {
          if (prev.find((c) => c._id === challenge._id)) {
            return prev.filter((c) => c._id !== challenge._id);
          }
          return [...prev, challenge];
        });
      }
    },
    [single],
  );

  const handleClose = useCallback(() => {
    if (isOpen === undefined) {
      setOpen(false);
    }
    onClose && onClose();
  }, [onClose, isOpen]);

  return (
    <>
      {trigger ? (
        isOpen === undefined ? (
          React.cloneElement(trigger, { onClick: () => setOpen(!open) })
        ) : (
          trigger
        )
      ) : isOpen === undefined ? (
        <Button
          size="small"
          primary
          icon="lightbulb"
          content={`Choose ${t("generic.challengeWithArticle")}`}
          onClick={() => setOpen(!open)}
        />
      ) : null}

      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={open} onClose={handleClose}>
        <Modal.Header>
          Choose {t("generic.challengeWithArticle")}
          {subtitle && <p style={{ fontSize: "1rem", fontWeight: "normal" }}>{subtitle}</p>}
        </Modal.Header>
        <Modal.Content>
          {beforeContentResolved ? <ExtraContent>{beforeContentResolved}</ExtraContent> : null}
          {loading ? (
            <Loader active />
          ) : (
            <>
              <div style={{ textAlign: "right", marginBottom: 20 }}>
                <Input
                  autoFocus
                  icon="search"
                  placeholder={`Search for ${t("generic.challengeWithArticle")}...`}
                  value={challengeSearchState.query}
                  onChange={(e) => {
                    setSelectedChallenges([]);
                    updateSearch({
                      ...challengeSearchState,
                      query: e.target.value,
                    });
                  }}
                />
              </div>

              {challenges.length > 0 ? (
                <ConfigurableTable
                  tableKey="challenge-chooser"
                  preventSelectAll
                  selectedKeys={selectedChallenges.map((c) => c._id)}
                  data={challenges}
                  columns={[
                    {
                      key: "name",
                      name: "Name",
                      render: ({ item }) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <ImageWithFallback avatar src={item.bannerImageUrl} fallbackSrc={util.challengeImage()} />
                          <Link to={`/challenges/${item._id}`} target="_blank" rel="noopener noreferrer">
                            {item.name}
                          </Link>
                        </div>
                      ),
                    },
                    {
                      key: "createdAt",
                      name: "Created",
                      render: ({ cell }) => moment(cell).format("DD/MM/YYYY"),
                    },
                    forType === "planner"
                      ? {
                          key: "events",
                          name: "Events",
                          render: ({ item }) => item?.calendar?.events?.length || 0,
                        }
                      : null,
                    {
                      key: "stage",
                      name: "Status",
                      render: ({ cell }) => (cell === "published" ? "Open" : "Closed"),
                    },
                  ]}
                  onSelect={onClickChallenge}
                />
              ) : (
                <Segment placeholder textAlign="center">
                  <img
                    src={IdeasImage}
                    style={{
                      maxHeight: 100,
                      maxWidth: "80%",
                      display: "block",
                      margin: "5px auto",
                    }}
                    alt={`No ${t("generic.challenges")} yet`}
                  />
                  <h4>No {t("generic.challenges")} found</h4>
                  {challengeSearchState.query ? (
                    <p>Try adjusting your search term.</p>
                  ) : (
                    <p>There are no {t("generic.challenges")} to choose from.</p>
                  )}
                </Segment>
              )}
            </>
          )}
        </Modal.Content>
        {afterContentResolved ? <ExtraContent>{afterContentResolved}</ExtraContent> : null}
        <Modal.Actions>
          <Button onClick={handleClose} content="Cancel" />
          {util.canCreateChallenges(user) && challenges?.length > 0 && createNewAction ? (
            <Button
              primary
              onClick={createNewAction}
              content={`Create a new ${t("generic.challenge")} instead`}
              icon="plus"
            />
          ) : null}
          <Button
            primary
            disabled={selectedChallenges.length === 0}
            loading={isLoading}
            onClick={finish}
            content={confirm || `Choose this ${t("generic.challenge")}`}
            icon="check"
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ChallengeChooser;
