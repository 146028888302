import React from "react";
import { Segment } from "semantic-ui-react";
import styled from "styled-components";

const StyledSegment = styled(Segment)`
  min-height: fit-content !important;
  .icon.image {
    height: inherit !important;
  }
`;

type IEmptyBox = {
  title?: string;
  message?: string;
  children?: any;
  style?: any;
};

function EmptyBox({ title, message, children, style }: IEmptyBox) {
  return (
    <StyledSegment style={style} placeholder textAlign="center">
      {children || (
        <>
          {title && <h2>{title}</h2>}
          <p>{message}</p>
        </>
      )}
    </StyledSegment>
  );
}

export default EmptyBox;
