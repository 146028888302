import React from "react";
import { HoverAction } from "components/lib/HoverActions";
import { OpenAPI } from "simplydo/interfaces";
import IdeaPdfExport from "components/lib/IdeaPdfExport";

type DownloadPDFActionProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
};

const DownloadPDFAction = ({ idea }: DownloadPDFActionProps) => {
  return (
    <IdeaPdfExport
      ideas={[idea]}
      challenge={idea?.ownerChallenge}
      popupOffset={[0, -7]}
      trigger={
        <div style={{ cursor: "pointer" }}>
          <HoverAction icon="file pdf">Download PDF</HoverAction>
        </div>
      }
    />
  );
};

export default DownloadPDFAction;
