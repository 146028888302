import React, { useState } from "react";
import { HoverAction } from "components/lib/HoverActions";
import { OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";
import util from "utils/utils";
import RequestJoinModal from "../../RequestJoinModal";

type JoinTeamActionProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
  onUpdateIdea: (idea: Partial<OpenAPI.GET<"/ideas/{id}">["response"]>) => void;
};

const JoinTeamAction = ({ idea, onUpdateIdea }: JoinTeamActionProps) => {
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const user = useAppSelector((state) => state.user);

  if (!user || !idea) {
    return null;
  }

  if (util.canEditIdea(user, idea)) {
    return null;
  }

  if (idea?.ownerChallenge?.preventCollaboration) {
    return null;
  }

  return (
    <>
      <RequestJoinModal
        idea={idea}
        open={joinModalOpen}
        onClose={() => setJoinModalOpen(false)}
        onConfirm={() => {
          onUpdateIdea({ requestedToJoin: true });
        }}
      />
      <HoverAction
        disabled={idea.requestedToJoin}
        icon="paper plane"
        onClick={() => {
          setJoinModalOpen(true);
        }}
      >
        {idea.requestedToJoin ? "Awaiting approval to join" : "Join team"}
      </HoverAction>
    </>
  );
};

export default JoinTeamAction;
