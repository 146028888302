import React, { useState, useEffect } from "react";
import { Button, Popup, Segment } from "semantic-ui-react";
import toast from "react-hot-toast";
import styled from "styled-components";

import { ImageChooser } from "components/lib/Choosers";
import { useTranslation } from "react-i18next";

const imageHeight = 250;
const fullImageHeight = 365;

const MultiImageSegment = styled(Segment)`
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: "space-evenly";
  flex-wrap: wrap;
  flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
  &:hover {
    ${({ $dragActive }) => $dragActive && "background: #c0c0c0c !important;"}
  }
`;

const MultiImageContainer = styled.div`
  padding: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: "space-evenly";
  flex-wrap: wrap;
  flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
`;

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 500;
`;

const GalleryImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;

const GalleryImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin: 3px;
  object-fit: cover;
  ${({ theme, $active }) => $active && `border: 3px solid ${theme.primaryColour}`};
  cursor: pointer;
`;

const NewMultiImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  flex: 0.5;
`;

const NoActiveImage = styled.div`
  border-radius: 10px;
  height: ${({ $imageHeight }) => $imageHeight};
  width: ${({ $imageWidth }) => $imageWidth};
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
`;

const CropTypeToggle = ({ cropType, setCropType }) => {
  if (cropType === "cover") {
    return <Button size="mini" icon="crop" onClick={() => setCropType("contain")} />;
  }
  return <Button size="mini" icon="crop" onClick={() => setCropType("cover")} />;
};

const MultiImageChooser = ({
  images = [],
  onComplete,
  deleteFile,
  removeAsImage,
  forType,
  forId,
  trigger,
  title,
  description,
  editing,
  getFileUrl,
  useSegment,
  maxImages = 99,
}) => {
  const [activeImage, setActiveImage] = useState(null);
  const [cropType, setCropType] = useState("cover");

  const { t } = useTranslation();

  const deleteImage = (s) => {
    setActiveImage(null);
    deleteFile(s._id);
  };

  const toggleOffImage = (s) => {
    setActiveImage(null);
    removeAsImage(s);
  };

  useEffect(() => {
    if (images) {
      setActiveImage(images[0]);
    } else setActiveImage(null);
  }, [images]);

  const sharedProps = {
    forType,
    forId,
    onError: (e) => toast.error(e),
  };

  const Wrapper = useSegment ? MultiImageSegment : MultiImageContainer;
  if (!editing && !images.length) return null;
  return (
    <Wrapper>
      <GalleryContainer>
        {activeImage ? (
          <div style={{ margin: 3, flex: 1 }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <h5
                style={{
                  margin: 0,
                  textOverflow: "ellipsis",
                  width: "90%",
                  overflow: "hidden",
                }}
              >
                {activeImage.name}
              </h5>
              <div style={{ display: "flex" }}>
                <Popup
                  content="Toggle cropping mode"
                  on="hover"
                  trigger={
                    <div>
                      <CropTypeToggle cropType={cropType} setCropType={setCropType} />
                    </div>
                  }
                />
                {editing ? (
                  <>
                    {removeAsImage ? (
                      <Popup
                        content="Remove as stock image"
                        on="hover"
                        trigger={<Button size="mini" basic icon="ban" onClick={() => toggleOffImage(activeImage)} />}
                      />
                    ) : null}
                    <Popup
                      content="Delete image"
                      on="hover"
                      trigger={<Button size="mini" basic icon="trash" onClick={() => deleteImage(activeImage)} />}
                    />
                  </>
                ) : null}
              </div>
            </div>
            <img
              src={getFileUrl ? getFileUrl(activeImage) : activeImage.url}
              alt={getFileUrl ? getFileUrl(activeImage) : activeImage.url}
              style={{
                height: editing ? imageHeight : fullImageHeight,
                width: "100%",
                objectFit: cropType,
                borderRadius: 10,
              }}
            />
          </div>
        ) : (
          <NoActiveImage $imageHeight={`${imageHeight + 40}px`} $imageWidth={"100%"}>
            <h5>{!images.length ? t("multiimage.upload.none") : "Select an image"}</h5>
          </NoActiveImage>
        )}
        <GalleryImages>
          {images && Array.isArray(images) && images.length
            ? images.map((s) => (
                <GalleryImage
                  src={getFileUrl ? getFileUrl(s) : s.url}
                  key={s._id}
                  onClick={() => setActiveImage(s)}
                  $active={activeImage?._id === s._id}
                />
              ))
            : null}
        </GalleryImages>
      </GalleryContainer>
      {editing ? (
        <NewMultiImageContainer>
          <h5 style={{ margin: 5 }}>{title}</h5>
          <p style={{ textAlign: "center", marginBottom: 10 }}>{description}</p>
          <ImageChooser position={"bottom right"} trigger={trigger} onComplete={onComplete} {...sharedProps} />
          {maxImages ? (
            <p style={{ marginTop: 10 }}>
              <b>
                {images.length} / {maxImages} maximum images uploaded
              </b>
            </p>
          ) : null}
        </NewMultiImageContainer>
      ) : null}
    </Wrapper>
  );
};

export default MultiImageChooser;
