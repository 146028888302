import React, { useCallback } from "react";
import { HoverAction } from "components/lib/HoverActions";
import TagModalChooser from "components/lib/Choosers/Tags/TagModalChooser";
import api from "api";
import toast from "react-hot-toast";
import { useAppSelector } from "store";
import util from "utils/utils";
import { OpenAPI } from "simplydo/interfaces";

type TagActionProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
  onUpdateIdea: (idea: Partial<OpenAPI.GET<"/ideas/{id}">["response"]>) => void;
};

const TagAction = ({ idea, onUpdateIdea }: TagActionProps) => {
  const user = useAppSelector((state) => state.user);

  const updateTagListWithTag = useCallback((taggedIdea, tag) => {
    const currentChallenge = taggedIdea?.ownerChallenge;
    const tagList = [...currentChallenge.tagList];
    const index = tagList.findIndex((t) => t._id === tag._id);
    tagList[index] = tag;
    return { ...currentChallenge, tagList };
  }, []);

  const addTag = useCallback(
    (tag) => {
      api.tags.tagSingle(
        "ideas",
        tag._id,
        idea._id,
        (updatedTag) => {
          const ownerTags = [...(idea?.ownerTags ?? [])];
          ownerTags.push(updatedTag);
          const updatedChallenge = updateTagListWithTag(idea, updatedTag);
          onUpdateIdea({ ownerTags, tags: ownerTags.map((t) => t._id), ownerChallenge: updatedChallenge });
        },
        (err) => toast.error(err.message),
      );
    },
    [idea, updateTagListWithTag, onUpdateIdea],
  );

  const removeTag = useCallback(
    (tag) => {
      api.tags.untagSingle(
        "ideas",
        tag._id,
        idea._id,
        (updatedTag) => {
          const ownerTags = [...(idea?.ownerTags ?? [])];
          const cIndex = ownerTags.findIndex((t) => t._id === updatedTag._id);
          ownerTags.splice(cIndex, 1);
          const updatedChallenge = updateTagListWithTag(idea, updatedTag);
          onUpdateIdea({ ownerTags, tags: ownerTags.map((t) => t._id), ownerChallenge: updatedChallenge });
        },
        () => {},
      );
    },
    [idea, updateTagListWithTag, onUpdateIdea],
  );

  if (!idea.ownerChallenge) {
    return null;
  }

  if (
    !util.canManageChallenge(user, idea.ownerChallenge) &&
    !util.hasPermission(user, "challenge.editIdeaTags", idea.challenge)
  ) {
    return null;
  }

  return (
    <TagModalChooser
      trigger={<HoverAction icon="hashtag">{` Assign tags (${idea?.tags?.length || 0})`}</HoverAction>}
      tags={idea.ownerTags}
      availableTags={idea.ownerChallenge.tagList.filter((tag) => !tag?.disabledEntities?.includes("ideas"))}
      addTag={addTag}
      removeTag={removeTag}
      hideView
      canEdit
    />
  );
};

export default TagAction;
