import { useState, useEffect, useCallback, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { Message, Divider, Segment, Input, Button, Icon } from "semantic-ui-react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import api from "api";
import actions from "actions";
import util from "utils/utils";
import styled from "styled-components";
import { useAppSelector } from "store";

import { CheckboxHeader, RadioHeader, EmptyBox } from "components/lib/UI";

const AudienceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const AudienceItem = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-of-type) {
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
    border-bottom: 1px solid lightgray;
  }
`;

const AudienceInfo = styled.div`
  h3 {
    margin: 0;
  }
  > span {
    font-size: 1.1rem;
  }
  .meta {
    color: gray;
    font-weight: 700;
  }
  .info {
    color: gray;
  }
`;

const AudienceActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .ui.button {
    height: 40px;
    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  }
`;

const AudienceChooser = ({ t, visibility, organisation, challenge, onVisibilityUpdated }) => {
  const dispatch = useDispatch();

  const [organisations, setOrganisations] = useState([]);
  const [groups, setGroups] = useState([]);
  const [audienceType, setAudienceType] = useState("");
  const [orgFilter, setOrgFilter] = useState("");
  const [groupFilter, setGroupFilter] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const user = useAppSelector((state) => state.user);
  const canManageChallenge = useMemo(() => util.canManageChallenge(user, challenge), [user, challenge]);

  const [submissionCode, setSubmissionCode] = useState(challenge.shortcutSubmissions?.challengeCode || "");
  const [editingSubmissionCode, setEditingSubmissionCode] = useState(false);

  const getPostable = useCallback(() => {
    api.challenges.getPostable(challenge._id, (data) => {
      setOrganisations(data.organisations);
      setGroups(data.groups);
    });
  }, [challenge._id]);

  const setInitialVisibility = useCallback(() => {
    if (visibility && !util.isEmpty(visibility)) {
      if (
        (visibility.groups && visibility.groups.length > 0) ||
        !(
          visibility.organisations &&
          visibility.organisations.length === 1 &&
          visibility.organisations[0] === organisation._id
        )
      ) {
        setAudienceType("custom");
      } else setAudienceType("organisation");
    } else setAudienceType("organisation");
    if (!visibility) setAudienceType(null);
  }, [visibility, organisation]);

  const updateShortcutSubmission = useCallback(
    (data, success, failure) => {
      const currSettings = challenge.shortcutSubmissions || {};
      const update = { ...currSettings, ...data };
      api.challenges.update(
        challenge._id,
        { shortcutSubmissions: update },
        (updateData) => {
          success && success(updateData);
          dispatch(actions.challenges.receive(updateData));
        },
        failure,
      );
    },
    [challenge._id, dispatch, challenge.shortcutSubmissions],
  );

  useEffect(() => {
    if (firstLoad) {
      getPostable();
      setInitialVisibility();
      setFirstLoad(false);
    }
  }, [getPostable, setInitialVisibility, firstLoad]);

  const isInAudience = useCallback(
    (id) =>
      visibility &&
      ((visibility.organisations && visibility.organisations.indexOf(id) > -1) ||
        (visibility.groups && visibility.groups.indexOf(id) > -1)),
    [visibility],
  );

  const toggleAudience = useCallback(
    (type, id) => {
      const updatedGroups = [...(visibility?.groups || [])];
      const updatedOrganisations = [...(visibility?.organisations || [])];
      if (type === "organisation") {
        if (updatedOrganisations.indexOf(id) === -1) updatedOrganisations.push(id);
        else updatedOrganisations.splice(updatedOrganisations.indexOf(id), 1);
      }
      if (type === "group") {
        if (updatedGroups.indexOf(id) === -1) updatedGroups.push(id);
        else updatedGroups.splice(updatedGroups.indexOf(id), 1);
      }
      const updatedVisibility = { ...visibility, organisations: updatedOrganisations, groups: updatedGroups };
      onVisibilityUpdated(updatedVisibility);
    },
    [onVisibilityUpdated, visibility],
  );

  const togglePublicLink = useCallback(
    (on) => {
      const updatedVisibility = {
        ...visibility,
        publicLink: on,
        publicIdeaCreation: !on ? false : visibility?.publicIdeaCreation,
      };
      onVisibilityUpdated(updatedVisibility);
    },
    [visibility, onVisibilityUpdated],
  );

  const togglePublicIdeaCreation = useCallback(
    (on) => {
      const updatedVisibility = { ...visibility, publicIdeaCreation: on, publicIdeaCreationRequirement: "noAction" };
      onVisibilityUpdated(updatedVisibility);
    },
    [visibility, onVisibilityUpdated],
  );

  const togglePublicIdeaCreationRequirement = useCallback(
    (update) => {
      const updatedVisibility = { ...visibility, publicIdeaCreationRequirement: update };
      onVisibilityUpdated(updatedVisibility);
    },
    [visibility, onVisibilityUpdated],
  );

  const copyLink = useCallback(() => {
    if (window.navigator?.clipboard) {
      window.navigator.clipboard
        .writeText(
          `https://${challenge.ownerOrganisation ? challenge.ownerOrganisation.code : "app"}.simplydo.co.uk/challenges/${challenge._id}`,
        )
        .then(() => toast.success("Link copied to your clipboard"))
        .catch(() => toast.error("Unable to copy the link"));
    } else {
      toast.error("Your browser does not support copying");
    }
  }, [challenge]);

  const changeAudienceType = useCallback(
    (type) => {
      setAudienceType(type);
      if (type === "organisation")
        onVisibilityUpdated({ organisations: [organisation._id], publicLink: visibility && visibility.publicLink });
      else if (type === "custom") onVisibilityUpdated(visibility);
    },
    [visibility, organisation, onVisibilityUpdated],
  );

  const filteredOrgs = orgFilter
    ? organisations.filter((o) => o.name.match(new RegExp(orgFilter, "i")))
    : organisations;
  const filteredGroups = groupFilter ? groups.filter((g) => g.name.match(new RegExp(groupFilter, "i"))) : groups;
  const smsDisabled = useMemo(() => challenge && challenge?.ipManagement?.isEnabled, [challenge]);

  const { enabledFeatures = [] } = organisation || {};
  return (
    <div>
      <h3>{t("challenge.settings.audience.info")}</h3>
      <Message info>{t("challenge.settings.audience.ideaCreators")}</Message>
      <Divider hidden />
      <RadioHeader
        as="h3"
        name="audienceRadio"
        value="organisation"
        disabled={!canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)}
        label="Default"
        checked={audienceType === "organisation"}
        onChange={() => changeAudienceType("organisation")}
        header={t("challenge.settings.audience.organisation.title")}
        content={t("challenge.settings.audience.organisation.info", { orgName: organisation && organisation.name })}
      />
      <RadioHeader
        disabled={!canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)}
        as="h3"
        name="audienceRadio"
        value="custom"
        checked={audienceType === "custom"}
        onChange={() => changeAudienceType("custom")}
        header={t("challenge.settings.audience.custom.title")}
        content={t("challenge.settings.audience.custom.info")}
      />

      <Divider hidden section />

      {audienceType === "custom" && (
        <div>
          <Segment attached="top">
            <h2>{t("challenge.settings.audience.custom.organisations")}</h2>
            <AudienceHeader>
              <p>{t("challenge.settings.audience.custom.organisationsInfo")}</p>
              {canManageChallenge || util.hasPermission(user, "challenge.editSettings", challenge?._id) ? (
                <Input
                  type="text"
                  icon="search"
                  size="mini"
                  placeholder={t("generic.filter", { type: "organisations" })}
                  onChange={(e) => setOrgFilter(e.target.value)}
                  style={{ float: "right" }}
                />
              ) : null}
            </AudienceHeader>

            {!filteredOrgs.length && (
              <EmptyBox
                style={{ marginTop: 15 }}
                title={t("challenge.settings.audience.custom.organisationsNone")}
                message={t("challenge.settings.audience.custom.organisationsNoneInfo")}
              />
            )}

            {filteredOrgs.map((org) => (
              <AudienceItem key={org._id}>
                <AudienceInfo>
                  {org._id !== organisation._id && <span className="meta">External organisation</span>}
                  <h3>{org.name}</h3>
                  <span className="info">{org.userCount} users</span>
                </AudienceInfo>
                <AudienceActions>
                  {!isInAudience(org._id) ? (
                    <Button
                      secondary
                      disabled={
                        !canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)
                      }
                      icon="user plus"
                      content={t("challenge.settings.audience.custom.organisationsAdd")}
                      onClick={() => toggleAudience("organisation", org._id)}
                    />
                  ) : (
                    <Button
                      color="orange"
                      basic
                      disabled={
                        !canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)
                      }
                      icon="user times"
                      content={t("challenge.settings.audience.custom.organisationsRemove")}
                      onClick={() => toggleAudience("organisation", org._id)}
                    />
                  )}
                </AudienceActions>
              </AudienceItem>
            ))}
          </Segment>

          {groups && groups.length > 0 && (
            <Segment attached>
              <h2>{t("common:capitalise", { key: "generic.groups" })}</h2>
              <AudienceHeader>
                <p>{t("challenge.settings.audience.custom.groupsInfo")}</p>
                {canManageChallenge || util.hasPermission(user, "challenge.editSettings", challenge?._id) ? (
                  <Input
                    type="text"
                    icon="search"
                    size="mini"
                    placeholder={t("generic.filter", { type: "groups" })}
                    onChange={(e) => setGroupFilter(e.target.value)}
                  />
                ) : null}
              </AudienceHeader>

              {!filteredGroups.length && (
                <EmptyBox
                  title={t("challenge.settings.audience.custom.groupsNone")}
                  message={t("challenge.settings.audience.custom.groupsNoneInfo")}
                />
              )}
              {filteredGroups.map((group) => (
                <>
                  <AudienceItem key={group._id}>
                    <AudienceInfo>
                      {group.organisation !== organisation._id && (
                        <span className="meta">{t("challenge.settings.audience.custom.groupsExternal")}</span>
                      )}
                      <h3>{group.name}</h3>
                      <span className="info">{`${group.userCount} users`}</span>
                    </AudienceInfo>
                    <AudienceActions>
                      {!isInAudience(group._id) ? (
                        <Button
                          secondary
                          disabled={
                            !canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)
                          }
                          icon="user plus"
                          content={t("challenge.settings.audience.custom.groupsAdd")}
                          onClick={() => toggleAudience("group", group._id)}
                        />
                      ) : (
                        <Button
                          color="orange"
                          basic
                          disabled={
                            !canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)
                          }
                          icon="user times"
                          content={t("challenge.settings.audience.custom.groupsRemove")}
                          onClick={() => toggleAudience("group", group._id)}
                        />
                      )}
                    </AudienceActions>
                  </AudienceItem>
                </>
              ))}
            </Segment>
          )}
        </div>
      )}
      {enabledFeatures?.indexOf("allowPublicChallenges") !== -1 ? (
        <>
          <Divider section />
          <CheckboxHeader
            disabled={!canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)}
            willHideChildren
            header={t("challenge.settings.audience.publicLink.title")}
            description={t("challenge.settings.audience.publicLink.enable")}
            as="h3"
            checked={visibility && visibility.publicLink}
            onChange={(checked) => togglePublicLink(checked)}
          >
            <Message info>
              Challenges with a public link also become available on your organisation's{" "}
              <a
                href={`https://${challenge.ownerOrganisation ? challenge.ownerOrganisation.code : "app"}.simplydo.co.uk/challenges/public`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Public Challenges Page
              </a>
              .
            </Message>
            <Segment>
              {challenge && challenge._id && (
                <div>
                  <h4>Public link to this {t("generic.challenge")}</h4>
                  <Input
                    size="small"
                    fluid
                    value={`https://${challenge.ownerOrganisation ? challenge.ownerOrganisation.code : "app"}.simplydo.co.uk/challenges/${challenge._id}`}
                    readOnly
                    action={
                      <Button
                        secondary
                        icon="copy"
                        content={t("challenge.settings.audience.copyLink")}
                        onClick={copyLink}
                      />
                    }
                  />
                </div>
              )}
              <Message>{t("challenge.settings.audience.publicLink.info", { appName: util.appName() })}</Message>
            </Segment>

            {/* Challenges with IP management enabled cannot use public ideas */}
            {!challenge?.ipManagement?.isEnabled ? (
              <>
                <Divider hidden />
                <CheckboxHeader
                  disabled={!canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)}
                  willHideChildren
                  header={`Public ${t("generic.idea")} creation`}
                  as="h4"
                  description={`Additionally, allow non-members to submit ${t("generic.ideaWithArticle")} without being logged-in to an account.`}
                  checked={visibility && visibility.publicIdeaCreation}
                  onChange={(checked) => togglePublicIdeaCreation(checked)}
                >
                  <Segment>
                    <RadioHeader
                      as="h5"
                      disabled={
                        !canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)
                      }
                      name="publicCreationRadio"
                      value="noAction"
                      label={t("generic.default")}
                      checked={visibility && visibility.publicIdeaCreationRequirement === "noAction"}
                      onChange={() => togglePublicIdeaCreationRequirement("noAction")}
                      header="Prompt users to enter an email address after submission"
                      content={`If selected, ${t("generic.idea")} creators will be given the option to provide an email address when submitting ${t("generic.ideaWithArticle")}. They will not be required to enter an email address in order to submit their ${t("generic.idea")}.`}
                    />

                    <RadioHeader
                      as="h5"
                      disabled={
                        !canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)
                      }
                      name="publicCreationRadio"
                      value="requireEmail"
                      checked={visibility && visibility.publicIdeaCreationRequirement === "requireEmail"}
                      onChange={() => togglePublicIdeaCreationRequirement("requireEmail")}
                      header={`Require users to provide an email address to submit their ${t("generic.idea")}`}
                      content={`If selected, ${t("generic.idea")} creators will be required to provide an email address in order to submit their ${t("generic.idea")}. They will not be required to complete their account setup, but they will receive ${t("generic.challenge")} update communications.`}
                    />

                    <RadioHeader
                      as="h5"
                      disabled={
                        !canManageChallenge && !util.hasPermission(user, "challenge.editSettings", challenge?._id)
                      }
                      name="publicCreationRadio"
                      value="accountCreation"
                      checked={visibility && visibility.publicIdeaCreationRequirement === "accountCreation"}
                      onChange={() => togglePublicIdeaCreationRequirement("accountCreation")}
                      header="Prompt user to create an account after submission"
                      content={`If selected, ${t("generic.idea")} creators will be asked to create an account when submitting their idea.`}
                    />
                  </Segment>
                </CheckboxHeader>
              </>
            ) : null}
          </CheckboxHeader>
        </>
      ) : null}
      <Divider hidden />
      <h4>Shortcut submissions</h4>
      <p>
        Shortcut submissions offer your users a quick way to submit {t("generic.ideas")} to your{" "}
        {t("generic.challenge")} without having to visit the platform.
      </p>
      {smsDisabled && (
        <Message warning>
          <Icon name="exclamation triangle" />
          Shortcut submissions are disabled for this {t("generic.challenge")}. This is because IP management is enabled,
          you can update this setting{" "}
          <Link to={"ip"} style={{ textDecorationLine: "underline" }}>
            here
          </Link>
          .
        </Message>
      )}
      <CheckboxHeader
        disabled={
          smsDisabled || !(canManageChallenge || util.hasPermission(user, "challenge.editSettings", challenge?._id))
        }
        willHideChildren
        header="Enable shortcut submissions"
        as="h5"
        description={`Enabling shortcut submissions allows users to submit ${t("generic.ideas")} using an external process, such as SMS.`}
        checked={challenge.shortcutSubmissions?.enabled}
        onChange={() => updateShortcutSubmission({ enabled: !challenge.shortcutSubmissions?.enabled })}
      >
        <>
          <Message info icon style={{ marginTop: 15 }}>
            <Icon name="info circle" />
            <Message.Content>
              <Message.Header>Shortcut submission notice</Message.Header>
              <p>
                Shortcut submissions will allow users to submit {t("generic.ideas")} without being logged in,
                circumventing any other audience settings for this {t("generic.challenge")}. This does not affect the
                regular audience rules of the submission process.
              </p>
            </Message.Content>
          </Message>
          {!visibility?.publicLink ? (
            <Message warning icon style={{ marginTop: 15 }}>
              <Icon name="exclamation triangle" />
              <Message.Content>
                <Message.Header>Potential visibility issue</Message.Header>
                <p>
                  Once an {t("generic.idea")} is submitted a user will only be able to view their {t("generic.idea")} if
                  they have access to this {t("generic.challenge")} on SimplyDo. Make sure that users will be able to
                  sign up with a SimplyDo account, or alternatively consider enabling{" "}
                  <b>{t("challenge.settings.audience.publicLink.title")}</b> above to give users access even if they are
                  not logged in. We will automatically supply them with an authenticated link to view their{" "}
                  {t("generic.ideas")} once they have submitted.
                </p>
              </Message.Content>
            </Message>
          ) : null}
          <p style={{ marginTop: 15 }}>
            <h5>
              {t("common:capitalise", { key: "generic.challenge" })} submission code{" "}
              <Icon name="asterisk" style={{ position: "relative", top: -5, marginLeft: 3 }} color="red" size="tiny" />
            </h5>
            Users will be able to submit to your {t("generic.challenge")} using this identifier. Try to make this code
            as simple as possible so that users can easily interact with your {t("generic.challenge")}. Please note that
            the following keywords are reserved and cannot be used: CONTINUE, CANCEL, CHALLENGES
          </p>
          <div>
            <Input
              placeholder="LOTR"
              disabled={
                !editingSubmissionCode ||
                smsDisabled ||
                !(canManageChallenge || util.hasPermission(user, "challenge.editSettings", challenge?._id))
              }
              value={submissionCode}
              onChange={(event) => setSubmissionCode(event.target.value)}
            />
            <Button
              primary
              content={editingSubmissionCode ? "Save" : "Update code"}
              disabled={
                !(canManageChallenge || util.hasPermission(user, "challenge.editSettings", challenge?._id)) ||
                (editingSubmissionCode &&
                  (submissionCode.length === 0 ||
                    ["CONTINUE", "CANCEL", "CHALLENGES"].includes(submissionCode.trim().toUpperCase())))
              }
              style={{ marginLeft: 5 }}
              onClick={() => {
                if (editingSubmissionCode) {
                  updateShortcutSubmission(
                    { challengeCode: submissionCode },
                    () => {
                      toast.success("Activated submission code.");
                      setEditingSubmissionCode(false);
                    },
                    () => {
                      toast.error("Code invalid or already in use.");
                    },
                  );
                } else {
                  setEditingSubmissionCode(true);
                }
              }}
            />
          </div>

          <h5>Enabled services</h5>
          <CheckboxHeader
            disabled={
              smsDisabled || !(canManageChallenge || util.hasPermission(user, "challenge.editSettings", challenge?._id))
            }
            checked={!!challenge.shortcutSubmissions?.externalServices?.sms?.enabled}
            onChange={() => {
              const services = challenge.shortcutSubmissions?.externalServices || {};
              const newServices = {
                ...services,
                sms: {
                  enabled: !challenge.shortcutSubmissions?.externalServices?.sms?.enabled,
                },
              };
              updateShortcutSubmission({ externalServices: newServices });
            }}
            description={`Anyone will be able to text the number ${import.meta.env.VITE_TWILIO_PHONE_NUMBER} with your ${t("generic.challenge")} submission code, and be taken through a SMS-based ${t("generic.idea")} creation process. Following creation of their ${t("generic.idea")}, they will be offered the chance to create an account and assign the new ${t("generic.idea")} to their new account. (Only text based fields can be created by SMS at this time)`}
            as="h5"
            header="SMS"
          />
        </>
      </CheckboxHeader>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default withTranslation()(connect(mapStateToProps)(AudienceChooser));
