import React, { useMemo } from "react";
import { Menu } from "semantic-ui-react";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import { OpenAPI } from "simplydo/interfaces";
import util from "utils/utils";
import { useAppSelector } from "store";

import AssessmentsDashboard from "./AssessmentsDashboard";
import YourAssessments from "./YourAssessments";
import Assignment from "./Assignment";

type IChallengeAssessments = {
  challenge: OpenAPI.Schemas["Challenge"];
  getAssignedAssessments: () => void;
  myAssignedAssessments: OpenAPI.GET<"/challenges/{id}/assessments">[];
  setMyAssignedAssessments: (assessments: OpenAPI.GET<"/challenges/{id}/assessments">[]) => void;
  demoingTour?: string;
};

const ChallengeAssessments = (props: IChallengeAssessments) => {
  const user = useAppSelector((state) => state.user);
  const canManageChallenge = useMemo(() => util.canManageChallenge(user, props.challenge), [user, props.challenge]);
  const { pathname } = useLocation();
  const challengeId = props?.challenge?._id;

  return (
    <div>
      {canManageChallenge || util.hasPermission(user, "challenge.viewAssessments", challengeId) ? (
        <Menu pointing secondary>
          <Menu.Item
            content="Dashboard"
            as={Link}
            to="."
            active={pathname === `/challenges/${challengeId}/assessments`}
          />
          {canManageChallenge || util.hasPermission(user, "challenge.editAssessments", challengeId) ? (
            <Menu.Item
              content="Assignment"
              as={Link}
              to="assignment"
              active={pathname === `/challenges/${challengeId}/assessments/assignment`}
            />
          ) : null}
          {props.myAssignedAssessments?.length ? (
            <Menu.Item
              content="Your assessments"
              as={Link}
              to="assigned"
              active={pathname === `/challenges/${challengeId}/assessments/assigned`}
            />
          ) : null}
        </Menu>
      ) : null}
      <Routes>
        <Route
          path="/"
          element={
            canManageChallenge || util.hasPermission(user, "challenge.viewAssessments", challengeId) ? (
              <AssessmentsDashboard {...props} />
            ) : (
              <YourAssessments {...props} />
            )
          }
        />
        {canManageChallenge || util.hasPermission(user, "challenge.editAssessments", challengeId) ? (
          <Route path="assignment" element={<Assignment {...props} />} />
        ) : null}
        <Route path="assigned" element={<YourAssessments {...props} />} />
      </Routes>
    </div>
  );
};

export default ChallengeAssessments;
