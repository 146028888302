import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState, CSSProperties } from "react";
import { createMarkPositioner, getPositioner } from "remirror/extensions";
import { useAttrs, useChainedCommands, useCurrentSelection, usePositioner, useUpdateReason } from "@remirror/react";
import { Button, Icon, Input, Popup } from "semantic-ui-react";
import { getMarkRange } from "remirror";
import { useFocusState } from "./hooks/useFocusState";

const hostNameSplit = window.location.host.split(".");
const withoutSubdomain = hostNameSplit.slice(1).join(".") || window.location.host;
const localRegex = new RegExp(
  `((^| )(http://www.https://www.|http://|https://)(([a-z]{1,15}.)?${withoutSubdomain}(/| |$)))`,
  "gi",
);

export const checkLocalLink = (href) => {
  if (href.startsWith("/")) {
    return { isLocal: true, path: href };
  }

  const match = href.match(localRegex);
  return {
    isLocal: !!match,
    path: match ? "/" + href.replace(localRegex, "") : href,
  };
};

function useFloatingLinkState() {
  const chain = useChainedCommands();
  const selection = useCurrentSelection();
  const { empty } = selection;

  const [isEditing, setIsEditing] = useState(false);

  const attrs = useAttrs();
  const url = (attrs.link()?.href as string) ?? "";
  const [href, setHref] = useState(url);

  // A positioner which only shows for links.
  const linkPositioner = useMemo(() => createMarkPositioner({ type: "link" }), []);
  const onRemove = useCallback(() => chain.removeLink().focus().run(), [chain]);
  const updateReason = useUpdateReason();

  useLayoutEffect(() => {
    if (!isEditing) {
      return;
    }

    if (updateReason.doc || updateReason.selection) {
      setIsEditing(false);
      setHref("");
    }
  }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

  const submitHref = useCallback(() => {
    setIsEditing(false);

    if (href === "") {
      chain.removeLink();
    } else {
      const markRange = getMarkRange(selection.$from, "link");
      chain.updateLink({ href, auto: false }, markRange);
    }

    chain.focus(selection?.to).run();
  }, [setIsEditing, chain, href, selection]);

  const cancelHref = useCallback(() => {
    setIsEditing(false);
    chain.focus().run();
  }, [setIsEditing, chain]);

  useEffect(() => {
    if (!isEditing) {
      return;
    }
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        cancelHref();
      }
      if (event.key === "Enter") {
        submitHref();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [isEditing, cancelHref, submitHref]);

  const clickEdit = useCallback(() => {
    if (empty) {
      chain.selectLink();
    }

    setHref(url);
    setIsEditing(true);
  }, [chain, url, empty, setIsEditing]);

  return useMemo(
    () => ({
      url,
      href,
      setHref,
      linkPositioner,
      isEditing,
      clickEdit,
      onRemove,
      submitHref,
      cancelHref,
      selection: { empty },
    }),
    [url, href, linkPositioner, isEditing, clickEdit, onRemove, submitHref, cancelHref, empty],
  );
}

const FloatingToolbar = ({ positioner = "selection", children }) => {
  const { ref, x, y, width, height, active } = usePositioner(() => getPositioner(positioner), [positioner]);
  const isFocused = useFocusState();

  const inlineStyle: CSSProperties = useMemo(
    () => ({
      position: "absolute",
      pointerEvents: "none",
      left: x,
      top: y,
      width,
      height,
    }),
    [x, y, width, height],
  );

  if (!isFocused) {
    return null;
  }

  return (
    <>
      <Popup
        mountNode={document.getElementById("semantic-modal-mount-node")}
        trigger={<div ref={ref} style={inlineStyle} />}
        open={active}
        content={<div>{children}</div>}
        basic
        style={{ padding: 0, border: "none" }}
      />
    </>
  );
};

const FloatingLinkToolbar = () => {
  const { url, isEditing, linkPositioner, clickEdit, onRemove, submitHref, href, setHref, cancelHref, selection } =
    useFloatingLinkState();

  const handleClickEdit = useCallback(() => {
    clickEdit();
  }, [clickEdit]);

  return (
    <>
      <FloatingToolbar positioner={selection.empty ? linkPositioner : undefined}>
        {isEditing ? (
          <Input
            ref={(ref) => {
              setTimeout(() => {
                ref?.focus();
              }, 0);
            }}
            type="text"
            value={href}
            placeholder="Enter link..."
            onChange={(e) => {
              setHref(e.target.value);
            }}
            style={{ width: 450 }}
            action
          >
            <input />
            <Button basic icon onClick={submitHref} type="submit">
              <Icon name="check" />
            </Button>
            <Button basic icon onClick={cancelHref}>
              <Icon name="close" />
            </Button>
          </Input>
        ) : url ? (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                padding: "4px 8px",
                bottom: 36,
                left: 0,
                background: "#eaeaea",
                borderRadius: 4,
                alignItems: "center",
                fontSize: "0.75rem",
                lineHeight: "0.85rem",
                wordBreak: "break-all",
                maxWidth: 350,
                width: "max-content",
              }}
            >
              <Icon name="linkify" size="small" style={{ top: -1, position: "relative" }} />
              {url}
            </div>
            <Button.Group>
              <Button basic onClick={handleClickEdit} icon>
                <Icon name="pencil" />
              </Button>
              <Button basic onClick={onRemove} icon>
                <Icon name="unlinkify" />
              </Button>
            </Button.Group>
          </div>
        ) : (
          <Button.Group>
            <Button basic onClick={handleClickEdit} icon>
              <Icon name="linkify" />
            </Button>
          </Button.Group>
        )}
      </FloatingToolbar>
    </>
  );
};

export default FloatingLinkToolbar;
