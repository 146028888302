import { useRef, useState, useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import api from "api";
import util from "utils/utils";
import websocketApi from "api/websocket";
import { useStore } from "react-redux";
import uuid from "uuid";

export const reducePath = (obj, changes, path) => {
  if (path.length === 0) {
    return changes;
  }
  const defaultObj = obj ?? {};
  const item = path[0];
  const nextObj = defaultObj[item] ?? {};

  return {
    ...defaultObj,
    [item]: reducePath(nextObj, changes, path.slice(1)),
  };
};

export const useIdeaEditorHook = (ideaId) => {
  const [historyState, setHistoryState] = useState({
    undo: [],
    redo: [],
  });
  const [websocketState, setWebsocketState] = useState({
    otherUsers: {},
    websocketConnected: true,
  });
  const [ideaState, setIdeaState] = useState({
    idea: {},
    unsaved: false,
    isLoading: true,
    isLoaded: false,
    isSaving: false,
    savedAt: "",
    notFound: false,
    errorMessage: "",
    history: [{}],
    historyIndex: -1,
  });
  const editTimeout = useRef(null);
  const websocketSubscription = useRef(null);
  const websocketSubscriptionHandler = useRef(null);
  const currentSave = useRef("");
  const updatedAt = useRef(new Date());
  const { user } = useStore().getState();
  const userId = user?._id;
  const { t } = useTranslation();

  const isCollaborator = ideaState.idea?.authors?.find((author) => author._id === userId);

  const getIdea = useCallback(() => {
    setIdeaState((prevState) => ({ ...prevState, isLoading: true, errorMessage: "" }));
    let authToken = null;
    if (util.localStorageIsSupported()) {
      authToken = localStorage.getItem(`ideaAuthToken:${ideaId}`);
    }
    api.ideas.get(
      ideaId,
      (receivedIdea) => {
        setIdeaState((prevState) => ({
          ...prevState,
          idea: receivedIdea,
          notFound: false,
          isLoading: false,
          isLoaded: true,
        }));
      },
      (err) => {
        setIdeaState((prevState) => ({
          ...prevState,
          idea: null,
          notFound: true,
          isLoading: false,
          errorMessage: err.message,
        }));
      },
      authToken,
    );
  }, [ideaId]);

  const saveIdea = useCallback(
    (changes = {}) =>
      new Promise(() => {
        const fetchId = uuid.v4();
        currentSave.current = fetchId;
        const savedAt = new Date();

        if (util.localStorageIsSupported()) {
          const authToken = localStorage.getItem(`ideaAuthToken:${ideaId}`);
          changes.authToken = authToken;
        }
        setIdeaState((prevState) => ({ ...prevState, isSaving: true }));
        return api.ideas.update(
          ideaId,
          changes,
          ({ templated, ideaTemplate: _remove, name: _remove2, ...changes }) => {
            if (currentSave.current !== fetchId) {
              return;
            }

            setIdeaState((prevState) => ({
              ...prevState,
              idea: {
                ...prevState.idea,
                ...changes,
                templated: updatedAt.current > savedAt ? prevState.idea.templated : templated,
              },
              isSaving: false,
              unsaved: false,
              savedAt,
            }));
          },
          (err) => {
            if (err.errorCode === "ideaIsSubmitted") {
              toast.error(`This ${t("generic.idea")} cannot be edited because it has been submitted.`);

              // If the idea is submitted we immediately lock the idea and then reload it to fetch the latest version
              setIdeaState((prevState) => ({ ...prevState, isSaving: false, isSubmitted: true }));
              getIdea();
            } else {
              toast.error(err.message);
              setIdeaState((prevState) => ({ ...prevState, isSaving: false }));
            }
          },
        );
      }),
    [ideaId, t, getIdea],
  );

  useEffect(() => getIdea(), [getIdea]);

  const addHistoryEntry = useCallback((updatedIdea) => {
    const { templated, name, coverImage } = updatedIdea;
    setHistoryState((prevHistory) => {
      const slicedUndoHistory = prevHistory.undo.slice(Math.max(prevHistory.undo.length - 9, 0));
      return {
        ...prevHistory,
        undo: [...slicedUndoHistory, { templated, name, coverImage }],
      };
    });
  }, []);

  const undoChanges = useCallback(() => {
    const undoItem = historyState.undo[historyState.undo.length - 1];
    setIdeaState((prevIdeaState) => ({ ...prevIdeaState, idea: { ...prevIdeaState.idea, ...undoItem } }));
    saveIdea(undoItem);
    if (!ideaState.idea) {
      return;
    }
    setHistoryState((prevHistory) => ({
      redo: [
        ...prevHistory.redo,
        { name: ideaState.idea.name, templated: ideaState.idea.templated, coverImage: ideaState.idea.coverImage },
      ],
      undo: prevHistory.undo.slice(0, -1),
    }));
  }, [historyState, saveIdea, ideaState.idea]);

  const redoChanges = useCallback(() => {
    const redoItem = historyState.redo[historyState.redo.length - 1];
    setIdeaState((prevIdeaState) => ({ ...prevIdeaState, idea: { ...prevIdeaState.idea, ...redoItem } }));
    saveIdea(redoItem);
    if (!ideaState.idea) {
      return;
    }
    setHistoryState((prevHistory) => ({
      redo: prevHistory.redo.slice(0, -1),
      undo: [
        ...prevHistory.undo,
        { name: ideaState.idea.name, templated: ideaState.idea.templated, coverImage: ideaState.idea.coverImage },
      ],
    }));
  }, [historyState, saveIdea, ideaState.idea]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        e.stopPropagation();
        if (e.shiftKey) {
          redoChanges();
        } else {
          undoChanges();
        }
      }
    },
    [undoChanges, redoChanges],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  /**
   * `update` can either be:
   *   - data which will be inserted into the idea at the path
   *   - a function which will be called with the current idea and
   *     should only return the data to be inserted at the path
   */
  const updateIdea = useCallback(
    (update, path, suppressSave = false, callback = () => {}) => {
      if (update === true && path.includes("isSubmitted")) {
        websocketSubscription.current?.publish("ideaSubmitted");
      }

      let prevIdea = null;
      let updatedIdea = null;
      setIdeaState((prevState) => {
        prevIdea = prevState.idea;

        if (typeof update === "function") {
          update = update(prevIdea);
        }
        updatedIdea = reducePath(prevIdea, update, path);

        callback(updatedIdea);
        updatedAt.current = new Date();
        return {
          ...prevState,
          unsaved: suppressSave ? prevState.unsaved : true,
          idea: updatedIdea,
        };
      });

      if (!suppressSave && editTimeout.current !== null) {
        clearTimeout(editTimeout.current);
        editTimeout.current = null;
      }
      editTimeout.current = setTimeout(() => {
        if (!suppressSave) {
          saveIdea(updatedIdea);
          websocketSubscription.current?.publish("fieldEdited", { fieldId: path, fieldData: update });
          /*
          If current history index is < 0, and we're making an edit
          Then the user is continuing from an old edit (i.e. they undid a few times, then started editing again)
          Therefore the history state should be reset, with the new state
          The history index should also be reset to 0
          Else we just add to the history
        */
          addHistoryEntry(prevIdea);
          setHistoryState((prevHistory) => ({ ...prevHistory, redo: [] }));
        }
      }, 400);
    },
    [saveIdea, addHistoryEntry, editTimeout],
  );

  const handleWebsocket = useCallback(
    (data) => {
      const { channel, fieldId, fieldData, toUser } = data;
      // Discard any messages that were sent by the current user
      if ((toUser && toUser !== userId) || (data.user && data.user._id === userId)) {
        return;
      }

      switch (channel) {
        // Make sure that if a user opens an idea they get the proper response back if someone else was already in it to begin with
        case "userSubscribed":
          websocketSubscription.current?.publish("userWasSubscribed", {
            toUser: data.user._id,
          });
        // eslinst-disable-line no-fallthrough
        case "userWasSubscribed":
          setWebsocketState((prevState) => ({
            ...prevState,
            otherUsers: { ...prevState.otherUsers, [data.user._id]: data.user },
          }));
          break;
        case "userUnsubscribed":
          setWebsocketState((prevState) => {
            const externalChanges = { ...(prevState.externalChanges || {}) };

            const updateDeep = (changes) => {
              Object.keys(changes).forEach((field) => {
                if (Array.isArray(changes[field])) {
                  changes[field] = [...changes[field].filter((u) => u._id !== data.user._id)];
                } else {
                  changes[field] = { ...updateDeep(changes[field]) };
                }
              });
              return changes;
            };
            updateDeep(externalChanges);

            // eslint-disable-next-line no-unused-vars
            const { [data.user._id]: delUser, ...keepUsers } = prevState.otherUsers;
            return { ...prevState, otherUsers: keepUsers, externalChanges };
          });
          break;
        case "ideaSubmitted":
          getIdea();
          toast(`${data.user.name} submitted the ${t("generic.idea")}.`);
          break;
        case "fieldEdited":
          setWebsocketState((prevState) => {
            const externalChanges = { ...(prevState.externalChanges || {}) };
            let change = externalChanges;
            fieldId.forEach((field, i) => {
              if (i < fieldId.length - 1) {
                change[field] = { ...(change[field] || {}) };
                change = change[field];
                return;
              }

              change[field] = [...(change[field] || [])];
              const index = change[field].findIndex((u) => u._id === data.user._id);
              if (index === -1) {
                change[field].push({ ...data.user, isEditing: { fieldId, fieldData } });
              } else {
                change[field][index] = { ...data.user, isEditing: { fieldId, fieldData } };
              }
            });

            return {
              ...prevState,
              otherUsers: {
                ...prevState.otherUsers,
                [data.user._id]: data.user,
              },
              externalChanges,
            };
          });
          updateIdea(fieldData, fieldId, true, () => {}, false);
          break;
        case "__websocketError":
          setWebsocketState((prevState) => ({ ...prevState, websocketConnected: false }));
          break;
        default:
          break;
      }
    },
    [getIdea, userId, updateIdea, t],
  );
  websocketSubscriptionHandler.current = handleWebsocket;

  // set up websocket connection for idea collaboration
  useEffect(() => {
    if (!ideaId) return;
    if (websocketSubscription.current) {
      // No change in collaborator status
      if (websocketSubscription.current.options.quiet === !isCollaborator) {
        return;
      }
      // Otherwise we need to unsub current subscription and resub with new options
      websocketSubscription.current.unsubscribe();
    }
    websocketSubscription.current = websocketApi.subscribe(
      `idea-${ideaId}`,
      (data) => websocketSubscriptionHandler.current(data),
      {
        receiveUserUpdates: true,
        receiveErrorUpdates: true,
        quiet: !isCollaborator,
      },
    );
  }, [handleWebsocket, ideaId, isCollaborator]);

  useEffect(() => {
    return () => {
      if (websocketSubscription.current) {
        websocketSubscription.current.unsubscribe();
        websocketSubscription.current = null;
      }
    };
  }, []);

  return {
    ideaState,
    updateIdea,
    reloadIdea: getIdea,
    undoChanges,
    redoChanges,
    canUndo: historyState.undo.length > 0,
    canRedo: historyState.redo.length > 0,
    otherUsers: websocketState.otherUsers,
    websocketConnected: websocketState.websocketConnected,
    externalChanges: websocketState.externalChanges,
  };
};

export default useIdeaEditorHook;
