import React, { useState, useEffect } from "react";
import { regex as mentionRegex } from "components/lib/FormattedComment";
import SimplePreset from "./presets/simple/simple";
import { useTranslation } from "react-i18next";

import { useGetMentions } from "./getMentions";
import { BasicRichText } from "./BasicRichText";

const constructText = (data) => {
  const { content } = data;
  if (content) {
    const separator = data.type === "paragraph" ? "" : "\n";
    return content.map(constructText).join(separator);
  }

  const { text, type, attrs, marks = [] } = data;
  switch (type) {
    case "mentionAtom":
      switch (attrs.name) {
        case "users":
          return `(@)[${attrs.label}](_id:${attrs.id})`;
        case "ideas":
        case "challenges":
        case "groups":
          return `($${attrs.name})[${attrs.label}](_id:${attrs.id})`;
        default:
          return attrs.label;
      }
    case "text":
      // In the instance that we automatically populated a mention into the box
      // The Richtext editor can get confused with priority, and think it's a link instead of a mention
      // To compensate, we allow links to include the mention attributes - atom-name and atom-id
      // We then test for these, and if so, update the HTML to reflect the text as a mention rather than just text
      if (marks.length) {
        const embeddedMention = marks[0];
        if (embeddedMention.type === "link" && embeddedMention.attrs["data-mention-atom-name"] === "users") {
          return `(@)[${text}](_id:${embeddedMention.attrs["data-mention-atom-id"]})`;
        }
      }
      return text;
    default:
      return text;
  }
};

const getMentionCaptureGroups = (text) => {
  const executedRegex = mentionRegex.exec(text);
  if (!executedRegex || executedRegex.length < 4) return {};
  return {
    fullName: executedRegex[2],
    _id: executedRegex[3],
  };
};

const insertionString = "<?insert!>";

const hasExternallyInsertedMention = (text) => text.indexOf("(@)") !== -1 && text.endsWith(insertionString);

type MentionTextProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  editorRef?: React.RefObject<any>;
};

const MentionText = ({ value, onChange, placeholder, editorRef }: MentionTextProps) => {
  const [mentions, getMentions] = useGetMentions();
  const [externalValue, setExternalValue] = useState(value || "");
  const [currentValue, setCurrentValue] = useState(value || "");
  const [currentHTML, setCurrentHTML] = useState(value || "<p></p>");

  const { t } = useTranslation();

  useEffect(() => {
    if (value === "") {
      setCurrentValue("");
      setCurrentHTML("<p></p>");
    }

    if (!currentValue.endsWith("@") && !externalValue.endsWith("@") && value.endsWith("@")) {
      const replace = currentValue.endsWith(" ") || currentValue.length === 0 ? "@</p>" : " @</p>";
      setCurrentHTML(currentHTML.replace(new RegExp("</p>$"), replace));
    } else if (
      !hasExternallyInsertedMention(currentValue) &&
      !hasExternallyInsertedMention(externalValue) &&
      hasExternallyInsertedMention(value)
    ) {
      const replacedInsertionString = value.replace(insertionString, "");
      const mentionCaptureGroup = getMentionCaptureGroups(value);
      const replacedMention = replacedInsertionString.replace(
        mentionRegex,
        `<a target="_blank" href="/users/${mentionCaptureGroup._id}" class="mention mention-users" rel="noopener noreferrer nofollow" data-mention-atom-id="${mentionCaptureGroup._id}" data-mention-atom-name="users">${mentionCaptureGroup.fullName}</a>`,
      );
      setCurrentValue(replacedInsertionString);
      setCurrentHTML(
        currentHTML.replace(
          "</p>",
          (!currentValue || currentValue.endsWith(" ") ? "" : " ") + replacedMention + "</p>",
        ),
      );
    }
    setExternalValue(value);
  }, [currentValue, externalValue, currentHTML, value]);

  return (
    <BasicRichText
      placeholder={placeholder || t("mention.guidance")}
      value={currentHTML}
      onChange={(newValue) => {
        setCurrentHTML(newValue);
      }}
      onChangeJson={(newValue) => {
        const constructed = constructText(newValue);
        setCurrentValue(constructed);
        if (onChange) {
          onChange(constructed);
        }
      }}
      mentions={mentions}
      // @ts-ignore
      onSuggest={(kind, query) => getMentions(kind, query)}
      options={SimplePreset}
      hideMenu
      noShadow
      editorRef={editorRef}
    />
  );
};

export default React.memo(MentionText);
