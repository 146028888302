import React from "react";
import { Card, Icon, Label, Divider } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import util from "utils/utils";
import moment from "moment";

import { UserChip, ProfileChip, AnonymousChip } from "components/lib/Chips";
import { ShadowedCard } from "components/lib/UI";
import { ImageWithFallback } from "../ImageWithFallback";
import { OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";

const ChallengeMeta = styled.span`
  color: gray;
  display: block;
  margin: 2.5px 0;
  .i.icon {
    margin-right: 5px;
  }
`;

const MiniIdeaCard = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 8px;
  cursor: pointer;
  margin-top: 2px;

  &:hover {
    background-color: #e9ebee;
  }
  > img {
    height: 30px;
    width: 40px;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 8px;
  }
  > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .title {
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.9em;
      line-height: 1.3em;
    }
    > div {
      line-height: 0.9em;
      display: flex;
      align-items: center;
      > span {
        color: gray;
        font-size: 0.9em;
      }
    }
  }
`;

type ChallengeCardProps = {
  challenge: OpenAPI.GET<"/challenges/all">["response"]["challenges"][number];
  isNew?: boolean;
  ideas?: OpenAPI.Schemas["Idea"][];
  showIdeas?: boolean;
  buttonText?: string;
};

const ChallengeCard = ({ challenge, isNew, ideas, showIdeas, buttonText }: ChallengeCardProps) => {
  const user = useAppSelector((state) => state.user);
  const userIdeas = (ideas || []).filter((i) => i.user === user._id);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <ShadowedCard>
      <Link to={util.challengeLink(challenge)}>
        <div
          style={{
            width: "100%",
            height: "75px",
            overflow: "hidden",
            backgroundImage: `url(${util.mixinCssUrlFallback(util.getImageUrl(challenge, ["bannerImage", "coverImage"], ["640x100", "300x300"]), util.challengeImage())})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
          }}
        >
          {challenge.stage === "published" && (
            <Label size="small" color="green" style={{ marginTop: 7, marginLeft: -14 }} ribbon="right">
              {t("generic.open")}
            </Label>
          )}
          {!challenge.stage && (
            <Label size="small" style={{ marginTop: 7, marginLeft: -14 }} color="yellow" ribbon="right">
              {t("generic.draft")}
            </Label>
          )}
          {challenge.stage === "closed" && (
            <>
              <br />
              <Label
                size="small"
                style={{
                  marginTop: 7,
                  marginLeft: -14,
                }}
                color="red"
                ribbon="right"
              >
                {t("generic.closed")}
              </Label>
            </>
          )}

          {challenge.stage === "published" && challenge.deadline && !isNew && (
            <>
              <br />
              <Label size="small" ribbon="right" style={{ marginTop: 7, marginLeft: -14 }} color="grey">
                {t("generic.closes")} {moment(challenge.deadline).fromNow()}
              </Label>
            </>
          )}

          {isNew && (
            <>
              <br />
              <Label size="small" color="blue" style={{ marginTop: 7, marginLeft: -14 }} ribbon="right">
                New
              </Label>
            </>
          )}
        </div>
      </Link>

      <Card.Content style={{ position: "relative" }}>
        {challenge?.coverImageUrl && challenge.bannerImageUrl ? (
          <>
            <div
              style={{
                width: 80,
                height: 80,
                overflow: "hidden",
                position: "absolute",
                marginTop: -80,
                boxShadow: "rgb(0 0 0 / 30%) 0.5px 2px 10px 4px",
                border: "2px solid white",
                backgroundImage: `url(${util.mixinCssUrlFallback(util.challengeImage(challenge), util.challengeImage())})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                borderRadius: 7,
              }}
            />
            <h3 style={{ marginBottom: challenge.tagline ? 5 : null, marginTop: 5 }}>
              <Link
                data-testid="challenge-card-name-link"
                to={util.challengeLink(challenge)}
                style={{ color: "#4d4949" }}
              >
                {challenge.name}
              </Link>
            </h3>
          </>
        ) : (
          <h3 style={{ display: "block", flex: 1, marginBottom: challenge.tagline || userIdeas?.length ? 5 : null }}>
            <Link to={util.challengeLink(challenge)} style={{ color: "#4d4949" }}>
              {challenge.name}
            </Link>
          </h3>
        )}

        {challenge.tagline ? <ChallengeMeta>{challenge.tagline}</ChallengeMeta> : null}
        {userIdeas.length && !showIdeas ? (
          <ChallengeMeta>
            You have {util.pluralise(userIdeas.length, `${t("generic.idea")}`, `${t("generic.ideas")}`)}
          </ChallengeMeta>
        ) : null}
        <div>
          {challenge.anonymous && <AnonymousChip />}
          {!challenge.anonymous &&
            challenge.postedBy &&
            (challenge.profile ? (
              <ProfileChip profile={challenge.postedBy} />
            ) : (
              <UserChip precedingText={t("challenge.postedBy")} user={challenge.postedBy} />
            ))}
        </div>
      </Card.Content>

      {showIdeas && userIdeas.length ? (
        <div>
          <Divider horizontal fitted style={{ color: "gray", fontSize: "0.8em" }}>
            {t("challenge.yourIdeas.title")}
          </Divider>
          {userIdeas.map((idea) => (
            <MiniIdeaCard
              key={idea._id}
              color={idea.isSubmitted ? "green" : "beige"}
              onClick={() => navigate(`/ideas/${idea._id}`)}
            >
              <ImageWithFallback src={util.ideaCoverImage(idea)} fallbackSrc={util.ideaCoverImage()} alt="" />
              <div>
                <span className="title">{idea.name}</span>
                <div>
                  <span>
                    {idea.isSubmitted
                      ? `Submitted ${moment(idea.submittedAt).format("DD/MM")}`
                      : `Updated ${moment(idea.updatedAt).format("DD/MM")}`}
                  </span>
                </div>
              </div>
            </MiniIdeaCard>
          ))}
          {challenge?.ideaCount > 1 ? (
            <div style={{ color: "gray", fontSize: "0.9em", textAlign: "right", paddingRight: 8 }}>
              +{challenge.ideaCount - userIdeas.length} {t("generic.more")}
            </div>
          ) : (
            <div style={{ height: 8 }} />
          )}
        </div>
      ) : null}

      <div
        className="content"
        style={{
          backgroundColor: theme.primaryColour,
          textAlign: "center",
          fontWeight: 600,
          maxHeight: 50,
        }}
      >
        <Link to={util.challengeLink(challenge)} style={{ color: "#fff", textTransform: "uppercase" }}>
          {buttonText || t("generic.viewType", { entity: t("generic.challenge") })}{" "}
          <Icon name="arrow alternate circle right outline" />
        </Link>
      </div>
    </ShadowedCard>
  );
};

export default ChallengeCard;
