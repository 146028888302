import React, { MouseEventHandler, useMemo } from "react";
import { Icon as SemanticIcon, SemanticICONS, Popup, Loader } from "semantic-ui-react";
import styled from "styled-components";

export const HoverActions = styled.div<{ $width?: number }>`
  position: absolute;
  padding: 10px 10px 50px;
  pointer-events: none;
  background-color: transparent;
  right: 100%;
  min-width: 300px;
  max-height: calc(100% + 10px);
  overflow-y: auto;
  .hover-actions-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-height: calc(100% + 10px);
    width: 100%;
  }
  ${({ $width, theme }) =>
    theme.sizes.isComputer &&
    `
    width: calc(100vw - ${$width}px - 10px);
  `}
`;

export const HoverActionButton = styled.div<{ $margined?: boolean }>`
  user-select: none;
  pointer-events: auto;
  background-color: rgba(18, 19, 19, 0.9);
  ${({ onClick }) =>
    !!onClick &&
    `
    cursor: pointer;
  `}
  display: flex;
  border-radius: 5px;
  padding: 7px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: ${({ $margined }) => ($margined ? "30px" : null)};
  i {
    margin-left: 3px;
    margin-bottom: 4px;
    color: #fff;
  }
  span {
    color: #fff;
    display: block;
    font-size: 19px;
  }
`;

export const HoverActionContainer = styled(HoverActionButton)<{ $disabled?: boolean }>`
  ${({ $disabled }) =>
    $disabled
      ? `
    filter: contrast(0.6);
  `
      : `
      &:hover {
        transform: translateX(-5px);
        transition-duration: 0.1s;
        transition-timing-function: ease-in;
      }
      transition-duration: 0.1s;
      transition-timing-function: ease-out;
    `}
`;

export const HoverActionsContentBox = styled.div<{
  $large?: boolean;
  $margined?: boolean;
  $isOpen?: boolean;
  $noClick?: boolean;
}>`
  pointer-events: auto;
  margin-top: ${({ $margined, theme }) => {
    if (theme.sizes.isMobile && $margined) {
      return "15px";
    }
    if (theme.sizes.isMobile || $margined) {
      return "30px";
    }
    return null;
  }};
  width: 100%;
  margin-bottom: 10px;
  ${HoverActionButton} {
    ${({ $isOpen }) =>
      $isOpen &&
      `
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
    margin-bottom: 0;
    ${({ $noClick }) =>
      $noClick &&
      `
      cursor: default;
    `}
    justify-content: space-between;
    ${({ $noClick }) =>
      !$noClick &&
      `
      i {
        cursor: pointer;
      }
    `}
  }
  .fake-icon {
    width: 16px;
  }
  &&&& {
    .accordion .title,
    .accordion .content {
      padding: 0;
    }
  }
  .content-box {
    background-color: #fff;
    padding: 10px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    ${({ theme, $large }) =>
      !theme.sizes.isMobile &&
      `
      max-height: ${$large ? "700px" : "500px"};
    `}
    ${({ $large }) => $large && "height: 500px;"}
    overflow-y: auto;
    .title {
      font-weight: 600;
      display: block;
      margin-bottom: 10px;
    }
    > .content-item {
      display: flex;
      margin-bottom: 10px;
    }
  }
`;

type HoverActionProps = {
  children: React.ReactNode;
  icon?: SemanticICONS;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  margined?: boolean;
  loading?: boolean;
  target?: string;
  rel?: string;
  popupContent?: React.ReactNode;
  popupPosition?:
    | "top left"
    | "top right"
    | "bottom right"
    | "bottom left"
    | "right center"
    | "left center"
    | "top center"
    | "bottom center";
  style?: React.CSSProperties;
  href?: string;
  download?: string;
  as?: string;
};

export const HoverAction = ({
  children,
  icon,
  onClick,
  disabled,
  margined,
  target,
  rel,
  popupContent,
  popupPosition,
  style,
  loading,
  href,
  download,
  as,
}: HoverActionProps) => {
  const HoverActionComponent = useMemo(
    () => (
      <HoverActionContainer
        onClick={disabled ? null : onClick}
        // @ts-ignore (if as="a")
        href={href}
        target={target}
        rel={rel}
        as={as}
        download={download}
        $disabled={disabled}
        $margined={margined}
        style={style}
      >
        <span>{children}</span>
        <div style={{ marginLeft: 7 }}>
          {loading ? (
            <Loader size="tiny" active inline="centered" style={{ marginLeft: 4, marginRight: 3 }} />
          ) : (
            icon && <SemanticIcon name={icon} />
          )}
        </div>
      </HoverActionContainer>
    ),
    [children, disabled, icon, margined, onClick, style, loading, href, download, as, target, rel],
  );

  if (popupContent) {
    return (
      <Popup
        trigger={HoverActionComponent}
        content={popupContent}
        on="hover"
        hoverable
        disabled={disabled}
        position={popupPosition}
      />
    );
  }
  return HoverActionComponent;
};
