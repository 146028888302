import { useMutation } from "@tanstack/react-query";
import api, { asMutation } from "api";
import { useCallback, useState } from "react";
import { Button, Divider, Form, Grid, Icon, Message, Segment } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";
import { styled } from "styled-components";
import { countries, regions } from "utils/countries";
import useThrottle from "utils/useThrottle";
import util from "utils/utils";
import uuid from "uuid";

type ContactProps = {
  businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
  onSave?: (businessProfile: OpenAPI.Schemas["IdeaBusinessProfile"]) => void;
};

export const ContactLayout = ({ contact }: { contact: OpenAPI.Schemas["IdeaBusinessProfile"]["contacts"][0] }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
      <div>
        <b>{contact.fullName}</b>
        <p>{contact.jobTitle}</p>
      </div>
      <Divider fitted />
      <div>
        <Icon name="mail" />
        {contact.email || <span style={{ color: "#9a9a9a" }}>No email</span>}
      </div>
      <div>
        <Icon name="phone" />
        {contact.phoneNumber || <span style={{ color: "#9a9a9a" }}>No phone number</span>}
      </div>
    </div>
  );
};

export const AddressLayout = ({ address }: { address: OpenAPI.Schemas["IdeaBusinessProfile"]["addresses"][0] }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
      <p style={{ marginBottom: 0 }}>
        <Icon name="map pin" />
        {address?.streetAddress || <span style={{ color: "#9a9a9a" }}>No street address</span>}
      </p>
      <div style={{ marginLeft: 20 }}>
        {address?.country ? (
          <p style={{ marginBottom: 4 }}>
            {countries.find((c) => c.iso2 === address.country)?.name}{" "}
            {address.region ? <span>- {regions.find((r) => r.value === address.region)?.name}</span> : ""}
          </p>
        ) : (
          <span style={{ color: "#9a9a9a" }}>No country</span>
        )}
        <p style={{ marginBottom: 4 }}>{address?.postcode || <span style={{ color: "#9a9a9a" }}>No postcode</span>}</p>
        <p style={{ marginBottom: 4 }}>{address?.city || <span style={{ color: "#9a9a9a" }}>No city</span>}</p>
      </div>
    </div>
  );
};

const MinInput = styled(Form.Input)`
  min-width: 0;
`;

const Contact = ({ businessProfile, onSave }: ContactProps) => {
  const [editingContact, setEditingContact] = useState<{
    id: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    jobTitle: string;
  } | null>(null);
  const [editingAddress, setEditingAddress] = useState<{
    id: string;
    streetAddress: string;
    city: string;
    postcode: string;
    country: string;
    region: string;
  } | null>(null);
  const { address, contacts, addresses } = businessProfile || {};

  const user = useAppSelector((state) => state.user);

  const canEditProfile = util.hasPermission(user, "ideaBusinessProfile.editDetails", businessProfile._id);

  const updateProfileMutation = useMutation({
    mutationKey: ["post", "users", "profiles", businessProfile._id],
    mutationFn: asMutation(api.users.updateProfile)!,
    onSuccess: (data) => {
      onSave?.(data.ideaBusinessProfile);
    },
  });
  const throttledUpdate = useThrottle(updateProfileMutation.mutate, 500, [updateProfileMutation.mutate]);

  const updateProfile = useCallback(
    (data: Partial<OpenAPI.POST<"/users/profiles/{business_profile_id}">["requestBody"]>) => {
      api.queryClient.setQueryData<OpenAPI.GET<"/users/profiles/{business_profile_id}">["response"]>(
        ["get", "users", "profiles", businessProfile._id],
        (oldData) => ({
          ideaBusinessProfile: {
            ...oldData.ideaBusinessProfile,
            ...data,
          },
        }),
      );

      throttledUpdate({
        params: [businessProfile._id, data],
      });
    },
    [throttledUpdate, businessProfile._id],
  );

  return (
    <Grid stackable columns={2}>
      <Grid.Column>
        <h4 style={{ marginBottom: 0 }}>
          Contacts
          {canEditProfile ? (
            <Icon name="asterisk" style={{ position: "relative", top: -5, marginLeft: 3 }} color="red" size="tiny" />
          ) : null}
        </h4>
        <p style={{ color: "grey", marginTop: 5 }}>These people are affiliated with your business.</p>
        {contacts?.map((contact) => (
          <Segment key={contact.id} padded>
            {editingContact?.id === contact.id ? (
              <>
                <Form>
                  <h4>Updating contact details</h4>
                  <Form.Input
                    placeholder="Brian McBrain"
                    label="Full name"
                    required
                    defaultValue={contact.fullName}
                    onChange={(e, { value }) =>
                      setEditingContact((prev) => ({
                        ...prev,
                        fullName: value,
                      }))
                    }
                  />
                  <Form.Input
                    placeholder="Engineer"
                    label="Position or job title"
                    required
                    defaultValue={contact.jobTitle}
                    onChange={(e, { value }) =>
                      setEditingContact((prev) => ({
                        ...prev,
                        jobTitle: value,
                      }))
                    }
                  />
                  <Form.Group widths="equal">
                    <MinInput
                      placeholder="+44 0000 000000"
                      label="Phone number"
                      required
                      defaultValue={contact.phoneNumber}
                      onChange={(e, { value }) =>
                        setEditingContact((prev) => ({
                          ...prev,
                          phoneNumber: value,
                        }))
                      }
                    />
                    <MinInput
                      placeholder="brian@simplydo.co.uk"
                      required
                      label="Contact email"
                      defaultValue={contact.email}
                      onChange={(e, { value }) =>
                        setEditingContact((prev) => ({
                          ...prev,
                          email: value,
                        }))
                      }
                    />
                  </Form.Group>
                </Form>
                <div
                  style={{
                    justifySelf: "flex-end",
                  }}
                >
                  <Button
                    size="tiny"
                    secondary
                    onClick={() => {
                      updateProfile({
                        contacts: contacts.map((c) => (c.id === editingContact.id ? editingContact : c)),
                      });
                      setEditingContact(null);
                    }}
                  >
                    <Icon name="check" />
                    Save contact
                  </Button>
                </div>
              </>
            ) : (
              <>
                <ContactLayout contact={contact} />
                {canEditProfile ? (
                  <div
                    style={{
                      justifySelf: "flex-end",
                      marginTop: 6,
                    }}
                  >
                    <Button
                      size="tiny"
                      onClick={() => {
                        // @ts-ignore
                        setEditingContact(contact);
                      }}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>
                    <Button
                      icon
                      basic
                      size="tiny"
                      onClick={() => {
                        util
                          .confirm("Are you sure you want to delete this contact?", "This action cannot be undone.")
                          .then(() => {
                            updateProfile({
                              contacts: contacts.filter((c) => c.id !== contact.id),
                            });
                          })
                          .catch(() => {});
                      }}
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                ) : null}
              </>
            )}
          </Segment>
        ))}
        {editingContact === null && canEditProfile ? (
          <>
            {!contacts?.length ? (
              <Message info>
                <Message.Header>No contacts</Message.Header>
                <p>Please add at least one person as a primary contact for your business.</p>
              </Message>
            ) : null}
            <Button
              secondary
              onClick={() => {
                const contact = {
                  id: uuid.v4(),
                  fullName: "",
                  email: "",
                  phoneNumber: "",
                  jobTitle: "",
                };
                if (!contacts?.length) {
                  contact.fullName = user.profile.fullName;
                  contact.jobTitle = user.profile.jobTitle ?? "";
                  contact.email = user.emails[0].address;
                  contact.phoneNumber = user.phoneNumbers?.[0]?.number ?? "";
                }
                updateProfile({
                  contacts: [...(contacts || []), contact],
                });
                setEditingContact(contact);
              }}
            >
              <Icon name="plus" />
              Add contact
            </Button>
          </>
        ) : null}
      </Grid.Column>
      <Grid.Column>
        <h4 style={{ marginBottom: 0 }}>
          Addresses
          {canEditProfile ? (
            <Icon name="asterisk" style={{ position: "relative", top: -5, marginLeft: 3 }} color="red" size="tiny" />
          ) : null}
        </h4>
        <p style={{ color: "grey", marginTop: 5 }}>These are the addresses associated with your business.</p>
        <Segment padded>
          <h5>Registered address</h5>
          <span style={{ color: "grey" }}>This address can be edited from the Profile tab</span>
          <Divider />
          <AddressLayout address={address} />
        </Segment>
        {addresses?.map((address) => (
          <Segment key={address.id} padded>
            {editingAddress?.id === address.id ? (
              <>
                <Form>
                  <h4>Updating address details</h4>
                  <Form.Input
                    label="Street address"
                    required
                    defaultValue={address.streetAddress}
                    onChange={(e, { value }) =>
                      setEditingAddress((prev) => ({
                        ...prev,
                        streetAddress: value,
                      }))
                    }
                  />
                  <Form.Select
                    search
                    clearable
                    required
                    label="Country"
                    defaultValue={address.country}
                    onChange={(e, { value }) =>
                      setEditingAddress((prev) => ({
                        ...prev,
                        country: value as string,
                        region: value !== "GB" ? "" : address?.region,
                      }))
                    }
                    placeholder="Select one"
                    options={countries.map((c) => ({
                      key: c.iso2,
                      flag: c.iso2.toLowerCase(),
                      value: c.iso2,
                      text: c.name,
                    }))}
                  />
                  {editingAddress.country === "GB" ||
                  editingAddress.country === "England" ||
                  editingAddress.country === "United Kingdom" ? (
                    <Form.Select
                      search
                      required
                      clearable
                      label="Region"
                      defaultValue={address.region}
                      onChange={(e, { value }) =>
                        setEditingAddress((prev) => ({
                          ...prev,
                          region: value as string,
                        }))
                      }
                      options={regions.map((r) => ({
                        key: r.value,
                        value: r.value,
                        text: r.name,
                      }))}
                    />
                  ) : null}
                  <Form.Group widths="equal">
                    <MinInput
                      label="City"
                      required
                      defaultValue={address.city}
                      onChange={(e, { value }) =>
                        setEditingAddress((prev) => ({
                          ...prev,
                          city: value,
                        }))
                      }
                    />
                    <MinInput
                      label="Postcode"
                      required
                      defaultValue={address.postcode}
                      onChange={(e, { value }) =>
                        setEditingAddress((prev) => ({
                          ...prev,
                          postcode: value,
                        }))
                      }
                    />
                  </Form.Group>
                </Form>
                <div
                  style={{
                    justifySelf: "flex-end",
                  }}
                >
                  <Button
                    size="tiny"
                    secondary
                    onClick={() => {
                      updateProfile({
                        addresses: addresses.map((a) => (a.id === editingAddress.id ? editingAddress : a)),
                      });
                      setEditingAddress(null);
                    }}
                  >
                    <Icon name="check" />
                    Save address
                  </Button>
                </div>
              </>
            ) : (
              <>
                <AddressLayout address={address} />
                {canEditProfile ? (
                  <div
                    style={{
                      justifySelf: "flex-end",
                      marginTop: 6,
                    }}
                  >
                    <Button
                      size="tiny"
                      onClick={() => {
                        // @ts-ignore
                        setEditingAddress(address);
                      }}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>
                    <Button
                      icon
                      basic
                      size="tiny"
                      onClick={() => {
                        util
                          .confirm("Are you sure you want to delete this address?", "This action cannot be undone.")
                          .then(() => {
                            updateProfile({
                              addresses: addresses.filter((c) => c.id !== address.id),
                            });
                          })
                          .catch(() => {});
                      }}
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                ) : null}
              </>
            )}
          </Segment>
        ))}
        {editingAddress === null && canEditProfile ? (
          <>
            {!addresses?.length && !address ? (
              <Message info>
                <Message.Header>No addresses</Message.Header>
                <p>Please add at least one address as a registered address for your business.</p>
              </Message>
            ) : null}
            <Button
              secondary
              onClick={() => {
                const address = {
                  id: uuid.v4(),
                  streetAddress: "",
                  city: "",
                  postcode: "",
                  country: "",
                  region: "",
                };
                updateProfile({
                  addresses: [...(addresses || []), address],
                });
                setEditingAddress(address);
              }}
            >
              <Icon name="plus" />
              Add address
            </Button>
          </>
        ) : null}
      </Grid.Column>
    </Grid>
  );
};

export default Contact;
