import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, Popup, Icon, Tab } from "semantic-ui-react";
import styled from "styled-components";
import websocketApi from "api/websocket";
import CircularButtonWithNumber from "../CircularButtonWithNumber";
import { OpenAPI } from "simplydo/interfaces";
import useTheme from "theme/useTheme";
import Downloads from "./Downloads";
import { useQuery } from "@tanstack/react-query";
import Notifications from "./Notifications";
import api, { asQuery } from "api";
import { useAppSelector } from "store";

const StyledNotificationTray = styled.div`
  min-width: ${({ theme }) => (theme.sizes.isMobile ? "100%" : "500px;")};
  max-height: ${({ theme }) => (theme.sizes.isMobile ? null : "600px")} !important;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  z-index: 9999;
`;

type NotificationsType = OpenAPI.GET<`/notifications`>["response"]["notifications"];

type NotificationTrayProps = {
  isMobile?: boolean;
  style?: React.CSSProperties;
};

export const useTrayStatus = () => {
  type TrayStatus = {
    open: boolean;
    activeIndex: number;
  };

  const defaultState: TrayStatus = {
    open: false,
    activeIndex: 0,
  };
  const openState = useQuery({
    queryKey: ["notificationTray"],
    queryFn: async () => defaultState,
  });

  const setTrayStatus = useCallback((trayStatus: Partial<TrayStatus>) => {
    api.queryClient.setQueryData(["notificationTray"], (prevStatus: TrayStatus) => ({
      ...prevStatus,
      ...trayStatus,
    }));
  }, []);

  return [openState.data || defaultState, setTrayStatus] as const;
};

const NotificationTray = ({ isMobile, style }: NotificationTrayProps) => {
  const theme = useTheme();
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const [trayStatus, setTrayStatus] = useTrayStatus();
  const [invitations, setInvitations] = useState([]);
  const [notifications, setNotifications] = useState<NotificationsType>([]);
  const [total, setTotal] = useState(0);
  const [unseen, setUnseen] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [downloadCompleted, setDownloadCompleted] = useState(false);

  const userId = user?._id;
  // Non-acceptable invitations aren't really interesting to the user in terms of notification content. They may be able to remove them, but it's not really "notification" worthy that e.g., another admin has invited a user to one of their groups
  const acceptableInvitations = useMemo(
    () => invitations.filter((invitation) => !invitation.createdByCurrentUser && invitation.canAccept),
    [invitations],
  );

  const getInvitations = useCallback(() => {
    api.invitations.get(
      ({ invitations: newInvitations }) => setInvitations(newInvitations),
      () => {},
    );
  }, []);

  const getNotifications = useCallback((nextPage) => {
    setPage(nextPage);
    setLoading(true);
    api.notifications.get(
      nextPage,
      ({ total: newTotal, unseen: newUnseen, notifications: newNotifications }) => {
        setTotal(newTotal);
        setUnseen(newUnseen);
        setNotifications(newNotifications);
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
    );
  }, []);

  useEffect(() => {
    if (!userId) return;
    const notificationSubscription = websocketApi.subscribe(`pushNotification-${userId}`, () => {
      getNotifications(1);
    });
    return notificationSubscription.unsubscribe;
  }, [getNotifications, userId]);

  useEffect(() => {
    if (!userId) return;
    const downloadSubscription = websocketApi.subscribe(`downloadCompleted-${userId}`, () => {
      setDownloadCompleted(true);
      setTrayStatus({ open: true, activeIndex: 1 });
      api.queryClient.invalidateQueries({
        queryKey: ["get", "users", "downloads"],
      });
    });
    return downloadSubscription.unsubscribe;
  }, [userId, setTrayStatus]);

  useEffect(() => {
    getNotifications(1);
    getInvitations();
  }, [getNotifications, getInvitations]);

  const downloadsQuery = useQuery({
    queryKey: ["get", "users", "downloads"],
    queryFn: asQuery(api.users.getDownloads, {
      params: [user._id, { limit: 10, page: page }],
    }),
    refetchInterval: 10000,
  });

  const markAsSeen = useCallback((newNotifications, markAllAsSeen) => {
    api.notifications.markAsSeen(
      newNotifications,
      markAllAsSeen,
      () => {
        setNotifications((prev) =>
          prev.map((notification) => {
            if (markAllAsSeen || newNotifications.includes(notification._id)) {
              return { ...notification, seen: true };
            }
            return notification;
          }),
        );
      },
      () => {},
    );
  }, []);

  const markAllAsSeen = useCallback(() => {
    markAsSeen([], true);
  }, [markAsSeen]);

  const notificationContent = (
    <StyledNotificationTray>
      <Tab
        defaultActiveIndex={0}
        activeIndex={trayStatus.activeIndex}
        onTabChange={(e, { activeIndex }) => {
          setTrayStatus({ activeIndex: activeIndex as number });
        }}
        menu={{ secondary: true, pointing: true }}
        style={{ position: "relative", margin: 0 }}
        panes={[
          {
            menuItem: (
              <Menu.Item>
                <Icon name="bell" />
                Notifications
              </Menu.Item>
            ),
            render: () => (
              <Notifications
                notifications={notifications}
                acceptableInvitations={acceptableInvitations}
                invitations={invitations}
                setInvitations={setInvitations}
                setNotifications={setNotifications}
                loading={loading}
                page={page}
                total={total}
                getNotifications={getNotifications}
              />
            ),
          },
          {
            menuItem: (
              <Menu.Item>
                <Icon name="download" />
                Downloads
              </Menu.Item>
            ),
            render: () => <Downloads downloadCompleted={downloadCompleted} downloadsQuery={downloadsQuery} />,
          },
        ]}
      />
    </StyledNotificationTray>
  );

  if (isMobile) {
    return notificationContent;
  }
  return (
    <Popup
      on="click"
      style={{ width: "fit-content" }}
      positionFixed
      wide="very"
      position="bottom right"
      offset={[5, 0]}
      open={trayStatus.open}
      onClose={() => {
        setDownloadCompleted(false);
        setTrayStatus({ open: false });
      }}
      trigger={
        <div style={style}>
          <CircularButtonWithNumber
            onClick={() => {
              if (!trayStatus.open) {
                markAllAsSeen();
              }
              if (theme.sizes.isMobile) {
                if (location.pathname === "/notifications") {
                  navigate("..");
                } else {
                  navigate("/notifications");
                }
              } else {
                if (!trayStatus.open) {
                  api.queryClient.invalidateQueries({
                    queryKey: ["get", "users", "downloads"],
                  });
                }
                setDownloadCompleted(false);
                setTrayStatus({ open: !trayStatus.open });
              }
            }}
            icon="bell"
            count={unseen === 0 && acceptableInvitations.length !== 0 ? "●" : unseen}
          />
        </div>
      }
      content={notificationContent}
    />
  );
};

export default NotificationTray;
