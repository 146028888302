import { keyframes, styled } from "styled-components";

const dotPulseBeforeAnimation = ($size: number) =>
  keyframes`0% { box-shadow: ${9999 - $size * 1.5}px 0 0 -${$size / 2}px; } 30% { box-shadow: ${9999 - $size * 1.5}px 0 0 ${$size / 5}px; } 60%, 100% { box-shadow: ${9999 - $size * 1.5}px 0 0 -${$size / 2}px; }`;
const dotPulseAnimation = ($size: number) =>
  keyframes`0% { box-shadow: 9999px 0 0 -${$size / 2}px; } 30% { box-shadow: 9999px 0 0 ${$size / 5}px; } 60%, 100% { box-shadow: 9999px 0 0 -${$size / 2}px; }`;
const dotPulseAfterAnimation = ($size: number) =>
  keyframes`0% { box-shadow: ${9999 + $size * 1.5}px 0 0 -${$size / 2}px; } 30% { box-shadow: ${9999 + $size * 1.5}px 0 0 ${$size / 5}px; } 60%, 100% { box-shadow: ${9999 + $size * 1.5}px 0 0 -${$size / 2}px; }`;

const DotPulseInner = styled.div<{ $size: number; $color: string }>`
  position: relative;
  left: -9999px;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: ${({ $size }) => $size / 2}px;
  background-color: ${({ $color }) => $color};
  color: ${({ $color }) => $color};
  box-shadow: 9999px 0 0 -${({ $size }) => $size / 2}px;
  animation: ${({ $size }) => dotPulseAnimation($size)} 1.5s infinite linear;
  animation-delay: 0s;
  animation-delay: 0.25s;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    border-radius: ${({ $size }) => $size / 2}px;
    background-color: ${({ $color }) => $color};
    color: ${({ $color }) => $color};
  }
  &::before {
    box-shadow: ${({ $size }) => `${9999 - $size * 1.5}px 0 0 -${$size / 2}px`};
    animation: ${({ $size }) => dotPulseBeforeAnimation($size)} 1.5s infinite linear;
    animation-delay: 0s;
    animation-delay: 0s;
  }
  &::after {
    box-shadow: ${({ $size }) => `${9999 + $size * 1.5}px 0 0 -${$size / 2}px`};
    animation: ${({ $size }) => dotPulseAfterAnimation($size)} 1.5s infinite linear;
    animation-delay: 0s;
    animation-delay: 0.5s;
  }
`;

type DotPulseProps = {
  size?: number;
  style?: React.CSSProperties;
};

const DotPulse = ({ size = 10, style }: DotPulseProps) => {
  return (
    <div
      style={{
        ...(style ?? {}),
        width: size * 4,
      }}
    >
      <DotPulseInner style={{ marginLeft: size * 1.5 }} $size={size} $color="#cacaca" />
    </div>
  );
};

export default DotPulse;
