import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Button, Icon, Modal, Input, Dropdown, Table, Popup } from "semantic-ui-react";
import styled from "styled-components";
import api from "api";
import toast from "react-hot-toast";
import { useSearchCompanyDispatch } from "components/innovationintelligence/CompanyState/client";
import { updateCompany } from "components/innovationintelligence/CompanyState/companyReducer";
import { useAppSelector } from "store";
import { countries } from "utils/countries";

const CompanySearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  .input-area {
    display: flex;
    flex-direction: column;
    > span {
      display: block;
      font-size: 12px;
      font-weight: 600;
    }
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
`;

type ICompanyDetailsLocation = {
  country?: string;
  city?: string;
  addressLine1?: string;
  addressLine2?: string;
  postcode?: string;
  coordinates?: {
    latitude: number;
    longitude: number;
    bounds?: [{ latitude: number; longitude: number }, { latitude: number; longitude: number }];
    viewport?: [{ latitude: number; longitude: number }, { latitude: number; longitude: number }];
  };
};

type CreditsafeManualMatchProps = {
  companyId: string;
  name: string;
  location?: ICompanyDetailsLocation;
  isOpen?: boolean;
  onClose?: () => void;
  onError?: (errorMessage: string) => void;
  onReceiveCreditSafeReport?: (report: any) => void;
};

type CreditsafeCompanyResult = {
  id: string;
  name: string;
  address?: {
    city?: string;
    postCode?: string;
    simpleValue?: string;
  };
  country: string;
  createdAt: string;
  phoneNumbers?: string[];
  regNo: string;
  status: string;
  statusDescription: string;
  type: string;
};

const CreditsafeManualMatch = ({
  companyId,
  isOpen,
  onClose,
  name: propName,
  location: propLocation,
  onError,
  onReceiveCreditSafeReport,
}: CreditsafeManualMatchProps) => {
  const user = useAppSelector((state) => state.user);
  const [name, setName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [initialSearchDone, setInitialSearchDone] = useState<boolean>(false);
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [countrySearchTerm, setCountrySearchTerm] = useState<string>("");
  const [companies, setCompanies] = useState<CreditsafeCompanyResult[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");

  const dispatch = useSearchCompanyDispatch();

  const searchCompanies = useCallback(
    (newName = "", newLocation = "") => {
      setLoadingCompanies(true);
      setSelectedCompany("");
      if (newName) {
        setName(newName);
      }
      if (newLocation) {
        setLocation(newLocation);
      }
      api.verifications.searchForCompanies(
        newLocation || location,
        "",
        newName || name,
        "org",
        user.ownerOrganisation._id,
        ({ companies: newCompanies }) => {
          setCompanies(newCompanies);
          setLoadingCompanies(false);
        },
        (err) => {
          toast.error(err.message);
          setLoadingCompanies(false);
          if (onError) {
            onError(err.message);
          }
        },
        undefined,
      );
    },
    [location, name, onError, user.ownerOrganisation._id],
  );

  const getUpdatedCreditInformation = useCallback(() => {
    setLoadingReport(true);
    api.innovationIntelligence.getCreditsafeReport(
      companyId,
      selectedCompany,
      ({ didFindReport, updatedCreditsafe, report }) => {
        dispatch(
          updateCompany({
            _id: companyId,
            sources: {
              creditsafe: updatedCreditsafe,
            },
          }),
        );
        if (didFindReport) {
          toast.success("Credit information updated");
          setLoadingReport(false);
          onClose();
          if (onReceiveCreditSafeReport) {
            onReceiveCreditSafeReport(report);
          }
        } else {
          const errMessage = "Failed to get credit information. Please try again later";
          toast.error(errMessage);
          if (onError) {
            onError(errMessage);
          }
          setLoadingReport(false);
        }
      },
      (err) => {
        toast.error(err.message);
        setLoadingReport(false);
        if (onError) {
          onError(err.message);
        }
      },
    );
  }, [companyId, selectedCompany, dispatch, onClose, onReceiveCreditSafeReport, onError]);

  useEffect(() => {
    if (isOpen && !initialSearchDone) {
      setInitialSearchDone(true);
      let newLocation = "";
      setName(propName);
      if (propLocation?.country) {
        const countryNameLowerCase = propLocation.country.toLowerCase();
        const countryIso2 = countries.find(
          (country) => country.name.toLowerCase().indexOf(countryNameLowerCase) > -1,
        )?.iso2;
        if (countryIso2) {
          newLocation = countryIso2;
          setLocation(countryIso2);
        }
      }
      if (propName && newLocation) {
        searchCompanies(propName, newLocation);
      }
    }
  }, [propName, propLocation, searchCompanies, initialSearchDone, isOpen]);

  const filteredCountries = useMemo(() => {
    const searchTerm = countrySearchTerm.toLowerCase();
    return countries.filter((country) => country.name.toLowerCase().includes(searchTerm));
  }, [countrySearchTerm]);

  const CountryPartialMatchIcon = useMemo(
    () => (
      <Popup
        on="hover"
        content="This piece of information matches the original company"
        trigger={<Icon name="check circle" color="green" style={{ marginLeft: 5 }} />}
      />
    ),
    [],
  );

  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={isOpen} onClose={onClose}>
      <Modal.Header>
        <p>Find credit information for {propName}</p>
        <p style={{ fontWeight: 400, fontSize: 14 }}>
          We couldn't find credit information for this company automatically - Please search for an option below to link
          credit information.
        </p>
      </Modal.Header>
      <Modal.Content>
        <CompanySearchContainer>
          <div className="input-area">
            <span>Company name</span>
            <Input
              value={name}
              onChange={(e, { value }) => {
                setName(value);
              }}
              fluid
            />
          </div>

          <div className="input-area">
            <span>Country</span>
            <Dropdown
              fluid
              selection
              value={location}
              onChange={(e, { value }) => {
                setLocation(value as string);
                setCountrySearchTerm("");
              }}
              searchQuery={countrySearchTerm}
              search
              onSearchChange={(e, { searchQuery }) => setCountrySearchTerm(searchQuery)}
              options={filteredCountries.map((country) => ({
                key: country.iso2,
                value: country.iso2,
                text: country.name,
              }))}
            />
          </div>

          <div className="input-area">
            <Button
              fluid
              primary={!selectedCompany}
              icon={selectedCompany ? "check" : "search"}
              color={selectedCompany ? "green" : null}
              onClick={selectedCompany ? () => getUpdatedCreditInformation() : () => searchCompanies()}
              loading={loadingCompanies || loadingReport}
              disabled={loadingCompanies || loadingReport}
              content={selectedCompany ? "Get credit information for this result" : "Search"}
            />
          </div>

          {companies.length > 0 ? (
            <Table basic="very" selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Company name</Table.HeaderCell>
                  <Table.HeaderCell>Address</Table.HeaderCell>
                  <Table.HeaderCell>Reg no</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {companies.map((company) => (
                  <Table.Row
                    onClick={() => setSelectedCompany(selectedCompany === company.id ? "" : company.id)}
                    style={{ cursor: "pointer" }}
                    active={selectedCompany === company.id}
                  >
                    <Table.Cell>
                      {company.name}
                      {company.name.toLowerCase() === name.toLowerCase() ? CountryPartialMatchIcon : null}
                    </Table.Cell>
                    <Table.Cell>
                      {company?.address?.simpleValue}
                      {company?.address?.postCode?.toLowerCase() === propLocation?.postcode?.toLowerCase()
                        ? CountryPartialMatchIcon
                        : null}
                    </Table.Cell>
                    <Table.Cell>{company.regNo}</Table.Cell>
                    <Table.Cell>{company.statusDescription}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : null}
        </CompanySearchContainer>
      </Modal.Content>
    </Modal>
  );
};

export default CreditsafeManualMatch;
