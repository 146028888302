import React, { useState, useCallback } from "react";
import { Button, Input } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";
import styled from "styled-components";
import constants from "utils/constants";
import actions from "actions";
import api from "api";
import SearchParams from "simplydo/src/searchParams";

import TermsCheckbox from "components/lib/Entry/TermsCheckbox";
import PasswordGuidance from "components/lib/Entry/PasswordGuidance";
import OrganisationChooser from "./OrganisationChooser";

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-self: center;
  margin: auto;
  ${({ theme }) =>
    !theme.sizes.isMobile
      ? `
    min-width: 500px;
    width: 50%;
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    border-radius: 5px;
    padding: 2rem;
  `
      : `
    padding: 0.5rem;
    margin: -30px -10px;
  `}
  ${({ theme }) =>
    !theme.sizes.isMobile &&
    `
    width: 75%;
  `}
  .ui.button {
    margin-top: 2rem;
  }
`;

const RegisterHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
  img {
    object-fit: contain;
    width: 100px;
    height: 75px;
  }
  h2 {
    margin: 0.5rem;
  }
`;

const DataEntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  position: relative;
  .title {
    font-weight: 600;
    margin-bottom: 5px;
    ${({ $required }) =>
      $required &&
      `
      &:after {
        padding-left: 5px;
        color: #cc202d;
        content: "*";
      }
    `}
  }
  .info {
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 5px;
    color: gray;
  }
  span.error {
    position: absolute;
    bottom: -19px;
    left: 5px;
    color: #cc202d;
    font-size: 11px;
  }
  .ui.input {
    width: 100%;
    /*
      > input {
        background-color: #FAFBFC;
        &:focus {
          background-color: #fff;
        }
      }
    */
  }
  .ui.input input {
    width: 100%;
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (!theme.sizes.isComputer ? "column" : "row")};
  ${DataEntryContainer} {
    ${({ theme }) =>
      !theme.sizes.isMobile &&
      `
      flex: 1;
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    `}
  }
`;

const StyledDivider = styled.div`
  height: 1px;
  background-color: lightgray;
  margin: 1rem 0 2rem;
`;

const DataEntry = ({ value, onChange, ref, title, placeholder, required, info, error, ...rest }) => (
  <DataEntryContainer $required={required}>
    <span className="title">{title}</span>
    <Input
      value={value}
      onChange={(e, { value: inputValue }) => onChange(inputValue)}
      ref={ref}
      placeholder={placeholder}
      error={error}
      {...rest}
    />
    {error ? <span className="error">{error}</span> : null}
    {info && <span className="info">{info}</span>}
  </DataEntryContainer>
);

const RegisterFlow = ({
  organisation,
  email,
  password,
  firstName,
  lastName,
  agreedTerms,
  challengeResponse,
  registering,
  showPassword,
  onUpdateEmail,
  onUpdatePassword,
  onUpdateFirstName,
  onUpdateLastName,
  onUpdateAgreedTerms,
  onUpdateRegistering,
  onUpdateShowPassword,
  t,
  utmLink,
  utmChallenge,
}) => {
  const [retypePassword, setRetypePassword] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const register = useCallback(() => {
    onUpdateRegistering(true);
    api.auth.verifyAccount(
      organisation?._id,
      email,
      challengeResponse,
      ({ exists }) => {
        if (exists) {
          toast.error("An account with that email already exists. Please login instead.");
          onUpdateRegistering(false);
        } else {
          const params = new SearchParams(location.search);
          const then = params.get("then") ?? "";
          const thenSplit = decodeURI(then).split("?");
          const thenParams = new SearchParams(thenSplit.length ? thenSplit[1] : "");
          const inviteToken = params.get("invite_token") ?? thenParams.get("invite_token");

          api.auth.register(
            organisation?._id,
            email,
            password,
            firstName,
            lastName,
            challengeResponse,
            agreedTerms ? constants.termsVersion : false,
            utmLink,
            utmChallenge,
            inviteToken,
            (addedToOrg) => {
              onUpdateRegistering(false);

              api.journey.record("users", null, "userRegistered");
              navigate(then || "/");
              window.scrollTo(0, 0);
              if (addedToOrg) toast(t("entry.register.success", { organisation }));
            },
            (err) => {
              toast.error(err.message);
              onUpdateRegistering(false);
            },
          );
        }
      },
      () => {
        toast.error("Failed to register your account. Please try again later.");
        onUpdateRegistering(false);
      },
    );
  }, [
    organisation,
    t,
    email,
    password,
    firstName,
    lastName,
    challengeResponse,
    agreedTerms,
    utmLink,
    utmChallenge,
    onUpdateRegistering,
    navigate,
    location,
  ]);

  let retypePasswordIcon = null;
  if (retypePassword) {
    if (password === retypePassword) {
      retypePasswordIcon = "check circle outline";
    } else {
      retypePasswordIcon = "times circle outline";
    }
  }

  const searchParams = new SearchParams(location.search);

  return (
    <>
      <Helmet title={"Register"} />
      {organisation ? (
        <RegisterContainer>
          <div>
            {t("entry.register.alreadyAccount")}{" "}
            <Link to={`/login?${searchParams.toString()}`}>{t("entry.register.signInInstead")}</Link>.
          </div>
          <RegisterHeader>
            <img src={organisation?.darkLogoUrl} alt="" />
            <h2>{t("entry.register.intro.title", { organisation })}</h2>
          </RegisterHeader>
          <DataEntry
            autoFocus
            title={t("entry.login.email")}
            placeholder="brian@simplydo.io"
            value={email}
            name="email"
            type="email"
            onChange={(value) => onUpdateEmail(value)}
            required
            autocomplete="off"
          />
          <NameContainer>
            <DataEntry
              title={t("users.edit.name.firstName")}
              placeholder="Brian"
              value={firstName}
              name="firstName"
              onChange={(value) => onUpdateFirstName(value)}
              required
            />
            <DataEntry
              title={t("users.edit.name.lastName")}
              placeholder="McBrain"
              value={lastName}
              name="lastName"
              onChange={(value) => onUpdateLastName(value)}
              required
            />
          </NameContainer>
          <DataEntry
            title={t("entry.login.password")}
            placeholder={t("entry.login.password")}
            name="password"
            value={password}
            icon={{
              name: showPassword ? "eye slash" : "eye",
              circular: true,
              link: true,
              onClick: () => onUpdateShowPassword(!showPassword),
            }}
            type={showPassword ? "text" : "password"}
            onChange={(value) => onUpdatePassword(value)}
            required
            autocomplete="off"
          />
          <DataEntry
            title={t("entry.login.passwordConfirm")}
            placeholder={t("entry.login.passwordConfirm")}
            name="retype-password"
            value={retypePassword}
            icon={{ name: retypePasswordIcon, style: { color: password === retypePassword ? "green" : "#cc202d" } }}
            type={showPassword ? "text" : "password"}
            onChange={(value) => setRetypePassword(value)}
            required
            error={retypePassword && password !== retypePassword ? "Those passwords do not match." : null}
            autocomplete="off"
          />
          <PasswordGuidance password={password} retypePassword={retypePassword} />
          <StyledDivider />
          <TermsCheckbox
            style={{ textAlign: "left" }}
            agreed={agreedTerms}
            onChange={(c) => onUpdateAgreedTerms(c ? constants.termsVersion : null)}
          />
          <Button
            primary
            disabled={
              !email ||
              !firstName ||
              !lastName ||
              !password ||
              !agreedTerms ||
              password.length < 8 ||
              password !== retypePassword
            }
            content={t("entry.register.createAccount")}
            fluid
            onClick={register}
            loading={registering}
          />
        </RegisterContainer>
      ) : (
        <div style={{ maxWidth: 450, display: "block", margin: "0px auto" }}>
          <OrganisationChooser />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { email, password, firstName, lastName, challengeResponse, agreedTerms, registering, loading, showPassword } =
    state.entry;
  return {
    organisation: state.organisations.urlOrganisation,
    email,
    firstName,
    lastName,
    password,
    agreedTerms,
    challengeResponse,
    registering,
    loading,
    showPassword,
    utmLink: state.auth.utmLink,
    utmChallenge: state.auth.utmChallenge,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onUpdateFirstName: (n) => dispatch(actions.entry.updateFirstName(n)),
  onUpdateLastName: (n) => dispatch(actions.entry.updateLastName(n)),
  onUpdateEmail: (e) => dispatch(actions.entry.updateEmail(e)),
  onUpdatePassword: (p) => dispatch(actions.entry.updatePassword(p)),
  onUpdateAgreedTerms: (a) => dispatch(actions.entry.updateAgreedTerms(a)),
  onUpdateChallengeResponse: (r) => dispatch(actions.entry.updateChallengeResponse(r)),
  onUpdateRegistering: (r) => dispatch(actions.entry.updateRegistering(r)),
  onUpdateShowPassword: (s) => dispatch(actions.entry.updateShowPassword(s)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RegisterFlow));
