import React, { useEffect, useMemo, useState } from "react";
import { Button, Divider, Icon } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import { useAppSelector } from "store";
import { Link } from "react-router-dom";
import util from "utils/utils";

import RowComponent from "components/lib/RowComponent";
import api from "api";

const HomeInnovationIntelligence = () => {
  const user = useAppSelector((state) => state.user);

  const [myLists, setMyLists] = useState<OpenAPI.Schemas["InnovationIntelligenceList"][]>([]);
  const [sharedLists, setSharedLists] = useState<OpenAPI.Schemas["InnovationIntelligenceList"][]>([]);
  const [dashboards, setDashboards] = useState([]);

  const innoIntelEnabled = useMemo(
    () =>
      util.organisationFeaturesEnabled(user, ["innovationIntelligence"]) &&
      util.hasPermission(user, "org.innovationIntelligence", user.ownerOrganisation._id),
    [user],
  );

  useEffect(() => {
    if (innoIntelEnabled) {
      api.innovationIntelligence.getLists(
        ({ userLists, sharedWith }) => {
          setMyLists(userLists);
          setSharedLists(sharedWith);
        },
        () => {},
      );
    }
  }, [innoIntelEnabled]);

  useEffect(() => {
    if (innoIntelEnabled) {
      api.innovationIntelligence.getDashboards(
        ({ dashboards: fetchedDashboards }) => {
          setDashboards(fetchedDashboards);
        },
        () => {},
      );
    }
  }, [setDashboards, innoIntelEnabled]);

  const bothLists = useMemo(() => [...myLists, ...sharedLists].slice(0, 5), [myLists, sharedLists]);

  const industryScoutLink = useMemo(
    () =>
      dashboards.length
        ? `/innovationintelligence/industryscout/${dashboards[0]._id}`
        : "/innovationintelligence/industryscout/new",
    [dashboards],
  );

  if (!innoIntelEnabled) return null;
  return (
    <RowComponent>
      <RowComponent.Fill>
        <RowComponent.Title linked to="/innovationintelligence" wrapping>
          Innovation Intelligence
        </RowComponent.Title>
        <Button primary size="mini" content="View companies" as={Link} to="/innovationintelligence" />
      </RowComponent.Fill>
      <RowComponent.Cell as={Link} to={"/innovationintelligence"}>
        <Icon name="building" size="large" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 5,
            marginRight: 5,
            position: "relative",
            top: -1,
          }}
        >
          <RowComponent.Title style={{ color: "#5d5d5d" }} block>
            Business Search
          </RowComponent.Title>
          <RowComponent.StatusArea>Companies and organisations worldwide</RowComponent.StatusArea>
        </div>
      </RowComponent.Cell>
      <RowComponent.Cell as={Link} to={industryScoutLink}>
        <Icon name="detective" size="large" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 5,
            marginRight: 5,
            position: "relative",
            top: -1,
          }}
        >
          <RowComponent.Title style={{ color: "#5d5d5d" }} block>
            Industry Scout
          </RowComponent.Title>
          <RowComponent.StatusArea>Innovation landscape of a specific industry</RowComponent.StatusArea>
        </div>
      </RowComponent.Cell>
      {bothLists.length > 0 ? (
        <>
          <Divider hidden />
          <RowComponent.Fill>
            <RowComponent.Title>Your company lists</RowComponent.Title>
          </RowComponent.Fill>
          {bothLists.map((list) => (
            <RowComponent.Cell
              key={list._id}
              as={Link}
              to={`/innovationintelligence/lists/${list._id}`}
              style={{ padding: "5px 5px" }}
            >
              <Icon name="list" size="large" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 5,
                  marginRight: 5,
                  position: "relative",
                  top: -1,
                }}
              >
                <RowComponent.Title style={{ color: "#5d5d5d" }} block>
                  {list.name}
                </RowComponent.Title>
                <RowComponent.StatusArea>
                  <>
                    {list.companies.length} companies
                    {list.user !== user._id ? ", shared with you." : ""}
                  </>
                </RowComponent.StatusArea>
              </div>
            </RowComponent.Cell>
          ))}
        </>
      ) : null}
    </RowComponent>
  );
};

export default HomeInnovationIntelligence;
