import React, { useCallback, useState } from "react";
import { Input, Message, TextArea, Button, Divider, Form } from "semantic-ui-react";
import api from "api";
import toast from "react-hot-toast";
import { useAppSelector } from "store";

type ReportContentProps = {
  forType: string;
  forId: string;
  reportUrl: string;
  onComplete: () => void;
};

const ReportContent = ({ forType, forId, reportUrl, onComplete }: ReportContentProps) => {
  const [location, setLocation] = useState<string>(reportUrl ?? "");
  const [description, setDescription] = useState<string>("");
  const user = useAppSelector((state) => state.user);
  const [email, setEmail] = useState<string>(user?.emails[0]?.address ?? "");

  const reportContent = useCallback(() => {
    api.auth.reportContent(
      {
        forType,
        forId,
        description,
        email,
        location,
      },
      () => {
        toast.success("Report submitted successfully");
        setDescription("");
        setLocation("");
        onComplete();
      },
      () => {
        toast.error("Failed to submit report");
      },
    );
  }, [forType, forId, description, email, location, onComplete]);

  return (
    <Form>
      <h2>Make a report or complaint</h2>
      <Divider hidden />
      <p>
        Online safety is hugely important for communities like SimplyDo. If you see something (e.g. user-generated
        content) that you consider to be potentially harmful or abusive to you or to others, please report it to us
        using the form below.
      </p>
      <p>
        You can also use the form to submit a complaint about content, our duty of care, our handling of previous
        complaints or content moderations, or anything else relevant to online safety or the operation of SimplyDo.
      </p>
      <p>We review all reports and will take appropriate action as soon as possible.</p>
      {/* <Message
        content={
          <>
            Read more about{" "}
            <a href="https://simplydo.co.uk/terms-of-use" target="_blank" rel="noopener noreferrer">
              Safety
            </a>{" "}
            on SimplyDo.
          </>
        }
      /> */}
      <Divider section hidden />
      <h4>URL or description of the content you saw</h4>
      <p>
        If the information here is too vague, we may not be able to find what you are referring to. If we have
        auto-filled it for you, you can leave it as it is.
      </p>
      <Input
        placeholder="https://simplydo.co.uk"
        value={location}
        fluid
        onChange={(e) => setLocation(e.target.value)}
      />
      <Divider hidden />
      <h4>Please describe the nature of your report or complaint</h4>
      <p>What is the issue? Why do you think the content is harmful or abusive? What is your complaint?</p>
      <TextArea
        fluid
        placeholder="Please provide as much detail as possible."
        onChange={(e) => setDescription(e.target.value)}
        value={description}
      />
      <Divider hidden />
      <h4>Your email address</h4>
      <p>
        This is optional. If you choose not to provide it you acknowledge that we will not be able to contact you
        regarding this submission.
      </p>
      <Input fluid placeholder="jane@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
      <Message
        content={
          <>
            Your information will be processed according to our{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://simplydo.co.uk/privacy-policy">
              Privacy Policy
            </a>
            {/* . Your complaint or report will be handled according to our{" "}
            <a href="https://simplydo.co.uk/terms-of-use" target="_blank" rel="noopener noreferrer">
              Policy
            </a> */}
            .
          </>
        }
      />
      <div style={{ textAlign: "right" }}>
        <Button primary content="Submit report" onClick={() => reportContent()} />
      </div>
    </Form>
  );
};

export default ReportContent;
