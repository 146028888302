import React from "react";
import { Divider, Form } from "semantic-ui-react";
import Radio from "../Forms/Radio";
import Checkbox from "../Forms/Checkbox";

const YourIdeasFilters = ({ setFilters, t, showDraft, showSubmitted, sort, filter }) => {
  return (
    <Form style={{ padding: 0 }}>
      <Form.Input
        placeholder={t("ideas.yours.sidebar.search")}
        icon="search"
        value={filter}
        onChange={(e) => setFilters((prevState) => ({ ...prevState, filter: e.target.value }))}
      />
      <Divider hidden />
      <Checkbox
        label={t("ideas.yours.sidebar.draft")}
        checked={showDraft}
        Click={() => setFilters((prevState) => ({ ...prevState, showDraft: !showDraft }))}
      />
      <Checkbox
        label={t("ideas.yours.sidebar.submitted")}
        checked={showSubmitted}
        Click={() => setFilters((prevState) => ({ ...prevState, showSubmitted: !showSubmitted }))}
      />
      <h5>Order by</h5>
      <Radio
        label={t("order.newest")}
        name="sort"
        checked={sort === "newest"}
        onChange={() => setFilters((prevState) => ({ ...prevState, sort: "newest" }))}
      />
      <Radio
        label={t("order.oldest")}
        name="sort"
        checked={sort === "oldest"}
        onChange={() => setFilters((prevState) => ({ ...prevState, sort: "oldest" }))}
      />
    </Form>
  );
};

export default YourIdeasFilters;
