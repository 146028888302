/* eslint-disable no-console */
import { initializeApp } from "firebase/app";
import { getToken, getMessaging, isSupported } from "firebase/messaging";

import api from "api";
import util from "utils/utils";
import { serviceWorkerBridge } from "../serviceWorkerBridge";

export const supportsPush = () => {
  if (!("Notification" in window)) {
    return false;
  }
  if (!("serviceWorker" in navigator)) {
    return false;
  }
  return true;
};

let messaging = null;
isSupported()
  .then((isMessagingSupported) => {
    if (supportsPush() && isMessagingSupported) {
      try {
        messaging = getMessaging(
          initializeApp({
            apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
            authDomain: "simply-do-1570545479058.firebaseapp.com",
            projectId: "simply-do-1570545479058",
            storageBucket: "simply-do-1570545479058.appspot.com",
            messagingSenderId: "115415578978",
            appId: "1:115415578978:web:f07d17d7b1d5455dc3943b",
          }),
        );
      } catch (e) {
        console.log("WebPush not supported", e);
      }
    }
  })
  .catch((e) => {
    console.log("WebPush not supported", e);
  });

const onGrant = () => {
  const serviceWorker = serviceWorkerBridge.getRegistration();
  if (!serviceWorker) {
    console.log("WebPush not supported");
    return;
  }
  serviceWorker
    .then((registration) => {
      getToken(messaging, {
        vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
        serviceWorkerRegistration: registration,
      })
        .then((token) => {
          if (util.localStorageIsSupported()) {
            // unsubscribe from current token if browser has one
            const fcmToken = localStorage.getItem("fcm_token");
            if (fcmToken && fcmToken !== token) {
              api.auth.removeFCMPushToken(
                fcmToken,
                () => {},
                () => {},
              );
            }
            localStorage.setItem("fcm_token", token);
          }

          api.auth.addFCMPushToken(
            token,
            () => {},
            () => {},
          );
          if (import.meta.env.MODE === "development") {
            console.log("[sw] FCM token:", token); // eslint-disable-line no-console
          }
        })
        .catch((e) => {
          console.log("WebPush not supported", e);
        });
    })
    .catch((e) => {
      console.log("WebPush not supported", e);
    });
};

export const unsubscribeFromPush = (callback) => {
  if (util.localStorageIsSupported()) {
    // Get fcm token from localstorage, clear it if it exists
    const fcmToken = localStorage.getItem("fcm_token");
    if (fcmToken) {
      api.auth.removeFCMPushToken(
        fcmToken,
        () => {
          callback();
        },
        () => {
          callback();
        },
      );
      localStorage.removeItem("fcm_token");
      return;
    }
  }
  callback();
};

export const setupNotifications = () => {
  if (!supportsPush() || !messaging) {
    // eslint-disable-next-line no-console
    console.log("This browser does not support desktop notifications");
  } else if (Notification.permission === "granted") {
    onGrant();
    return Promise.resolve();
  } else if (Notification.permission !== "denied") {
    return Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          onGrant();
          return Promise.resolve();
        }
        return Promise.reject();
      })
      .catch(() => {});
  }
  return Promise.reject();
};
