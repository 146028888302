const supportsPush = () => {
  if (!("Notification" in window)) {
    return false;
  }
  if (!("serviceWorker" in navigator)) {
    return false;
  }
  return true;
};

class ServiceWorkerBridge {
  _registration: ReturnType<typeof navigator.serviceWorker.register>;

  navigator: any;

  constructor() {
    if (supportsPush()) {
      this._registration = navigator.serviceWorker.register(
        import.meta.env.MODE !== "development" ? "/serviceWorker.js" : "/dev-sw.js?dev-sw",
        { type: import.meta.env.MODE !== "development" ? "classic" : "module" },
      );

      navigator.serviceWorker.addEventListener("message", (event) => {
        const { data } = event;
        if ("debug" in data) {
          console.log(data.debug); // eslint-disable-line no-console
        }
        if (data.ping) {
          // send message back
          navigator.serviceWorker.controller.postMessage({
            pong: true,
          });
        }
        if (data.path) {
          this.navigator.push(data.path);
        }
      });
    }
  }

  getRegistration() {
    if (this._registration) {
      return this._registration;
    }
    return Promise.reject(new Error("Service worker not supported"));
  }
}

export const serviceWorkerBridge = new ServiceWorkerBridge();
