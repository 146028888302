import React, { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { Checkbox, Modal, Input, Button, Message, Divider } from "semantic-ui-react";
import toast from "react-hot-toast";
import api from "api";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import SignatureCanvas from "components/lib/SignatureCanvas";

const InputAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .ui.input {
    width: 100%;
  }
  span {
    display: block;
    margin-bottom: 5px;
    ${({ $required }) =>
      $required &&
      `
      &:after {
        padding-left: 5px;
        color: #cc202d;
        content: "*";
      }`}
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${InputAreaContainer} {
    width: 50%;
    margin-bottom: 1rem;
    &:not(:last-of-type) {
      width: calc(50% - 1rem);
      margin-right: 1rem;
    }
  }
`;

const ModalActions = styled(Modal.Actions)`
  .wallet-adapter-dropdown {
    top: 6.5px;
  }
  .wallet-adapter-button {
    top: 15px;
    height: 33px;
  }
`;

const StyledModalHeader = styled(Modal.Header)`
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  > h3 {
    display: block;
    font-size: 1.1em;
    margin: 0;
  }
`;

export const StyledAsterix = styled.div`
  padding-top: 5px;
  :before {
    color: #cc202d;
    margin-left: 3px;
    content: "*";
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
`;

const StyledMessage = styled(Message)`
  margin-bottom: 1rem !important;
  margin-top: auto !important;
`;

const InputArea = ({ label, required, ...rest }) => (
  <InputAreaContainer $required={required}>
    <span>{label}</span>
    <Input {...rest} />
  </InputAreaContainer>
);

const ChallengeIPStatementAgreement = ({
  isOpen,
  onClose,
  ipManagementInfo,
  setIpManagementInfo,
  ipStatement,
  setIpStatement,
  user,
  challenge,
  isPreview,
  createNewStatement,
  setHasSignedStatement,
}) => {
  const [signatureState, setSignatureState] = useState({});
  const [statementSignatures, setStatementSignatures] = useState([]);
  const [agreed, setAgreed] = useState(!!isPreview);
  const [loading, setLoading] = useState(false);
  const [signatureFields, setSignatureFields] = useState([]);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const signatureFieldsToMap = useMemo(() => signatureFields.filter((field) => !field.ignoreMap), [signatureFields]);
  const canAgreeToTerms = useMemo(
    () => signatureFieldsToMap.every((field) => !!signatureState[field.key]) && isAuthorised,
    [signatureFieldsToMap, signatureState, isAuthorised],
  );
  const userHasSigned = useMemo(() => ipManagementInfo?.userHasSigned, [ipManagementInfo]);
  const hasSignedStatement = userHasSigned || isPreview;
  const { t } = useTranslation();

  const canvasRef = useRef(null);

  const userEmails = useMemo(() => user?.emails || [], [user]);
  const userName = user?.profile?.fullName;
  const challengeId = challenge?._id;
  const statementId = ipStatement?._id;

  const getStatementSignatures = useCallback(() => {
    api.challenges.getAgreementsForIPStatement(challengeId, statementId, ({ signatures }) => {
      setStatementSignatures(signatures);
    });
  }, [challengeId, statementId]);

  useEffect(() => {
    api.challenges.getIpSignatureFields(({ allSignatureFields }) => {
      setSignatureFields(allSignatureFields);
    });
  }, []);

  const signIpStatement = useCallback(() => {
    const signatureImageData = canvasRef.current.getCanvas().toDataURL("image/png");
    setLoading(true);
    api.challenges.agreeToLatestIpStatement(
      challengeId,
      statementId,
      { ...signatureState, signatureImageData },
      ({ activeStatement }) => {
        if (setIpManagementInfo) {
          setIpManagementInfo((prevIpManagement) => ({
            ...prevIpManagement,
            userHasSigned: true,
            userSignature: { signatureImageData },
          }));
          setHasSignedStatement(true);
        }
        setIpStatement(activeStatement);
        getStatementSignatures();
        onClose();
        setLoading(false);
        toast.success("Successfully agreed to IP statement");
      },
      (err) => {
        toast.error(err.message);
        setLoading(false);
      },
    );
  }, [
    setIpStatement,
    onClose,
    setIpManagementInfo,
    statementId,
    challengeId,
    signatureState,
    canvasRef,
    getStatementSignatures,
    setHasSignedStatement,
  ]);

  useEffect(() => {
    if (!challengeId || !statementId) {
      return;
    }
    getStatementSignatures();
  }, [challengeId, statementId, isOpen, getStatementSignatures]);

  const userSignatureData = ipManagementInfo?.userSignature;
  useEffect(() => {
    setSignatureState((prevState) => {
      const updater = {};
      signatureFields
        .filter((field) => field.key !== "signatureImageData")
        .forEach(({ key }) => {
          if (isPreview && ipStatement && ipStatement[key]) {
            // Autofill the name/email from the existing statement if previewing, else we just show the placeholder
            updater[key] = ipStatement[key];
          } else if (userSignatureData && userSignatureData[key]) {
            // If the user has already signed, and we have the data from their signature, we can attempt to show this
            updater[key] = userSignatureData[key];
          } else {
            // If user is signing, we can autofill their name/email where possible
            if (key === "signedName" && userName) updater.signedName = userName;
            if (key === "signedEmail" && userEmails.length) updater.signedEmail = userEmails[0].address;
          }
        });
      return { ...prevState, ...updater };
    });
  }, [userEmails, userName, isPreview, ipStatement, signatureFields, userSignatureData]);

  const statementContent = useMemo(() => {
    if (ipStatement) {
      let statementText = ipStatement.statementContent || "";
      const placeholderFields = signatureFields.filter((field) => !!field.placeholder && field.key !== "date");
      placeholderFields.forEach(({ placeholder, key }) => {
        if (signatureState[key]) {
          statementText = statementText.replaceAll(`{{${placeholder}}}`, signatureState[key]);
        }
      });
      statementText = statementText.replaceAll(
        "{{date}}",
        isPreview && ipStatement?.createdAt
          ? moment(ipStatement.createdAt).format("DD/MM/YY")
          : moment().format("DD/MM/YY"),
      );
      statementText = statementText.replaceAll(
        "{{scope}}",
        challenge?.ownerOrganisation?.ipScopes?.find((s) => s._id === ipStatement.statementScope)?.content,
      );
      return statementText;
    }
    return "";
  }, [ipStatement, signatureFields, isPreview, signatureState, challenge]);

  const statementDate = useMemo(() => {
    if (isPreview && ipStatement) return moment(ipStatement.createdAt).format("DD/MM/YY");
    if (userHasSigned && ipManagementInfo && ipManagementInfo?.userSignature)
      return moment(ipManagementInfo.userSignature?.createdAt).format("DD/MM/YY");
    return moment().format("DD/MM/YY");
  }, [ipManagementInfo, userHasSigned, ipStatement, isPreview]);

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={!!isOpen}
      onClose={onClose}
      style={{ margin: "3rem 1rem" }}
    >
      <StyledModalHeader>
        <h3>{hasSignedStatement ? "IP statement" : "Before you continue..."} </h3>
      </StyledModalHeader>
      <Modal.Content>
        {statementSignatures.length || userHasSigned ? (
          <>
            {statementSignatures[0]?.statementReferenceNo ? (
              <div style={{ whiteSpace: "pre-line", marginBottom: 10 }}>
                Agreement Ref: {statementSignatures[0]?.statementReferenceNo}
              </div>
            ) : null}
            {statementSignatures[0]?.pdfUrl ? (
              <Button
                secondary
                size="tiny"
                as="a"
                href={statementSignatures[0]?.pdfUrl}
                target="_blank"
                rel="noopener noreferrer"
                icon="download"
                content="Download IP statement agreement"
                style={{ marginTop: 10 }}
              />
            ) : null}
            {userHasSigned ? (
              <>
                <Divider />
                <Message success>You have signed this IP statement.</Message>
              </>
            ) : null}
          </>
        ) : null}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!hasSignedStatement && (
            <>
              <h4 style={{ marginBottom: 0 }}>
                In order to submit {t("generic.ideas")} to this {t("generic.challenge")}, you need to agree to an
                intellectual property statement.
              </h4>
              <h4>Step 1: To begin, please tell us about yourself and your business</h4>
            </>
          )}
          <InputContainer>
            {signatureFieldsToMap.map((field) => (
              <InputArea
                required={!hasSignedStatement}
                key={field.key}
                label={field.value}
                value={signatureState[field.key]}
                onChange={(e, { value }) => setSignatureState((prevState) => ({ ...prevState, [field.key]: value }))}
                disabled={userHasSigned || isPreview || field.isDisabled}
              />
            ))}
          </InputContainer>
          <CheckboxContainer>
            <Checkbox
              checked={isAuthorised}
              disabled={userHasSigned || isPreview}
              onChange={() => setIsAuthorised(!isAuthorised)}
              label={`I am authorised to make legally-binding agreements on behalf of ${signatureState.companyName || "my company"}`}
            />
          </CheckboxContainer>
          {isPreview || userHasSigned || canAgreeToTerms ? (
            <>
              <h4>
                {hasSignedStatement
                  ? "Intellectual property statement"
                  : "Step 2: Now, please read the statement below"}
              </h4>
              <StyledMessage>
                <p style={{ whiteSpace: "pre-line" }}>{statementContent}</p>
              </StyledMessage>
              {(isPreview || userHasSigned) && ipStatement?.statementSensitiveInfo ? (
                <>
                  <h4 style={{ marginTop: 0 }}>Sensitive information</h4>
                  <StyledMessage style={{ marginBottom: 20 }}>
                    <RichTextViewer
                      content={ipStatement?.statementSensitiveInfo}
                      forType="challenge"
                      forId={challenge?._id}
                    />
                  </StyledMessage>
                </>
              ) : null}
              {!hasSignedStatement && (
                <h4>
                  Step 3: Finally, please sign below to indicate your agreement to the terms in the above statement
                </h4>
              )}
              <InputArea>
                <InputAreaContainer style={{ marginRight: "1rem" }}>
                  <>
                    <span>{hasSignedStatement ? "Signature" : "Sign here with your mouse or finger"}</span>
                    {isPreview || ipManagementInfo?.userSignature?.signatureImageData ? (
                      <img
                        src={
                          isPreview
                            ? ipStatement?.signatureImageData
                            : ipManagementInfo?.userSignature?.signatureImageData
                        }
                        alt="User signature"
                        style={{ width: 400, height: 75 }}
                      />
                    ) : (
                      <SignatureCanvas canvasRef={canvasRef} width={400} height={75} />
                    )}
                  </>
                </InputAreaContainer>
                <InputArea label="Date" disabled value={statementDate} />
              </InputArea>
              <CheckboxContainer>
                <Checkbox
                  checked={hasSignedStatement}
                  disabled={userHasSigned || !canAgreeToTerms || isPreview}
                  onChange={() => setAgreed(!agreed)}
                  label="I agree to this statement"
                  style={{ marginTop: "1rem" }}
                />
                {!hasSignedStatement && <StyledAsterix />}
              </CheckboxContainer>
            </>
          ) : null}
        </div>
      </Modal.Content>
      <ModalActions>
        {isPreview && createNewStatement && (
          <Button
            icon="add"
            content="Create new version from this template"
            onClick={() => createNewStatement(ipStatement._id)}
            size="small"
          />
        )}
        {!userHasSigned && !isPreview ? (
          <>
            <Message info style={{ textAlign: "left" }}>
              After completing your signature, all parties will be sent a copy of the signed agreement. This action
              cannot be undone.
            </Message>
            <Button onClick={() => onClose()} size="small">
              Cancel
            </Button>
            <Button
              primary
              loading={loading}
              disabled={!canAgreeToTerms || !agreed || loading}
              onClick={() => signIpStatement()}
              icon="check"
              content="Complete signature"
              size="small"
            />
          </>
        ) : (
          <Button onClick={() => onClose()} size="small" primary>
            Done
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};

export default ChallengeIPStatementAgreement;
