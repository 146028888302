import { ReactNode } from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .company-info {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    span {
      display: block;
      margin: 0;
    }
    .description {
      opacity: 0.7;
    }
  }
`;

type StyledDropdownItemProps = {
  text: string;
  description?: string;
  selected?: boolean;
  image?: ReactNode;
};

const StyledDropdownItem = ({ text, description, image, selected }: StyledDropdownItemProps) => {
  return (
    <ItemContainer>
      {selected ? <Icon name="check square outline" size="large" style={{ marginRight: 8 }} /> : null}
      {image}
      <div className="company-info">
        <span>{text}</span>
        {description ? <span className="description">{description}</span> : null}
      </div>
    </ItemContainer>
  );
};

export default StyledDropdownItem;
