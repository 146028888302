import React, { useCallback } from "react";
import { Modal, Table, Button } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import util from "utils/utils";
import { EmptyBox } from "components/lib/UI";
import { IModalAction, TableHeaderItem } from ".";
import { ImageWithFallback } from "components/lib/ImageWithFallback";

type IAssessorModal = {
  isOpen: boolean;
  onClose: () => void;
  pendingInvitation: OpenAPI.Schemas["Invitation"];
  existingIdeas?: OpenAPI.Schemas["Idea"][];
  potentialIdeas?: OpenAPI.Schemas["Idea"][];
  getInvitationActions: (
    pendingInvitation: OpenAPI.Schemas["Invitation"],
    idea: OpenAPI.Schemas["Idea"],
  ) => IModalAction[];
};

const PendingInvitationModal = ({
  isOpen,
  onClose,
  pendingInvitation,
  existingIdeas = [],
  potentialIdeas = [],
  getInvitationActions,
}: IAssessorModal) => {
  const IdeaRow = useCallback(
    (idea) => (
      <Table.Row key={idea._id}>
        <Table.Cell>
          <TableHeaderItem as="h4" style={{ fontWeight: "400" }}>
            <ImageWithFallback avatar src={util.ideaCoverImage(idea)} fallbackSrc={util.ideaCoverImage()} />
            {idea.name}
          </TableHeaderItem>
        </Table.Cell>
        <Table.Cell collapsing>
          {getInvitationActions(pendingInvitation, idea).map((action) => (
            <Button
              size="tiny"
              content={action.content}
              icon={action.icon}
              loading={action.loading}
              onClick={action.onClick}
            />
          ))}
        </Table.Cell>
      </Table.Row>
    ),
    [pendingInvitation, getInvitationActions],
  );

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={isOpen}
      onClose={onClose}
      size="fullscreen"
    >
      <Modal.Header>{pendingInvitation?.invitee}</Modal.Header>
      <Modal.Content>
        {!existingIdeas.length && !potentialIdeas.length ? (
          <EmptyBox>You have removed all invitations for this email</EmptyBox>
        ) : null}
        <Table basic="very">
          {existingIdeas.length && pendingInvitation ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">Previously assigned ideas</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{existingIdeas.map((idea) => IdeaRow(idea))}</Table.Body>
            </>
          ) : null}

          {potentialIdeas.length && pendingInvitation ? (
            <>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Selected local ideas</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{potentialIdeas.map((idea) => IdeaRow(idea))}</Table.Body>
            </>
          ) : null}
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Done" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};

export default PendingInvitationModal;
