import React, { useCallback, useState, useMemo } from "react";
import util from "utils/utils";
import api from "api";
import toast from "react-hot-toast";
import { Button, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import useTheme from "theme/useTheme";
import { useNavigate } from "react-router-dom";
import { useUserReload } from "utils/useUserReload";
import { useAppSelector } from "store";
import { OpenAPI } from "simplydo/interfaces";

type GroupJoinButtonProps = {
  group: OpenAPI.GET<"/groups/{id}">["response"] & {
    isInvited?: boolean;
  };
  getGroup: () => void;
  isCardView?: boolean;
};

const GroupJoinButton = ({ group, getGroup, isCardView }: GroupJoinButtonProps) => {
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const isInGroup = util.isInGroup(user, group);
  const [requested, setRequested] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const groupId = group?._id;
  const userId = user?._id;
  const theme = useTheme();
  const navigate = useNavigate();
  const canManageGroups = util.canManageGroups(user, group?.organisation);

  const reloadUser = useUserReload();

  const canAccessGroup = useMemo(
    () =>
      group &&
      user &&
      (group.organisation === user.ownerOrganisation._id ||
        (user?.availableOrganisations?.map((org) => org._id) ?? []).indexOf(group.organisation) > -1),
    [group, user],
  );

  const joinGroup = useCallback(() => {
    api.groups.join(
      groupId,
      userId,
      () => {
        reloadUser(() => {
          navigate(`/groups/${groupId}`);
        });
      },
      (err) => toast.error(err.message),
    );
  }, [groupId, userId, reloadUser, navigate]);

  const requestToJoinGroup = useCallback(() => {
    setRequesting(true);
    api.invitations.create(
      {
        forId: groupId,
        forType: "requestJoinGroup",
        invitee: userId,
      },
      () => {
        setRequesting(false);
        toast.success("Request to join group created");
        setRequested(true);
        if (isCardView) {
          navigate(`/groups/${groupId}`);
        } else {
          getGroup();
        }
      },
      (err) => toast.error(err.message),
    );
  }, [userId, groupId, getGroup, navigate, isCardView]);

  if (group.isInvited) {
    return (
      <p
        style={{ color: "#fff", cursor: "pointer" }}
        onClick={() => {
          navigate(`/groups/${groupId}`);
        }}
      >
        {t("invitations.redirect.title")} <Icon name="arrow alternate circle right outline" />
      </p>
    );
  }

  if (group.isClosed && !isInGroup && !canManageGroups) return null;
  if (group.requestToJoin && !isInGroup && !canManageGroups && canAccessGroup) {
    if (!isCardView) {
      return (
        <Button
          primary
          size={theme.sizes.isMobile ? "tiny" : null}
          loading={requesting}
          disabled={requested || group.requestedToJoin}
          icon="paper plane"
          content={group.requestedToJoin ? t("groups.requestedToJoin") : t("groups.requestToJoinGroup")}
          onClick={requestToJoinGroup}
        />
      );
    }
    return (
      <p
        style={{ color: "#fff", cursor: "pointer" }}
        onClick={
          group.requestedToJoin
            ? () => {
                navigate(`/groups/${groupId}`);
              }
            : requestToJoinGroup
        }
      >
        {(group.requestedToJoin ? t("groups.requestedToJoinShort") : t("groups.requestToJoinGroup")).toUpperCase()}{" "}
        <Icon name="paper plane" />
      </p>
    );
  }
  if (!isCardView) {
    if (!isInGroup && canAccessGroup) {
      return <Button primary icon={"user plus"} onClick={joinGroup} content={t("groups.joinGroup")} />;
    }
    return null;
  }
  if (!canAccessGroup) return null;
  return (
    <p
      style={{ color: "#fff", cursor: "pointer" }}
      onClick={
        isInGroup
          ? () => {
              navigate(`/groups/${groupId}`);
            }
          : joinGroup
      }
    >
      {(isInGroup ? t("generic.viewType", { entity: "Group" }) : t("groups.joinGroup")).toUpperCase()}{" "}
      <Icon name={isInGroup ? "arrow alternate circle right outline" : "user plus"} />
    </p>
  );
};

export default GroupJoinButton;
