type Init = ConstructorParameters<typeof URLSearchParams>;

/**
 * SearchParams is a subclass of the builtin URLSearchParams class
 * This extends the base class to add some desired functionality and desired helper methods
 */
class SearchParams extends URLSearchParams {
  constructor(
    params: Init[0] | SearchParams | Record<string, string | string[] | number>,
  ) {
    // By default if the params is an array, string, URLSearchParams or SearchParams, we just pass it to the parent class, only if it is an object we want to handle it differently
    if (
      Array.isArray(params) ||
      typeof params === "string" ||
      params instanceof URLSearchParams ||
      params instanceof SearchParams
    ) {
      super(params);
      return;
    }
    super();

    if (params === null || params === undefined) {
      return;
    }
    // If params is an object we want to catch the specific case where any value of the object is an array. In this case where there is an array value, instead of appending the whole array as a single value we want to append each of the values in the array individually. The result is that when `toString()` is called each value will be appended as a separate key value pair in the URL instead of a comma separated list
    // For example:
    //   new SearchParams({ key: ["val1", "val2"] }).toString() => "key=val1&key=val2"
    // Instead of:
    //   new URLSearchParams({ key: ["val1", "val2"] }).toString() => "key=val1,val2"
    for (const [key, val] of Object.entries(params)) {
      if (val === null || val === undefined || val === "") {
        continue;
      } else if (Array.isArray(val)) {
        val.forEach((v) => this.append(key, v));
      } else {
        this.append(key, val.toString());
      }
    }
  }

  /**
   * This method is used to convert the SearchParams object to a string, we can pass a glue string to be used as a prefix that is only added if the constructed string is not empty
   */
  toSafeString(glue = "?"): string {
    const constructed = super.toString();
    return constructed ? `${glue}${constructed}` : "";
  }
}

export default SearchParams;
