import api from "api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Divider, Header, Icon, Label, Message, Table } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import { UserChip } from "../../../Chips";
import util from "utils/utils";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import ImpactStatementView from "../../ImpactStatements/ImpactStatementView";
import useThrottle from "utils/useThrottle";

type ImpactStatements = OpenAPI.GET<"/ideas/{idea_id}/impactStatement">["response"]["impactStatements"];

const ImpactStatementManagement = ({ idea, onUpdateIdea, impactList }) => {
  const { t } = useTranslation();

  const [currentImpactStatementId, setCurrentImpactStatementId] = useState<string | null>(null);
  const [impactStatements, setImpactStatements] = useState<ImpactStatements>([]);
  const activeImpactStatement = useMemo(
    () => impactStatements.find((impactStatement) => impactStatement.active),
    [impactStatements],
  );
  const currentImpactStatement = useMemo(
    () => impactStatements.find((impactStatement) => impactStatement._id === currentImpactStatementId),
    [impactStatements, currentImpactStatementId],
  );

  const throttledUpdate = useThrottle(onUpdateIdea, 400, [onUpdateIdea]);

  const updateImpactStatement = useCallback(
    (impactStatement: OpenAPI.Schemas["ImpactStatements"]) => {
      setImpactStatements((prev) =>
        prev.map((statement) => {
          if (statement._id === impactStatement._id) {
            return impactStatement;
          }
          return statement;
        }),
      );
      throttledUpdate({
        totalImpacts: Object.entries(impactStatement.impacts)
          .filter(([_key, value]) => value.enabled)
          .map(([key, value]) => ({
            impactId: key,
            ownerImpact: impactList.find((impact) => impact._id === key),
            ...value,
          })),
      });
    },
    [throttledUpdate, impactList],
  );

  const setSubmitted = (impactStatementId: string) => {
    api.ideas.updateImpactStatementStatus(
      idea._id,
      impactStatementId,
      { isSubmitted: true },
      () => {
        setImpactStatements((prev) =>
          prev.map((impactStatement) => ({
            ...impactStatement,
            active: impactStatement._id === impactStatementId,
          })),
        );
      },
      () => {},
    );
  };

  const setActive = (impactStatementId: string) => {
    api.ideas.updateImpactStatementStatus(
      idea._id,
      impactStatementId,
      { active: true },
      () => {
        setImpactStatements((prev) =>
          prev.map((impactStatement) => ({
            ...impactStatement,
            active: impactStatement._id === impactStatementId,
          })),
        );
      },
      () => {},
    );
  };

  const deleteStatement = (impactStatementId: string) => {
    api.ideas.deleteImpactStatement(
      idea._id,
      impactStatementId,
      () => {
        setImpactStatements((prev) => prev.filter((impactStatement) => impactStatement._id !== impactStatementId));
      },
      () => {},
    );
  };

  useEffect(() => {
    api.ideas.getImpactStatements(
      idea._id,
      ({ impactStatements }) => {
        impactStatements.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
        setImpactStatements(impactStatements);
      },
      () => {},
    );
  }, [idea._id]);

  if (currentImpactStatement) {
    return (
      <div>
        {!currentImpactStatement?.isSubmitted ? (
          <>
            <Header as="h3">
              <Header.Content>Editing impact statement</Header.Content>
              <Header.Subheader>
                Please identify the impact of this idea on the {t("generic.challenge")} "{idea.ownerChallenge.name}".
                Once you are happy with the statement, you can mark it as completed below.
              </Header.Subheader>
            </Header>
            <Button basic compact onClick={() => setCurrentImpactStatementId(null)}>
              <Icon name="arrow left" />
              Back to list
            </Button>

            <Divider />
          </>
        ) : (
          <>
            <Button basic compact onClick={() => setCurrentImpactStatementId(null)}>
              <Icon name="arrow left" />
              Back to list
            </Button>
            <Divider />
          </>
        )}

        <ImpactStatementView
          impactStatement={currentImpactStatement}
          editable={!currentImpactStatement?.isSubmitted}
          ideaId={idea._id}
          statementDefintion={idea.ownerChallenge.impactStatements}
          setImpactStatement={updateImpactStatement}
        />
        {!currentImpactStatement?.isSubmitted ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingBottom: 30,
            }}
          >
            <Button
              primary
              onClick={() => {
                util
                  .confirm(
                    "Mark as completed",
                    "Are you sure you want to mark this impact statement as completed? You will not be able to edit the statement anymore, however you can always create a new statement version based on this one.",
                  )
                  .then(() => {
                    toast.success("Impact statement marked as completed");
                    setSubmitted(currentImpactStatement._id);
                    setCurrentImpactStatementId(null);
                    setImpactStatements((prev) => {
                      const updated = prev.map((impactStatement) => {
                        if (impactStatement._id === currentImpactStatement._id) {
                          return {
                            ...impactStatement,
                            isSubmitted: true,
                          };
                        }
                        return impactStatement;
                      });
                      return updated;
                    });
                  })
                  .catch(() => {});
              }}
            >
              <Icon name="check circle" />
              Mark as completed
            </Button>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <Button
        primary
        onClick={() => {
          api.ideas.createImpactStatement(
            idea._id,
            { impacts: activeImpactStatement?.impacts || {} },
            ({ impactStatement }) => {
              setImpactStatements((prev) => [
                impactStatement,
                ...prev.map((statement) => ({ ...statement, active: false })),
              ]);
              setCurrentImpactStatementId(impactStatement._id);
            },
            () => {},
          );
        }}
      >
        <Icon name="plus circle" />
        Create new impact statement
      </Button>

      <Header as="h3">Impact statements</Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Created by</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!impactStatements.length ? (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <Message color="blue">
                  <Message.Content>No impact statements yet</Message.Content>
                </Message>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {impactStatements.map((impactStatement) => (
            <Table.Row key={impactStatement._id}>
              <Table.Cell>
                <div>
                  <UserChip compact user={impactStatement.ownerCreatedBy as OpenAPI.Schemas["User"]} />
                  {impactStatement.ownerSubmittedBy && impactStatement.submittedBy !== impactStatement.createdBy ? (
                    <UserChip compact user={impactStatement.ownerSubmittedBy as OpenAPI.Schemas["User"]} />
                  ) : null}
                </div>
              </Table.Cell>
              <Table.Cell>{impactStatement.description}</Table.Cell>
              <Table.Cell>{new Intl.DateTimeFormat().format(new Date(impactStatement.createdAt))}</Table.Cell>
              <Table.Cell>
                {impactStatement.active ? (
                  <Label size="tiny" color="blue" style={{ marginBottom: 5 }}>
                    <Icon name="check" />
                    Active
                  </Label>
                ) : null}
                {impactStatement.isSubmitted ? (
                  <Label size="tiny" color="green">
                    Finalised
                  </Label>
                ) : (
                  <Label size="tiny" color="yellow">
                    Draft
                  </Label>
                )}
              </Table.Cell>
              <Table.Cell>
                <Button
                  size="mini"
                  compact
                  style={{ marginBottom: 5 }}
                  onClick={() => {
                    setCurrentImpactStatementId(impactStatement._id);
                  }}
                >
                  {impactStatement.isSubmitted ? "View" : "Edit"} statement
                </Button>
                <div>
                  {!impactStatement.active ? (
                    <Button
                      size="mini"
                      basic
                      compact
                      onClick={() => {
                        util
                          .confirm(
                            "Set active impact statement",
                            "Are you sure you want to set this as the active impact statement? The active statement is going to be used for reporting.",
                          )
                          .then(() => {
                            setActive(impactStatement._id);
                          })
                          .catch(() => {});
                      }}
                    >
                      Set active
                    </Button>
                  ) : null}
                  <Button
                    basic
                    icon
                    size="mini"
                    compact
                    onClick={() => {
                      if (impactStatement.active) {
                        util
                          .confirm(
                            "Delete impact statement",
                            "Are you sure you want to delete this impact statement? Deleting the active statement will set the next most recent statement as active. Prioritises finalised statements.",
                          )
                          .then(() => {
                            deleteStatement(impactStatement._id);
                          })
                          .catch(() => {});
                      } else {
                        util
                          .confirm("Delete impact statement", "Are you sure you want to delete this impact statement?")
                          .then(() => {
                            deleteStatement(impactStatement._id);
                          })
                          .catch(() => {});
                      }
                    }}
                  >
                    <Icon name="trash" />
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ImpactStatementManagement;
