import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import util from "utils/utils";
import toast from "react-hot-toast";
import api from "api";

import DiscussionFeed from "components/lib/Discussion";

type IProjectComments = {
  forIdea: string;
  forId: string;
  onIdeaActivity?: (ideaId: string) => void;
  canPost: boolean;
  refetchComments?: boolean;
  setRefetchComments: (refresh: boolean) => void;
};

const ProjectComments = ({
  forIdea,
  forId,
  onIdeaActivity = () => {},
  canPost,
  refetchComments,
  setRefetchComments,
}: IProjectComments) => {
  const user = useAppSelector((state) => state.user);

  const { t } = useTranslation();

  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);

  const [commentLoading, setCommentLoading] = useState(false);

  const [newComment, setNewComment] = useState({ text: "", media: [] });
  const [replies, setReplies] = useState<{ [key: string]: { text: string; media: any[] } }>({});

  const [commentsState, setCommentsState] = useState({
    page: 1,
    limit: 15,
    nextPageAvailable: false,
    previousPageAvailable: false,
    total: 0,
  });

  const { page, limit, nextPageAvailable, previousPageAvailable, total } = commentsState;

  const getComments = useCallback(() => {
    setCommentsLoading(true);
    api.boards.getProjectComments(
      forId,
      forIdea,
      { page, limit },
      (data) => {
        setComments(data.comments);
        setCommentsState((prevState) => ({
          ...prevState,
          nextPageAvailable: data.nextPageAvailable,
          previousPageAvailable: data.previousPageAvailable,
          total: data.total,
        }));
        setCommentsLoading(false);
      },
      (err) => {
        toast.error(err.message);
        setCommentsLoading(false);
      },
    );
  }, [forId, forIdea, page, limit, setComments, setCommentsState, setCommentsLoading]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  useEffect(() => {
    if (refetchComments) {
      getComments();
      setRefetchComments(false);
    }
  }, [getComments, refetchComments, setRefetchComments]);

  const onPageChange = useCallback(
    (newPage: number) => {
      setCommentsState((prevState) => ({ ...prevState, page: newPage }));
    },
    [setCommentsState],
  );

  const postComment = useCallback(
    (parent: string | undefined = "") => {
      setCommentLoading(true);
      api.boards.postProjectComment(
        forId,
        forIdea,
        { message: parent ? replies[parent] : newComment, parent },
        () => {
          toast.success(t("ideas.comments.posted"));
          if (parent) {
            setReplies((prev) => {
              const newState = { ...prev };
              delete newState[parent];
              return newState;
            });
            setCommentLoading(false);
            getComments();
          } else {
            setNewComment({ text: "", media: [] });
            setCommentsState((prevState) => ({ ...prevState, page: 1 }));
            setCommentLoading(false);
            getComments();
          }
          onIdeaActivity(forIdea);
        },
        (err) => toast.error(err.message),
      );
    },
    [newComment, replies, setCommentsState, t, forIdea, getComments, forId, onIdeaActivity],
  );

  const deleteComment = useCallback(
    (commentId: string) =>
      util
        .confirm(t("ideas.comments.delete.title"), t("ideas.comments.delete.info"))
        .then(() => {
          api.boards.deleteProjectComment(
            forId,
            forIdea,
            commentId,
            () => {
              const currentComments = Object.assign([], comments).filter((c) => c._id !== commentId);
              setComments(currentComments);
              toast(t("ideas.comments.delete.success"));
            },
            (err) => toast.error(err.message),
          );
        })
        .catch(() => {}),
    [forIdea, t, comments, forId],
  );

  const addAttachment = useCallback((storedName, attachmentName, signedDownloadRequest, parentId) => {
    const attachment = {
      value: storedName,
      name: attachmentName,
      signedDownloadRequest,
      type: attachmentName.match(/\.(jpg)|(png)|(gif)|(jpeg)$/i) ? "image" : "file",
    };
    if (parentId) {
      setReplies((prev) => ({
        ...prev,
        [parentId]: {
          ...(prev[parentId] || {}),
          media: [...(prev[parentId]?.media || []), attachment],
        },
      }));
    } else {
      setNewComment((prev) => ({ ...prev, media: [...prev.media, attachment] }));
    }
  }, []);

  const deleteAttachment = useCallback((attachment, parentId) => {
    if (parentId) {
      setReplies((prev) => ({
        ...prev,
        [parentId]: {
          ...prev[parentId],
          media: prev[parentId].media.filter((a) => a.value !== attachment.value),
        },
      }));
    } else {
      setNewComment((prev) => ({ ...prev, media: prev.media.filter((a) => a.value !== attachment.value) }));
    }
  }, []);

  const getFile = useCallback((att) => att.signedDownloadRequest || att.value, []);

  return (
    <div style={{ marginTop: 15 }}>
      <h5 style={{ marginBottom: 0 }}>Project notes</h5>
      <p style={{ color: "grey" }}>
        These notes are only visible to admins and users with access to the project board.
      </p>
      <DiscussionFeed
        post={postComment}
        deleteComment={deleteComment}
        feed={comments}
        loading={commentsLoading}
        commentLoading={commentLoading}
        canPost={canPost || util.hasPermission(user, "challenge.createProjectNotes", forId)}
        postReply={(parent) => postComment(parent)}
        onPageChange={onPageChange}
        updateComment={(text) => setNewComment((prev) => ({ ...prev, text }))}
        updateReply={(commentId, text) => {
          setReplies((prev) => ({
            ...prev,
            [commentId]: { text, media: prev[commentId]?.media },
          }));
        }}
        getReplyData={(commentId) => replies[commentId] ?? { text: "", media: [] }}
        commentText={newComment.text}
        commentMedia={newComment.media}
        page={page}
        limit={limit}
        nextPageAvailable={nextPageAvailable}
        previousPageAvailable={previousPageAvailable}
        total={total}
        user={user}
        disableReacts
        addAttachment={addAttachment}
        deleteAttachment={deleteAttachment}
        getFile={getFile}
        fileChooserForType="idea"
        fileChooserForId={forIdea}
        postText="Post note"
        placeholder="Enter a note.."
        type="projectComment"
      />
    </div>
  );
};

export default ProjectComments;
