import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import api from "api";
import styled from "styled-components";
import RowComponent from "components/lib/RowComponent";
import { useTranslation } from "react-i18next";

const SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
  box-shadow: ${({ theme }) => theme.boxShadow};
  > h3 {
    margin-bottom: 0.5rem;
    display: block;
  }
  > .suggestions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  h4 {
    max-width: 500px;
  }
`;

const SuggestionItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 0 5px;
`;

const getName = (keys, item, index = 0) => {
  // We can access nested objects here, so we track an index
  // If we are at the end of our array and the final product is still not a string, we throw an error
  const currentKey = keys[index];
  const finalName = item[currentKey];
  if (index === keys.length - 1) {
    if (typeof finalName === "object") throw new Error(`Result key ${currentKey} did not point to a string`);
    else return finalName;
  }
  return getName(keys, item, index + 1);
};

const getLink = (linkIds, item, link, index = 0) => {
  // If we don't have an id, we can just return the link
  // Else recursively build it, based on the number of ids we are gathering
  if (!linkIds?.length) return link;
  const currentLinkId = getName(linkIds[index], item);
  const updatedLink = link.replace(`{${index}}`, currentLinkId);
  if (index === linkIds.length - 1) return updatedLink;
  return getLink(linkIds, item, updatedLink, index + 1);
};

const HomeSuggestions = () => {
  const [suggestions, setSuggestions] = useState([]);
  const { t } = useTranslation();

  // Result name key is the key in the returned item we use to identify it, e.g. the name of an idea, or the name of a challenge. We use an array in case we need to access a key inside a nested object
  // LinkIds indicate the ids for a given link. This can be multiple recursive calls; for example if we need to go to /challenges/{_id}/board?idea={_id}, this allows accessing that
  const suggestionsData = {
    create_an_idea: {
      text: `Create ${t("generic.ideaWithArticle")} for`,
      resultNameKey: ["name"],
      link: "/challenges/{0}",
      linkIds: [["_id"]],
      imageUrl: ["bannerImageUrl"],
    },
    create_challenges: { text: `Create ${t("generic.challengeWithArticle")}`, link: "/challenges", icon: "star" },
    access_admin: { text: "View the admin dashboard", link: "/admin", icon: "settings" },
    view_challenges: {
      text: `View the ${t("generic.challenge")}`,
      resultNameKey: ["name"],
      link: "/challenges/{0}",
      linkIds: [["_id"]],
      imageUrl: ["bannerImageUrl"],
    },
    complete_assignments: {
      text: "Complete your assignment for",
      resultNameKey: ["name"],
      link: "/challenges/{0}/board?idea={1}",
      linkIds: [["challenge"], ["_id"]],
      imageUrl: ["coverImageUrl"],
    },
    continue_ideas: {
      text: `Continue your ${t("generic.idea")}`,
      resultNameKey: ["name"],
      link: "/ideas/{0}",
      linkIds: [["_id"]],
      imageUrl: ["coverImageUrl"],
    },
    view_recent_comments: {
      text: `View new comments in your ${t("generic.idea")}`,
      resultNameKey: ["ownerIdea", "name"],
      link: "/ideas/{0}",
      linkIds: [["_id"]],
      imageUrl: ["ownerIdea", "coverImageUrl"],
    },
  };

  const getSuggestions = useCallback(() => {
    api.users.suggestions(({ suggestions: responseSuggestions }) => {
      setSuggestions(responseSuggestions);
    });
  }, []);

  useEffect(() => {
    getSuggestions();
  }, [getSuggestions]);

  if (!suggestions.length) return null;
  return (
    <SuggestionsContainer>
      <h3>{t("home.suggestions.title")}</h3>
      <RowComponent $noMargin $noBorder>
        {suggestions.slice(0, 4).map((suggestion, index) => {
          const suggestionData = suggestionsData[suggestion.type];
          const suggestionLink = getLink(suggestionData.linkIds, suggestion.item, suggestionData.link);
          if (!suggestion.item) {
            return (
              <RowComponent.Cell key={index} as={Link} to={suggestionLink}>
                {suggestionData.icon ? (
                  <Icon name={suggestionData.icon} size="big" style={{ width: 50, marginRight: 0 }} />
                ) : null}
                <SuggestionItem style={{ position: "relative", top: -1 }}>
                  <RowComponent.Title block style={{ color: "#5d5d5d", fontWeight: 500 }}>
                    {suggestionData.text}
                  </RowComponent.Title>
                </SuggestionItem>
              </RowComponent.Cell>
            );
          }
          const suggestionImage = getName(suggestionData.imageUrl, suggestion.item);
          return (
            <RowComponent.Cell
              key={index}
              as={Link}
              to={suggestionLink}
              onClick={() => {
                api.journey.record("suggestion", suggestion.type, "clicked");
              }}
            >
              {suggestionImage ? (
                <div
                  style={{
                    width: 50,
                    height: 40,
                    borderRadius: 3,
                    flex: "0 0 50px",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundImage: `url(${suggestionImage})`,
                  }}
                />
              ) : null}
              <SuggestionItem>
                <RowComponent.StatusArea style={{ color: "#8a8a8a", marginBottom: 3 }}>
                  {suggestionData.text}
                  {"..."}
                </RowComponent.StatusArea>
                <RowComponent.Title block wrapping style={{ color: "#5d5d5d", fontWeight: 500 }}>
                  {getName(suggestionData.resultNameKey, suggestion.item)}
                </RowComponent.Title>
              </SuggestionItem>
            </RowComponent.Cell>
          );
        })}
      </RowComponent>
    </SuggestionsContainer>
  );
};

export default HomeSuggestions;
