import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Link, Routes, Route, useParams, useNavigate } from "react-router-dom";
import { Container, Grid, Icon, Popup, Form, Divider, Button } from "semantic-ui-react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import api from "api";
import util from "utils/utils";
import actions from "actions";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import Radio from "components/lib/Forms/Radio";
import Checkbox from "components/lib/Forms/Checkbox";

import { Banner, BasicAccordion } from "components/lib/UI";
import { SideNav } from "components/lib/SideNav";
import UserStatusBar from "components/lib/UserStatusBar";
import PageLoadError from "components/lib/PageLoadError";
import AchievementAwarder from "components/lib/Users/AchievementAwarder";

import { TagView } from "components/lib/Choosers/Tags/TagModalChooser";
import ProfileFollowing from "./Following";
import { ProfileIdeas } from "./Ideas";
import { ProfileDetails, socialMediaLinks } from "./Details";
import ReportContentChip from "components/lib/ReportContent/ReportContentChip";

const UserProfileLink = styled.a`
  display: inline;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

const LargeAvatar = styled.div`
  display: inline-block;
  background-size: cover;
  background-position: center center;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 3px 10px 20px rgba(0, 0, 0, 0.05);
  border: ${({ theme }) => (theme.sizes.isMobile ? 2 : 5)}px solid white;
  height: ${({ theme }) => (theme.sizes.isMobile ? 50 : 150)}px;
  width: ${({ theme }) => (theme.sizes.isMobile ? 50 : 150)}px;
`;

const Profile = (props) => {
  const { onMessageThreadSelected, user, t } = props;

  const [profileUser, setProfileUser] = useState({});
  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ideasLoading, setIdeasLoading] = useState(false);
  const [customAchievements, setCustomAchievements] = useState([]);
  const [ideaOptions, setIdeaOptions] = useState({
    filter: "",
    showDraft: true,
    showSubmitted: true,
    sort: "newest",
  });
  const [achievementAwardModalOpen, setAchievementAwardOpen] = useState(false);
  const { filter, showDraft, showSubmitted, sort } = ideaOptions;

  const params = useParams();
  const matchId = params?.id;
  const navigate = useNavigate();
  const theme = useTheme();

  const getUser = useCallback((id) => {
    setLoading(true);
    setErrorMessage("");
    api.users.get(
      id,
      (u) => {
        setProfileUser(u);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        setErrorMessage(err.message);
      },
    );
  }, []);

  const refreshAchievements = useCallback(() => {
    api.users.get(
      matchId,
      (data) => {
        setProfileUser((prevUser) => ({ ...prevUser, customAchievements: data.customAchievements }));
      },
      (err) => setErrorMessage(err.message),
    );
  }, [matchId]);

  const fetchIdeas = useCallback((id) => {
    setIdeasLoading(true);
    api.users.ideas(
      id,
      null,
      ({ ideas: userIdeas }) => {
        setIdeasLoading(false);
        setIdeas(userIdeas);
      },
      () => setIdeasLoading(false),
    );
  }, []);

  useEffect(() => {
    getUser(matchId);
    fetchIdeas(matchId);
  }, [matchId, fetchIdeas, getUser]);

  const setFilters = useCallback(
    (filters) => {
      setIdeaOptions({ ...ideaOptions, ...filters });
    },
    [setIdeaOptions, ideaOptions],
  );

  const messageUser = useCallback(() => {
    api.messages.findThreadByUser(
      matchId,
      (data) => {
        onMessageThreadSelected(data?.thread || { _id: `newThread:${matchId}`, participantUsers: [profileUser] });
      },
      (err) => toast.error(err.message),
    );
  }, [profileUser, onMessageThreadSelected, matchId]);

  const checkURL = (url) => (url.indexOf("http") === 0 ? url : `http://${url}`);

  const ghost = useCallback(() => {
    util
      .confirm(
        "Really ghost as this user?",
        "Ghosting allows you to view the platform and carry out actions as though you are the user. You can un-ghost at any time by tapping the avatar at the top-right of the screen.",
      )
      .then(() =>
        api.users.ghost(
          matchId,
          () => {
            navigate("/");
          },
          (err) => toast.error(err.message),
        ),
      )
      .catch(() => {});
  }, [navigate, matchId]);

  const ProfileIdeaFilters = useMemo(
    () => (
      <Form style={{ padding: 0, marginTop: 20 }}>
        <Form.Input
          label={t("filter.title")}
          placeholder={t("filter.keyword")}
          icon="filter"
          value={filter}
          onChange={(e) => setFilters({ filter: e.target.value })}
        />
        <Divider hidden />
        <Checkbox
          label={t("ideas.yours.sidebar.draft")}
          checked={showDraft}
          onChange={() => setFilters({ showDraft: !showDraft })}
        />
        <Checkbox
          label={t("ideas.yours.sidebar.submitted")}
          checked={showSubmitted}
          onChange={() => setFilters({ showSubmitted: !showSubmitted })}
        />
        <Divider hidden />
        <Radio
          label={t("order.newest")}
          name="sort"
          checked={sort === "newest"}
          onChange={() => setFilters({ sort: "newest" })}
        />
        <Radio
          label={t("order.oldest")}
          name="sort"
          checked={sort === "oldest"}
          onChange={() => setFilters({ sort: "oldest" })}
        />
      </Form>
    ),
    [filter, setFilters, showDraft, showSubmitted, sort, t],
  );

  if (errorMessage) return <PageLoadError title={t("users.profile.problem")} message={errorMessage} />;

  const { profile = {}, ownerOrganisation, organisation, ownerTags, canBeMessagedByCurrentUser } = profileUser || {};

  const { fullName, tagline, website, department, jobTitle, avatar, uploads = [] } = profile;

  const isYourProfile = profileUser._id === user._id;

  const profileSocialElements = ["facebook", "twitter", "linkedin", "instagram", "github"].filter(
    (key) => !!profile[key],
  );

  return (
    <div>
      <Helmet title={fullName} />
      {util.hasPermission(user, "org.viewDashboard", user && user.ownerOrganisation._id) ? (
        <AchievementAwarder
          targetUser={achievementAwardModalOpen ? profileUser : null}
          user={user}
          onClose={() => {
            setAchievementAwardOpen(false);
            refreshAchievements();
          }}
        />
      ) : null}
      <UserStatusBar profileUser={profileUser} />
      <Banner
        marginless
        image={util.getThumbnail(profile, "coverImage", "300x300")}
        style={{ backgroundColor: "rgb(200,200,200)" }}
      >
        <Container>
          <div className="overlay" />
          <div className="overlay-body">
            <Grid stackable>
              <Grid.Column computer={2}>
                {!theme.sizes.isMobile && (
                  <>
                    <LargeAvatar
                      theme={theme}
                      style={{
                        backgroundImage: `url(${util.mixinCssUrlFallback(util.avatarUrl(profileUser, "300x300"), util.avatarUrl())})`,
                        marginLeft: -10,
                      }}
                    />
                    {isYourProfile && !avatar && (
                      <div style={{ marginTop: 10 }}>
                        <Link style={{ color: "#e2e7f3" }} to={"/preferences/profile"}>
                          <Icon name="upload" /> Upload a profile image
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </Grid.Column>
              <Grid.Column computer={7}>
                {theme.sizes.isMobile ? (
                  <div style={{ marginBottom: -40 }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ position: "relative", display: "inline-flex" }}>
                        <LargeAvatar
                          theme={theme}
                          style={{
                            backgroundImage: `url(${util.mixinCssUrlFallback(util.avatarUrl(profileUser, "300x300"), util.avatarUrl())})`,
                          }}
                        />
                        {profileUser?.isAdmin && (
                          <Popup
                            content="Admin user"
                            trigger={
                              <div style={{ position: "absolute", top: 0.5, left: 31 }}>
                                <Icon
                                  name="certificate"
                                  size="large"
                                  style={{ color: "#45abff", position: "absolute" }}
                                />
                                <Icon
                                  name="checkmark"
                                  style={{
                                    position: "relative",
                                    color: "white",
                                    left: 4.2,
                                    top: 2,
                                  }}
                                />
                              </div>
                            }
                          />
                        )}
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <h2>{fullName}</h2>
                        {ownerOrganisation && <h5 style={{ marginTop: -12 }}>Member of {ownerOrganisation?.name}</h5>}
                        {isYourProfile && !avatar && (
                          <div style={{ marginTop: 10 }}>
                            <Link style={{ color: "#e2e7f3" }} to={"/preferences/profile"}>
                              <Icon name="upload" /> Upload a profile image
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    {(department || jobTitle) && (
                      <h5>
                        {jobTitle} {department && <span>({department})</span>}
                      </h5>
                    )}
                    {website && (
                      <div>
                        <UserProfileLink href={checkURL(website)} target="_blank" rel="noopener noreferrer">
                          <Icon name="external" /> {website}
                        </UserProfileLink>
                      </div>
                    )}
                    {tagline && (
                      <div style={{ marginBottom: 15, color: "white", fontStyle: "italic" }}>
                        <span>{tagline}</span>
                      </div>
                    )}
                    {ownerTags?.length > 0 ? <TagView tags={ownerTags} /> : null}
                  </div>
                ) : (
                  <div style={{ marginLeft: 20 }}>
                    {ownerOrganisation && <h5 style={{ margin: 0 }}>Member of {ownerOrganisation?.name}</h5>}
                    <div style={{ position: "relative", display: "inline-flex" }}>
                      <h1>{fullName}</h1>
                      {profileUser?.iconRoles &&
                        profileUser?.iconRoles?.map((role) => (
                          <Popup
                            key={role._id}
                            content={role?.name}
                            trigger={
                              <Icon
                                name={role?.icon?.name}
                                size="large"
                                color={role?.icon?.colour}
                                style={{ marginLeft: 5 }}
                              />
                            }
                          />
                        ))}
                    </div>
                    {(department || jobTitle) && (
                      <h3 style={{ marginTop: 0, marginBottom: 5 }}>
                        {jobTitle} {department && <span>({department})</span>}
                      </h3>
                    )}
                    {tagline && <div style={{ color: "white", fontStyle: "italic", marginBottom: 8 }}>{tagline}</div>}
                    {website && (
                      <div
                        style={{
                          background: "white",
                          padding: "2px 5px",
                          borderRadius: 3,
                          maxWidth: "fit-content",
                        }}
                      >
                        <UserProfileLink href={checkURL(website)} target="_blank" rel="noopener noreferrer">
                          <Icon name="external" /> {website}
                        </UserProfileLink>
                      </div>
                    )}
                  </div>
                )}
              </Grid.Column>
              <Grid.Column computer={7} textAlign="right" verticalAlign="bottom">
                <div style={{ marginTop: 30 }}>
                  {!isYourProfile && util.hasPermission(user, "super.ghostUser", profileUser.ownerOrganisation) ? (
                    <Button
                      size={theme.sizes.isMobile ? "tiny" : null}
                      inverted
                      icon="snapchat ghost"
                      content="Ghost user"
                      onClick={ghost}
                    />
                  ) : null}
                  {customAchievements?.length > 0 &&
                  (util.hasPermission(user, "org.viewDashboard") || util.hasPermission(user, "super.viewDashboard")) ? (
                    <Button
                      size={theme.sizes.isMobile ? "tiny" : null}
                      inverted
                      icon="trophy"
                      content="Award custom achievement"
                      onClick={() => setAchievementAwardOpen(true)}
                      customAchievements={customAchievements}
                      setCustomAchievements={setCustomAchievements}
                    />
                  ) : null}
                  {!isYourProfile &&
                  canBeMessagedByCurrentUser &&
                  user?.ownerOrganisation?.enabledFeatures.includes("directMessaging") ? (
                    <Button
                      size={theme.sizes.isMobile ? "tiny" : null}
                      style={{ marginTop: 5 }}
                      icon="send"
                      inverted
                      content="Send a message"
                      onClick={messageUser}
                    />
                  ) : null}
                  {isYourProfile ? (
                    <Button
                      size={theme.sizes.isMobile ? "tiny" : null}
                      style={{ marginTop: 5 }}
                      as={Link}
                      to={"/preferences/profile"}
                      icon="edit"
                      content={t("users.preferences.profile.edit")}
                      inverted
                    />
                  ) : null}
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Container>
      </Banner>

      <SideNav
        navItems={[
          {
            key: "profile",
            content: t("generic.profile"),
            iconLeft: "user",
            to: ".",
            active: window.location.pathname === `/users/${profileUser._id}`,
          },
          util.hasPermission(user, "org.viewDashboard", organisation) || profileUser._id === user._id
            ? {
                key: "ideas",
                content: t("generic.ideas"),
                iconLeft: "lightbulb",
                to: "ideas",
                active: window.location.pathname === `/users/${profileUser._id}/ideas`,
              }
            : null,
          util.hasPermission(user, "org.viewDashboard", organisation) || profileUser._id === user._id
            ? {
                key: "following",
                content: "Following",
                iconLeft: "star",
                to: "following",
                active: window.location.pathname === `/users/${profileUser._id}/following`,
              }
            : null,

          ...(window.location.pathname === `/users/${profileUser._id}`
            ? [
                profileSocialElements.length
                  ? {
                      key: "links",
                      type: "custom",
                      content: <h5 style={{ textTransform: "uppercase" }}>{t("generic.links")}</h5>,
                    }
                  : null,

                ...profileSocialElements.map((socialElement) => ({
                  key: socialElement,
                  iconLeft: socialMediaLinks[socialElement].icon,
                  iconLeftAlt: socialMediaLinks[socialElement].iconLeftAlt,
                  content: socialMediaLinks[socialElement].content,
                  to: socialMediaLinks[socialElement].link + profile[socialElement],
                  external: true,
                })),

                uploads.length
                  ? {
                      key: "uploads",
                      type: "custom",
                      content: <h5 style={{ textTransform: "uppercase" }}>Uploads</h5>,
                    }
                  : null,

                ...uploads.map((upload) => ({
                  key: upload.storedName,
                  iconLeft: util.getFileIcon(upload.storedName),
                  external: true,
                  content: upload.title,
                  to: upload.url,
                })),
              ].filter((item) => Boolean(item))
            : []),

          window.location.pathname === `/users/${profileUser._id}/ideas`
            ? {
                key: "ideaFilters",
                type: "custom",
                content: theme.sizes.isMobile ? (
                  <BasicAccordion title={`${t("generic.idea")} filters`}>{ProfileIdeaFilters}</BasicAccordion>
                ) : (
                  ProfileIdeaFilters
                ),
              }
            : null,
        ].filter((item) => Boolean(item))}
        main={
          <Routes>
            <Route
              path="/"
              element={
                <ProfileDetails {...props} loading={loading} profileUser={profileUser} isYourProfile={isYourProfile} />
              }
            />
            {(util.hasPermission(user, "org.viewDashboard", organisation) || profileUser._id === user._id) && (
              <Route
                path="ideas"
                element={
                  <ProfileIdeas
                    ideas={ideas}
                    loading={ideasLoading}
                    sort={sort}
                    filter={filter}
                    showDraft={showDraft}
                    showSubmitted={showSubmitted}
                    profileUser={profileUser}
                  />
                }
              />
            )}
            {(util.hasPermission(user, "org.viewDashboard", organisation) || profileUser._id === user._id) && (
              <Route
                path="following"
                element={<ProfileFollowing user={user} loading={loading} profileUser={profileUser} />}
              />
            )}
          </Routes>
        }
        afterContent={<ReportContentChip forType="user" forId={profileUser._id} />}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onMessageThreadSelected: (threadId) => dispatch(actions.messages.selectThread(threadId)),
});
const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default withTranslation()(ProfileContainer);
