import React, { useState, useMemo } from "react";
import api from "api";
import { confirmable } from "react-confirm";
import { createConfirmation } from "components/lib/Modal/MountPoint";

import { Modal, Header, Button, Icon, Form, Table, Input, Select, Message, Divider } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import util from "utils/utils";
import useTheme from "theme/useTheme";
import toast from "react-hot-toast";
import { UserChip } from "./Chips";
import { LargeCheckbox } from "./UI";
import { useTranslation } from "react-i18next";
import { ImageWithFallback } from "./ImageWithFallback";

type MergeIdea = OpenAPI.Schemas["Idea"] & { owner?: OpenAPI.Schemas["User"]; parent?: string; children?: string[] };

type MergeModalProps = {
  ideas: MergeIdea[];
  challenge: OpenAPI.Schemas["Challenge"];
  projectLanes: OpenAPI.Schemas["ProjectLane"][];
  show?: boolean;
  proceed?: () => void;
  cancel?: () => void;
  dismiss?: () => void;
};

const IdeaTableRow = ({
  idea,
  onChangeCheckbox,
  checked,
}: {
  idea: MergeIdea;
  onChangeCheckbox?: () => void;
  checked?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Table.Row key={idea._id}>
      {onChangeCheckbox ? (
        <Table.Cell collapsing>
          <LargeCheckbox checked={checked} onChange={onChangeCheckbox} />
        </Table.Cell>
      ) : null}
      <Table.Cell collapsing>
        <ImageWithFallback src={util.ideaCoverImage(idea)} fallbackSrc={util.ideaCoverImage()} avatar />
      </Table.Cell>
      <Table.Cell collapsing={!theme.sizes.isComputer}>
        <span
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {idea.name}
        </span>
      </Table.Cell>
      <Table.Cell collapsing={!theme.sizes.isComputer}>
        <UserChip user={idea.owner} />
      </Table.Cell>
    </Table.Row>
  );
};

const MergeModal = ({ show, proceed, dismiss, cancel, ideas, projectLanes, challenge }: MergeModalProps) => {
  const theme = useTheme();
  const [selectedOwner, setSelectedOwner] = useState(ideas[0]);
  const [name, setName] = useState(ideas[0].name);
  const [projectLane, setProjectLane] = useState("none");
  const [mergeJustification, setMergeJustification] = useState("");
  const { t } = useTranslation();

  // You can only merge ideas, where either:
  // - None of the ideas have been merged
  // - One of the ideas is a merge parent, and the rest of the selected ideas have not been merged/are not merge parents

  // Therefore, there are two possible states of merge
  // All ideas are being merged into a NEW parent
  // All ideas excluding the selected parent are being merged into the selected parent
  const nonMergedIdeas = useMemo(() => ideas.filter((i) => !(i.children || i.parent)), [ideas]);
  const mergeParent = useMemo(() => ideas.find((i) => i.children), [ideas]);
  const isExistingMerge = useMemo(
    () => ideas.filter((i) => i.children).length === 1 && nonMergedIdeas.length === ideas.length - 1,
    [ideas, nonMergedIdeas.length],
  );

  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={show} onClose={dismiss} size="small">
      <Header content={`Merge ${ideas.length} ${t("generic.ideas")}`} />
      <Modal.Content>
        <h5>{`${t("common:capitalise", { key: "generic.ideas" })} to be merged`}</h5>
        {!isExistingMerge ? (
          <>
            <span>{`The following ${t("generic.ideas")} will be included in the merge.`}</span>
            <span>
              {`Please choose the ${t("generic.idea")} owner and cover image you wish to use for the new merged ${t("generic.idea")}. Note that the
              authors from the other ${t("generic.ideas")} will be added as authors to the new merged ${t("generic.idea")}.`}
            </span>
          </>
        ) : null}
        <Message info size="small">
          {challenge?.ideaVisibility === "users"
            ? `${t("common:capitalise", { key: "generic.idea" })} visibility is currently set to "Users". Authors of the ${t("generic.ideas")} you merge will be added as authors to the merged ${t("generic.idea")}. They will be able to see the merged ${t("generic.idea")}, and if ${t("generic.idea")} visibility is set to "Users", they will be able to see the other ${t("generic.ideas")} in the merge.`
            : `${t("common:capitalise", { key: "generic.idea" })} visibility is currently set to "${t("common:capitalise", { key: "generic.challenge" })} Managers". Authors of the ${t("generic.ideas")} you merge will not be added as authors to the merged ${t("generic.idea")}. They will not be able to see the merged ${t("generic.idea")}, and if ${t("generic.idea")} visibility is set to "Users", they will not be able to see the other ${t("generic.ideas")} in the merge.`}
        </Message>
        {isExistingMerge ? (
          <>
            <Divider horizontal>
              The following {util.pluralise(nonMergedIdeas.length, `${t("generic.idea")}`, `${t("generic.ideas")}`)}...
            </Divider>
            <Table compact={!theme.sizes.isComputer} unstackable>
              <Table.Body>
                {nonMergedIdeas.map((idea) => (
                  <IdeaTableRow key={idea._id} idea={idea} />
                ))}
              </Table.Body>
            </Table>
            <Divider horizontal>Will be merged into...</Divider>
            <Table compact={!theme.sizes.isComputer} unstackable>
              <Table.Body>
                <IdeaTableRow key={mergeParent._id} idea={mergeParent} />
              </Table.Body>
            </Table>
          </>
        ) : (
          <>
            <Table compact={!theme.sizes.isComputer} unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="1"></Table.HeaderCell>
                  <Table.HeaderCell width="1"></Table.HeaderCell>
                  <Table.HeaderCell width="6">Name</Table.HeaderCell>
                  <Table.HeaderCell width="4">User</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {ideas.map((idea) => (
                  <IdeaTableRow
                    key={idea._id}
                    idea={idea}
                    checked={selectedOwner._id === idea._id}
                    onChangeCheckbox={() => {
                      if (name === selectedOwner.name) {
                        setName(idea.name);
                      }
                      setSelectedOwner(idea);
                    }}
                  />
                ))}
              </Table.Body>
            </Table>
            <h5>{t("common:capitalise", { key: "generic.idea" })} details</h5>
            <span>
              Please choose a name and project lane (if appropriate) for the newly-merged {t("generic.idea")}.
            </span>
            <Form style={{ marginTop: 10 }}>
              <Form.Field
                control={Input}
                label={`${t("common:capitalise", { key: "generic.idea" })} name`}
                value={name}
                onChange={(e, data) => {
                  setName(data.value);
                }}
              />
              {projectLanes ? (
                <Form.Field
                  control={Select}
                  label="Lane"
                  value={projectLane}
                  onChange={(e, data) => {
                    setProjectLane(data.value);
                  }}
                  options={[
                    {
                      key: "none",
                      text: "No lane",
                      value: "none",
                    },
                    {
                      key: "defaultLane",
                      text: "Default lane",
                      value: "defaultLane",
                    },
                    ...projectLanes.map((lane) => ({
                      key: lane._id,
                      text: lane.name,
                      value: lane._id,
                    })),
                  ]}
                />
              ) : null}
            </Form>
          </>
        )}
        <h5>Considerations</h5>
        <ul>
          <li>
            Merged {t("generic.ideas")} can be un-merged at any time. The {t("generic.ideas")} included will not be
            changed.
          </li>
          <li>
            The individual {t("generic.ideas")} will no longer appear in the {t("generic.idea")} list. Instead, the
            "merged" {t("generic.idea")} will be displayed.
          </li>
          <li>
            Depending on your {t("generic.challenge")} settings, users with {t("generic.ideas")} in the merge may be
            able to see the other users and {t("generic.ideas")}.
          </li>
        </ul>
        <Form>
          <Form.Field>
            <b>Justify this merge</b>
          </Form.Field>
          <Form.TextArea
            value={mergeJustification}
            onChange={(e, { value }) => setMergeJustification(value as string)}
            placeholder={`I am merging the ${t("generic.ideas")} ${ideas.map((idea) => idea.name).join(", ")} because...`}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={cancel}>
          <Icon name="trash" /> Cancel
        </Button>
        <Button
          primary
          onClick={() => {
            if (isExistingMerge) {
              api.ideas.updateMergedIdea(
                mergeParent._id,
                {
                  ideaIds: nonMergedIdeas.map((idea) => idea._id),
                  justification: mergeJustification,
                },
                () => {
                  toast.success(
                    `Added ${util.pluralise(nonMergedIdeas.length, `${t("generic.idea")}`, `${t("generic.ideas")}`)} to ${mergeParent.name}`,
                  );
                  proceed();
                },
                () => {
                  toast.error(`Failed to merge ${t("generic.ideas")}`);
                },
              );
            } else {
              const mergeData: Parameters<typeof api.ideas.merge>[0] = {
                ideaIds: ideas.map((idea) => idea._id),
                challengeId: ideas[0].challenge,
                ownerIdeaId: selectedOwner._id,
                name,
                justification: mergeJustification,
              };
              if (projectLane !== "none") {
                mergeData.projectLane = projectLane;
              }

              api.ideas.merge(
                mergeData,
                () => {
                  toast.success(`Successfully merged ${t("generic.ideas")}`);
                  proceed();
                },
                () => {
                  toast.error(`Failed to merge ${t("generic.ideas")}`);
                },
              );
            }
          }}
          disabled={!name}
        >
          <Icon name="checkmark" /> Merge {t("generic.ideas")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const confirmMerge = (
  ideas: OpenAPI.Schemas["Idea"][],
  projectLanes: OpenAPI.Schemas["ProjectLane"][],
  challenge: OpenAPI.Schemas["Challenge"],
) =>
  createConfirmation(confirmable(MergeModal))({
    ideas,
    projectLanes,
    challenge,
  });
