import React, { useEffect } from "react";
import { Message, Input, Divider, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import actions from "actions";
import api from "api";
import constants from "utils/constants";
import SearchParams from "simplydo/src/searchParams";
import { useNavigate } from "react-router-dom";

import TermsCheckbox from "components/lib/Entry/TermsCheckbox";

function Enrol({
  password,
  setPassword,
  firstName,
  setFirstName,
  loading,
  setLoading,
  email,
  setEmail,
  error,
  setError,
  agreedTerms,
  setAgreedTerms,
  onTermsAgreed,
  t,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    const params = new SearchParams(window.location.search);
    const token = params.get("token");
    if (token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const data = JSON.parse(window.atob(base64));
      if (data && data.email && data.email !== email) {
        setEmail(data.email);
        setFirstName(data.given_name);
      }
    }
  }, [email, setEmail, setFirstName]);

  function completeSetPassword(callback) {
    const params = new SearchParams(window.location.search);
    const token = params.get("token");
    setLoading(true);
    setError("");
    api.auth.updatePasswordWithToken(
      token,
      password,
      () => {
        if (email) {
          api.auth.login(
            { email, password },
            () => {
              navigate("/");
              setTimeout(() => {
                callback();
              }, 50);
            },
            (err) => {
              setError(err.message);
              setLoading(false);
            },
          );
        } else {
          setLoading(false);
          navigate("/");
          toast(t("entry.enrol.passwordSuccess"));
        }
      },
      (err) => {
        setLoading(false);
        setError(err.message);
      },
    );
  }

  function agreeTerms() {
    setError("");
    api.auth.agreeToTerms(
      constants.termsVersion,
      (agreements) => {
        onTermsAgreed(agreements);
        setLoading(false);
      },
      (err) => {
        setError(err.message);
        setLoading(false);
      },
    );
  }

  function clicked() {
    if (!agreedTerms) return setError(t("entry.enrol.agreeToTerms"));
    completeSetPassword(agreeTerms);
  }

  return (
    <div style={{ maxWidth: 450, display: "block", margin: "0px auto" }}>
      <h2>{t("entry.enrol.welcome", { context: firstName || null })}</h2>
      <p>{t("entry.enrol.info")}</p>
      <Divider hidden />
      {error && <Message warning>{error}</Message>}
      <Input
        style={{ minHeight: "40px" }}
        fluid
        type="password"
        placeholder={t("entry.enrol.newPass")}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        autoFocus
      />
      <Divider hidden />
      <TermsCheckbox agreed={agreedTerms} onChange={setAgreedTerms} />
      <div style={{ marginTop: 30, textAlign: "right" }}>
        <Button primary content={t("entry.enrol.complete")} onClick={clicked} loading={loading} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { email, password, firstName, lastName, loading, error, agreedTerms } = state.entry;
  return {
    email,
    password,
    firstName,
    lastName,
    loading,
    error,
    agreedTerms,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onTermsAgreed: (agreements) => dispatch(actions.user.agreeTerms(agreements)),
  setAgreedTerms: (a) => dispatch(actions.entry.updateAgreedTerms(a)),
  setEmail: (e) => dispatch(actions.entry.updateEmail(e)),
  setPassword: (p) => dispatch(actions.entry.updatePassword(p)),
  setFirstName: (n) => dispatch(actions.entry.updateFirstName(n)),
  setLastName: (n) => dispatch(actions.entry.updateLastName(n)),
  setLoading: (l) => dispatch(actions.entry.updateLoading(l)),
  setError: (e) => dispatch(actions.entry.updateError(e)),
});
const EnrolContainer = connect(mapStateToProps, mapDispatchToProps)(Enrol);

export default withTranslation()(EnrolContainer);
