import { useState, useCallback, useEffect, useRef } from "react";
import { OpenAPI } from "simplydo/interfaces";
import api from "api";
import toast from "react-hot-toast";
import uuid from "uuid";
import { IPotentialAssessor, IExistingAssessor, IPendingInvitationAssessor } from ".";
import useThrottle from "utils/useThrottle";
import { useAppSelector } from "store";
import { ChallengesReducer } from "reducers/challenges";

type IdeaFilters = OpenAPI.GET<"/challenges/{id}/ideas/filters">["response"];

export const useIdeas = (challengeId: string) => {
  const challengesReducer: ChallengesReducer = useAppSelector((state) => state.challenges);
  const {
    tagFilter,
    ideaIncludes: filter,
    ideaOrder: sort,
    ideaOrderDirection: sortDirection,
    ideaPage: page,
    stampsFilter: stamps,
    ideaLimit: limit,
    ideaFieldChoices: fieldChoices,
    projectBoardLanesFilter,
    groupFilter,
    orgFilter,
    projectBoardFilter,
    adminHasCommented,
    impactFilter,
  } = challengesReducer;

  const [total, setTotal] = useState(0);
  const [potentialIdeas, setPotentialIdeas] = useState<OpenAPI.Schemas["Idea"][]>([]);
  const currentFetch = useRef<string>("");
  const [allIdeas, setAllIdeas] = useState<OpenAPI.Schemas["Idea"][]>([]);
  const [loadingPotentialIdeas, setLoadingPotentialIdeas] = useState<boolean>(false);
  const [ideaFilters, setIdeaFilters] = useState<IdeaFilters>({});

  useEffect(() => {
    let mounted = true;
    api.challenges.getIdeaFilters(
      challengeId,
      (filters) => {
        if (!mounted) {
          return;
        }
        setIdeaFilters(filters);
      },
      () => {},
    );

    return () => {
      mounted = false;
    };
  }, [challengeId]);

  const [potentialIdeaSearchSettings, setPotentialIdeaSearchSettings] = useState<{
    query: string;
    page: number;
    limit: number;
  }>({
    query: "",
    page: 1,
    limit: 15,
  });
  const [nextPageAvailable, setNextPageAvailable] = useState<boolean>(false);
  const [prevPageAvailable, setPrevPageAvailable] = useState<boolean>(false);

  const fetchPotentialIdeas = useThrottle(
    (query: string, page: number, limit: number) => {
      const fetchId = uuid.v4();
      currentFetch.current = fetchId;

      const queryParams = {
        page,
        query,
        tags: tagFilter,
        filter: filter || [],
        sort,
        limit,
        sortDirection,
        stamps,
        groupFilter,
        orgFilter,
        fieldChoices,
        adminHasCommented,
        projectBoardFilter,
        projectBoardLanesFilter,
        impactFilter,
        contentType: "assessment",
      };
      api.challenges.getIdeas(
        challengeId,
        queryParams,
        ({
          ideas: newIdeas,
          nextPageAvailable: newNextPageAvailable,
          previousPageAvailable: newPrevPageAvailable,
          total: newTotal,
        }) => {
          if (currentFetch.current !== fetchId) {
            return;
          }

          setTotal(newTotal);
          setLoadingPotentialIdeas(false);
          setPotentialIdeas(newIdeas);
          setNextPageAvailable(newNextPageAvailable);
          setPrevPageAvailable(newPrevPageAvailable);
        },
        () => {
          setLoadingPotentialIdeas(false);
        },
      );
    },
    400,
    [
      challengeId,
      adminHasCommented,
      fieldChoices,
      filter,
      groupFilter,
      impactFilter,
      limit,
      orgFilter,
      page,
      projectBoardFilter,
      projectBoardLanesFilter,
      sort,
      sortDirection,
      stamps,
      tagFilter,
    ],
  );

  const getPotentialIdeas = useCallback(
    (query: string, page: number, limit: number) => {
      setLoadingPotentialIdeas(true);
      setPotentialIdeaSearchSettings({ query, page, limit });
      fetchPotentialIdeas(query, page, limit);
    },
    [fetchPotentialIdeas],
  );

  useEffect(() => {
    getPotentialIdeas("", 1, 15);
  }, [getPotentialIdeas]);

  const getAllIdeas = useCallback(() => {
    // Effectively get all using limit 1000
    api.challenges.getIdeas(
      challengeId,
      { limit: 1000, contentType: "assessment" },
      ({ ideas: newIdeas }) => {
        setAllIdeas(newIdeas);
      },
      (err) => toast.error(err.message),
    );
  }, [challengeId]);

  useEffect(() => {
    getAllIdeas();
  }, [getAllIdeas]);

  return {
    getPotentialIdeas,
    potentialIdeas,
    allIdeas,
    loadingPotentialIdeas,
    potentialIdeaSearchSettings,
    nextPageAvailable,
    prevPageAvailable,
    setPotentialIdeas,
    setAllIdeas,
    getAllIdeas,
    ideaFilters,
    total,
  };
};

export const useAssessors = (challengeId: string) => {
  const [potentialAssessors, setPotentialAssessors] = useState<IPotentialAssessor[]>([]);
  const [loadingPotentialAssessors, setLoadingPotentialAssessors] = useState<boolean>(false);
  const [existingAssessors, setExistingAssessors] = useState<IExistingAssessor[]>([]);
  const [existingAssessorsLoading, setExistingAssessorsLoading] = useState<boolean>(false);
  const [pendingInvitations, setPendingInvitations] = useState<IPendingInvitationAssessor[]>([]);

  const getPotentialAssessors = useCallback(() => {
    setLoadingPotentialAssessors(true);
    api.challenges.getPotentialAssessors(
      challengeId,
      ({ ownerUsers, ownerEmails }) => {
        setPotentialAssessors(
          [...ownerUsers, ...ownerEmails].map((assessor) => ({ ...assessor, type: "potentialAssessor" })),
        );
        setLoadingPotentialAssessors(false);
      },
      () => {
        setLoadingPotentialAssessors(false);
      },
    );
  }, [challengeId]);

  useEffect(() => {
    getPotentialAssessors();
  }, [getPotentialAssessors]);

  // Get existing assessors and invitations for this challenge
  const getExistingAssessors = useCallback(() => {
    setExistingAssessorsLoading(true);
    api.challenges.getAssessmentDashboard(
      challengeId,
      "",
      ({
        assessors: newAssessors,
        pendingInvitations: newPendingInvitations,
      }: {
        assessors: any;
        pendingInvitations: OpenAPI.Schemas["Invitation"][];
      }) => {
        setExistingAssessors(newAssessors.map((assessor) => ({ ...assessor.ownerAssessor, type: "existingAssessor" })));
        setPendingInvitations(
          newPendingInvitations.map((pi) => ({ ...pi, type: "pendingInvitation", isEmailInvitee: true })),
        );
        setExistingAssessorsLoading(false);
      },
      () => {
        setExistingAssessorsLoading(false);
      },
    );
  }, [challengeId]);

  useEffect(() => {
    getExistingAssessors();
  }, [getExistingAssessors]);

  return {
    potentialAssessors,
    setPotentialAssessors,
    loadingPotentialAssessors,
    existingAssessors,
    setExistingAssessors,
    existingAssessorsLoading,
    pendingInvitations,
    setPendingInvitations,
  };
};
