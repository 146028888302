import React, { useCallback, useState } from "react";
import { Divider, Button } from "semantic-ui-react";
import toast from "react-hot-toast";
import constants from "utils/constants";
import actions from "actions";
import api from "api";

import SettingActions from "components/lib/SettingActions";
import TermsCheckbox from "./TermsCheckbox";
import { useAppDispatch } from "store";

const AgreeToTerms = () => {
  const dispatch = useAppDispatch();
  const [hasAgreed, setHasAgreed] = useState(false);
  const [loading, setLoading] = useState(false);

  const clicked = useCallback(() => {
    if (hasAgreed) {
      setLoading(true);
      api.auth.agreeToTerms(
        constants.termsVersion,
        (agreements) => {
          dispatch(actions.user.agreeTerms(agreements));
          setLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setLoading(false);
        },
      );
    } else {
      toast.error("Please agree to the terms before continuing");
    }
  }, [hasAgreed, dispatch]);

  return (
    <>
      <h3>We value your privacy</h3>
      <p>You are seeing this message because our Policies or Terms may have recently changed.</p>
      <TermsCheckbox agreed={hasAgreed} onChange={(agreed) => setHasAgreed(agreed)} />
      <Divider hidden />
      <SettingActions>
        <Button primary content="Continue" onClick={clicked} loading={loading} disabled={!hasAgreed} />
      </SettingActions>
    </>
  );
};

export default AgreeToTerms;
