import React, { useCallback } from "react";
import { HoverAction } from "components/lib/HoverActions";
import UserChooser from "components/lib/Choosers/UserChooser/UserChooser";
import api from "api";
import toast from "react-hot-toast";
import { useAppSelector } from "store";
import util from "utils/utils";
import { OpenAPI } from "simplydo/interfaces";
import { ChallengeAudienceWarning } from "components/lib/Choosers/UserChooser/Content/ChallengeAudienceWarning";
import { useTranslation } from "react-i18next";

type AssignAssessorsActionProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
  onUpdateIdea: (idea: OpenAPI.GET<"/ideas/{id}">["response"]) => void;
};

const AssignAssessorsAction = ({ idea, onUpdateIdea }: AssignAssessorsActionProps) => {
  const user = useAppSelector((state) => state.user);
  const ideaId = idea?._id;
  const challengeId = idea?.challenge;
  const { t } = useTranslation();

  const addAssessors = useCallback(
    (users) => {
      const existingUsers = users.filter((assessor) => !assessor.isEmailInvitee);
      if (existingUsers.length === 0) {
        return;
      }
      api.challenges.assignAssessors(
        challengeId,
        [ideaId],
        existingUsers.map((u) => u._id),
        ({ ideas }) => {
          const updatedIdea = ideas[0];
          onUpdateIdea({
            assessments: updatedIdea.assessments,
            isAssessor: updatedIdea.isAssessor,
          });
          toast.success("Assessors updated");
        },
        (err) => toast.error(err.message),
      );
    },
    [ideaId, challengeId, onUpdateIdea],
  );

  if (
    !util.canManageChallenge(user, idea.ownerChallenge) &&
    !util.hasPermission(user, "challenge.editAssessments", idea.challenge)
  ) {
    return null;
  }

  return (
    <UserChooser
      trigger={<HoverAction icon="add user"> Assign assessors</HoverAction>}
      enabledFeatures={{ search: true, invite: true }}
      onComplete={(users) => {
        const emailUsers = users.filter((u) => u.isEmailInvitee);
        if (emailUsers.length) {
          api.invitations.createBulk(
            {
              invitees: emailUsers.map((u) => u._id),
              invitationType: "email",
              forType: "ideaAssessor",
              forId: idea.challenge,
              forIdeas: [idea._id],
            },
            () => {
              toast.success(`Invitation${emailUsers.length > 1 ? "s" : ""} sent`);
            },
            () => {},
          );
        }
        addAssessors(users);
      }}
      forId={challengeId}
      afterContent={(extraContentProps) => (
        <ChallengeAudienceWarning
          extraContentProps={extraContentProps}
          externalInvitesInAudience={idea?.ownerChallenge?.visibility?.organisations?.length > 0}
        >
          <ChallengeAudienceWarning.AssessorAudienceWarning challengeId={challengeId} />
        </ChallengeAudienceWarning>
      )}
      clearOnComplete
      forType="assessor"
      subtitle={`Choose users to assign as assessors of the selected ${t("generic.ideas")}.`}
      confirm="Assign these assessors"
      searchFunction={(term, cb, fail) =>
        api.search.challengeAssessorsIncludeExisting(idea.challenge, term, ({ users }) => cb(users), fail)
      }
    />
  );
};

export default AssignAssessorsAction;
