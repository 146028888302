import { useState, useEffect, useCallback, useMemo } from "react";
import { useMentionAtom, useEmoji, useEditorFocus, useRemirrorContext } from "@remirror/react";
import { Preset } from "../../presets/types";
import { RemirrorMention } from ".";

/**
 * Mention structure
 * Any additional parameter that is specified will be kept for 'onMentionSelect'
 *
 * @param id - Uniquely identifies this mention
 * @param label - The label for the inline text of this mention
 * @param title (optional) - The title in the dropdown, default to label
 * @param description (optional) - Dropdown description of the mention
 * @param image (options) - Dropdown preview image url
 */
const defaultMentionFormat = (item) => ({
  ...item,
  appendText: " ",
});
const emojiMentionFormat = (emoji) => ({
  id: emoji.hexcode,
  emoji: emoji.emoji,
  label: `:${emoji.shortcodes[0]}:`,
  description: emoji.annotation,
});
const defaultFilter = () => true;

const filterMentions = (parameter, allMentions, config) => {
  if (!parameter || !allMentions) {
    return [];
  }

  const { name, query } = parameter;
  const theConfig = config[name];
  if (!theConfig) {
    return [];
  }

  let result = [];
  const { char } = theConfig;
  Object.values(config).forEach((c: Preset[number]["config"]) => {
    if (c.char === char) {
      const { filter = defaultFilter, formatMention = defaultMentionFormat } = c;
      result = result.concat(
        (allMentions[c.name] ?? [])
          .map((mention) => ({
            ...formatMention(mention),
            name: c.name,
            priority: mention.priority,
            appendText: " ",
          }))
          .filter((t) => filter(t, query)),
      );
    }
  });
  return result.sort((a, b) => b.priority - a.priority);
};

/**
 * Creates a mention filter parameter
 *
 * @attr name - the corresponding name passed to the extension matcher
 * @attr char - the character used to initiate the matcher (e.g. @)
 * @attr query - the text after the symbol
 * @attr addMention - a function that allows to create a mention from the suggestion
 */
const createFilterParameter = (
  state,
): {
  name: string;
  query: string;
  addMention: (mention: RemirrorMention) => void;
  char: string;
} =>
  state
    ? {
        name: state.name,
        query: state?.query?.full,
        addMention: state.command,
        char: state?.text?.full?.charAt(0),
      }
    : null;

type UseCustomMentionProps = {
  config: Record<Preset[number]["name"], Preset[number]>;
  allMentions: Array<RemirrorMention>;
  onSuggest: (names: string[], query: string) => void;
  onMentionSelect: (mention: RemirrorMention) => void;
};

const useCustomMention = ({ config, allMentions, onSuggest, onMentionSelect }: UseCustomMentionProps) => {
  const [isFocused, focus] = useEditorFocus();
  const rem = useRemirrorContext();
  const emojisEnabled = useMemo(() => "emoji" in config, [config]);
  const getFilteredMentions = useCallback((param) => filterMentions(param, allMentions, config), [config, allMentions]);
  const [filterParameter, setFilterParameter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isNewLoad, setIsNewLoad] = useState(false); // Controls the first load when entering a valid parameter
  const [mentions, setMentions] = useState<Array<RemirrorMention>>([]);
  const mentionState = useMentionAtom({
    items: mentions,
  });
  const emojiState = useEmoji();

  const configNamesForChar: Record<string, Array<string>> = useMemo(() => {
    const names = {};
    Object.values(config).forEach((c: Preset[number]["config"]) => {
      if (!c.char) {
        return;
      }

      if (!names[c.char]) {
        names[c.char] = [];
      }
      names[c.char].push(c.name);
    });
    return names;
  }, [config]);

  useEffect(() => {
    const newParameter = createFilterParameter(mentionState?.state);
    const didChange = filterParameter?.name !== newParameter?.name || filterParameter?.query !== newParameter?.query;

    if (didChange) {
      if (newParameter && onSuggest) {
        setLoading(true);
        if (!filterParameter) {
          setIsNewLoad(true);
        }

        const paramNames = configNamesForChar[newParameter.char];
        onSuggest(paramNames, newParameter.query);
      }
      setFilterParameter(newParameter);
    }
  }, [mentionState?.state, filterParameter, onSuggest, isFocused, configNamesForChar]);

  useEffect(() => {
    setMentions(getFilteredMentions(filterParameter));
  }, [allMentions, filterParameter, getFilteredMentions]);

  useEffect(() => {
    setLoading(false);
    setIsNewLoad(false);
  }, [allMentions]);

  const createMention = useCallback(
    (index) => {
      if (mentions.length === 0 || index >= mentions.length) {
        return;
      }
      if (!filterParameter) {
        return;
      }

      const mention = mentions[index];
      if (onMentionSelect) {
        onMentionSelect(mention);
      }
      filterParameter.addMention(mention);
      // Needs to queue focus to properly reset state
      setTimeout(() => {
        focus();
        rem.commands.emptyUpdate();
      }, 0);
    },
    [rem, filterParameter, mentions, focus, onMentionSelect],
  );

  // If currently searching through emojis
  if (emojisEnabled && emojiState?.state?.list?.length) {
    return {
      suggestions: emojiState?.state?.list?.map(emojiMentionFormat),
      activeIndex: emojiState?.index || 0,
      createMention: (index) => {
        emojiState.state.apply(emojiState.state.list[index].emoji);
        // Needs to queue focus to properly reset state
        setTimeout(() => !isFocused && focus(), 0);
      },
      isActive: isFocused,
      loading: false,
    };
  }

  return {
    suggestions: isNewLoad ? [] : mentions,
    activeIndex: (mentionState?.index as number) || 0,
    createMention,
    isActive: !!mentionState?.state && isFocused,
    loading,
  };
};

export default useCustomMention;
