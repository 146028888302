import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OpenAPI } from "simplydo/interfaces";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "store";
import util from "utils/utils";
import api from "api";
import BaseChip, { ChipConfiguration } from "./BaseChip";
import { Icon, Menu, Popup } from "semantic-ui-react";
import actions from "actions";

type IUserChipBase = {
  user: Partial<OpenAPI.Schemas["User"]>;
  displayOnly?: boolean;
};

type IUserChip = IUserChipBase & ChipConfiguration;

function UserChip({ user, displayOnly, actionIcon, actionClick, actionTooltip, ...props }: IUserChip) {
  const [actionsOpen, setActionsOpen] = useState(false);

  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.user);
  const userCanListUsers = util.hasPermission(currentUser, "org.listUsers", currentUser?.ownerOrganisation?._id);

  const userId = user?._id;

  const ghost = useCallback(() => {
    util
      .confirm(
        "Really ghost as this user?",
        "Ghosting allows you to view the platform and carry out actions as though you are the user. You can un-ghost at any time by tapping the avatar at the top-right of the screen.",
      )
      .then(() =>
        api.users.ghost(
          userId,
          () => {
            navigate("/");
          },
          (err) => toast.error(err.message),
        ),
      )
      .catch(() => {});
  }, [navigate, userId]);

  const dispatch = useAppDispatch();
  const messageUser = useCallback(
    (member) => {
      api.messages.findThreadByUser(
        member._id,
        (data) => {
          dispatch(
            actions.messages.selectThread(
              data?.thread || { _id: `newThread:${member._id}`, participantUsers: [member] },
            ),
          );
        },
        (err) => toast.error(err.message),
      );
    },
    [dispatch],
  );

  const hasProfile = user && user.profile;
  if (!hasProfile) {
    user = {
      profile: {
        fullName: "Unknown User",
      },
    };
  }

  const canGhost =
    user?._id &&
    !(user?._id === currentUser?._id) &&
    util.hasPermission(currentUser, "super.ghostUser", user.ownerOrganisation);

  const { jobTitle = "", department = "" } = user.profile;
  let description = jobTitle;
  if (department) {
    if (jobTitle) {
      description += " - ";
    }
    description += department;
  }

  // If a user doesnt exist anymore just render a placeholder chip
  if (!hasProfile || displayOnly) {
    return (
      <BaseChip
        {...props}
        title={user.profile.fullName}
        description={description}
        imageUrl={util.avatarUrl(user)}
        labels={user.iconRoles}
        actionIcon={actionIcon}
        actionClick={actionClick}
        actionTooltip={actionClick ? actionTooltip : undefined}
        details={user?.profile?.tagline}
      />
    );
  }

  return (
    <Popup
      position="bottom left"
      basic
      pinned
      on="click"
      offset={[0, 4]}
      open={actionsOpen}
      onClose={() => setActionsOpen(false)}
      style={{ margin: 0, padding: "5px 10px 1px 10px" }}
      trigger={
        <BaseChip
          {...props}
          onClick={() => setActionsOpen(!actionsOpen)}
          title={user.profile.fullName}
          description={description}
          imageUrl={util.avatarUrl(user)}
          labels={user.iconRoles}
          actionIcon={actionIcon}
          actionClick={actionClick}
          actionTooltip={actionClick ? actionTooltip : undefined}
          details={user?.profile?.tagline}
        />
      }
      content={
        <Menu vertical secondary style={{ width: 150, zIndex: 999 }}>
          {userCanListUsers ? (
            <Menu.Item onClick={() => navigate(`/users/${userId}`)}>
              <Icon name="user outline" />
              View profile
            </Menu.Item>
          ) : null}
          {user._id !== currentUser?._id &&
          currentUser?.ownerOrganisation?.enabledFeatures.includes("directMessaging") ? (
            <Menu.Item
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActionsOpen(false);
                messageUser(user);
              }}
            >
              <Icon name="mail outline" />
              Message user
            </Menu.Item>
          ) : null}
          {canGhost ? (
            <Menu.Item
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setActionsOpen(false);
                ghost();
              }}
            >
              <Icon name="snapchat ghost" />
              Ghost user
            </Menu.Item>
          ) : null}
        </Menu>
      }
    />
  );
}

export default UserChip;
