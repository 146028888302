import uuid from "uuid";
import util from "utils/utils";

type SubscriptionOptions = {
  receiveErrorUpdates?: boolean;
  receiveUserUpdates?: boolean;
  quiet?: boolean;
};

type Subscription = {
  id: string;
  topic: string;
  onEvent: (event: any) => void;
  options: SubscriptionOptions;
};

type Message = {
  topic: string;
  options?: SubscriptionOptions;
  data?: {
    channel: string;
  };
};

type WebsocketAPI = {
  isConnected: boolean;
  isConnecting: boolean;
  subscriptions: Subscription[];
  queue: string[];
  failedConnections: number;
  socket: WebSocket | undefined;

  connect(generateToken: () => Promise<string | null>): void;
  disconnect(): void;
  subscribe(
    topic: string,
    onEvent: (event: any) => void,
    options?: SubscriptionOptions,
  ): {
    publish: (eventType: string, data?: Record<string, any>) => void;
    unsubscribe: () => void;
  };
  unsubscribeAll(): void;
  sendMessage(type: "subscribe" | "unsubscribe" | "publish", message: Message): void;
  sendOrQueue(item: string): void;
};

const websocketApi: WebsocketAPI = {
  isConnected: false,
  isConnecting: false,
  subscriptions: [],
  queue: [],
  failedConnections: 0,
  socket: undefined,

  connect(generateToken: () => Promise<string | null>) {
    if (!import.meta.env.VITE_WEBSOCKET_API_URL || websocketApi.isConnecting || websocketApi.isConnected) return;

    websocketApi.isConnecting = true;
    generateToken()
      .then((token) => {
        if (!token) {
          websocketApi.isConnecting = false;
          return;
        }

        let connectionString = `${import.meta.env.VITE_WEBSOCKET_API_URL}?token=${token}`;
        if (websocketApi.failedConnections > 0) {
          connectionString += `&retry=${websocketApi.failedConnections}`;
        }
        websocketApi.socket = new WebSocket(connectionString);

        websocketApi.socket.onopen = () => {
          websocketApi.isConnecting = false;
          websocketApi.isConnected = true;
          websocketApi.failedConnections = 0;

          websocketApi.queue.forEach((q) => {
            websocketApi.sendOrQueue(q);
          });
          websocketApi.queue = [];
        };

        websocketApi.socket.onmessage = (event) => {
          if (event && event.data) {
            const data = JSON.parse(event.data);
            websocketApi.subscriptions.forEach((s) => {
              if (s.topic === data.type && s.onEvent) {
                const { user, ...rest } = data.payload;
                if (user) {
                  user.avatarUrl = util.avatarUrl(user);
                }
                s.onEvent({ user, ...rest });
              }
            });
          }
        };

        websocketApi.socket.onclose = (event) => {
          websocketApi.isConnecting = false;
          if (websocketApi.isConnected) {
            websocketApi.isConnected = false;

            // Try to reconnect immediately, after a few tries, poll every 30 seconds
            const maxInstantRetries = 4;
            const retryTimeout = websocketApi.failedConnections < maxInstantRetries ? 0 : 30000;

            const retry = () => {
              websocketApi.socket = null;
              websocketApi.failedConnections += 1;
              setTimeout(() => websocketApi.connect(generateToken), retryTimeout);

              if (websocketApi.failedConnections >= maxInstantRetries) {
                websocketApi.subscriptions.forEach((s) => {
                  if (s.options.receiveErrorUpdates) {
                    s.onEvent({ channel: "__websocketError" });
                  }
                });
              }
            };

            if (event.wasClean) {
              // If not regular shut-down due to logout, retry.
              if (event.code !== 1000) {
                retry();
              }
            } else {
              retry();
            }
          }
        };

        websocketApi.socket.onerror = () => {
          websocketApi.isConnecting = false;
          websocketApi.isConnected = false;
        };
      })
      .catch(() => {
        websocketApi.isConnecting = false;
        websocketApi.isConnected = false;
      });
  },

  disconnect() {
    websocketApi.isConnected = false;
    websocketApi.isConnecting = false;
    if (websocketApi.socket && websocketApi.socket.readyState === WebSocket.OPEN) {
      // If invoked manually we disconnect with a message to clear the user topics
      websocketApi.socket.send(JSON.stringify({ action: "disconnect" }));
      websocketApi.socket.close();
    }
  },

  subscribe(
    topic,
    onEvent,
    options = {
      receiveUserUpdates: false,
      receiveErrorUpdates: false,
      quiet: false,
    },
    onSubscribe = () => {},
  ) {
    const id = uuid.v4();
    const subscription = {
      id,
      topic,
      onEvent,
      options,
    };
    const { receiveUserUpdates = false, quiet = false } = options;

    websocketApi.subscriptions.push(subscription);

    // Slightly delay subscriptions to prevent issues when rapidly subscribing/unsubscribing
    setTimeout(() => {
      websocketApi.sendMessage("subscribe", { topic, options: { receiveUserUpdates, quiet } });
      onSubscribe();
    }, 250);
    return {
      publish(eventType, data = {}) {
        if (!quiet) {
          websocketApi.sendMessage("publish", { topic, data: { channel: eventType, ...data } });
        }
      },
      unsubscribe() {
        const index = websocketApi.subscriptions.map((s) => s.id).indexOf(id);

        if (index > -1) {
          const sub = websocketApi.subscriptions[index];
          websocketApi.sendMessage("unsubscribe", { topic: sub.topic });
          websocketApi.subscriptions.splice(index, 1);
        }
      },
      topic,
      options,
    };
  },

  unsubscribeAll() {
    websocketApi.subscriptions.forEach((s) => {
      websocketApi.sendMessage("unsubscribe", { topic: s.topic });
    });
    websocketApi.subscriptions = [];
  },

  sendMessage(action, data) {
    websocketApi.sendOrQueue(
      JSON.stringify({
        action,
        ...data,
        _tstamp: Date.now(),
      }),
    );
  },

  sendOrQueue(message) {
    if (!websocketApi.isConnected) {
      // Only allow queueing of subscribe/unsubscribe messages, to prevent spamming the server is user disconnects and reconnects
      // Need to decode the message to check the action
      const { action } = JSON.parse(message);
      if (action === "subscribe" || action === "unsubscribe") {
        websocketApi.queue.push(message);
      }
    } else {
      websocketApi.socket.send(message);
    }
  },
};

export default websocketApi;
