/* eslint-disable no-nested-ternary */
import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { Icon, SemanticICONS, SemanticCOLORS, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { SideNavComponentCollapsibleProps } from "./SideNavComponent";
import useTheme from "theme/useTheme";

export type SideNavLinkProps = {
  to?: string;
  external?: boolean;
  showExternalIcon?: boolean;
  onClick?: () => void;
  content: ReactNode;
  tooltip?: ReactNode;
  iconLeft?: SemanticICONS | ReactNode;
  iconLeftAlt?: ReactNode;
  iconRight?: SemanticICONS | ReactNode;
  active?: boolean;
  compact?: "very";
  inset?: boolean;
  completed?: "done" | "almost";
  rightIcon?: any;
  secondary?: boolean;
  oneLine?: boolean;
} & (
  | {
      to: string;
    }
  | {
      onClick: () => void;
    }
);

const StyledSideNavLink = styled.div<{
  $active?: boolean;
  $compact?: string;
  $inset?: boolean;
  $secondary?: boolean;
  $isCollapsed?: boolean;
  $oneLine?: boolean;
}>`
  .sidebar-nav-link {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      display: inline-flex;
      ${({ $oneLine }) => ($oneLine ? "white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" : "")}
    }
    text-transform: capitalize;
    justify-content: space-between;
    width: ${({ $inset }) => ($inset ? "calc(100% - 30px)" : "100%")};
    padding: 5px;
    padding-top: ${({ $compact }) => ($compact === "very" ? "5px" : "10px")};
    padding-left: 10px;
    padding-bottom: ${({ $compact }) => ($compact === "very" ? "5px" : "10px")};
    font-size: ${({ $compact }) => ($compact === "very" ? "13.5px" : "15px")};
    background-color: ${({ $active }) => ($active ? "#d2dde5" : "none")};
    &:hover {
      background-color: ${({ $active }) => ($active ? "#d2dde5" : "#eef1f2")};
    }
    .icon-alt {
      margin: 0 15px 0 2.5px;
    }
    > * {
      color: ${({ $secondary }) => ($secondary ? "gray" : "black")} !important;
      display: block;
      font-weight: ${({ $active }) => ($active ? "bold" : "normal")};
    }
    border-radius: 5px;
    margin-left: ${({ $inset }) => ($inset ? "30px" : "auto")};
  }
  ${({ $isCollapsed }) =>
    $isCollapsed &&
    `
    margin: 0 !important;
    .sidebar-nav-link {
      padding-left: 11.5px;
      padding-top: ${({ $compact }) => ($compact === "very" ? "5.5px" : "10.5px")};
    }
  `}
`;

const createIconComponent = (icon: SemanticICONS | ReactNode, isCollapsed = false) => {
  if (!icon) {
    return null;
  }
  if (typeof icon === "string") {
    return <Icon style={{ marginRight: isCollapsed ? 0 : 10, height: 17 }} name={icon as SemanticICONS} />;
  }
  return icon;
};

const SideNavLinkContainerComponent = ({
  children,
  external,
  to,
  onClick,
}: {
  children: JSX.Element;
  external?: boolean;
  to: string;
  onClick?: () => void;
}) => {
  if (to) {
    if (external) {
      return (
        <a href={to} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    }
    return <Link to={to}>{children}</Link>;
  }
  return <div onClick={onClick}>{children}</div>;
};

const SideNavLink = ({
  to,
  content,
  tooltip,
  iconLeft,
  iconLeftAlt,
  iconRight,
  active,
  onClick,
  compact,
  inset,
  completed,
  external,
  showExternalIcon,
  rightIcon,
  isCollapsed,
  secondary,
  oneLine,
}: SideNavLinkProps & Partial<SideNavComponentCollapsibleProps>) => {
  const [colorMap] = useState<{ [key: string]: SemanticCOLORS }>({
    done: "green",
    almost: "orange",
  });
  const theme = useTheme();

  return (
    <Popup
      disabled={!theme.sizes.isComputer || (!tooltip && !isCollapsed)}
      position="right center"
      hoverable={!!tooltip}
      on="hover"
      content={tooltip || content}
      trigger={
        <StyledSideNavLink
          $active={active}
          $compact={compact}
          $inset={inset}
          $isCollapsed={theme.sizes.isComputer && isCollapsed}
          $secondary={secondary}
          $oneLine={oneLine}
        >
          <SideNavLinkContainerComponent external={external} to={to} onClick={onClick}>
            <div className="sidebar-nav-link">
              <div>
                {iconLeftAlt ? (
                  <span className="icon-alt">{iconLeftAlt}</span>
                ) : (
                  <>{createIconComponent(iconLeft, isCollapsed)}</>
                )}
                {!(theme.sizes.isComputer && isCollapsed) ? content : null}
              </div>
              {!(theme.sizes.isComputer && isCollapsed) ? (
                <div className="icons-right">
                  {external || showExternalIcon ? (
                    <Icon name="external alternate" size="small" style={{ marginLeft: 5, height: 17 }} />
                  ) : null}{" "}
                  {completed !== undefined && (
                    <Icon
                      name={completed === "done" ? "check circle" : "exclamation circle"}
                      style={{ marginLeft: 5, height: 20 }}
                      color={colorMap[completed] || "red"}
                    />
                  )}{" "}
                  {rightIcon && rightIcon}
                  {createIconComponent(iconRight)}
                </div>
              ) : null}
            </div>
          </SideNavLinkContainerComponent>
        </StyledSideNavLink>
      }
    />
  );
};

export default SideNavLink;
