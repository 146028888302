import { styled } from "styled-components";
import Color from "color";

// Component that sets/overrides a bunch of default stylings, particularly to set the font to Inter and to set gentler colors for semantic ui
const AppStyling = styled.div`
  flex: 1;
  max-width: 100vw;

  &,
  .ui,
  .ui :not(.icon),
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  span,
  input,
  p {
    font-family: "Inter";
    -webkit-font-smoothing: antialiased;
  }
  .ui.container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .ui.loader {
    z-index: auto;
  }

  .idea img {
    object-fit: contain;
    max-width: 100%;
  }

  #semantic-modal-mount-node.dimmed.dimmable {
    overflow: visible;
  }

  ${({ theme }) => {
    if (!theme) {
      return "";
    }

    let override = `
      a,a:hover{color:${theme.primaryColour};}
      a.with-border{border-bottom:1px dotted ${theme.primaryColour};}
      .primary-text{color: ${theme.primaryColour};}
    `;

    Object.entries(theme.colorMap || {}).forEach(([color, overrideColor]) => {
      override += `
          .ui.${color} {
            border-color: ${overrideColor} !important;
          }
          .ui.${color}.button, .ui.${color}.label {
            color: ${Color(overrideColor).darken(0.5)} !important;
          }
          i.icon.${color}.pastel {
            color: ${overrideColor} !important;
          }
          .ui.msteams.button {
            color: #fff !important;
          }
          .ui.${color}.label:not(.basic), .ui.${color}.button:not(.inverted, .basic) {
            background-color: ${overrideColor} !important;
          }
          .ui.${color}.button:not(.inverted, .basic):hover {
            background-color: ${Color(overrideColor).darken(0.1)} !important;
          }
          i.${color}.icon.inverted.pastel {
            background-color: ${overrideColor} !important;
            color: ${Color(overrideColor).darken(0.5)} !important;
          }
          .ui.${color}.button.inverted {
            background-color: transparent !important;
            box-shadow: 0 0 0 2px ${Color(overrideColor).darken(0.3)} inset !important;
            color: ${Color(overrideColor).darken(0.3)} !important;
          }
          .ui.${color}.button.inverted:hover {
            box-shadow: 0 0 0 2px ${Color(overrideColor).darken(0.5)} inset !important;
            background-color: ${Color(overrideColor).darken(0.3)} !important;
            color: #fff !important;
          }

          .ui.${color}.button.inverted.basic {
            box-shadow: 0 0 0 1px ${Color(overrideColor).darken(0.3)} inset !important;
          }

          .ui.${color}.button.inverted.basic:hover {
            box-shadow: 0 0 0 1px ${Color(overrideColor).darken(0.5)} inset !important;
          }
          `;
    });

    ["primary", "secondary"].map((classify) => {
      const background = theme[`${classify}Colour`];
      const shouldBeWhite = theme[`shouldBeWhiteOn${classify.charAt(0).toUpperCase() + classify.slice(1)}`];

      const color = shouldBeWhite ? "#fff" : "#000";

      override += `
        .ui.button.${classify} {
          background-color: ${background};
          color: ${color};
          outline: 0.5px solid ${Color(background).darken(0.1).hex()};

          &:hover {
            background-color: ${Color(background).darken(0.1).hex()};
            outline: 0.5px solid ${Color(background).darken(0.2).hex()};
          }

          &:active {
            background-color: ${Color(background).darken(0.2).hex()};
          }

          &:active {
            box-shadow: 0 0 3px 1px rgba(0, 0, 0, .15) inset, 0 1px 4px 0 ${Color(background).alpha(0.3).rgb()} inset;
            outline: 0.5px solid ${Color(background).darken(0.4).hex()};
          }

          &.basic:not(.inverted) {
            color: rgba(0, 0, 0, .6) !important;
            box-shadow: 0 0 0 1px rgba(34, 36, 38, .15) inset !important;
            outline: none !important;

            &:hover {
              background: ${background} !important;
              color: ${color} !important;
              box-shadow: 0 0 0 1px rgba(34, 36, 38, .35) inset, 0 0 0 0 rgba(34, 36, 38, .15) inset !important;
            }

            &:hover:active, &:active {
              background: ${Color(background).darken(0.2).hex()} !important;
              color: ${color} !important;
              box-shadow: 0 0 0 1px rgba(34, 36, 38, .35) inset, 0 0 0 0 rgba(34, 36, 38, .15) inset !important;
            }
          }

          &.inverted:not(.basic) {
            box-shadow: 0 0 0 2px ${background} inset !important;
            color: ${background};
            background-color: transparent;
            background: none;

            &:hover {
              box-shadow: none !important;
              background-color: ${background};
              color: ${color};
            }

            &:active {
              background-color: ${Color(background).darken(0.2).hex()};
            }

            &:active {
              box-shadow: 0 0 3px 1px rgba(0, 0, 0, .15) inset, 0 1px 4px 0 ${Color(background).alpha(0.15).rgb()} inset !important;
              outline: 0.5px solid ${Color(background).darken(0.3).hex()};
            }
          }

          &.inverted.basic {
            box-shadow: 0 0 0 2px ${Color(background).alpha(0.5).rgb()} inset !important;
            outline: 0.5px solid transparent !important;
            color: ${Color(background).darken(0.1).hex()} !important;
            background-color: transparent;
            background: none;

            &:hover {
              box-shadow: 0 0 0 2px ${background} inset !important;
            }

            &:active {
              background-color: ${Color(background).alpha(0.1).rgb()} !important;
            }
          }
        }
      `;
    });

    return override;
  }}
`;

export default AppStyling;
