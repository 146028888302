import React from "react";
import { Label, Popup } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { OpenAPI } from "simplydo/interfaces";
import { useTranslation } from "react-i18next";

export const TagContainer = styled.div<{
  $noMargin?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ $noMargin }) => (!$noMargin ? "5px" : "0px")};
  border-radius: 5px;
  &&& > * {
    margin: ${({ $noMargin }) => (!$noMargin ? "5px" : "0px")};
  }
`;

const StyledDisabledSimpleTag = styled(Label)`
  color: rgba(16, 16, 16, 0.3) !important;
  background-color: rgba(239, 239, 239, 0.3) !important;
  cursor: default;
`;

type TagComponentProps = {
  tag: OpenAPI.Schemas["Tag"];
  disabled?: boolean;
  onRemove?: (tag: OpenAPI.Schemas["Tag"]) => void;
  showCount?: boolean;
  onClick?: (tag: OpenAPI.Schemas["Tag"]) => void;
  noRedirect?: boolean;
};

const TagComponent = ({ tag, disabled, onRemove, showCount, onClick, noRedirect }: TagComponentProps) => {
  const navigate = useNavigate();

  if (!tag) {
    return null;
  }

  const TagLabel = disabled ? StyledDisabledSimpleTag : Label;

  return (
    <TagLabel
      key={tag._id}
      size="medium"
      pointing="left"
      as={(!onClick && noRedirect) || disabled ? null : "a"}
      onClick={(e) => {
        e.preventDefault();

        if (disabled) {
          return;
        }
        if (onClick) {
          onClick(tag);
        }
        if (!noRedirect) {
          navigate(`/tags/${tag._id}`);
        }
      }}
      onRemove={
        onRemove
          ? (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (disabled) {
                return;
              }
              onRemove(tag);
            }
          : null
      }
      content={showCount && "totalCount" in tag ? `${tag.value} (${tag.totalCount})` : tag.value}
      style={{ marginLeft: 2 }}
    />
  );
};

export type TagProps = Identity<
  TagComponentProps & {
    noPopup?: boolean;
  }
>;

export const Tag = ({ tag, noPopup, ...props }: TagProps) => {
  const { t } = useTranslation();
  if (!tag.description || noPopup) {
    return <TagComponent tag={tag} {...props} />;
  }
  return (
    <Popup
      trigger={
        <div>
          <TagComponent tag={tag} {...props} />
        </div>
      }
      on="hover"
    >
      <Popup.Content>
        <h5>{t("generic.aboutTags")}</h5>
        {tag.description}
      </Popup.Content>
    </Popup>
  );
};

export default Tag;
