import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Label, Icon, Comment, Pagination, Message, Image } from "semantic-ui-react";
import styled from "styled-components";

import FileChooser from "components/lib/Choosers/FileChooser/FileChooser";
import { GiphyPicker, GiphyGif } from "components/lib/Giphy";

import util from "utils/utils";

import MentionText from "components/lib/Editors/RichText/MentionText";
import FeedComment, { MediaContainer } from "./FeedComment";
import { InputContainer, ActionArea, ActionButton } from "./Actions";
import { OpenAPI } from "simplydo/interfaces";

const PaginationArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`;

const NoComments = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  span {
    display: block;
    font-size: 1.2em;
    font-weight: 600;
    color: #585858;
  }
`;

const DiscussionDiv = styled.div`
  .comment {
    display: block;
    clear: both;
  }
  .custom-margin {
    margin-top: 10px;
  }

  .pinned-comment {
    background-color: rgb(255, 255, 197) !important;
    padding: 10px 5px !important;
    margin-bottom: 9px !important;
    border-radius: 5px;
    box-shadow: 1px 1px 5px grey;
  }

  .feed-replies {
    margin: 0;
    padding: 0;
  }

  .ui.toggle.small.checkbox.custom-checkbox {
    margin-right: 20px;
  }

  .map.pin.icon {
    font-size: 20px;
  }

  .comment-img {
    max-width: 100%;
  }

  .comment-media-previews {
    margin: 8px 0px;
    .comment-file-preview {
      position: relative;
      width: 100%;
      height: 150px;
      text-align: center;
      border: 1px solid rgb(230, 230, 230);
      border-radius: 4px;
      margin: 5px;
      padding: 4px;
      display: inline-block;
      h2 {
        margin-top: 0px;
      }
    }
    .comment-image-preview {
      position: relative;
      width: 100%;
      height: 150px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin: 5px;
      padding: 5px;
      display: inline-block;
    }
    .button.js-delete-comment-media {
      position: absolute;
      top: 5px;
      left: 0px;
      opacity: 0;
      transition: opacity 0.5s;
    }
    &:hover {
      .button {
        opacity: 1;
      }
    }
  }
`;

// There are more types of feeds but these two cover all cases
export type Comment = OpenAPI.GET<"/groups/{id}/feed">["response"]["feed"][number] &
  OpenAPI.GET<"/ideas/{id}/comments">["response"]["comments"][number];

type FeedViewProps = {
  loading: boolean;
  commentLoading: boolean;
  feed: Comment[];
  getReplyData?: (commentId: string) => { text: string; media: Comment["media"] };
  commentMedia: any[];
  addAttachment: (file: any) => void;
  addGiphy: (gif: string) => void;
  deleteAttachment: (index: number, parentId?: string) => void;
  deleteComment: (commentId: string) => void;
  approveComment: (commentId: string) => void;
  rejectComment: (commentId: string) => void;
  getFile: (media: Comment["media"][number]) => string;
  reactComment: (commentId: string, reaction: string) => void;
  fileChooserForType: string;
  fileChooserForId: string;
  commentText: string;
  updateComment: (text: string) => void;
  updateReply: (parentId: string, text: string) => void;
  placeholder: string;
  postText: string;
  canManage: boolean;
  canPost: boolean;
  post: () => void;
  postReply: (parentId: string) => void;
  page: number;
  nextPageAvailable: boolean;
  previousPageAvailable: boolean;
  onPageChange: (page: number) => void;
  total: number;
  limit: number;
  type: string;
  message: string;
  pin: (commentId: string) => void;
  unpin: (commentId: string) => void;
  disableAttachments: boolean;
  noCommentsText: string;
  disableMentions?: boolean;
  disableReacts?: boolean;
  disableReplies?: boolean;
  authors?: string[];
  moderationEnabled?: boolean;
};

const FeedView = (props: FeedViewProps) => {
  const {
    loading,
    commentLoading,
    // Feed comments
    feed = [],
    commentMedia = [],
    getReplyData,
    // Attachment/file uploading
    addAttachment,
    addGiphy: propAddGiphy,
    deleteAttachment: propDeleteAttachment,
    fileChooserForType,
    fileChooserForId,
    commentText,
    updateComment,
    updateReply,
    placeholder,
    postText,
    // Comment functions
    deleteComment,
    approveComment,
    moderationEnabled,
    rejectComment,
    getFile,
    reactComment,
    authors,
    // Management props to test whether user can alter settings
    canManage,
    canPost,
    // Function called on comment post
    post,
    postReply,
    page,
    nextPageAvailable,
    previousPageAvailable,
    onPageChange,
    total,
    limit,
    // Discussion context e.g. ideas, challenge etc
    type,
    message,
    // pin functions
    pin,
    unpin,
    // Meta
    noCommentsText,
    disableAttachments,
    disableReacts,
    disableReplies,
    disableMentions,
  } = props;
  const { t } = useTranslation();

  const [giphyPickerOpen, setGiphyPickerOpen] = useState(false);
  const [recommendPinning, setRecommendPinning] = useState(false);
  const editorRef = useRef();

  useEffect(() => {
    if (type !== "idea") {
      const noPinnedComments = feed.findIndex((c) => c.isPinned);
      setRecommendPinning(noPinnedComments === -1);
    }
  }, [feed, type]);

  const isLastChild = useCallback(
    (comment) => {
      const index = feed
        .slice()
        .reverse()
        .findIndex((c) => !c.parent);
      const count = feed.length - 1;
      const finalIndex = index >= 0 ? count - index : index;
      const finalComment = feed[finalIndex];
      return finalComment?._id === comment._id;
    },
    [feed],
  );

  const onAddGiphy = useCallback(
    (gif: string) => {
      setGiphyPickerOpen(false);
      propAddGiphy(gif);
    },
    [propAddGiphy],
  );

  const addAutoMention = useCallback(
    (parentCommentId: string, user: OpenAPI.Schemas["User"]) => {
      /*
      - The comment box uses rich text to allow various mention compatibility, however the replyText is a plain string
      - The comment box thus manages it's own state, and sends onChanges back here when the HTML changes
      - This means we can't just run updateComment(), because it won't update the reply box
      - Therefore we supply a special insertion string: <?insert!>
      - The Mention box will recognise this special string, and know to insert this text to it's HTML, while removing the special string itself
      - We also need to add the mention as HTML, rather than the standard "(@)[Name](_id:id_here)" format
      - So we supply an a element instead
    */
      const newComment = `(@)[${user.profile.fullName}](_id:${user._id}) <?insert!>`;
      // We also need the regular mention - Else the API won't recognise a mention is contained in this text, and be unable
      updateReply(parentCommentId, newComment);
    },
    [updateReply],
  );

  const deleteAttachment = useCallback(
    (index: number, parentId?: string) => {
      util
        .confirm(t("feeds.attachments.delete.title"), t("feeds.attachments.delete.info"))
        .then(() => {
          propDeleteAttachment(index, parentId);
        })
        .catch(() => {});
    },
    [propDeleteAttachment, t],
  );

  const totalPages = Math.ceil(total / limit);

  return (
    <DiscussionDiv>
      {message ? <Message size="tiny">{message}</Message> : null}
      {(canPost || canManage) && (
        <>
          {recommendPinning && canManage && (
            <Message
              size="tiny"
              info
              style={{
                display: "inline-block",
                width: "100%",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <Message.Header>Why not pin a post?</Message.Header>
              <p>
                Pinning a post can be useful to explain the purpose of the discussion and helps to drive user
                engagement. To pin a post, find an existing entry in the feed below and tap "Pin comment".
              </p>
            </Message>
          )}
          {canPost ? (
            <InputContainer>
              <MentionText
                placeholder={placeholder}
                value={commentText}
                onChange={(value) => updateComment(value)}
                editorRef={editorRef}
              />

              <MediaContainer $margined>
                {commentMedia.map((file, index) => {
                  switch (file.type) {
                    case "gif":
                      return (
                        <GiphyGif
                          onClickGif={() => deleteAttachment(index)}
                          canDelete={true}
                          key={file.value}
                          gif={file.data}
                          size="fixed_small"
                        />
                      );
                    case "image":
                      return (
                        <div style={{ position: "relative" }}>
                          <Image
                            bordered
                            rounded
                            spaced
                            src={file.signedDownloadRequest}
                            width={75}
                            height={75}
                            style={{ objectFit: "cover" }}
                          />
                          <Icon
                            name="close"
                            onClick={() => deleteAttachment(index)}
                            style={{ cursor: "pointer", position: "absolute", top: 3, right: 8 }}
                          />
                        </div>
                      );
                    default:
                      return (
                        <Label key={file.value}>
                          {file.name} <Icon name="close" onClick={() => deleteAttachment(index)} />
                        </Label>
                      );
                  }
                })}
              </MediaContainer>

              <ActionArea>
                <div>
                  {!disableMentions ? (
                    <ActionButton
                      title={t("feed.mention")}
                      position="top left"
                      iconName="user"
                      onClick={(e) => {
                        e.preventDefault();
                        const newComment = `${commentText}@`;
                        updateComment(newComment);
                        setTimeout(() => {
                          if (editorRef.current) {
                            // @ts-ignore
                            editorRef.current.focus(100000);
                          }
                        }, 150);
                      }}
                    />
                  ) : null}
                  {!disableAttachments && (
                    <>
                      <FileChooser
                        forType={fileChooserForType}
                        forId={fileChooserForId}
                        trigger={<ActionButton title={t("feeds.attachments.attach")} iconName="paperclip" />}
                        onComplete={addAttachment}
                      />
                      {propAddGiphy ? (
                        <GiphyPicker
                          onChoose={onAddGiphy}
                          trigger={
                            <ActionButton
                              title={t("feeds.attachments.gif")}
                              iconName="file image outline"
                              onClick={() => setGiphyPickerOpen(true)}
                            />
                          }
                          open={giphyPickerOpen}
                          onClose={() => setGiphyPickerOpen(false)}
                        />
                      ) : null}
                    </>
                  )}
                </div>
                <ActionButton
                  iconName={commentLoading ? "spinner" : "send"}
                  onClick={() => post()}
                  disabled={!(commentText || commentMedia.length)}
                  content={postText || t("feeds.post")}
                />
              </ActionArea>
            </InputContainer>
          ) : null}
        </>
      )}

      {loading && <Loader active inline="centered" />}
      {!loading && !feed.length && (
        <NoComments>
          <span>{noCommentsText || t("challenge.discussion.noneTitle")}</span>
        </NoComments>
      )}

      {feed.length > 0 && (
        <Comment.Group style={{ margin: "1em 0", maxWidth: "100%" }}>
          {feed
            .filter((i) => i.owner)
            .map((item) => {
              if (!item.parent)
                return (
                  <FeedComment
                    key={item._id}
                    isLastChild={isLastChild(item)}
                    comment={item}
                    getReplyData={getReplyData}
                    onAddGiphy={propAddGiphy}
                    onAddAttachment={addAttachment}
                    addAutoMention={addAutoMention}
                    canManage={canManage}
                    feed={feed}
                    updateReply={updateReply}
                    deleteComment={deleteComment}
                    approveComment={approveComment}
                    moderationEnabled={moderationEnabled}
                    rejectComment={rejectComment}
                    deleteAttachment={deleteAttachment}
                    getFile={getFile}
                    pin={pin}
                    unpin={unpin}
                    reactComment={reactComment}
                    authors={authors}
                    commentLoading={commentLoading}
                    disableReacts={disableReacts}
                    disableAttachments={disableAttachments}
                    disableReplies={disableReplies}
                    canPost={canPost}
                    onPostReply={postReply}
                    fileChooserForType={fileChooserForType}
                    fileChooserForId={fileChooserForId}
                  />
                );
              return null;
            })}
        </Comment.Group>
      )}

      {page && total && (nextPageAvailable || previousPageAvailable) ? (
        <PaginationArea>
          <Pagination
            onPageChange={(e, { activePage }) => onPageChange(activePage as number)}
            activePage={page}
            totalPages={totalPages}
            boundaryRange={1}
            siblingRange={1}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
          />
        </PaginationArea>
      ) : null}
    </DiscussionDiv>
  );
};

export default FeedView;
