import React, { useMemo, useCallback } from "react";
import { useRemirrorContext } from "@remirror/react";
import { Icon, Popup } from "semantic-ui-react";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import { useFocusState } from "./hooks/useFocusState";

const MenuBar = styled.div.attrs(() => ({ className: "ProseMirror-menubar" }))<{
  $sticky: boolean;
  $stickyOffset: number;
}>`
  display: "flex";
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
  ${({ $sticky, $stickyOffset, theme }) =>
    $sticky
      ? `
    position: sticky;
    min-height: ${theme.sizes.isMobile ? 100 : 40}px;
    top: ${$stickyOffset - (theme.sizes.isMobile ? 10 : 0)}px;
    z-index: 1;
    background-color: white;
    padding: 5px 5px;
    box-shadow: 0 0 0 1px rgb(0, 0, 0, 0.1);
    margin-bottom: 10px;
    border-radius: 5px;
  `
      : ""}
`;

const Align = styled.div<{ $right?: boolean }>`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ $right }) =>
    $right
      ? `
    justify-content: flex-end;
  `
      : ""}
`;

const Separator = styled.div`
  ${({ theme }) =>
    theme.sizes.isMobile
      ? `
    display: none;
  `
      : ""}
  width: 1px;
  background-color: #aaa;
  height: 15px;
  align-self: center;
  justify-self: center;
  margin-left: 2px;
  margin-right: 5px;
`;

const IconWrapper = styled.div<{ $isActive: boolean; $disabled: boolean }>`
  border-radius: 4px;
  overflow: hidden;
  padding: 5px;
  margin-right: 3px;
  ${({ $disabled, $isActive }) =>
    $disabled
      ? `
    opacity: 0.5;
  `
      : `
    cursor: pointer;
    &:hover {
      background-color: rgba(0,0,0,0.1);
    }
    &:active {
      background-color: none;
      box-shadow: inset 2px 3px 8px 0px rgba(0, 0, 0, 0.15);
    }
  ${
    $isActive
      ? `
      background-color: none;
      box-shadow: inset 4px 6px 11px 4px rgba(0,0,0,0.25);
    `
      : ""
  }
  `}
`;

const StyledIcon = styled(Icon)`
  && {
    flex-direction: row;
    font-size: 16px;
    position: relative;
    top: ${({ $top }) => $top || 1}px;
    left: ${({ $left }) => $left || 2}px;
  }
`;

const IconChar = styled.div`
  display: inline;
  left: -2px;
  vertical-align: top;
  top: -1px;
  position: relative;
`;

type MenuIconProps = {
  isActive: boolean;
  name: string;
  onClick?: () => void;
  children?: React.ReactNode;
  top?: number;
  left?: number;
  style?: React.CSSProperties;
  disabled?: boolean;
};

const MenuIcon = ({ isActive, onClick, children, top, left, name, style, disabled, ...props }: MenuIconProps) => (
  <IconWrapper onClick={onClick} $isActive={isActive} $disabled={disabled} style={style} {...props}>
    <StyledIcon $top={top} $left={left} name={name} />
    {children}
  </IconWrapper>
);

const createGroup = (items) => {
  if (!items?.length) return null;

  return (
    <>
      {items.map((item, index) => {
        const { MenuItem = ({ RenderItem }) => <RenderItem /> } = item;
        const DefaultItem = () => (
          <MenuIcon
            key={item.name}
            name={item.icon || item.name}
            top={item.top}
            disabled={item.disabled}
            onClick={() => {
              const attrs = {
                name: item.name,
                commands: item.commands,
                selectedText: item.selectedText,
                isActive: item.isActive,
              };
              item.onClick(attrs);
            }}
            isActive={item.isActive}
          >
            {item.content}
          </MenuIcon>
        );
        return <MenuItem key={index} RenderItem={DefaultItem} />;
      })}
      <Separator />
    </>
  );
};

const Menu = ({ extraMenuItems = [], HelpText, sticky, stickyOffset, historyEnabled, autoHideMenu }) => {
  const rem = useRemirrorContext({ autoUpdate: true });
  const isFocused = useFocusState();

  const theme = useTheme();

  const { getState, active, chain, commands } = rem;
  const { selection, doc, history$ } = getState();

  // Enable automatic chaining of refocus event after button press
  const autoRefocus = useMemo(() => {
    const mapped: any = Object.keys(chain).reduce((p, k) => {
      p[k] = (param) => chain[k](param).focus().run();
      return p;
    }, {});

    mapped.createCustomLink = (data) => {
      const { text, href } = data;
      const url = text || href;
      // Insert text if there is no selection, otherwise just set the link
      if (selection.from === selection.to) {
        chain
          .insertText(`${url} `)
          .focus()
          .updateLink({ href }, { from: selection.from, to: selection.from + url.length })
          .run();
      } else {
        chain.focus().updateLink({ href }).run();
      }
    };

    mapped.insertLink = (data) => {
      const { href } = data;
      const url = href;
      chain
        .insertText(`${url} `)
        .focus()
        .updateLink({ href }, { from: selection.from, to: selection.from + url.length })
        .run();
    };

    return mapped;
  }, [chain, selection]);

  const activeCreateGroup = useCallback(
    (items) => {
      const filtered = items.filter((i) => !!active[i.name] || i.forceEnable);
      let selectedText = "";
      try {
        // this call may fail if the text was updated through an outside source such as a collaborator
        selectedText = doc.cut(selection.from, selection.to).textContent;
      } catch (e) {
        // Do nothing
      }

      return createGroup(
        filtered.map((i) => {
          const cap = i.name[0].toUpperCase() + i.name.slice(1);
          const fallback = () => (autoRefocus[`toggle${cap}`] || (() => {}))();
          let isActive = false;
          try {
            // Same issue as above
            isActive = (i.active || active[i.name] || (() => false))();
          } catch (e) {
            // Do nothing
          }
          return {
            ...i,
            onClick: i.onClick || fallback,
            commands: autoRefocus,
            isActive,
            selectedText,
          };
        }),
      );
    },
    [active, doc, selection, autoRefocus],
  );

  if (autoHideMenu && !isFocused) {
    return null;
  }

  return (
    <MenuBar
      $sticky={sticky}
      $stickyOffset={stickyOffset}
      theme={theme}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
        const focusableTarget = e.target as HTMLElement;
        focusableTarget.focus?.();
      }}
    >
      <Align>
        {activeCreateGroup([
          { name: "bold" },
          { name: "italic" },
          { name: "underline", top: 2 },
          { name: "strike", icon: "strikethrough" },
        ])}

        {activeCreateGroup([
          {
            name: "heading",
            onClick: () => autoRefocus.toggleHeading({ level: 1 }),
            active: () => active.heading({ level: 1 }),
            content: <IconChar>1</IconChar>,
          },
          {
            name: "heading",
            onClick: () => autoRefocus.toggleHeading({ level: 2 }),
            active: () => active.heading({ level: 2 }),
            content: <IconChar>2</IconChar>,
          },
        ])}

        {activeCreateGroup([
          { name: "bulletList", icon: "list ul" },
          { name: "orderedList", icon: "list ol" },
        ])}

        {activeCreateGroup([
          {
            icon: "align left",
            name: "alignment",
            onClick: () => autoRefocus.removeAlignment(),
            active: () => !active.alignment(),
          },
          {
            icon: "align center",
            name: "alignment",
            active: () => active.alignment({ direction: "center" }),
            onClick: () => autoRefocus.setAlignment({ direction: "center" }),
          },
          {
            icon: "align right",
            name: "alignment",
            active: () => active.alignment({ direction: "right" }),
            onClick: () => autoRefocus.setAlignment({ direction: "right" }),
          },
        ])}

        {activeCreateGroup(extraMenuItems)}
      </Align>
      <Align $right>
        {historyEnabled
          ? activeCreateGroup([
              {
                icon: "undo alternate",
                name: "undo",
                onClick: () => commands.undo(),
                disabled: history$.done.eventCount === 0,
                forceEnable: true,
              },
              {
                icon: "redo alternate",
                name: "redo",
                onClick: () => commands.redo(),
                disabled: history$.undone.eventCount === 0,
                forceEnable: true,
              },
            ])
          : null}
        {HelpText ? (
          <Popup
            position="top right"
            wide="very"
            inverted
            on="hover"
            trigger={
              <div>
                <MenuIcon isActive={false} name="help" />
              </div>
            }
            content={HelpText}
            header="Tips"
          />
        ) : null}
      </Align>
    </MenuBar>
  );
};

export default Menu;
