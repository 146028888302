import React from "react";
import RichText, { RichTextProps } from "./RichText";

// For some reason we allow to set `content` as well as value for forwarding this, however because of the spread operation actually EITHER content or value are allowed
type RichTextViewerProps = {
  content?: RichTextProps["value"];
  value?: RichTextProps["value"];
} & Omit<RichTextProps, "readOnly" | "value" | "onChange" | "onChangeJson">;

const RichTextViewer = ({ content, ...props }: RichTextViewerProps) => {
  return <RichText readOnly value={content} {...props} />;
};

export default RichTextViewer;
