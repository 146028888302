import {
  BoldExtension,
  StrikeExtension,
  HeadingExtension,
  ItalicExtension,
  UnderlineExtension,
  ImageExtension,
  BulletListExtension,
  ListItemExtension,
  OrderedListExtension,
  EmojiExtension,
  LinkExtension,
} from "remirror/extensions";
import emojiData from "svgmoji/emoji.json";
import { CustomIFrameExtension } from "./custom/iframe";

const extensionConstructor =
  (Cls) =>
  (options = {}) =>
    new Cls(options);

const defaultExtensions = {
  image: extensionConstructor(ImageExtension),
  heading: extensionConstructor(HeadingExtension),
  link: extensionConstructor(LinkExtension),
  iframe: extensionConstructor(CustomIFrameExtension),
  emoji: extensionConstructor(EmojiExtension),
  list: extensionConstructor(ListItemExtension),
  bulletList: extensionConstructor(BulletListExtension),
  orderedList: extensionConstructor(OrderedListExtension),
  bold: extensionConstructor(BoldExtension),
  strike: extensionConstructor(StrikeExtension),
  italic: extensionConstructor(ItalicExtension),
  underline: extensionConstructor(UnderlineExtension),
};

export const DefaultPreset = [
  { name: "image" },
  { name: "heading", config: { levels: [1, 2] } },
  { name: "emoji", config: { data: emojiData, moji: "openmoji", suggestionCharacter: ":", plainText: true } },
];

export default defaultExtensions;
