import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { ImageWithFallback } from "./ImageWithFallback";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Button, Icon, Message, Modal, Popup, Table } from "semantic-ui-react";
import moment from "moment";
import { UserChip } from "./Chips";
import util from "utils/utils";
import { OpenAPI } from "simplydo/interfaces";
import { useRef } from "react";

const ReadonlyEmail = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;

  cursor: default;

  display: flex;
  justify-content: center;

  overflow: hidden;

  iframe {
    overflow: hidden;
    border: none;
    width: 500px;

    border: none;
    display: block;
  }
`;

const CenterBreadcrumb = styled.div`
  display: flex;
  align-items: center;
`;

const Chip = ({ image, name, to, fallbackSrc }) => (
  <Link style={{ display: "flex", alignItems: "center" }} to={to}>
    <ImageWithFallback avatar src={image} fallbackSrc={fallbackSrc} style={{ objectFit: "contain" }} />
    <span>{name}</span>
  </Link>
);

type EmailLogModalProps = {
  log: OpenAPI.GET<"/organisations/{org_id}/communication/emails/logs/{log_id}">["response"]["log"];
  onClose: () => void;
};

export const EmailLogModal = ({ log, onClose }: EmailLogModalProps) => {
  const { t } = useTranslation();
  const frameRef = useRef();

  if (!log) return;

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open
      onClose={onClose}
      closeOnDimmerClick
      closeOnEscape
    >
      <Modal.Header>Email overview</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <h4>
            The following email was sent on {moment(log?.sentAt).format("DD/MM/YYYY")} at{" "}
            {moment(log?.sentAt).format("HH:mm")}
          </h4>
          <Table>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Subject</Table.Cell>
                <Table.Cell>{log?.subject}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>Notification type</Table.Cell>
                <Table.Cell>{log?.notificationType}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Recipient</Table.Cell>
                <Table.Cell>
                  <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
                    {log?.ownerUser ? <UserChip user={log?.ownerUser} /> : null}
                    <p>
                      Email:
                      <br />
                      <a href={`mailto:${log?.recipientEmail}`}>{log?.recipientEmail}</a>
                    </p>
                  </div>
                </Table.Cell>
              </Table.Row>
              {log?.ownerChallenge ? (
                <Table.Row>
                  <Table.Cell>{t("common:capitalise", { key: "generic.challenge" })}</Table.Cell>
                  <Table.Cell>
                    <Chip
                      name={log?.ownerChallenge?.name}
                      image={log?.ownerChallenge?.coverImageUrl}
                      fallbackSrc={util.challengeImage()}
                      to={`/challenges/${log?.ownerChallenge?._id}`}
                    />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {log?.ownerIdea ? (
                <Table.Row>
                  <Table.Cell>{t("common:capitalise", { key: "generic.idea" })}</Table.Cell>
                  <Table.Cell>
                    <Chip
                      name={log?.ownerIdea?.name}
                      image={log?.ownerIdea?.coverImageUrl}
                      fallbackSrc={util.ideaCoverImage()}
                      to={`/ideas/${log?.ownerIdea?._id}`}
                    />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {log?.ownerGroup ? (
                <Table.Row>
                  <Table.Cell>Group</Table.Cell>
                  <Table.Cell>
                    <Chip
                      name={log?.ownerGroup?.name}
                      image={log?.ownerGroup?.coverImageUrl}
                      fallbackSrc={util.groupCoverImage()}
                      to={`/groups/${log?.ownerGroup?._id}`}
                    />
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </Table.Body>
          </Table>
          <h4>Delivery status:</h4>
          <Message info>
            The status of emails may not be reflected in real-time. Additionally, be aware that users may be using
            software which blocks certain status updates (such as detecting opening) from registering in our system.
            <br />
            <br />
            You can hover over individual status events to learn more about their meaning.
          </Message>
          <Breadcrumb style={{ display: "flex" }}>
            <Popup
              trigger={
                <CenterBreadcrumb>
                  <Breadcrumb.Section>
                    <strong>Generated</strong>
                    <br />
                    {moment(log?.sentAt).format("MMM DD, HH:mm")}
                  </Breadcrumb.Section>
                </CenterBreadcrumb>
              }
              position="top center"
              content="The email has been created and was passed on to the mail server to be processed."
            />
            {log?.events?.processed ? (
              <Popup
                trigger={
                  <CenterBreadcrumb>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Breadcrumb.Section>
                      <strong>Processed</strong>
                      <br />
                      {moment(log?.events?.processedAt).format("MMM DD, HH:mm")}
                    </Breadcrumb.Section>
                  </CenterBreadcrumb>
                }
                position="top center"
                content="The email has been processed by the mail server and is ready to be sent."
              />
            ) : null}
            {log?.events?.deferred ? (
              <Popup
                trigger={
                  <CenterBreadcrumb>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Breadcrumb.Section>
                      <strong>Deferred {!log?.events?.delivered ? <Icon name="wait" color="grey" /> : null}</strong>
                      <br />
                      {moment(log?.events?.deferredAt).format("MMM DD, HH:mm")}
                    </Breadcrumb.Section>
                  </CenterBreadcrumb>
                }
                position="top center"
                content="The mail server temporarily rejected this message. Re-delivery will be attempted shortly. If this issue persists please contact us."
              />
            ) : null}
            {log?.events?.delivered ? (
              <Popup
                trigger={
                  <CenterBreadcrumb>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Breadcrumb.Section>
                      <strong>Delivered {!log?.events?.opened ? <Icon name="check" color="green" /> : null}</strong>
                      <br />
                      {moment(log?.events?.deliveredAt).format("MMM DD, HH:mm")}
                    </Breadcrumb.Section>
                  </CenterBreadcrumb>
                }
                position="top center"
                content="The email has succesfully been delivered to the user's inbox."
              />
            ) : null}
            {log?.events?.opened ? (
              <Popup
                trigger={
                  <CenterBreadcrumb>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Breadcrumb.Section>
                      <strong>
                        Opened <Icon name="check" color="green" />
                      </strong>
                      <br />
                      {moment(log?.events?.openedAt).format("MMM DD, HH:mm")}
                    </Breadcrumb.Section>
                  </CenterBreadcrumb>
                }
                position="top center"
                content="The user has opened the email. Please note that depending on the user's email client and privacy settings this may not be accurate and/or may not be reported at all."
              />
            ) : null}
            {log?.events?.bounce || log?.events?.dropped ? (
              <Popup
                trigger={
                  <CenterBreadcrumb>
                    <Breadcrumb.Divider icon="right arrow" />
                    <Breadcrumb.Section>
                      <strong>
                        Not delivered <Icon name="close" color="red" />
                      </strong>
                      <br />
                      {moment(log?.events?.bounceAt || log?.events?.droppedAt).format("MMM DD, HH:mm")}
                    </Breadcrumb.Section>
                  </CenterBreadcrumb>
                }
                position="top center"
                content="It looks like an issue prevented this email from being sent correctly. Please double check the recipient's email address and try again. If this issue persists please contact us."
              />
            ) : null}
          </Breadcrumb>
          {log.html ? (
            <>
              <h4>Message content:</h4>
              <Message warning>
                Please be aware that this email has already been delivered, you should not interact with the contents of
                this email and use it purely as a source of information.
              </Message>
              <ReadonlyEmail
                onClickCapture={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <iframe
                  scrolling="no"
                  ref={frameRef}
                  onLoad={() => {
                    if (!frameRef.current) {
                      return;
                    }
                    frameRef.current.style.height = frameRef.current.contentWindow.document.body.scrollHeight + "px";
                  }}
                  srcDoc={
                    log.html.replace('src="cid:bannerImage"', 'src="cid:bannerImage" height="150"') +
                    `<style>body { pointer-events: none; } table { max-width: 500px !important; } img { max-width: 460px !important; } </style>`
                  }
                  title="Email content"
                />
              </ReadonlyEmail>
            </>
          ) : null}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose()}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
};
