import React, { useState, useMemo, ReactElement, JSXElementConstructor, cloneElement } from "react";
import { Form, Modal, Label, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import util from "utils/utils";

import Tag, { TagContainer, TagProps } from "components/lib/Tag";
import { OpenAPI } from "simplydo/interfaces";

type TagViewProps = {
  tags: OpenAPI.Schemas["Tag"][];
  hideView?: boolean;
  noMargin?: boolean;
  children?: React.ReactNode;
} & Omit<TagProps, "tag">;

export const TagView = ({ tags, hideView, noMargin, children, ...tagProps }: TagViewProps) =>
  hideView ? (
    children
  ) : (
    <TagContainer $noMargin={noMargin}>
      {tags ? tags.map((t) => <Tag tag={t} key={t._id} {...tagProps} />) : null}
      {children}
    </TagContainer>
  );

const TagChooserContent = ({ tags, allTags, removeTag, addTag, showCount }) => {
  const tagIds = useMemo(() => tags.map((c) => c._id), [tags]);
  const { t } = useTranslation();
  const { selectedTags = [], availableTags = [] } = allTags.reduce((p, c) => {
    p.selectedTags = p.selectedTags || [];
    p.availableTags = p.availableTags || [];

    if (tagIds.includes(c._id)) {
      p.selectedTags.push(c);
    } else {
      p.availableTags.push(c);
    }
    return p;
  }, {});
  const tagsRemaining = tags?.length ? 4 - tags.length : 4;
  return (
    <Form>
      {tags?.length > 0 && <h5>Selected tags</h5>}
      {tags?.length > 0 && (
        <Label size="tiny" color={tagsRemaining === 0 ? "orange" : undefined} basic>
          {util.pluralise(tagsRemaining, "tag", "tags")} remaining
        </Label>
      )}
      <TagView
        tags={selectedTags}
        showCount={showCount}
        noRedirect
        onClick={(t) => {
          removeTag(t);
        }}
        onRemove={(t) => {
          removeTag(t);
        }}
      />
      <h5>{t("generic.tags")}</h5>
      <p style={{ marginBottom: 0, color: tagsRemaining === 0 && "grey" }}>{t("tags.click")}</p>
      <TagView
        tags={availableTags}
        noRedirect
        showCount={showCount}
        disabled={tagsRemaining === 0}
        onClick={(t) => {
          if (!tagIds.includes(t._id)) {
            addTag(t);
          }
        }}
      />
    </Form>
  );
};

type TagModalChooserProps = {
  tags: OpenAPI.Schemas["Tag"][];
  availableTags?: OpenAPI.Schemas["Tag"][];
  removeTag?: (tag: OpenAPI.Schemas["Tag"]) => void;
  addTag?: (tag: OpenAPI.Schemas["Tag"]) => void;
  trigger?: ReactElement<any, string | JSXElementConstructor<any>>;
  noRedirect?: boolean;
  canEdit?: boolean;
  showCount?: boolean;
  viewCanRemove?: boolean;
  onStateChange?: (state: boolean) => void;
  hideView?: boolean;
  noMargin?: boolean;
  isChallenge?: boolean;
};

const TagModalChooser = ({
  tags = [],
  availableTags = [],
  removeTag,
  addTag,
  trigger,
  noRedirect,
  canEdit = true,
  showCount = true,
  viewCanRemove,
  onStateChange,
  hideView = false,
  noMargin,
  isChallenge,
}: TagModalChooserProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const triggerElement = useMemo(
    () =>
      trigger
        ? cloneElement(trigger, {
            onClick: () => {
              onStateChange && onStateChange(true);
              setModalOpen(true);
            },
          })
        : null,
    [trigger, onStateChange],
  );
  const allTags = availableTags || [];

  if (!canEdit) {
    return <TagView tags={tags} />;
  }

  if (!trigger) {
    return (
      <TagChooserContent tags={tags} allTags={allTags} removeTag={removeTag} addTag={addTag} showCount={showCount} />
    );
  }

  if (availableTags.length === 0) {
    return null;
  }
  return (
    <>
      <TagView
        tags={tags}
        noRedirect={noRedirect}
        onRemove={viewCanRemove ? removeTag : null}
        hideView={hideView}
        noMargin={noMargin}
      >
        {triggerElement}
      </TagView>

      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={modalOpen}
        closeOnDimmerClick
        onClose={() => {
          onStateChange && onStateChange(false);
          setModalOpen(false);
        }}
      >
        <Modal.Header>{t("generic.tags")}</Modal.Header>
        <Modal.Content>
          <p>
            {t("tags.select")}
            {isChallenge ? t("generic.challenge") : `${t("generic.idea")}`}.
          </p>
          <TagChooserContent
            tags={tags}
            allTags={allTags}
            removeTag={removeTag}
            addTag={addTag}
            showCount={showCount}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              onStateChange && onStateChange(false);
              setModalOpen(false);
            }}
          >
            {t("generic.done")}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default TagModalChooser;
