import React, { Component } from "react";
import { Card, Icon, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import styled, { withTheme } from "styled-components";
import util from "utils/utils";
import moment from "moment";

import { UserChip, ProfileChip, AnonymousChip } from "components/lib/Chips";
import { ShadowedCard } from "components/lib/UI";

const ChallengeTime = styled.span`
  display: block;
  color: gray;
  margin: 5px 0;
`;

class DiscoverChallengeCard extends Component {
  render() {
    const { challenge, theme, t } = this.props;
    return (
      <ShadowedCard>
        <Link to={util.challengeLink(challenge)}>
          <div
            style={{
              width: "100%",
              height: "75px",
              overflow: "hidden",
              backgroundImage: `url(${util.mixinCssUrlFallback(util.getImageUrl(challenge, ["bannerImage", "coverImage"], ["640x100", "300x300"]), util.challengeImage())})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
            }}
          >
            {challenge.stage === "published" && (
              <Label
                size="small"
                color="green"
                style={{
                  marginTop: 7,
                  paddingLeft: 10,
                  marginLeft: -14,
                }}
                ribbon="right"
              >
                Open
              </Label>
            )}
            {!challenge.stage && (
              <Label size="small" style={{ marginTop: 7, marginLeft: -14 }} color="yellow" ribbon="right">
                Draft
              </Label>
            )}
            {challenge.stage === "closed" && (
              <Label
                size="small"
                color="red"
                style={{
                  marginTop: 7,
                  paddingLeft: 10,
                  marginLeft: -14,
                }}
                ribbon="right"
              >
                Closed
              </Label>
            )}
          </div>
        </Link>
        <Card.Content style={{ position: "relative" }}>
          {challenge?.coverImageUrl && challenge.bannerImageUrl ? (
            <>
              <div
                style={{
                  width: 80,
                  height: 80,
                  overflow: "hidden",
                  position: "absolute",
                  marginTop: -80,
                  boxShadow: "rgb(0 0 0 / 30%) 0.5px 2px 10px 4px",
                  border: "2px solid white",
                  backgroundImage: `url(${util.mixinCssUrlFallback(util.challengeImage(challenge), util.challengeImage())})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  borderRadius: 7,
                }}
              />
              <h3 style={{ marginBottom: challenge.tagline ? 5 : null, marginTop: 5 }}>
                <Link to={util.challengeLink(challenge)} style={{ color: "#4d4949" }}>
                  {challenge.name}
                </Link>
              </h3>
            </>
          ) : (
            <div className="ui header" style={{ marginBottom: 5 }}>
              <Link to={util.challengeLink(challenge)} style={{ color: "#000" }}>
                {challenge.name}
              </Link>
            </div>
          )}

          {challenge.tagline && (
            <div>
              <span style={{ color: "gray" }}>{challenge.tagline}</span>
            </div>
          )}
          {challenge.updatedAt && !challenge.publishedAt ? (
            <ChallengeTime>Updated on {moment(challenge.updatedAt).format("DD/MM/YY")}</ChallengeTime>
          ) : null}
          {challenge.publishedAt ? (
            <ChallengeTime>Opened on {moment(challenge.publishedAt).format("DD/MM/YY")}</ChallengeTime>
          ) : null}
          <div style={{ minHeight: 30 }} />
          <div style={{ position: "absolute", bottom: 10 }}>
            {challenge.anonymous && <AnonymousChip />}
            {!challenge.anonymous && challenge.postedBy && challenge.profile ? (
              <ProfileChip profile={challenge.postedBy} />
            ) : (
              <UserChip precedingText={"Posted by"} user={challenge.postedBy} />
            )}
          </div>
        </Card.Content>
        <div
          className="content"
          style={{
            backgroundColor: theme.primaryColour,
            textAlign: "center",
            fontWeight: 600,
            maxHeight: 50,
          }}
        >
          <Link to={util.challengeLink(challenge)} style={{ color: "#fff", textTransform: "uppercase" }}>
            {t("generic.viewType", { entity: t("generic.challenge") })}{" "}
            <Icon name="arrow alternate circle right outline" />
          </Link>
        </div>
      </ShadowedCard>
    );
  }
}

export default withTranslation()(withTheme(DiscoverChallengeCard));
