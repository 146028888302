import React, { useMemo } from "react";
import styled from "styled-components";
import { usePositioner } from "@remirror/react";

import { Image, Loader } from "semantic-ui-react";
import { useMultishift, Type } from "multishift";
import useCustomMention from "./useMention";

const Wrapper = styled.div<{ $isActive: boolean; $left: number; $top: number }>`
  position: absolute;
  z-index: 999;
  min-width: 300px;
  background: #fff;
  display: ${({ $isActive }) => ($isActive ? "block" : "none")};
  border: 1px solid ${({ theme }) => theme.primaryColour};
  border-radius: 4px;
  left: ${({ $left }) => $left}px;
  top: ${({ $top }) => $top}px;
  overflow: hidden;
  box-shadow:
    0 4px 8px rgba(12, 43, 65, 0.25),
    0 0 1px rgba(12, 43, 65, 0.31);
`;

const DropdownContainer = styled.div`
  max-height: 250px;
  max-width: 400px;
  overflow-y: auto;

  position: relative;
`;

const BaseListItem = styled.div`
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
`;

const EmptyListItem = styled(BaseListItem)`
  flex-direction: column;
`;

const ListItem = styled(BaseListItem)<{ $isHighlighted: boolean }>`
  cursor: pointer;
  background-color: ${({ $isHighlighted }) => ($isHighlighted ? "rgba(0, 0, 0, 0.1)" : "#fff")};
  &:hover {
    background-color: ${({ $isHighlighted }) => ($isHighlighted ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.05)")};
  }
`;

const Avatar = styled(Image)`
  object-fit: cover;
  &&& {
    margin-right: 10px;
  }
`;

const Emoji = styled.div`
  font-size: 28px;
  align-self: center;
  margin-right: 10px;
`;

const Label = styled.div`
  font-size: 15px;
  font-weight: bold;
`;

const Description = styled.div`
  color: #777;
`;

const Loading = styled(Loader)`
  &&,
  &:before,
  &:after {
    left: auto;
    top: 20px;
    right: 5px;
  }
`;

type DropdownProps = ReturnType<typeof useCustomMention>;

const Dropdown = ({ suggestions, loading, isActive, activeIndex, createMention }: DropdownProps) => {
  const posState = usePositioner("nearestWord", isActive);
  const { ref, x: left, y: bottom } = posState;

  const { getMenuProps, getItemProps, itemHighlightedAtIndex } = useMultishift({
    highlightedIndexes: [activeIndex],
    type: Type.ControlledMenu,
    items: suggestions,
    isOpen: isActive,
  });

  const menuProps = useMemo(() => getMenuProps({ ref }), [ref, getMenuProps]);

  return (
    <Wrapper {...menuProps} $top={(bottom ?? 0) + 20} $left={left} $isActive={isActive} style={{ minHeight: 40 }}>
      <Loading size="small" active={loading} />
      {isActive ? (
        <DropdownContainer>
          {suggestions.length > 0 || loading ? (
            suggestions.map((mention, index) => {
              const isHighlighted = itemHighlightedAtIndex(index);

              return (
                <ListItem
                  key={mention.id ?? mention.emoji ?? mention.label}
                  $isHighlighted={isHighlighted}
                  {...getItemProps({
                    item: mention,
                    index,
                    onMouseDown: (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    },
                    onClick: (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      createMention(index);
                    },
                  })}
                >
                  {mention.image ? <Avatar avatar src={mention.image} /> : null}
                  {mention.emoji ? <Emoji>{mention.emoji}</Emoji> : null}
                  <div>
                    <Label>{mention.label || ""}</Label>
                    <Description>{mention.description || ""}</Description>
                  </div>
                </ListItem>
              );
            })
          ) : (
            <EmptyListItem>
              <Label>Sorry, no results found</Label>
              <Description>Start typing or adjust your query.</Description>
            </EmptyListItem>
          )}
        </DropdownContainer>
      ) : null}
    </Wrapper>
  );
};

export default Dropdown;
