import { useAppSelector } from "store";
import api from "api";
import { Menu, Popup } from "semantic-ui-react";
import util from "utils/utils";
import { OpenAPI } from "simplydo/interfaces";
import { cloneElement, useCallback, useMemo } from "react";
import { useTrayStatus } from "./Notifications";

export type PDFExportBaseProps = {
  trigger: React.ReactElement<{
    loading: boolean;
    onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>, ...args: any[]) => void;
  }>;
  popupOffset?: [number, number];
};

type IdeaPdfExportProps = PDFExportBaseProps & {
  ideas: OpenAPI.Schemas["Idea"][];
  challenge?: OpenAPI.Schemas["Challenge"];
};

const IdeaPdfExport = (props: IdeaPdfExportProps) => {
  const user = useAppSelector((state) => state.user);
  const { ideas = [], trigger, popupOffset = [0, 0], challenge } = props;

  const [_, setTrayStatus] = useTrayStatus();

  const ideaIds = ideas.map((idea) => idea._id);

  const hasCreditsafeReport = useMemo(() => {
    if (challenge) {
      if (util.canManageChallenge(user, challenge) || util.hasPermission(user, "challenge.viewIdeas", challenge?._id)) {
        const ideaTemplate: OpenAPI.Schemas["IdeaTemplate"] =
          (challenge as any).ideaTemplateData ||
          (challenge as any).ownerIdeaTemplate ||
          (ideas.find((idea) => (idea as any).ideaTemplate) as any)?.ideaTemplate ||
          (ideas.find((idea) => (idea as any).ownerChallenge?.ownerIdeaTemplate) as any)?.ownerChallenge
            ?.ownerIdeaTemplate ||
          challenge.ideaTemplate;
        return ideaTemplate?.body?.find((section) =>
          section?.fields?.find((field) => field.type === "creditsafereport"),
        );
      }
    }
  }, [challenge, ideas, user]);

  const generateReport = useCallback(
    (appendCreditsafeReport) => {
      const challengeName = challenge?.name || "Challenge";
      api.users.createDownload(
        user._id,
        {
          downloadType: "ideaPdfExport",
          displayName: `${challengeName} idea export.pdf`,
          ideas: ideaIds,
          challenge: challenge?._id,
          appendCreditsafeReport,
        },
        (download) => {
          api.queryClient.setQueryData(["get", "users", "downloads"], (prev: any) => ({
            ...prev,
            pendingDownloads: (prev?.pendingDownloads || []).concat([download]),
          }));
          setTrayStatus({ open: true, activeIndex: 1 });
        },
        () => {},
      );
    },
    [ideaIds, challenge, user, setTrayStatus],
  );

  if (hasCreditsafeReport) {
    return (
      <Popup
        on="click"
        hoverable
        position="bottom center"
        trigger={cloneElement(trigger, {
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
        })}
        offset={popupOffset}
        content={
          <Menu secondary vertical>
            <Menu.Item header content="Export as..." />
            <Menu.Item onClick={() => {}} onMouseUp={() => generateReport(false)}>
              Regular PDF
            </Menu.Item>
            <Menu.Item key={"creditsafe"} onClick={() => {}} onMouseUp={() => generateReport(true)}>
              With creditsafe Report
            </Menu.Item>
          </Menu>
        }
      />
    );
  }

  return cloneElement(trigger, {
    onClick: () => generateReport(false),
  });
};

export default IdeaPdfExport;
