import { OpenAPI } from "simplydo/interfaces";

export const pages = (api) => ({
  getAll(forType, forId, success: (data: OpenAPI.GET<"/pages/{for_type}/{for_id}">["response"]) => void, fail) {
    api.authenticatedRequest("GET", `/pages/${forType}/${forId}`, null, success, fail);
  },
  getPinned(
    forType,
    forId,
    success: (data: OpenAPI.GET<"/pages/pinned/{for_type}/{for_id}">["response"]) => void,
    fail,
  ) {
    api.authenticatedRequest("GET", `/pages/pinned/${forType}/${forId}`, null, success, fail);
  },
  create(forType, forId, success, fail) {
    api.authenticatedRequest("POST", `/pages/${forType}/${forId}`, null, success, fail);
  },
  get(pageId, success, fail) {
    api.maybeAuthenticatedRequest("GET", `/pages/${pageId}`, null, success, fail);
  },
  edit(pageId, data, success, fail) {
    api.authenticatedRequest("PUT", `/pages/${pageId}`, data, success, fail);
  },
  delete(pageId, success, fail) {
    api.authenticatedRequest("DELETE", `/pages/${pageId}`, null, success, fail);
  },
});
