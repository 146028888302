import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dropdown, Divider, Icon, Image, Placeholder, Button } from "semantic-ui-react";
import IdeaBusinessProfileModal from "components/lib/IdeaBusinessProfileModal/IdeaBusinessProfileModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import api, { asMutation, asQuery } from "api";

import RowComponent from "components/lib/RowComponent";

import util from "utils/utils";
import actions from "actions";
import { useUserReload } from "utils/useUserReload";

const IconImage = ({ url, size = 30 }) => (
  <div
    style={{
      width: size,
      height: size,
      borderRadius: "50%",
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundImage: `url(${url})`,
      marginRight: 8,
    }}
  />
);

function HomeUser({ user, onSwitchOrganisation }) {
  const [selectedBusinessProfile, setSelectedBusinessProfile] = useState(null);
  const reloadUser = useUserReload();
  const switchOrganisation = (orgId) => {
    if (orgId === user.ownerOrganisation._id) {
      return;
    }
    api.users.switchOrganisation(
      user._id,
      orgId,
      (data) => {
        onSwitchOrganisation(data.organisation);
      },
      () => {},
    );
  };

  const profileQuery = useQuery({
    queryKey: ["get", "users", "profiles"],
    queryFn: asQuery(api.users.getProfiles),
  });

  const joinProfileMutation = useMutation({
    mutationKey: ["post", "users", "profiles", "join"],
    mutationFn: asMutation(api.users.joinProfile),
    onSuccess: () => {
      toast.success("Profile joined");
    },
    onError: () => {
      toast.error("Failed to join profile");
    },
  });

  const joinProfile = useCallback(
    (profileId) => {
      joinProfileMutation.mutate(
        {
          params: [profileId],
        },
        {
          onSuccess: () => {
            // Invalidate the profiles query to refetch the updated list of profiles
            reloadUser();
            api.queryClient.invalidateQueries({ queryKey: ["get", "users", "profiles"] });
          },
        },
      );
    },
    [joinProfileMutation, reloadUser],
  );

  return (
    <>
      <RowComponent>
        {selectedBusinessProfile && (
          <IdeaBusinessProfileModal
            businessProfile={selectedBusinessProfile}
            onClose={() => setSelectedBusinessProfile(null)}
          />
        )}
        <div style={{ width: "100%" }}>
          <Dropdown
            style={{
              flexDirection: "row",
              display: "flex",
              fontWeight: "bold",
              alignItems: "center",
            }}
            size="tiny"
            basic
            direction="right"
            trigger={
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <IconImage url={util.mixinCssUrlFallback(util.avatarUrl(user), util.avatarUrl())} />
                {`${user?.profile?.firstName} ${user?.profile?.lastName}`}
              </div>
            }
          >
            <Dropdown.Menu style={{ minWidth: "20vw" }}>
              {user.availableOrganisations && user.availableOrganisations.length > 1 ? (
                <>
                  <Dropdown.Header content="Switch organisation context" style={{ margin: "9px 0 8px" }} />
                  {user.availableOrganisations.map((o) => (
                    <React.Fragment key={o._id}>
                      <Divider style={{ margin: 0, borderBottomWidth: 0 }} />
                      <Dropdown.Item style={{ padding: "5px !important" }} onClick={() => switchOrganisation(o._id)}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <Icon
                            name={o._id === user.ownerOrganisation._id ? "check" : null}
                            style={{ marginRight: 8 }}
                          />
                          <IconImage size={20} url={o.darkLogoUrl} />
                          {o.name}
                        </div>
                      </Dropdown.Item>
                    </React.Fragment>
                  ))}
                  <Divider style={{ margin: 0, borderBottomWidth: 0 }} />
                  <Dropdown.Item as={Link} to="/preferences">
                    <Icon name="building" style={{ marginRight: 8 }} />
                    Manage organisations
                  </Dropdown.Item>
                  <Divider style={{ margin: 0, borderBottomWidth: 0 }} />
                </>
              ) : null}
              <Dropdown.Item as={Link} to={`/users/${user._id}`}>
                <Icon name="user" style={{ marginRight: 8 }} />
                View profile
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </RowComponent>
      {profileQuery.isLoading ? (
        <RowComponent>
          <RowComponent.Fill style={{ display: "block" }}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line length="medium" />
              </Placeholder.Header>
              <Placeholder.Header image>
                <Placeholder.Line length="full" />
                <Placeholder.Line length="medium" />
              </Placeholder.Header>
            </Placeholder>
          </RowComponent.Fill>
        </RowComponent>
      ) : (
        <>
          {profileQuery.data?.joinableProfiles?.length || profileQuery.data?.profiles?.length ? (
            <RowComponent>
              {profileQuery.data?.joinableProfiles?.length > 0 ? (
                <>
                  <RowComponent.Fill>
                    <RowComponent.Title>Joinable organisations</RowComponent.Title>
                  </RowComponent.Fill>
                  {profileQuery.data?.joinableProfiles.map((bp) => (
                    <RowComponent.Cell
                      key={bp._id}
                      as={Link}
                      onClick={() => {
                        setSelectedBusinessProfile(bp);
                      }}
                      style={{
                        padding: "5px 5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <RowComponent.Cell style={{ padding: 0 }}>
                        {bp?.imageUrl ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 4,
                              overflow: "hidden",
                              width: 28,
                              height: 28,
                            }}
                          >
                            <Image src={bp.imageUrl} style={{ objectFit: "contain", objectPosition: "center" }} />
                          </div>
                        ) : (
                          <Icon name="building" size="large" />
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: 5,
                            marginRight: 5,
                            position: "relative",
                            top: -1,
                          }}
                        >
                          <RowComponent.Title style={{ color: "#5d5d5d" }} block>
                            {bp?.name}
                          </RowComponent.Title>
                          <RowComponent.StatusArea>{bp?.websiteUrl || "New profile"}</RowComponent.StatusArea>
                        </div>
                      </RowComponent.Cell>
                      <Button onClick={() => joinProfile(bp._id)} basic>
                        Join
                      </Button>
                    </RowComponent.Cell>
                  ))}
                  <div style={{ marginBottom: 20 }}></div>
                </>
              ) : null}
              {profileQuery.data?.profiles?.length > 0 ? (
                <>
                  <RowComponent.Fill>
                    <RowComponent.Title>Your organisations</RowComponent.Title>
                  </RowComponent.Fill>
                  <>
                    {profileQuery.data?.profiles.map((bp) => (
                      <RowComponent.Cell
                        key={bp._id}
                        as={Link}
                        onClick={() => setSelectedBusinessProfile(bp)}
                        style={{ padding: "5px 5px" }}
                      >
                        {bp?.imageUrl ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 4,
                              overflow: "hidden",
                              width: 28,
                              height: 28,
                            }}
                          >
                            <Image src={bp.imageUrl} style={{ objectFit: "contain", objectPosition: "center" }} />
                          </div>
                        ) : (
                          <Icon name="building" size="large" />
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: 5,
                            marginRight: 5,
                            position: "relative",
                            top: -1,
                          }}
                        >
                          <RowComponent.Title style={{ color: "#5d5d5d" }} block>
                            {bp?.name}
                          </RowComponent.Title>
                          <RowComponent.StatusArea>{bp?.websiteUrl || "New profile"}</RowComponent.StatusArea>
                        </div>
                      </RowComponent.Cell>
                    ))}
                  </>
                </>
              ) : null}
            </RowComponent>
          ) : null}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  onSwitchOrganisation: (org) => dispatch(actions.user.switchOrganisation(org)),
});

const HomeUserContainer = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HomeUser));

export default HomeUserContainer;
