import { useCallback } from "react";
import moment from "moment";

import { eventTypes } from "components/lib/ProjectBoard/BoardEvents";
import { OpenAPI } from "simplydo/interfaces";
import { styled } from "styled-components";
import { useQuery } from "@tanstack/react-query";
import api, { asQuery } from "api";
import { useAppSelector } from "store";
import util from "utils/utils";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const EventItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon-container {
    .i {
      font-size: 0.9em;
      line-height: 1.1em;
    }
    margin-right: 10px;
  }
  .info-container {
    > span {
      display: block;
    }
    .meta {
      color: gray;
    }
  }
  margin-bottom: 10px;
`;

type ManagementActivityProps = {
  idea: OpenAPI.GET<"/ideas/{id}">["response"];
  userIsAssigned?: boolean;
};

const ActivityManagement = ({ idea, userIsAssigned }: ManagementActivityProps) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const canManage = util.canManageChallenge(user, idea?.ownerChallenge);

  const eventQuery = useQuery({
    queryKey: ["boards", idea?.challenge, "ideas", idea?._id, "events"],
    queryFn: asQuery(api.boards.getProjectIdeaEvents, {
      params: [idea?.challenge, idea?._id],
    }),
    enabled: userIsAssigned || canManage || util.hasPermission(user, "challenge.viewProjectActivity", idea?.challenge),
  });

  const getLaneName = useCallback(
    (event, laneKey = "previousLane") => {
      // If previousLane is empty string/null, we know it was moved from default lane
      if (!event[laneKey]) {
        return idea?.ownerChallenge?.projectBoard?.defaultLane?.name ?? "Default";
      }
      if (event[`${laneKey}Object`]) {
        return event[`${laneKey}Object`].name;
      }
      return "an unknown lane";
      // Else if we know the previous lane object, we can return that
    },
    [idea?.ownerChallenge],
  );

  return (
    <>
      {eventQuery.data?.events?.map((e) =>
        eventTypes[e.action] ? (
          <EventItem key={e._id}>
            <div className="icon-container">
              <Icon name={eventTypes[e.action].icon} />
            </div>
            <div className="info-container">
              <span>
                <b>{e.userObject ? `${e.userObject.profile.fullName}` : "Unknown user"}</b>
                {e.action === "addedToBoard" && ` added the ${t("generic.idea")} to the board`}
                {e.action === "removedFromBoard" && ` removed the ${t("generic.idea")} from the board`}
                {e.action === "assignedUser" && (
                  <>
                    {e.userObject ? " assigned " : " auto-assigned "}
                    <b>{e.targetUserObject ? `${e.targetUserObject.profile.fullName}` : "unknown user"}</b>
                  </>
                )}
                {e.action === "assignedExternalUser" && (
                  <>
                    {" invited "}
                    <b>{e.email}</b>
                  </>
                )}
                {e.action === "unassignedUser" && (
                  <>
                    {" un-assigned "}
                    <b>{e.targetUserObject ? `${e.targetUserObject.profile.fullName}` : "unknown user"}</b>
                  </>
                )}
                {e.action === "changedLane" && (
                  <>
                    {" moved from "}
                    <b>{getLaneName(e)}</b>
                    {" to "}
                    <b>{getLaneName(e, "targetLane")}</b>
                  </>
                )}
                {e.action === "changedDeadline" && (
                  <>
                    {" changed the deadline to "}
                    <b>{e.deadline ? moment(e.deadline).format("DD/MM/YYYY") : "No deadline"}</b>
                  </>
                )}
                {e.action === "changedReviewDate" && (
                  <>
                    {" changed the review date to "}
                    <b>{e.reviewDate ? moment(e.reviewDate).format("DD/MM/YYYY") : "No review date"}</b>
                  </>
                )}
                {e.action === "createComment" && (
                  <>
                    {" added a project note. Note: "}
                    <b>"{e?.comment?.length > 50 ? e?.comment.substring(0, 50) + "..." : e?.comment}"</b>
                  </>
                )}
              </span>
              <span className="meta">{moment(e.createdAt).fromNow()}</span>
            </div>
          </EventItem>
        ) : null,
      )}
    </>
  );
};

export default ActivityManagement;
