import React from "react";
import handleViewport from "react-in-viewport";
import styled from "styled-components";
import { Segment, Message, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import util from "utils/utils";

const StyledSegment = styled(Segment)`
  ${({ $isAssessment }) =>
    $isAssessment
      ? `
    border: none !important;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${util.adjustColour("#6435c9", -0.08)} !important;
    h3 {
      margin: 0;
      padding: 0;
    }
  `
      : ""}
  ${({ $isPreview }) =>
    $isPreview
      ? `
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  `
      : ""}
`;

function SectionHeader(props) {
  const { section, t, isPreview, assessment } = props;

  return (
    <StyledSegment attached="top" $isPreview={isPreview} $isAssessment={section.type === "assessment"}>
      <h3>{section.name}</h3>
      {section.type === "assessment" ? (
        <Label
          active
          color={
            section.fields.filter(
              (field) =>
                assessment?.assessment?.[field.id]?.score !== undefined &&
                assessment.assessment?.[field.id]?.score !== "",
            ).length === section.fields.filter((field) => field.type === "assessment").length
              ? "green"
              : undefined
          }
        >
          Assessed{" "}
          {
            section.fields.filter(
              (field) =>
                assessment?.assessment?.[field.id]?.score !== undefined &&
                assessment.assessment?.[field.id]?.score !== "",
            ).length
          }{" "}
          / {section.fields.filter((field) => field.type === "assessment").length}
        </Label>
      ) : null}
      {section.type === "edi" && (
        <Message success>
          <Message.Header>{t("ideas.edi.header")}</Message.Header>
          <Message.Content>{t("ideas.edi.info")}</Message.Content>
        </Message>
      )}
    </StyledSegment>
  );
}

export default withTranslation()(handleViewport(SectionHeader, { threshold: 1 }));
