import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import util from "utils/utils";
import { useTranslation } from "react-i18next";

const IdeaHelmet = ({ user, idea }) => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{idea ? idea.name : t("common:capitalise", { key: "generic.ideas" })}</title>
      <meta property="og:title" content={idea.name} />
      <meta property="og:description" content={`by ${idea.owner?.profile.fullName}`} />
      <meta property="og:image" content={util.ideaCoverImage(idea)} />
      <meta property="og:url" content={`${util.host(user)}/ideas/${idea._id}`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@_simplydo"></meta>
      <meta name="twitter:url" content={`${util.host(user)}/ideas/${idea._id}`} />
      <meta name="twitter:title" content={idea.name} />
      <meta name="twitter:description" content={`by ${idea.owner?.profile.fullName}`} />
      <meta name="twitter:image" content={util.ideaCoverImage(idea)} />
    </Helmet>
  );
};

export default connect((state) => ({ user: state.user }))(IdeaHelmet);
