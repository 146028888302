import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import NotificationTray from "components/lib/Notifications";

const NotificationsContainer = styled.div`
  background-color: #fff;
  padding: 1rem;
`;

const MobileNotificationsWidget = ({ user }) => {
  if (!user) return null;
  return (
    <NotificationsContainer>
      <NotificationTray isMobile />
    </NotificationsContainer>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(MobileNotificationsWidget);
