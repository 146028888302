import React from "react";
import { Form, TextArea } from "semantic-ui-react";
import { Slider as CustomSlider } from "components/lib/UI";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

const SliderContainer = styled.div`
  text-align: center;
  > h2 {
    font-size: 50px;
    margin: 20px auto;
  }
  .slider-notes-container {
    margin-top: 35px;
    > p {
      text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 4px;
    }
  }
`;

const hasSliderValue = (sliderValue) => !(sliderValue === null || sliderValue === undefined);

const Slider = ({ field, fieldValue = {}, updateIdea, isEditing, t }) => {
  const { value, notes = "" } = fieldValue;
  const { minslider, maxslider, hideTextbox } = field;

  // When using a custom slider limit, leaving minslider empty means the user expects the min value to be 0
  // Therefore, if maxslider is a truthy value, but minslider is not, we know the user is using custom limits and set the lower bound to 0
  // If BOTH minslider and maxslider are falsey, we know the user is not using custmo limits. In that case, the lower bound is 1
  // eslint-disable-next-line no-nested-ternary
  const min = hasSliderValue(minslider) ? minslider : hasSliderValue(maxslider) ? 0 : 1;
  const max = hasSliderValue(maxslider) ? maxslider : 10;

  return (
    <SliderContainer>
      <h2
        style={{
          color: value === undefined ? "#888" : "black",
        }}
      >
        {value ?? "-"}
      </h2>
      {isEditing ? (
        <div>
          <CustomSlider
            value={value}
            min={min}
            max={max}
            step={1}
            onChange={(sliderValue) => updateIdea(sliderValue, ["templated", field.id, "value"])}
            style={{ marginBottom: 20 }}
          />
          {!hideTextbox ? (
            <div className="slider-notes-container">
              <p>{t("ideas.actions.explain.title")}</p>
              <Form>
                <TextArea
                  value={notes}
                  onChange={(e, { value: notesValue }) => updateIdea(notesValue, ["templated", field.id, "notes"])}
                />
              </Form>
            </div>
          ) : null}
        </div>
      ) : null}
      {!isEditing && notes ? (
        <div>
          <p>{notes}</p>
        </div>
      ) : null}
    </SliderContainer>
  );
};

export default withTranslation()(Slider);
