import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Loader, Button, Modal, ModalProps, Input, Icon } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RoleTable, { IRolePermissionConstant } from "components/lib/Roles/RoleTable";

type IRole = OpenAPI.Schemas["Role"] & OpenAPI.Schemas["roleInformation"];

type RoleAssignerProps = {
  forType: "organisation" | "group" | "global" | "ideaBusinessProfile";
  forId: string;
  permissionOptions: IRolePermissionConstant[];
  forUsers: string[];
  modalProps?: ModalProps;
  onAssign?: (userIds: string[], role: IRole) => void;
  onUnassign?: (userIds: string[], role: IRole) => void;
};

const RoleAssigner = ({
  forType,
  forId,
  forUsers,
  permissionOptions,
  modalProps = {},
  onAssign,
  onUnassign,
}: RoleAssignerProps) => {
  const { t } = useTranslation();

  const [roles, setRoles] = useState<IRole[]>([]);
  const [userRoles, setUserRoles] = useState<IRole[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [assigningRole, setAssigningRole] = useState<string>("");
  const usingForId = forType === "global" ? "global" : forId;

  const [roleNameSearch, setRoleNameSearch] = useState("");
  const [rolePermissionSearch, setRolePermissionSearch] = useState("");
  const navigate = useNavigate();

  const filteredRoles = useMemo(() => {
    const nameSearch = roleNameSearch.toLowerCase();
    const permissionSearch = rolePermissionSearch.toLowerCase();

    if (!nameSearch && !permissionSearch) {
      return roles;
    }

    return roles.filter((role) => {
      const nameMatch = role.name.toLowerCase().includes(nameSearch);
      if (nameSearch && !nameMatch) {
        return false;
      }
      if (!permissionSearch) {
        return true;
      }
      for (const permission of role.permissions) {
        if (permission.toLowerCase().includes(permissionSearch)) {
          return nameMatch;
        }

        const option = permissionOptions.find((perm) => perm.key === permission);
        if (!option) {
          return false;
        }
        if (t(option.title).toLowerCase().includes(permissionSearch)) {
          return nameMatch;
        }
      }

      return false;
    });
  }, [roleNameSearch, permissionOptions, rolePermissionSearch, roles, t]);

  const usingSingleUser = forUsers.length === 1;

  const getUserRoles = useCallback(() => {
    if (!forUsers.length) return;
    setLoading(true);
    /*
      If assigning to a single user, we can get their current roles and be able to add/remove
      For multiple users we don't know what roles they currently have - so roles can only be added
    */
    if (usingSingleUser) {
      api.roles.getForUser(
        forType,
        usingForId,
        forUsers[0],
        (data) => {
          setRoles(data.allRoles);
          setUserRoles(data.roles);
          setLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setLoading(false);
        },
      );
    } else {
      api.roles.get(
        forType,
        usingForId,
        {},
        (data) => {
          setRoles(data.roles);
          setLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setLoading(false);
        },
      );
    }
  }, [forUsers, usingSingleUser, forType, usingForId]);

  const assignRoleToUser = useCallback(
    (roleId: string) => {
      if (!forUsers.length) return;
      setAssigningRole(roleId);
      const usingRole = roles.find((role) => role._id === roleId);
      api.roles.assignRole(
        forType,
        usingForId,
        roleId,
        forUsers,
        () => {
          setAssigningRole("");
          usingRole.userCount += 1;
          setUserRoles((prevRoles) => [...prevRoles, usingRole]);
          toast.success(`Role ${roles.find((role) => role._id === roleId)?.name} assigned to user`);
          onAssign?.(forUsers, usingRole);
        },
        (err) => {
          toast.error(err.message);
          setAssigningRole("");
        },
      );
    },
    [forType, forUsers, roles, usingForId, onAssign],
  );

  const unassignRoleFromUser = useCallback(
    (roleId: string) => {
      if (!forUsers.length) return;
      setAssigningRole(roleId);
      const usingRole = roles.find((role) => role._id === roleId);
      api.roles.unassignRole(
        forType,
        usingForId,
        roleId,
        forUsers,
        () => {
          setAssigningRole("");
          usingRole.userCount -= 1;
          setUserRoles((prevRoles) => prevRoles.filter((role) => role._id !== roleId));
          toast.success(`Role ${usingRole?.name} unassigned from user`);
          onUnassign?.(forUsers, usingRole);
        },
        (err) => {
          toast.error(err.message);
          setAssigningRole("");
        },
      );
    },
    [forType, forUsers, roles, usingForId, onUnassign],
  );

  useEffect(() => {
    getUserRoles();
  }, [getUserRoles]);

  const openRolesSettings = useMemo(() => {
    if (forType === "organisation") {
      return () =>
        navigate({
          search: `?tab=${1}`,
        });
    }
  }, [navigate, forType]);

  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} {...modalProps} open={!!forUsers.length}>
      <Modal.Header>Assign roles to user</Modal.Header>
      <Modal.Content scrolling style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", gap: 10, marginBottom: 10 }}>
          <Input
            icon="search"
            placeholder="Search by name..."
            value={roleNameSearch}
            onChange={(e) => setRoleNameSearch(e.target.value)}
          />
          <Input
            icon="search"
            placeholder="Search by permission..."
            value={rolePermissionSearch}
            onChange={(e) => setRolePermissionSearch(e.target.value)}
          />
        </div>

        {loading ? (
          <Loader active />
        ) : (
          <RoleTable
            roles={filteredRoles}
            permissionOptions={permissionOptions}
            getRoleActions={(role) => {
              const userHasRole = usingSingleUser
                ? userRoles.map((userRole) => userRole._id).includes(role._id)
                : false;
              if (role.isDefaultRole) {
                return null;
              }

              return (
                <Button
                  basic={userHasRole}
                  primary={!userHasRole}
                  icon={userHasRole ? "trash" : "plus"}
                  content={userHasRole ? "Remove" : "Assign"}
                  loading={assigningRole === role._id}
                  onClick={() => {
                    if (userHasRole) {
                      util
                        .confirm(
                          "Removing role",
                          `Are you sure you want to remove ${role.name} from ${util.pluralise(forUsers.length, "this user", "these users", false)}?`,
                        )
                        .then(() => {
                          unassignRoleFromUser(role._id);
                        })
                        .catch(() => {});
                    } else {
                      util
                        .confirm(
                          "Assign role",
                          `Are you sure you want to add ${util.pluralise(forUsers.length, "this user", "these users", false)} to ${role.name}?`,
                        )
                        .then(() => {
                          assignRoleToUser(role._id);
                        })
                        .catch(() => {});
                    }
                  }}
                />
              );
            }}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        {openRolesSettings ? (
          <Button basic onClick={() => openRolesSettings()}>
            <Icon name="settings" />
            Manage roles
          </Button>
        ) : null}
        <Button onClick={(e) => modalProps.onClose(e, null)}>Done</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RoleAssigner;
