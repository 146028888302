import React, { Component } from "react";
import { Message, Button, Divider } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SearchParams from "simplydo/src/searchParams";

class LoginError extends Component {
  constructor(props) {
    super(props);
    this.state = { errorMessage: "" };
  }

  componentDidMount() {
    const params = new SearchParams(window.location.search);
    this.setState({ errorMessage: params.get("message") });
  }

  render() {
    const { errorMessage } = this.state;
    const { t } = this.props;
    return (
      <div>
        <h1>{t("entry.login.title")}</h1>
        <Divider />
        <p>{t("entry.login.error.info")}</p>
        {errorMessage && <Message color="orange">{errorMessage}.</Message>}
        <Button style={{ float: "right" }} primary as={Link} to="/" content={t("generic.close")} />
      </div>
    );
  }
}

export default withTranslation()(LoginError);
